import { officePath } from '../products/ProductsPage.shared';

export const officeFileRequestPath = officePath + '/file-request';

export const getProductFileRequestUrl = (productId: string, requestType: string, modelSlug: string | undefined) => {
  const nextURL = new URLSearchParams();
  nextURL.set('productId', productId);
  nextURL.set('requestType', requestType);

  if (modelSlug) {
    nextURL.set('modelSlug', modelSlug);
  }

  return officeFileRequestPath + '?' + nextURL.toString();
};