import { useResource, useResourceLazy } from 'libs/restful';
import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import Cropper from 'react-cropper';
import { useHistory } from 'react-router';
import { Button, Col, Row, UncontrolledPopover } from 'reactstrap';
import styled from 'styled-components';

import { GlobalModalNoActions, PageLoading } from '@/_shared/components';
import {
    IWishlist,
    IWishlistMoodboardItem,
    wishlistMoodboardItemResources
} from '@/api';

import { scaleDataURL } from './wishlist-header';
import {
    WishlistMoodboardItem,
    WishlistMoodboardOrganize
} from './wishlist-moodboard';

const _WishlistMoodboard = styled.div`
  .moodboard-container {
    min-height: 50vh;
  }
  .actions {
    display: inline-flex;
    border: 1px solid #e4e5e7;
    border-radius: 4px;
    line-height: 47px;
    overflow: hidden;
    z-index: 100;
    background-color: #fff;
  }
  
  .action {
    height: 50px;
    padding: 0 20px;
    cursor: pointer;
    &:first-child {
      border-right: 1px solid #e4e5e7;
    }
  }
`;

const _AddItemButton = styled.div`
  line-height: 30px;
  width: 80px;
  cursor: pointer;
`;

const _AddImageModal = styled.div`
  width: 600px;
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: ${window.innerWidth - 50}px;
  }
`;

const _AddColorModal = styled.div`
  width: 400px;
  .color {
    border: 1px solid #e4e5e7;
    border-radius: 8px;
    overflow: hidden;
  }
  .color-show {
    height: 100px;
  }
  .color-hex {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      font-size: 20px;
      border: 1px solid #e4e5e7;
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: ${window.innerWidth - 50}px;
  }
`;

interface IWishlistMoodboardProps {
  readonly wishlist: IWishlist;
}

export const WishlistMoodboard = (props: React.PropsWithChildren<IWishlistMoodboardProps>) => {

  const history = useHistory();

  const [wishlistMoodboardItems, loading, refetch] = useResource(
    wishlistMoodboardItemResources.find,
    [
      { type: 'query', parameter: 'wishlist', value: props.wishlist.id },
      { type: 'query', parameter: '_sort', value: 'displayIndex:asc' }
    ]
  );

  const [, creating, createItem] = useResourceLazy(wishlistMoodboardItemResources.create);
  const [, updating, updateItem] = useResourceLazy(wishlistMoodboardItemResources.update);

  const [pickedColor, setPickedColor] = React.useState('#000000');

  const [showReorderModal, setShowReorderModal] = useState(false);

  const [showAddImageModal, setShowAddImageModal] = React.useState(false);
  const [showAddColorModal, setShowAddColorModal] = React.useState(false);

  const [image, setImage] = useState<string | ArrayBuffer | null>(null);
  const [cropper, setCropper] = useState<any>();

  const handleAddItem = async (item: Partial<IWishlistMoodboardItem>) => {
    const itemIndex =
      wishlistMoodboardItems?.length ?
        (wishlistMoodboardItems[wishlistMoodboardItems.length - 1]?.displayIndex ?? 0) + 1
        : 0;

    const newItem: Partial<IWishlistMoodboardItem> = {
      ...item,
      displayIndex: itemIndex,
      wishlist: props.wishlist.id,
    };

    const createdItem = await createItem([{
      type: 'body',
      value: newItem
    }]);

    refetch();

    history.replace('#' + createdItem.id);
  };

  const handleAddImage = async (cropData) => {
    if (!cropData) {
      return;
    }

    const newItem: Partial<IWishlistMoodboardItem> = {
      type: 'image',
      image: cropData!,
    };

    await handleAddItem(newItem);
    setShowAddImageModal(false);
  };

  const handleAddText = () => {
    const newItem: Partial<IWishlistMoodboardItem> = {
      type: 'text',
    };
    handleAddItem(newItem);
  };

  const handleAddColor = async () => {
    const newItem: Partial<IWishlistMoodboardItem> = {
      type: 'color',
      color: pickedColor,
    };
    await handleAddItem(newItem);
    setShowAddColorModal(false);
  };

  const handleUpdateItem = async (item: IWishlistMoodboardItem) => {
    await updateItem([
      {
        type: 'path',
        parameter: 'id',
        value: item.id
      },
      {
        type: 'body',
        value: item as any
      }
    ]);

    refetch();
  };

  const onItemDelete = async (item: IWishlistMoodboardItem) => {
    refetch();
  };

  const onImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      const originBase64 = cropper.getCroppedCanvas().toDataURL('image/jpeg');
      const cropFile = await scaleDataURL(originBase64, 512, 512);
      handleAddImage(cropFile);
    }
  };

  return (
    <_WishlistMoodboard>
      <div className="moodboard-container">
        {
          (!wishlistMoodboardItems?.length && loading) && (
            <PageLoading />
          )
        }
        {(!wishlistMoodboardItems?.length && !loading) && (
          <div className="text-center">
            <strong>No Content</strong>
            <br />
            <br />
            <i>Select "Add" bellow to start create a project moodboard</i>
          </div>
        )}
        {wishlistMoodboardItems?.map((item, index) => {
          return (<WishlistMoodboardItem key={item.id} wishlistMoodboardItem={item} onUpdate={handleUpdateItem} onDelete={onItemDelete} />);
        })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', margin: 50 }}>
        <div className="actions">
          <div className="action" id="addItem">
            Add
          </div>
          <div
            className="action"
            onClick={() => {
              if (!wishlistMoodboardItems?.length) {
                return;
              }
              setShowReorderModal(true);
            }}
          >
            Organize
          </div>
        </div>
      </div>
      <UncontrolledPopover placement="top" offset={1} target="addItem" trigger="hover">
        <div>
          <_AddItemButton onClick={() => setShowAddImageModal(true)}>
            Add Image
          </_AddItemButton>
          <_AddItemButton onClick={() => setShowAddColorModal(true)}>
            Add Color
          </_AddItemButton>
          <_AddItemButton onClick={handleAddText}>
            Add Text
          </_AddItemButton>
        </div>
      </UncontrolledPopover>
      <GlobalModalNoActions isOpen={showAddImageModal} onClosed={() => setShowAddImageModal(false)}>
        <_AddImageModal>
          <div className="thumbnail">
            {
              (image) && (
                <div>
                  <Cropper
                    style={{ height: 400, width: '100%' }}
                    zoomTo={0}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image as string}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      setCropper(instance);
                    }}
                    guides={true}
                  />
                  <div className="actions">
                    <Button type="button" color="secondary" size="sm" onClick={() => setImage(null)}>
                      Cancel
                    </Button>
                    <Button type="button" color="black" size="sm" onClick={() => getCropData()} disabled={creating}>
                      Save image
                    </Button>
                  </div>
                </div>
              )
            }
            {
              (!image) && (
                <input type="file" onChange={onImageChange} accept="image/*" />
              )
            }
          </div>
        </_AddImageModal>
      </GlobalModalNoActions>
      <GlobalModalNoActions isOpen={showAddColorModal} onClosed={() => setShowAddColorModal(false)}>
        <_AddColorModal>
          <HexColorPicker onChange={(e) => setPickedColor(e)} style={{ width: '100%', marginBottom: '24px' }} />
          <div className="color">
            <div className="color-show" style={{ backgroundColor: pickedColor }}>
            </div>
            <div className="color-hex">
              <input type="text" value={pickedColor} onChange={(e) => setPickedColor(e.target.value)} />
            </div>
          </div>
          <div className="actions">
            <Button type="button" color="secondary" size="sm" onClick={() => setShowAddColorModal(false)}>
              Cancel
            </Button>
            <Button type="button" color="black" size="sm" onClick={() => handleAddColor()} disabled={creating}>
              Save Color
            </Button>
          </div>
        </_AddColorModal>
      </GlobalModalNoActions>
      {
        !!wishlistMoodboardItems?.length && (
          <GlobalModalNoActions isOpen={showReorderModal} onClosed={() => setShowReorderModal(false)}>
            <WishlistMoodboardOrganize
              items={wishlistMoodboardItems}
              onCancel={() => setShowReorderModal(false)}
              onOk={() => {
                setShowReorderModal(false);
                refetch();
              }}
            />
          </GlobalModalNoActions>
        )
      }
    </_WishlistMoodboard>
  );
};