import { atom } from 'recoil';

import { IWishlistGroup, IWishlistItem } from '@/api';

import { wishlistsPagePath } from '../whislists/WishlistsPage.shared';

export const wishlistDetailPath = `${wishlistsPagePath}/detail/:id`;

export const getWishlistDetailUrl = (id: string) => wishlistDetailPath.replace(':id', id);

export const wishlistGroupsState = atom<IWishlistGroup[]>({
  key: 'wishlistGroups',
  default: [],
});

export const wishlistItemsState = atom<IWishlistItem[]>({
  key: 'wishlistItems',
  default: [],
});