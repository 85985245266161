import { ICartItem } from './Types';

export const getAllItemFormCart = () => {
  if (!('localStorage' in window)) {
    throw new Error('(pushOrderItemToCart) localStorage not found in window');
  }

  const valueString = window.localStorage.getItem('CART');
  if (!valueString) {
    return [];
  }

  return JSON.parse(valueString) as Partial<ICartItem>[];
};
