import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { formatDate, replaceRoutePath } from '@/_shared';
import { IModel, IProject } from '@/api';
import {
    projectBplusPagePath
} from '@/pages/project-bplus/ProjectBplusPage.shared';
import {
    projectDetailPagePath
} from '@/pages/project-detail/ProjectDetailPage.shared';
import { textIgnoreCase } from '@/translation';

const _ProjectBplusModelInfo = styled.div`
  /** Styles here */
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-top: 0.5px solid #C4C4C4;
  border-bottom: 0.5px solid #C4C4C4;

  .model-info {
  }
  .model-name {
    font-size: 34px;
    margin-bottom: 6px;
  }
  .model-description {
    margin-top: 1rem;
  }
`;

interface IProjectBplusModelInfoProps {
  readonly model: IModel;
  readonly project: IProject;
}

export const ProjectBplusModelInfo = (props: IProjectBplusModelInfoProps) => {
  const { model, project } = props;

  return (
    <_ProjectBplusModelInfo id="ProjectBplusModelInfo">
      <Container>
        <div className="model-info">
          <h1 className="model-name">{model.name}</h1>
          <div className="mb-2">{textIgnoreCase('Last update')} - <strong>{formatDate(model?.updatedAt)}</strong></div>
          <div>
            <Link to={replaceRoutePath(projectBplusPagePath, { projectSlug: project.slug, productTypeSlug: model.productType?.slug })}>
              <b>{project?.name}</b> - {model.productType?.name}
            </Link>
          </div>
          {
            model.description && (
              <div className="model-description">
                <ReactMarkdown source={model.description} />
              </div>
            )
          }
        </div>
      </Container>
    </_ProjectBplusModelInfo>
  );
}
