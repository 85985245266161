import { RequestParameter, RequestParams } from '../Types';

interface ResourceProps {
  readonly url: string;
  readonly method: string;
  readonly getDefaultParams?: () => RequestParams;
}

const shouldParmeterIgnore = (param: RequestParameter) =>
  !param || param.type === 'body' || param.value === undefined || param.value === '';

export class Resource<TReq, TRes> {
  public readonly url: string;
  public readonly method: string;
  public readonly getDefaultParams?: () => RequestParams;

  constructor(props: ResourceProps) {
    this.url = props.url;
    this.method = props.method;
    this.getDefaultParams = props.getDefaultParams;
  }

  mixinWithDefaultParams = (requestParams: RequestParams) => {
    const params = this.getDefaultParams!();

    if (Array.isArray(params)) {
      return [...requestParams, ...params];
    }

    return [...requestParams, params];
  }

  urlReslover = (
    params: Array<RequestParameter> = [],
    parser?: (value: unknown, param: RequestParameter) => unknown
  ): string => {
    let uRL: string = this.url;
    const searchs: URLSearchParams = new URLSearchParams();

    const mixedRequestParams = this.getDefaultParams ? this.mixinWithDefaultParams(params) : params;

    for (const param of mixedRequestParams) {
      const ignore = shouldParmeterIgnore(param);
      if (ignore) {
        continue;
      }

      const paramValue = parser ? parser(param.value, param) : param.value;

      if (param.type === 'path') {
        uRL = uRL.replace(`/:${param.parameter}`, `/${paramValue}`);
      } else {
        if (Array.isArray(paramValue)) {
          for (const paramValueItem of paramValue) {
            searchs.append(param.parameter!, paramValueItem);
          }
        } else {
          searchs.append(param.parameter!, paramValue as string);
        }
      }
    }

    const searchString = searchs.toString();
    return searchString ? `${uRL}?${searchString}` : uRL;
  }

  requestInitReslover(
    params: Array<RequestParameter> = [],
    parser?: (key: string, value: unknown) => unknown
  ): RequestInit {
    const bodyParam = params.find(param => param.type === 'body');

    if (!bodyParam) {
      return {
        headers: new Headers({}),
        method: this.method
      };
    }

    const body = bodyParam.value as Record<string, unknown>;
    if (body instanceof FormData) {
      return {
        headers: new Headers({}),
        method: this.method,
        body: body
      } as RequestInit;
    }

    const requestBody = { ...body };

    if (parser) {
      const bodyKeys = Object.keys(body);
      bodyKeys.forEach(bodyKey => {
        const element = body[bodyKey];
        requestBody[bodyKey] = parser(bodyKey, element);
      });
    }

    const requestInit: RequestInit = {
      headers: new Headers({
        'Content-Type': bodyParam.contentType || 'application/json'
      }),
      body: JSON.stringify(requestBody),
      method: this.method
    };

    return requestInit;
  }
}