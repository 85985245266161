import flatten from 'lodash/flatten';

import { IFurnitureModule } from '@/_shared/Types';
import { IModel } from '@/api';

const validateModule = (model: Partial<IModel>, modules: Partial<IFurnitureModule>[]) => {
  for (const furnitureModule of modules) {
    const modelComponent = model.components?.find(o => o.id === furnitureModule.component?.id);
    if (!modelComponent) {
      return `${validateModule.name}: component #${furnitureModule.component?.id} was not found in model #${model.id}`;
    }

    if (!furnitureModule.material) {
      continue;
    }

    const componentMaterials = flatten(modelComponent.materialTypes?.map(o => o.materials));
    const modelMaterial = componentMaterials.find(material => material?.id === furnitureModule.material?.id)!;
    if (!modelMaterial) {
      return `${validateModule.name}: material #${furnitureModule.material?.id} was not found in model #${model.id}`;
    }
  }

  return undefined;
}

export const getModelPrice = (
  model: Partial<IModel>,
  modules: Partial<IFurnitureModule>[]) => {
  if (!model) {
    throw new Error('getModulesPrice: model undefined');
  }

  if (!modules?.length) {
    return 0;
  }

  const invalid = validateModule(model, modules);

  if (invalid) {
    throw new Error(invalid);
  }

  return modules.reduce(
    (totalPrice, currentModule) => {
      const modelComponent = model.components?.find(o => o.id === currentModule.component?.id);
      if (!modelComponent) {
        return totalPrice;
      }
      
      const componentMaterials = flatten(modelComponent.materialTypes?.map(o => o.materials));

      const moduleMaterial = componentMaterials.find(material => material?.id === currentModule.material?.id);

      const variant = model.componentVariants?.find(o => o.id === currentModule.component?.variantId);

      const materialQuota = variant?.materialQuota ?? modelComponent.materialQuota ?? 0;
      const materialPrice = (moduleMaterial?.unitPrice ?? 0) * materialQuota;

      const componentPrice = variant?.price ?? modelComponent.price ?? 0;

      const nextTotalPrice = totalPrice + componentPrice + materialPrice;

      return nextTotalPrice;
    },
    0
  )
}
