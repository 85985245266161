import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Popover } from 'reactstrap';
import { atom, useRecoilState } from 'recoil';
import styled from 'styled-components';

import { IPerspectiveItem } from '@/api';
import { textIgnoreCase } from '@/translation';

import { getMCollectionProductUrl } from '../../MCollectionProductPage.shared';

const _MCollectionPerspectiveItem = styled.div`
  position: absolute;
  .popover-inner {
    .title {
      font-weight: 700;
    }
  }
  
  .dot{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }
`;

interface IMCollectionPerspectiveItemProps {
  readonly index: number;
  readonly perspectiveItem: Partial<IPerspectiveItem>;
}

const MCollectionPerspectiveOpenState = atom<string | null>({
  key: 'MCollectionPerspectiveOpenState',
  default: null
});

export const MCollectionPerspectiveItem = (props: React.PropsWithChildren<IMCollectionPerspectiveItemProps>) => {
  const { index, perspectiveItem } = props;

  const [itemToOpen, setTtemToOpen] = useRecoilState(MCollectionPerspectiveOpenState);
  const isOpen = itemToOpen === perspectiveItem.id;

  return (
    <_MCollectionPerspectiveItem
      style={{ left: `${perspectiveItem.pointX}%`, top: `${perspectiveItem.pointY}%` }}
    >
      <Button className='dot' color='black' id={'id' + perspectiveItem.id}>
        {index}
      </Button>
      <Popover
        popperClassName="border-0"
        placement="bottom"
        isOpen={isOpen}
        target={'id' + perspectiveItem.id}
        toggle={() => {
          if (isOpen) {
            return void setTtemToOpen(null);
          }

          setTtemToOpen(perspectiveItem.id!);
        }}
      >
        <p>
          <strong>
            {perspectiveItem.product?.name}
          </strong>
        </p>
        <Button
          tag="a"
          href={getMCollectionProductUrl(perspectiveItem.product!)}
          color='black'
          style={{fontWeight: 'normal'}}
        >
          {textIgnoreCase('FIND OUT MORE')}
        </Button>
      </Popover>
    </_MCollectionPerspectiveItem >
  );
};