import { Resource } from 'libs/restful';

import { IFile } from './file';
import { IProduct } from './product';
import { ISlider } from './slider';

export interface IOutdoorIntroduce {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly valueLeft?: string;
  readonly valueRight?: string;
  readonly wireframePhoto?: Partial<IFile>;
}

export interface IOutdoorCollection {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly Name: string;
  readonly author?: string;
  readonly content?: string;
  readonly ImageTopPgae?: Partial<IFile>;
  readonly PhotoRepresent?: Partial<IFile>;
  readonly products?: IProduct[];
  readonly photosSilder?: Partial<IFile>[];
  readonly outdoorIntroduce?: IOutdoorIntroduce[];
}

export const outdoorResources = {
  find: new Resource<IOutdoorCollection, IOutdoorCollection[]>({
    method: "GET",
    url: "/admin/outdoorcollections",
  }),
  count: new Resource<IOutdoorCollection, number>({
    method: "GET",
    url: "/admin/outdoorcollections/count",
  }),
  findOne: new Resource<IOutdoorCollection, IOutdoorCollection>({
    method: "GET",
    url: "/admin/outdoorcollections/:id",
  }),
};
