import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import styled from 'styled-components';

import { getFileUrl } from '@/_shared';
import { Img } from '@/_shared/components';
import { IModel } from '@/api';

const _ModelPreviewPhotos = styled.div`
  /** Styles here */
  bottom: 12px;
  left: 12px;
  overflow: auto;

  ::-webkit-scrollbar {
        height: 10px;
        width: 12px;
        background: transparent;
      }

      /* Optional: show position indicator in red */
      ::-webkit-scrollbar-thumb {
        background: lightgray;
      }

  img {
    max-width: 100%;
  }

  .model-preview-photos {
    &-thumbs {
      overflow: visible;
      white-space: nowrap;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      min-width: 100%;
    }
    &-thumb {
      width: 75px;
      display: inline-block;
      margin-right: 12px;
      cursor: pointer;
      border: 1px solid lightgray;
    }
  }
`;

interface IModelPreviewPhotosProps {
  readonly model?: IModel;
}

interface IModelPreviewPhotosState {
  readonly lightBoxIsOpen: boolean;
  readonly lightBoxPhotoIndex: number;
}

export class ModelPreviewPhotos extends React.PureComponent<
  IModelPreviewPhotosProps,
  IModelPreviewPhotosState> {

  public constructor(props: IModelPreviewPhotosProps) {
    super(props);
    this.state = {
      lightBoxPhotoIndex: 0,
      lightBoxIsOpen: false
    };
  }

  public render() {
    const { currentBreakpoint } = this.context;
    const { model } = this.props;
    const { lightBoxIsOpen, lightBoxPhotoIndex } = this.state;

    if (!model?.photos?.length) {
      return null;
    }

    const images = model.photos;

    return (
      <_ModelPreviewPhotos className={currentBreakpoint === 'lg' || currentBreakpoint === 'xl' ? 'position-absolute' : ''}>
        <div className="model-preview-photos-thumbs">
          {images.map((element, index) => {
            const onImageClick = () => {
              this.setState({
                lightBoxIsOpen: true,
                lightBoxPhotoIndex: index
              });
            };

            return (
              <div
                key={index}
                className="model-preview-photos-thumb"
                onClick={onImageClick}
                role="button"
              >
                <Img file={element} ratioX={1} ratioY={1} />
              </div>
            );
          })}
        </div>
        {!!(lightBoxIsOpen && images.length) && (
          <Lightbox
            mainSrc={getFileUrl(images[lightBoxPhotoIndex])!}
            nextSrc={getFileUrl((images[(lightBoxPhotoIndex + 1) % images.length]))}
            prevSrc={getFileUrl(images[(lightBoxPhotoIndex + images.length - 1) % images.length])}
            onCloseRequest={() => this.setState({ lightBoxIsOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                lightBoxPhotoIndex: (lightBoxPhotoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                lightBoxPhotoIndex: (lightBoxPhotoIndex + 1) % images.length,
              })
            }
          />
        )}
      </_ModelPreviewPhotos>
    );
  }
}
