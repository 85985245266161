
import { Resource } from 'libs/restful';

import { IInspirationCategory, IInspirationType } from './';
import { ICategory } from './category';
import { IFile } from './file';
import { IGroupInspirationProduct } from './groupInspirationProduct';
import { ITag } from './tag';

export interface IInspiration {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug: string;
  readonly description: string;
  readonly description_en?: string;
  readonly thumbnail?: Partial<IFile>;
  readonly cover?: Partial<IFile>;
  readonly published?: boolean;
  readonly inspirationProducts?: Partial<IGroupInspirationProduct>[];
  readonly content?: string;
  readonly content_en?: string;
  readonly inspirationCategory: IInspirationCategory;
  readonly inspirationType: IInspirationType;
  readonly photos: IFile[];
  readonly coverText?: string;
}

export const inspirationResources = {
  find: new Resource<IInspiration, IInspiration[]>({
    method: 'GET',
    url: '/admin/inspirations'
  }),
  count: new Resource<IInspiration, number>({
    method: 'GET',
    url: '/admin/inspirations/count'
  }),
  findOne: new Resource<IInspiration, IInspiration>({
    method: 'GET',
    url: '/admin/inspirations/:id'
  }),
  create: new Resource<IInspiration, IInspiration>({
    method: 'POST',
    url: '/admin/inspirations'
  }),
  update: new Resource<IInspiration, IInspiration>({
    method: 'PUT',
    url: '/admin/inspirations/:id'
  }),
  delete: new Resource<IInspiration, IInspiration>({
    method: 'DELETE',
    url: '/admin/inspirations/:id'
  }),
};
