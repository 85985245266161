import classNames from 'classnames';
import { useResourceLazy } from 'libs/restful';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { DEFAULT_DATETIME_FORMAT, formatDate } from '@/_shared';
import { commentResources, IComment } from '@/api';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

const _CommentItem = styled.div`
  /** Styles here */
  margin-bottom: 16px;
  display: flex;

  .comment-avatar {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: lightgray;
    margin-right: 30px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    &.me {
      background: antiquewhite;
    }
  }

  .comment-content {
    flex-grow: 1;
    background: #F0F0F0;
    border-radius: 10px;
    padding: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #7E7E7E;
    position: relative;
  }

  .comment-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    color: #000000;
  }

  .comment-action {
    cursor: pointer;
  }

  .divider {
    border-top: 0.5px solid #C4C4C4;
    margin: 10px -15px;
  }
  .arrow-left {
    width: 0; 
    height: 0; 
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent; 
    border-right: 20px solid #F0F0F0; 
    position: absolute;
    left: -20px;
  }

  @media (max-width: 991px) {
    .comment-avatar {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
  }
`;

interface ICommentItemProps {
  readonly comment: IComment;
  readonly onDeleted: (comment: IComment) => void;
}

export const CommentItem = (props: ICommentItemProps) => {
  const { comment, onDeleted } = props;
  const [currentUser] = useRecoilState(currentUserState);
  const [deleteCommnentResult, , requestDelComment] = useResourceLazy(commentResources.delete);

  React.useEffect(
    () => {
      if (!deleteCommnentResult) {
        return;
      }

      onDeleted(comment);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteCommnentResult]
  );

  const createdBy = comment.createdBy;
  const createdByUsername = createdBy?.username ?? 'Unknow';

  if (!createdBy) {
    return null;
  }

  const isMe = createdBy.id === currentUser?.id;
  const displayName = createdBy.displayName ?? createdByUsername;

  const avatarChars = createdByUsername[0] + createdByUsername[createdByUsername.length - 1];

  return (
    <_CommentItem>
      <div className={classNames('comment-avatar', { me: isMe })}>
        <span>{avatarChars}</span>
      </div>
      <div className="comment-content">
        <div className="arrow-left"></div>
        <div >
          <strong className="comment-name">{displayName}</strong> <span>{textIgnoreCase('at')} {formatDate(comment.createdAt, DEFAULT_DATETIME_FORMAT)}</span>
        </div>
        <div className="divider" />
        {comment.content.split('\n').map((o, i) => {
          return (<div key={i}>{o}</div>);
        })}
        <div>
          {
            isMe && (
              <span
                // tslint:disable-next-line:jsx-no-lambda
                onClick={() => {
                  requestDelComment([{
                    type: 'path',
                    parameter: 'id',
                    value: comment.id
                  }]);
                }}
                className="comment-action"
                role="button"
              >
                <small>{textIgnoreCase('Delete')}</small>
              </span>
            )
          }
        </div>
      </div>
    </_CommentItem>
  );
}