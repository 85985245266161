import { useResourceLazy } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Form, Input } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { authResources } from '@/api';
import { useAccessToken } from '@/hooks';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

const _PartnerLoginForm = styled.div`
  form {
    padding-bottom : 20px;
    .form-control {
      background  :#E9E9E9;
      height : 50px;
      border: none;
    }
    button.btn.btn-black{
      padding : 10px 34px;
    }
    .wraper-list-login{
      display : flex;
      justify-content : space-between;
      p {
          margin-bottom:5px;
        }
      .list-socical {
        display : flex;
        justify-content : flex-end;
        .socical-login{
          &.google{
            margin-right: 0;
          }
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #000000;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right : 5px;
          i {
            font-size: 15px;
            color : #ffffff;
          }
        }
      }
    }
  }
`;

interface IPartnerLoginFormProps {
}

export const PartnerLoginForm = (props: React.PropsWithChildren<IPartnerLoginFormProps>) => {

  const [loginResult, loading, sendLogin, error] = useResourceLazy(authResources.localLogin);
  const [, setCurrentUser] = useRecoilState(currentUserState);
  const [, setAccessToken] = useAccessToken();

  React.useEffect(
    () => {
      if (!loginResult) {
        return;
      }

      setAccessToken(loginResult.jwt);
      setCurrentUser(loginResult.user);
    },
    [loginResult, setAccessToken, setCurrentUser]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const formValues = getHtmlFormValue(e.currentTarget);
      try {
        sendLogin([{
          type: 'body',
          value: formValues
        }]);
      } catch (error) {
        //
      }
    },
    [sendLogin]
  );

  return (
    <_PartnerLoginForm>
      {error && (<Alert color="danger">{textIgnoreCase('Username or password invalid')}</Alert>)}
      <Form onSubmit={onSubmit}>
        <Input type="email" name="identifier" placeholder="Your email address" />
        <Input type="password" name="password" placeholder="Password" />
        <div className='wraper-list-login'>
          <div>
            <Button
              type='submit'
              color='black'
              disabled={loading}
            >
              {textIgnoreCase('LOGIN')}
            </Button>
          </div>
          <div className="d-none">
            <p>{textIgnoreCase('Use account')}</p>
            <div className='list-socical' >
              <Link className='socical-login' to='/'>
                <i className="fa fa-facebook" aria-hidden="true"></i>
              </Link>
              <Link className='socical-login google' to='/'>
                <i className="fa fa-google-plus" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>
      </Form>
    </_PartnerLoginForm >
  );
};