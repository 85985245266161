
import { Resource } from 'libs/restful';

import { IFile } from './file';
import { IPerspectiveItem } from './perspectiveItem';

export interface IPerspective {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly photo: Partial<IFile>;
  readonly perspectiveItems?: Partial<IPerspectiveItem>[];
}

export const perspectiveResources = {
  find: new Resource<IPerspective, IPerspective[]>({
    method: 'GET',
    url: '/admin/perspectives'
  }),
  count: new Resource<IPerspective, number>({
    method: 'GET',
    url: '/admin/perspectives/count'
  }),
  findOne: new Resource<IPerspective, IPerspective>({
    method: 'GET',
    url: '/admin/perspectives/:id'
  }),
  create: new Resource<IPerspective, IPerspective>({
    method: 'POST',
    url: '/admin/perspectives'
  }),
  update: new Resource<IPerspective, IPerspective>({
    method: 'PUT',
    url: '/admin/perspectives/:id'
  }),
  delete: new Resource<IPerspective, IPerspective>({
    method: 'DELETE',
    url: '/admin/perspectives/:id'
  }),
};
