import 'cropperjs/dist/cropper.css';

import { useResourceLazy } from 'libs/restful';
import React, { FormEventHandler } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IWishlistItem, wishlistItemResources } from '@/api';
import { textIgnoreCase } from '@/translation';

import { wishlistGroupsState } from '../../WishlistDetailPage.shared';

const _UpdateItemModal = styled.div`
  width: ${window.innerWidth * 0.9}px;
  max-width: 1000px;

  label {
    text-transform: uppercase;
    font-weight: 500;
  }

  .info {
    margin-bottom: 14px;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
  }

  .actions {
    margin-top: 14px;
    display: flex;
    gap: 10px;
    button {
      flex-grow: 1;
    }
  }
`;

interface IWishlistItemFormProps {
  readonly onUpdated: (wishlistItem: IWishlistItem) => void;
  readonly onCanceled: () => void;
  readonly updatingWishlistItem: IWishlistItem;
}

export const WishlistItemForm = (props: React.PropsWithChildren<IWishlistItemFormProps>) => {
  const [wishListGroups] = useRecoilState(wishlistGroupsState);

  const [, updating, updateItem] = useResourceLazy(wishlistItemResources.update);

  const handleUpdateItem: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const item = {
      ...props.updatingWishlistItem,
      quantity: Number(data.get('quantity')),
      estPrice: Number(data.get('estPrice')),
      wishlistGroup: String(data.get('wishlistGroup')),
      description: String(data.get('description')),
    } as IWishlistItem;

    updateItem([
      { type: 'path', parameter: 'id', value: item.id },
      { type: 'body', value: item as any }
    ]).then((newWishlistItem) => {
      props.onUpdated(newWishlistItem);
    });
  };

  return (
    <_UpdateItemModal>
      <Row>
        <Col xs={12} lg={6}>
          <div className="thumbnail">
            <Img file={props.updatingWishlistItem.thumbnail} ratioX={1} ratioY={1} />
          </div>
        </Col>
        <Col xs={12} lg={6}>
          <div className="info">
            <div className="type">
              {props.updatingWishlistItem?.data?.type}
            </div>
            <div className="name">
              {props.updatingWishlistItem.productName}
            </div>
          </div>
          <form onSubmit={handleUpdateItem}>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="quantity">Quantity</label>
                  <input type="number" className="form-control" id="quantity" name="quantity" placeholder="Quantity" defaultValue={props.updatingWishlistItem.quantity ?? 1} min={1} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="estPrice">Estimated Price</label>
                  <input type="number" className="form-control" id="estPrice" name="estPrice" placeholder="Price" defaultValue={props.updatingWishlistItem.estPrice} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="description">Product description</label>
                  <input className="form-control" id="description" name="description" defaultValue={props.updatingWishlistItem.description} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="group">Group</label>
                  <select
                    className="form-control"
                    id="wishlistGroup"
                    name="wishlistGroup"
                    placeholder="Select a group"
                    defaultValue={typeof props.updatingWishlistItem.wishlistGroup === 'string' ? props.updatingWishlistItem.wishlistGroup : props.updatingWishlistItem.wishlistGroup?.id ?? ''}
                  >
                    <option value="" disabled selected>Select a group</option>
                    {
                      wishListGroups.map((group) => (
                        <option key={group.id} value={group.id}>{group.name}</option>
                      ))
                    }
                  </select>
                </div>
              </Col>
            </Row>
            <div className="actions">
              <Button className="" type="button" disabled={updating} onClick={props.onCanceled}>
                {textIgnoreCase('Cancel')}
              </Button>
              <Button className="btn-black" type="submit" disabled={updating}>
                {textIgnoreCase('Update')}
              </Button>
            </div>
          </form>
        </Col>
      </Row>
    </_UpdateItemModal>
  );
};