/**
 * Template: page-section/section.ejs.t
 * Version: 0.0.1
 */

import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { IModel } from '@/api';
import { textIgnoreCase } from '@/translation';

import {
    DesignToolAddToCart,
    DesignToolAttackments,
    DesignToolBase,
    DesignToolCollapse,
    DesignToolComponents,
    DesignToolDecors,
    DesignToolMaterials,
    DesignToolPreview,
    DesignToolVariants,
    DesingToolInfo
} from './design-page-tool';

const _DesignPageTool = styled.section`
  color: #7E7E7E;
  border-bottom: 1px solid #C4C4C4;
  h1 {
    font-size: 36px;
    margin-bottom: 36px;
    padding-left: 12px;
    color: #000000;
  }
  select {
    background: #E9E9E9;
  }
  .col-lg-6 {
    padding: 0;
  }
  .design-tool {
    &-edit-wrapper {
      border-left: 0.5px solid #C4C4C4;
      padding-left: 60px;
      padding-top: 40px;
      padding-bottom: 40px;
      width: 100%;
      height: 100%;
      overflow-anchor: none;
      overflow: auto;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }

      > :last-child {
        border-bottom: 0;
      }
    }
    &-preview-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      margin-bottom: 0;
    }
    .design-tool {
      &-edit-wrapper {
        padding-left: 0px;
        padding-top: 0px;
        margin-bottom: 12px;
      }
      &-preview-wrapper {
        width: 100%;
      }
    }
    #threeViewWindow {
      height: 400px!important;
    }
  }
`;

interface IDesignPageToolProps {
  readonly model?: IModel;
}

interface IDesignPageToolState {
}

export class DesignPageTool extends DesignToolBase<IDesignPageToolProps, IDesignPageToolState> {
  public constructor(props: IDesignPageToolProps) {
    super(props);

    this.state = {
      ...this.state
    };
  }

  public render() {
    const { model } = this.props;
    const { selectedComponentId } = this.state;

    const hadDecorations = this.hadDecorations();
    const hadVariants = this.hadVariants();

    return (
      <_DesignPageTool
        id="designPageTool"
      >
        <Container className="design-tool">
          <Row>
            <Col sm={12} lg={6}>
              <div className="design-tool-preview-wrapper">
                <DesignToolPreview model={model} />
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="design-tool-edit-wrapper">
                <h1>{model?.name}</h1>
                {
                  selectedComponentId
                  && (
                    <>
                      <DesignToolComponents model={model} />
                      <DesignToolMaterials model={model} />
                    </>
                  )
                }
                {
                  hadDecorations && (
                    <DesignToolCollapse label={textIgnoreCase('Choose decor pillows')}>
                      <DesignToolDecors model={model} onChange={this.onChangeDecorations} />
                    </DesignToolCollapse>
                  )
                }
                {
                  hadVariants && (
                    <DesignToolCollapse label={textIgnoreCase('Select option')}>
                      <DesignToolVariants model={model} onChange={this.onChangeDecorations} />
                    </DesignToolCollapse>
                  )
                }
                <DesignToolCollapse label={textIgnoreCase('PRODUCTS INFORMATION')}>
                  <DesingToolInfo model={model} />
                </DesignToolCollapse>
                <DesignToolCollapse label={textIgnoreCase('PLACE AN ORDER')}>
                  <DesignToolAddToCart
                    model={model}
                    onAddToCart={() => {
                      this.forceUpdate();
                    }}
                  />
                </DesignToolCollapse>
                {
                  !!model?.attachments?.length && (
                    <DesignToolCollapse label={textIgnoreCase('Attachments')} defaultOpen={true}>
                      <DesignToolAttackments model={model} />
                    </DesignToolCollapse>
                  )
                }
              </div>
            </Col>
          </Row>
        </Container>
      </_DesignPageTool>
    );
  }

  private readonly hadDecorations = () => {
    const { model } = this.props;
    if (!model?.components?.length) {
      return false;
    }

    return !!model.components.find((component) => component.isDecorations);
  }

  private readonly hadVariants = () => {
    const { model } = this.props;

    return !!model?.componentVariants?.length;
  }

  private readonly onChangeDecorations = (toggle: boolean) => {
    this.forceUpdate();
  }
}
