import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { GlobalModalNoActions } from '@/_shared/components';
import {
    ProductSaveDialog
} from '@/_shared/components/domain/ProductSaveDialog';
import { IProduct } from '@/api';
import {
    getMCollectionFileRequestUrl
} from '@/pages/m-collection-file-request/MCollectionFileRequest.shared';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

import { MCollectionProductPhotos } from './m-collection-product-detail';

export const _MCollectionProductDetail = styled.div`
  border-bottom : 0.5px solid #C4C4C4;

  .custom-col{
    margin: 0;
    padding: 0;
  }

  .col-right {
    height: 100%;
    padding: 100px 0 0 100px;
    border-left : 0.5px solid #C4C4C4;
    h3 {
      font-size: 36px;
    }

    h4 {
      font-size: 24px;
    }

    i{
      color : #000000;
    }

    .block {
      margin-bottom: 20px;
      p {
        color : #7E7E7E;
      }
      strong {
        color : #7E7E7E;
        font-weight : 700;
        font-family :'Roboto';
        font-size: 13px;
      }
    }

    .share {
      display : flex;
      justify-content: space-between;
      border-top: 1px solid #C4C4C4;
      padding: 45px 0;
      h5{
          color : #7E7E7E;
          font-weight : 700;
          font-family :'Roboto';
          font-size: 13px;
      }
      a {
        width :30px;
        height : 30px;
        background :#000000;
        margin-left: 16px;
        display: inline-block;
        i {
          color: #ffffff;
          font-size: 20px;
          padding: 7px;
        }
      }
    }
  }


  .block-button {
    border-top: 1px solid #C4C4C4;
    padding: 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
  }
  
  @media(max-width : 992px){
    .col-right {
      padding: 22px;
      border-left:none;
    }
    .block-button {
      grid-template-columns: 1fr 1fr;
      gap: 5px;
    }
    .share {
      padding: 20px 0!important;
    }
  }
`;

interface IMCollectionProductDetailProps {
  readonly product: IProduct;
}

export const MCollectionProductDetail = (props: React.PropsWithChildren<IMCollectionProductDetailProps>) => {
  const { product } = props;
  const [currentUser] = useRecoilState(currentUserState);
  const [isSaveDialogVisible, setIsSaveDialogVisible] = React.useState(false);

  const modelUrl = product?.modelUrl ? new URL(product.modelUrl) : null;
  const modelUrlPaths = modelUrl?.pathname.split('/');
  const modelSlug = modelUrlPaths && modelUrlPaths[modelUrlPaths?.length - 1];

  return (
    <_MCollectionProductDetail>
      <Container>
        <Row>
          <Col className='custom-col' lg={6}>
            <MCollectionProductPhotos files={product.photos!} />
          </Col>
          <Col className='custom-col' lg={6}>
            <div className='col-right'>
              <h3>{product.name}</h3>
              <h4>
                {product.productType?.name}
              </h4>
              <div
                className="product-detail-content"
                dangerouslySetInnerHTML={{ __html: product.detailDescription! }}
              />
              <div className='block-button'>
                <Button className="d-block" color='black' tag={Link} to={getMCollectionFileRequestUrl(product.id, 'quote', modelSlug)}>
                  {textIgnoreCase('QUOTE')}
                </Button>
                <Button className="d-block" color='grey' tag={Link} to={getMCollectionFileRequestUrl(product.id, 'catalogue', modelSlug)}>
                  {textIgnoreCase('CATALOGUE')}
                </Button>
                <Button
                  className="d-block"
                  color='grey'
                  tag={Link}
                  to={getMCollectionFileRequestUrl(product.id, '3d-files', modelSlug)}
                >
                  {textIgnoreCase('DATA')}
                </Button>
                {
                  currentUser && (
                    <Button color='grey' onClick={() => setIsSaveDialogVisible(!isSaveDialogVisible)}>
                      {textIgnoreCase('SAVE')}
                    </Button>
                  )
                }
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <GlobalModalNoActions isOpen={isSaveDialogVisible} onClosed={() => setIsSaveDialogVisible(false)}>
        <ProductSaveDialog product={product} onClose={() => setIsSaveDialogVisible(false)} />
      </GlobalModalNoActions>
    </_MCollectionProductDetail >
  );
};