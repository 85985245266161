
import { Resource } from 'libs/restful';

import { IColor } from './color';
import { IFile } from './file';
import { IPerspective } from './perspective';
import { IProductType } from './productType';
import { ITag } from './tag';

export interface IProduct {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly slug?: string;
  readonly price?: number;
  readonly previewDescription?: string;
  readonly previewDescription_en?: string;
  readonly detailDescription?: string;
  readonly detailDescription_en?: string;
  readonly details?: object;
  readonly details_en?: object;
  readonly modelUrl?: string;
  readonly photos?: Partial<IFile>[];
  readonly thumbnail?: Partial<IFile>;
  readonly colors?: Partial<IColor>[];
  readonly productType?: Partial<IProductType>;
  readonly tags?: Partial<ITag>[];
  readonly for?: string;
  readonly isFeature?: boolean;
  readonly featureDescription?: string;
  readonly featureDescription_en?: string;
  readonly featurePhoto?: Partial<IFile>;
  readonly featurePhotoThumbnail?: Partial<IFile>;
  readonly secondaryDescription?: string;
  readonly secondaryDescription_en?: string;
  readonly perspectives?: IPerspective[];
  readonly ReallifePhotos?: IFile[];
  readonly illustrationsPhotos?: IFile[];
}

export const productResources = {
  find: new Resource<IProduct, IProduct[]>({
    method: 'GET',
    url: '/admin/products'
  }),
  count: new Resource<IProduct, number>({
    method: 'GET',
    url: '/admin/products/count'
  }),
  findOne: new Resource<IProduct, IProduct>({
    method: 'GET',
    url: '/admin/products/:id'
  }),
  create: new Resource<IProduct, IProduct>({
    method: 'POST',
    url: '/admin/products'
  }),
  update: new Resource<IProduct, IProduct>({
    method: 'PUT',
    url: '/admin/products/:id'
  }),
  delete: new Resource<IProduct, IProduct>({
    method: 'DELETE',
    url: '/admin/products/:id'
  }),
};
