import * as React from 'react';
import { Col, Input, Row } from 'reactstrap';
import styled from 'styled-components';

import { textIgnoreCase } from '@/translation';

import { DesignToolBase } from './DesignToolBase';

const _DesignToolDecors = styled.div`
  padding-left: 1.25rem;

  label {
    line-height: 1.8;
  }

  p {
    font-size: 16px;
    line-height: 130%;
    text-align: justify;
    letter-spacing: 0.1em;
  }
`;

interface IDesignToolDecorsProps {
  readonly onChange: (toggle: boolean) => void;
}

export class DesignToolDecors extends DesignToolBase<IDesignToolDecorsProps> {
  public componentDidUpdate(prevProps, prevState) {
    if (prevState.hadDecorations !== this.state.hadDecorations) {
      this.props.onChange(prevState);
    }
  }

  public render() {
    const { hadDecorations } = this.state;

    return (
      <_DesignToolDecors>
        <Row >
          <Col>
            <div
              onClick={() => {
                this.setState({
                  hadDecorations: true
                });
              }}
            >
              <Input
                type="checkbox"
                id="use-decor"
                checked={hadDecorations}
              />
              <label htmlFor="use-decor">{textIgnoreCase('Yes')}</label>
            </div>
          </Col>
          <Col>
            <div
              onClick={() => {
                this.setState({
                  hadDecorations: false
                });
              }}
            >
              <Input
                type="checkbox"
                id="no-decor"
                checked={!hadDecorations}
              />
              <label htmlFor="no-decor">{textIgnoreCase('No')}</label>
            </div>
          </Col>
        </Row>
      </_DesignToolDecors>
    );
  }
}
