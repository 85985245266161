import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IProject } from '@/api';

const _ProjectCard = styled.div`
  background-color: #E2E2E2;
  
  .productItemBox {
    position: relative;
    overflow: hidden;
    &:hover {
      .project-item-info {
        bottom: 0;
      }
    }
    .logo {
      position: absolute;
      right: 0;
      top: 0;
      &:after {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        border-top: 80px solid #000;
        border-right: 80px solid #000;
        border-bottom: 80px solid transparent;
        border-left: 80px solid transparent;
        width: 0;
        height: 0;
      }
      img {
        position: absolute;
        top: 40px;
        right: 20px;
        z-index: 1;
        width: 43px;
      }
    }

    .project-item-info {
      position: absolute;
      width: 100%;
      bottom: -100%;
      left: 0;
      padding: 15px;
      color: #fff;
      background-color: #00000069;
      transition: 0.2s all;
      .name-project {
        margin-bottom: 0;
        font-family: Copperplate;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 6px;
      }
      .info-project {
        margin-bottom: 0;
        font-family: Roboto;
        font-weight: normal;
        font-size: 10px;
        line-height: 20px;
        color: #fff;
        p {
          margin-bottom: 0;
        }
        span {
          font-weight: 600;
        }
      }
      .arrow {
        color: #fff;
        font-size: 20px;
        margin-left: 10px;
      }
    }
  }
`;

interface IProjectCardProps {
  readonly project: IProject;
  readonly projectDetailUrl: string;
}

export const ProjectCard = (props: React.PropsWithChildren<IProjectCardProps>) => {
  const { project, projectDetailUrl } = props;

  return (
    <_ProjectCard>
      <Link to={projectDetailUrl}>
        <div className="productItemBox">
          <Img file={project.cover} ratioX={4} ratioY={3} />
          <div className='d-flex align-items-center justify-content-between project-item-info'>
            <div>
              <h4 className='name-project'>
                {project.name}
              </h4>
              <p className='info-project'>
                <ReactMarkdown source={project.description} />
              </p>
            </div>
            <i className="fa fa-long-arrow-right" />
          </div>
        </div>
      </Link>
    </_ProjectCard>
  );
};