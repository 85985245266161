import moment from 'moment';
import React from 'react';

import { IWishlist } from '@/api';
import {
    _WishlistDetailHeader
} from '@/pages/wishlist-detail/views/WishlistDetailHeader';

interface IWishlistDetailHeaderProps {
  readonly wishlist: IWishlist;
}

export const WishlistDetailHeader = (props: React.PropsWithChildren<IWishlistDetailHeaderProps>) => {
  const { wishlist } = props;

  const updatedAtList = [new Date(wishlist.updatedAt!)];
  console.log(wishlist.wishlistItems);
  
  const lastUpdatedItem = [...wishlist.wishlistItems].sort((a, b) => b.updatedAt!.localeCompare(a.updatedAt!))?.[0];
  
  if (lastUpdatedItem) {
    updatedAtList.push(new Date(lastUpdatedItem.updatedAt!));
  }
  const lastUpdatedGroup = wishlist.wishlistGroups.sort((a, b) => b.updatedAt!.localeCompare(a.updatedAt!))[0];
  if (lastUpdatedGroup) {
    updatedAtList.push(new Date(lastUpdatedGroup.updatedAt!));
  }

  const lastUpdated = moment(Math.max(...updatedAtList as any));

  return (
    <_WishlistDetailHeader>
      <div className="header-wrapper">
        <div>
          <h2 className="header-name">{props.wishlist.name}</h2>
          <p>
            <i>
              Project created by {wishlist.createdBy?.displayName} on {moment(wishlist.createdAt).format('MM/DD/YYY')}, updated {moment(lastUpdated).format('MM/DD/YYY [at] h:mm a')}
            </i>
          </p>
        </div>
      </div>
    </_WishlistDetailHeader>
  );
};