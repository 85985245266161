import * as React from 'react';
import styled from 'styled-components';

import { IModel } from '@/api';

import { ModelPreview } from './design-tool-preview';
import { DesignToolBase } from './DesignToolBase';

const _DesignToolPreview = styled.div`
  /** Styles here */
  position: relative;

  .preview-header {
    background: #C4C4C4;
    height: 40px;
    line-height: 40px;
    padding: 0 2rem;
    font-weight: bold;
    font-size: 14px;
    text-align: justify;
    letter-spacing: 0.1em;
    color: #000000;
  }
`;

interface IDesignToolPreviewProps {
  readonly height?: number;
  readonly model?: IModel;
  readonly on3DObjectSelect?: (componentId?: string) => void;
}

interface IDesignToolPreviewState {

}

export class DesignToolPreview extends DesignToolBase<
  IDesignToolPreviewProps,
  IDesignToolPreviewState> {
  public constructor(props: IDesignToolPreviewProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { model, height, on3DObjectSelect } = this.props;

    return (
      <_DesignToolPreview>
        <ModelPreview model={model} height={height} on3DObjectSelect={on3DObjectSelect} showHint={true} />
      </_DesignToolPreview>
    );
  }
}