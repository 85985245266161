import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

const _WelcomeBack = styled.div`
  padding-bottom: 20px;
  border-bottom: 0.5px solid #C4C4C4;
  margin-bottom: 30px;

  .title {
    font-family: Copperplate;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 6px;
  }
  .name-user {
    font-family: Roboto;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #7E7E7E;
  }
  
  @media (max-width: 1106px) {
    padding: 0 15px 30px 15px;
    margin-bottom: 30px;
  }
`;

interface IWelcomeBackProps {

}

export const WelcomeBack = (props: React.PropsWithChildren<IWelcomeBackProps>) => {
  const [currentUser] = useRecoilState(currentUserState);

  return (
    <Container>
      <Row>
        <Col>
          <_WelcomeBack className="welcome-back">
            <div className="title">{textIgnoreCase('Welcome back')}</div>
            <div className="name-user">{currentUser?.displayName ?? currentUser?.username}</div>
          </_WelcomeBack>
        </Col>
      </Row>
    </Container>
  );
};