import { RequestParams, useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img, PageLoading } from '@/_shared/components';
import { DataLoading } from '@/_shared/components/generic/DataLoading';
import {
    IInspirationCategory,
    IInspirationType,
    inspirationResources
} from '@/api';
import {
    getInspriationDetailsPageUrl
} from '@/pages/inspiration-details/InspirationDeatilsPage.shared';

const _InspirationList = styled.div`
  min-height: 200px;
  .container {

  }
  .row {
    border-left: 1px solid #C4C4C4;
    @media screen and (max-width: 991px) {
      margin: 0 -15px;
    }
  }
  .col {
    border: 1px solid #C4C4C4;
    border-top: none;
    border-left: none;
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      border-top: 1px solid #C4C4C4;
    }
  }

  .item {
    padding: 30px;
    @media (max-width: 991px) {
      padding: 12px!important;
    }
  }
  
  .name {
    font-family: Copperplate;
    font-weight: bold;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    padding: 40px 0 0 0;
    @media screen and (max-width: 991px) {
      font-size: 14px;
      padding: 20px 0 10px 0;
    }
  }
`;

interface IInspirationListProps {
  readonly category: IInspirationCategory;
  readonly type?: IInspirationType;
}

export const InspirationList = (props: React.PropsWithChildren<IInspirationListProps>) => {
  const fetchParams: RequestParams = [
    { type: 'query', parameter: 'inspirationCategory', value: props.category.id },
    { type: 'query', parameter: 'inspirationType', value: props.type?.id ?? undefined },
  ];

  const [inspriations, loading] = useResourceAnonymous(inspirationResources.find, fetchParams);

  return (
    <_InspirationList>
      <Container>
        {
          loading && (
            <DataLoading />
          )
        }
        <Row noGutters>
          {inspriations?.map(o => {
            return (
              <Col key={o.id} xs={6} md={4} className="col">
                <Link to={getInspriationDetailsPageUrl(o.slug)}>
                  <div className="item">
                    <div className="cover">
                      <Img file={o.thumbnail ?? o.cover} />
                    </div>
                    <div className="name">
                      {o.name}
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </_InspirationList>
  );
};