import { Resource } from 'libs/restful';

export interface IWishlistGroup {
  readonly id: string;
  readonly name: string;
  readonly wishlist: string;
  readonly updatedAt?: string;
}

export const wishlistGroupResources = {
  find: new Resource<IWishlistGroup, IWishlistGroup[]>({
    method: 'GET',
    url: '/customer/wishlistgroups'
  }),
  count: new Resource<IWishlistGroup, number>({
    method: 'GET',
    url: '/customer/wishlistgroups/count'
  }),
  findOne: new Resource<IWishlistGroup, IWishlistGroup>({
    method: 'GET',
    url: '/customer/wishlistgroups/:id'
  }),
  create: new Resource<IWishlistGroup, IWishlistGroup>({
    method: 'POST',
    url: '/customer/wishlistgroups'
  }),
  update: new Resource<IWishlistGroup, IWishlistGroup>({
    method: 'PUT',
    url: '/customer/wishlistgroups/:id'
  }),
  delete: new Resource<IWishlistGroup, IWishlistGroup>({
    method: 'DELETE',
    url: '/customer/wishlistgroups/:id'
  }),
};