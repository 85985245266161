import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { PrivacyPolicyContent } from './views';

const _PrivacyPolicyPage = styled.div`
`;

interface IPrivacyPolicyPageProps {

}

export const PrivacyPolicyPage = (props: React.PropsWithChildren<IPrivacyPolicyPageProps>) => {
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'privacy-policy' }]);

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_PrivacyPolicyPage>
      <PrivacyPolicyContent page={page} />
    </_PrivacyPolicyPage>
  );
};