import * as React from 'react';
import {
    GoogleMap,
    Marker,
    withGoogleMap,
    WithGoogleMapProps,
    withScriptjs
} from 'react-google-maps';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { getPageSettingValue } from '@/_shared';
/* eslint-disable react/display-name */
import { IPage } from '@/api';

const GMAP_KEY = 'AIzaSyCmVp90wecwVdLZdugvsz_raSCUspBVOMU';

const _ContactGoogleMap = styled.div`
  .container {
    padding: 0;
  }
`;

interface IContactGoogleMap {
  readonly location: {
    readonly lat: number;
    readonly lng: number;
  };
}

interface ContactGoogleMapProps {
  readonly page: IPage;
}

const ContactGoogleMapComponent = withScriptjs<WithGoogleMapProps & IContactGoogleMap>(withGoogleMap((props) => {
  const { location } = props;

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={location}
      defaultOptions={{
        scrollwheel: false
      }}
    >
      <Marker position={location} />
    </GoogleMap>
  );
}));

export const ContactGoogleMap = (props: ContactGoogleMapProps) => {
  const { page } = props;

  const locationSetting = getPageSettingValue(page, 'GOOGLE MAP LOCATION');
  if (!locationSetting) {
    return null;
  }

  const location: IContactGoogleMap['location'] = {
    lat: +locationSetting.split(',')[0],
    lng: +locationSetting.split(',')[1]
  };

  return (
    <_ContactGoogleMap>
      <Container>
        <ContactGoogleMapComponent
          // tslint:disable-next-line:max-line-length
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GMAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          location={location}
        />
      </Container>
    </_ContactGoogleMap>
  );
};
