import { Resource } from 'libs/restful';

export interface IWishlistMoodboardItem {
  readonly id: string;
  readonly wishlist: string;
  readonly type: 'text' | 'image' | 'color';
  readonly text?: string;
  readonly image?: string;
  readonly color?: string;
  readonly createdAt?: string;
  readonly displayIndex?: number;
  readonly caption?: string;
  readonly showCaption?: boolean;
  readonly spaceTop?: number;
  readonly spaceBottom?: number;
}

export const wishlistMoodboardItemResources = {
  find: new Resource<IWishlistMoodboardItem, IWishlistMoodboardItem[]>({
    method: 'GET',
    url: '/customer/wishlistMoodboardItems'
  }),
  count: new Resource<IWishlistMoodboardItem, number>({
    method: 'GET',
    url: '/customer/wishlistMoodboardItems/count'
  }),
  findOne: new Resource<IWishlistMoodboardItem, IWishlistMoodboardItem>({
    method: 'GET',
    url: '/customer/wishlistMoodboardItems/:id'
  }),
  create: new Resource<IWishlistMoodboardItem, IWishlistMoodboardItem>({
    method: 'POST',
    url: '/customer/wishlistMoodboardItems'
  }),
  update: new Resource<IWishlistMoodboardItem, IWishlistMoodboardItem>({
    method: 'PUT',
    url: '/customer/wishlistMoodboardItems/:id'
  }),
  reorder: new Resource<IWishlistMoodboardItem, IWishlistMoodboardItem>({
    method: 'PUT',
    url: '/customer/wishlistMoodboardItems/reorder'
  }),
  delete: new Resource<IWishlistMoodboardItem, IWishlistMoodboardItem>({
    method: 'DELETE',
    url: '/customer/wishlistMoodboardItems/:id'
  }),
};