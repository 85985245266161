import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { NavLink } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared';
import { IProductType, IProject } from '@/api';
import { textIgnoreCase } from '@/translation';

import { projectBplusPagePath } from '../ProjectBplusPage.shared';

const _ProjectBplusInfo = styled.div`
  /** Styles here */
  .col-product-types {
    border-left: 1px solid #C4C4C4;
  }

  .product-types-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
  }

  .product-types {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    li {
      width: 25%;
      margin-bottom: 12px;
      padding-right: 12px;
      text-transform: uppercase;
      .active {
        font-weight: bold;
      }
    }
  }

  .project {
    &-body {
      padding: 60px 0;
      max-width: 80%;
      margin: 0 auto;
    }
    &-name {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 6px;
      font-size: 24px;
      font-family: "Copperplate";
    }
    &-description {
      text-align: justify;
      margin-bottom: 24px;
      font-family: "Copperplate";
      p {
        font-weight: 300;
        margin-bottom: 0;
        strong, b {
          font-weight: 600;
        }
      }
    }
    &-content {
      margin-bottom: 48px;
      font-size: 13px;
      color: #7E7E7E;
      line-height: 20px;
    }
  }

  @media (max-width: 991px) {
    .product-types-wrapper {
      max-width: 100%;
    }
    .product-types {
      justify-content: center;
      li {
        width: unset;
      }
    }
    .col-info {
      padding: 0;
    }
    .project-body {
      padding: 15px;
      max-width: 100%;
      border-bottom: 1px solid #C4C4C4;
      padding-bottom: 0;
      margin-bottom: 30px;
    }
  }
`;

interface IProjectBplusInfoProps {
  readonly project?: IProject;
  readonly productTypes?: IProductType[];
}

interface IProjectBplusInfoState {

}

export class ProjectBplusInfo extends React.Component<
  IProjectBplusInfoProps,
  IProjectBplusInfoState> {
  public constructor(props: IProjectBplusInfoProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { project, productTypes } = this.props;
    if (!project) {
      return null;
    }

    return (
      <_ProjectBplusInfo id="ProjectBplusInfo">
        <Container>
          <Row>
            <Col xs={12} lg={6} className="col-info">
              <div className="project-body">
                <div className="project-name">
                  {project.name}
                </div>
                <p className="project-description">
                  <ReactMarkdown source={project.description} />
                </p>
                {!!project.content && (
                  <div className="project-content">
                    <ReactMarkdown source={project.content} />
                  </div>
                )}
              </div>
            </Col>
            <Col xs={12} lg={6} className="col-product-types">
              <div className="product-types-wrapper">
                <ul className="product-types">
                  <li>
                    <NavLink exact={true} to={replaceRoutePath(projectBplusPagePath, { productTypeSlug: undefined })}>
                      {textIgnoreCase('All Product')}
                    </NavLink>
                  </li>
                  {
                    productTypes?.map((productType) => {
                      const nextUrl = new URL(location.href);
                      nextUrl.pathname = replaceRoutePath(projectBplusPagePath, { productTypeSlug: productType.slug });

                      return (
                        <li key={productType.id}>
                          <NavLink to={nextUrl.pathname + nextUrl.search}>
                            {productType.name}
                          </NavLink>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </_ProjectBplusInfo>
    );
  }
}
