
import { Resource } from 'libs/restful';

import { IFile } from './file';
import { ILightMap } from './lightMap';
import { IModel } from './model';

export interface IModelLightMap {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly groundLightMap?: Partial<IFile>;
  readonly lightMaps?: Partial<ILightMap>[];
  readonly model?: Partial<IModel>;
}

export const modelLightMapResources = {
  find: new Resource<IModelLightMap, IModelLightMap[]>({
    method: 'GET',
    url: '/admin/modellightmaps'
  }),
  count: new Resource<IModelLightMap, number>({
    method: 'GET',
    url: '/admin/modellightmaps/count'
  }),
  findOne: new Resource<IModelLightMap, IModelLightMap>({
    method: 'GET',
    url: '/admin/modellightmaps/:id'
  }),
  create: new Resource<IModelLightMap, IModelLightMap>({
    method: 'POST',
    url: '/admin/modellightmaps'
  }),
  update: new Resource<IModelLightMap, IModelLightMap>({
    method: 'PUT',
    url: '/admin/modellightmaps/:id'
  }),
  delete: new Resource<IModelLightMap, IModelLightMap>({
    method: 'DELETE',
    url: '/admin/modellightmaps/:id'
  }),
};
