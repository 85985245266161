import { useResourceLazy } from 'libs/restful';
import React from 'react';
import { Route, Router, Switch } from 'react-router';
import { useRecoilState } from 'recoil';

import { browserHistory } from '@/_shared';
import { DefaultLayout } from '@/_shared/layouts';
import { userResources } from '@/api';
import { useAccessToken } from '@/hooks';
import {
    AboutPage,
    ContactPage,
    HomePage,
    MCollectionFileRequestPage,
    MCollectionPage,
    MCollectionProductPage,
    PartnerPage,
    ProductPage,
    ProductsPage,
    ShortenUrlPage
} from '@/pages';
import { CataloguePage } from '@/pages/catalogue';
import { cataloguePagePath } from '@/pages/catalogue/CataloguePage.shared';
import { contentPagePath } from '@/pages/content-page';
import { ContentPage } from '@/pages/content-page/ContentPage';
import { DesignPage } from '@/pages/design/DesignPage';
import { designPagePath } from '@/pages/design/DesignPage.shared';
import { PriceListPage } from '@/pages/download-list';
import { priceListPagePath } from '@/pages/download-list/PriceListPage.shared';
import {
    featureDetailsPagePath
} from '@/pages/feature-details/FeatureDeatilsPage.shared';
import { FeatureDetailsPage } from '@/pages/feature-details/FeatureDetailsPage';
import { FeaturesPage } from '@/pages/features';
import { featuresPagePath } from '@/pages/features/FeaturesPage.shared';
import {
    inspirationDetailsPagePath
} from '@/pages/inspiration-details/InspirationDeatilsPage.shared';
import {
    InspirationDetailsPage
} from '@/pages/inspiration-details/InspirationDetailsPage';
import { InspirationsPage } from '@/pages/inspirations';
import {
    inspirationsPagePath
} from '@/pages/inspirations/InspirationsPage.shared';
import {
    MCollectionFileRequestPath
} from '@/pages/m-collection-file-request/MCollectionFileRequest.shared';
import {
    MCollectionProductPagePath
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import {
    MCollectionTypePagePath
} from '@/pages/m-collection/MCollectionPage.shared';
import { ModelQuoteRequestPage } from '@/pages/model-quote-request';
import {
    modelQuoteRequestPath
} from '@/pages/model-quote-request/ModelQuoteRequest.shared';
import {
    OutdoorCollectionPage
} from '@/pages/outdoor-collection/OutdoorCollectionPage';
import { OutdoorPage } from '@/pages/outdoor/OutdoorPage';
import {
    outdoorPath,
    outdoorPathDetail
} from '@/pages/outdoor/OutDoorPage.shared';
import { PrivacyPolicyPage } from '@/pages/privacy-policy';
import {
    privacyPolicyPagePath
} from '@/pages/privacy-policy/PrivacyPolicyPage.shared';
import {
    officeFileRequestPath
} from '@/pages/product-file-request/ProductFileRequest.shared';
import {
    productPageDetail,
    productTypePage
} from '@/pages/product/ProductPage.shared';
import { officePagePath } from '@/pages/products/ProductsPage.shared';
import { ProjectBplusPage } from '@/pages/project-bplus';
import { ProjectBplusModelPage } from '@/pages/project-bplus-model';
import {
    projectBplusModelPagePath
} from '@/pages/project-bplus-model/ProjectBplusModel.shared';
import {
    projectBplusPagePath
} from '@/pages/project-bplus/ProjectBplusPage.shared';
import { ProjectDetailPage } from '@/pages/project-detail';
import {
    projectDetailPagePath
} from '@/pages/project-detail/ProjectDetailPage.shared';
import { ProjectProductPage } from '@/pages/project-product';
import {
    projectProductPagePath
} from '@/pages/project-product/ProjectProductPage.shared';
import { ProjectRequestPage } from '@/pages/project-request';
import {
    projectRequestPagePath
} from '@/pages/project-request/ProjectRequestPage.shared';
import { ProjectsPage } from '@/pages/projects/ProjectsPage';
import { projectsPagePath } from '@/pages/projects/ProjectsPage.shared';
import {
    shortenModelPath,
    shortenProductPath
} from '@/pages/shorten-url/ShortenUrlPage.shared';
import { UserProfilePage } from '@/pages/user-profile';
import {
    userProfilePagePath
} from '@/pages/user-profile/UserProfilePage.shared';
import { WishlistsPage } from '@/pages/whislists';
import { wishlistsPagePath } from '@/pages/whislists/WishlistsPage.shared';
import { WishlistDetailPage } from '@/pages/wishlist-detail/WishlistDetailPage';
import {
    wishlistDetailPath
} from '@/pages/wishlist-detail/WishlistDetailPage.shared';
import { WishlistSharedPage } from '@/pages/wishlist-shared/WishlistSharedPage';
import {
    wishlistSharedPath
} from '@/pages/wishlist-shared/WishlistSharedPage.shared';
import { currentUserState } from '@/state';

export const App = () => {
  const [accessToken] = useAccessToken();
  const [currentUser, , fetchCurrentUser] = useResourceLazy(userResources.me);
  const [, setCurrentUser] = useRecoilState(currentUserState);

  React.useEffect(
    () => {
      if (!accessToken) {
        return;
      }

      fetchCurrentUser();
    },
    [accessToken, fetchCurrentUser]
  );

  React.useEffect(
    () => {
      if (currentUser === undefined) {
        return;
      }

      setCurrentUser(currentUser);
    },
    [currentUser, setCurrentUser]
  );

  return (
    <Router history={browserHistory}>
      <DefaultLayout>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path={outdoorPath}>
            <OutdoorPage />
          </Route>
          <Route exact path={outdoorPathDetail}>
            <OutdoorCollectionPage />
          </Route>
          <Route exact path={MCollectionFileRequestPath}>
            <MCollectionFileRequestPage />
          </Route>
          <Route exact path={MCollectionProductPagePath}>
            <MCollectionProductPage />
          </Route>
          <Route exact path={MCollectionTypePagePath}>
            <MCollectionPage />
          </Route>
          <Route exact path={officeFileRequestPath}>
            <MCollectionFileRequestPage />
          </Route>
          <Route exact path={productPageDetail}>
            <ProductPage />
          </Route>
          <Route exact path={officePagePath}>
            <ProductsPage />
          </Route>
          <Route exact path={productTypePage}>
            <ProductsPage />
          </Route>
          <Route exact path={designPagePath}>
            <DesignPage />
          </Route>
          <Route exact path={projectBplusPagePath}>
            <ProjectBplusPage />
          </Route>
          <Route exact path={projectBplusModelPagePath}>
            {(p) => <ProjectBplusModelPage key={p.location.pathname} />}
          </Route>
          <Route exact path={projectRequestPagePath}>
            <ProjectRequestPage />
          </Route>
          <Route exact path={projectDetailPagePath}>
            {(p) => <ProjectDetailPage key={p.location.pathname} />}
          </Route>
          <Route exact path={projectProductPagePath}>
            {(p) => <ProjectProductPage key={p.location.pathname} />}
          </Route>
          <Route exact path={projectsPagePath}>
            <ProjectsPage />
          </Route>
          <Route exact path="/partner">
            <PartnerPage />
          </Route>
          <Route exact path="/about-us">
            <AboutPage />
          </Route>
          <Route exact path="/contact">
            <ContactPage />
          </Route>
          <Route exact path={cataloguePagePath}>
            <CataloguePage />
          </Route>
          <Route exact path={priceListPagePath}>
            <PriceListPage />
          </Route>
          <Route exact path={privacyPolicyPagePath}>
            <PrivacyPolicyPage />
          </Route>
          <Route exact path={userProfilePagePath}>
            <UserProfilePage />
          </Route>
          <Route exact path={modelQuoteRequestPath}>
            {() => <ModelQuoteRequestPage />}
          </Route>
          <Route exact path={inspirationDetailsPagePath}>
            <InspirationDetailsPage />
          </Route>
          <Route exact path={inspirationsPagePath}>
            <InspirationsPage />
          </Route>
          <Route exact path={featureDetailsPagePath}>
            <FeatureDetailsPage />
          </Route>
          <Route exact path={featuresPagePath}>
            <FeaturesPage />
          </Route>
          <Route exact path={wishlistSharedPath}>
            <WishlistSharedPage />
          </Route>
          <Route exact path={wishlistDetailPath}>
            <WishlistDetailPage />
          </Route>
          <Route exact path={wishlistsPagePath}>
            <WishlistsPage />
          </Route>
          <Route exact path={shortenModelPath}>
            <ShortenUrlPage />
          </Route>
          <Route exact path={shortenProductPath}>
            <ShortenUrlPage />
          </Route>
          <Route exact path={contentPagePath}>
            {(p) => <ContentPage key={p.location.pathname} />}
          </Route>
        </Switch>
      </DefaultLayout>
    </Router>
  );
};
