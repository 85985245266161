import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { getPageSettingValue } from '@/_shared';
import { IPage } from '@/api';

const _OutdoorIntroduce = styled.div`
.container{
    padding: 0;
}
.wrapper-introduce {
    display: flex;
    border: 0.5px solid #C4C4C4;
    .introduce-left {
        width: 320px;
        margin:  auto;
        border-right: 0.5px solid #C4C4C4;
        text-align: center;
        a {
            color: #818181;
            font-size: 13px;
        }
    }
    .introduce-right {
        flex: 1;
        padding: 80px 80px;
        margin:  auto;
        border-left: 0.5px solid #C4C4C4;
        b {
            font-weight: 600;
            font-family: "Copperplate";
        }
    }
}

  @media (max-width: 991px) {
    .wrapper-introduce  {
        flex-direction: column;
        padding: 16px 0;
        border: none;
        .introduce-left {
            width: 100%;
            border: none;
            text-align: start;
            border-bottom: 0.5px solid #C4C4C4;
            a {
               display:none
            }
        }
        .introduce-right {
            margin-top: 16px;
            flex: 1;
            padding: 0;
            border-left: none;
            text-align: justify;
            b {
                font-weight: 600;
                font-family: "Copperplate";
            }
        }
    }
    

  }
`;

interface IOutdoorIntroduceProps {
    readonly page: IPage;
}

export const OutdoorIntroduce = (props: React.PropsWithChildren<IOutdoorIntroduceProps>) => {
    const { page } = props;

    const pageContentSettings = getPageSettingValue(page, 'INTRODUCE');

    return (
        <_OutdoorIntroduce>
            <Container>
                <div className='wrapper-introduce' dangerouslySetInnerHTML={{ __html: pageContentSettings! }} />
            </Container>
        </_OutdoorIntroduce>
    );
};