import React, { useState } from 'react';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';

import { Img } from '@/_shared/components/generic/Img';
import { ISlider } from '@/api';

import { _Slider } from './Slider';

interface ISliderProps {
  readonly className?: string;
  readonly slider?: Partial<ISlider>;
}

export const GallerySlider = (props: ISliderProps) => {
  const { slider, className } = props;

  const slides = slider?.slides;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  if (!slides) {
    return null;
  }

  const next = () => {
    if (animating) { return; }
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) { return; }
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slideElements = slides
    .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
    .map((item, index) => {
      return (
        <CarouselItem
          onExiting={() => { setAnimating(true); }}
          onExited={() => { setAnimating(false); }}
          key={index}
        >
          <Img file={item.image} />
        </CarouselItem>
      );
    });

  return (
    <_Slider className={className}>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        enableTouch={true}
        interval={0}
      >
        {slideElements}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </_Slider>
  );
};
