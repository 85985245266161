import React from 'react';
import Slider from 'react-slick';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { getPageSettingFiles } from '@/_shared';
import { Img } from '@/_shared/components';
import { IPage } from '@/api';
import { textIgnoreCase } from '@/translation';

const _ContactClients = styled.div`
  font-family: 'Roboto';
  .logo {
    overflow: hidden;
    transform: scale(1.1);
  }
  .contact-info-container {
    border-right: 1px solid #ccc;
    text-align: center;
  }
  .contact-row, .contact-col {
    padding: 0;
  }
  .contact-col {
    color: #000000;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-right-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.label {
      background: #F1F1F1;
      h4 {
        font-family: Copperplate;
        font-weight: 500;
        font-size: 24px;
        line-height: 25px;
        text-transform: uppercase;
      }
    }
  }
  .slick-slider {
    width: 100%;
  }
  .slick-slide {
    border-right: 1px solid #ccc;
    overflow: hidden;
  }
  
  @media (max-width: 992px) {
    .contact-col {
      height: unset;
    }
    .contact-col.label {
      height: 100px;
      text-align: left;
      justify-content: left;
      h4{
        padding-left: 20px;
        margin: 0;
      }
    }
  }
`;

interface IContactClientsProps {
  readonly page: IPage;
}

export const ContactClients = (props: React.PropsWithChildren<IContactClientsProps>) => {
  const { page } = props;

  const clientLogos = getPageSettingFiles(page, 'CLIENTS');

  const isDesktop = window.innerWidth > 991;
  const slidesToShow = isDesktop ? 3 : 2;

  return (
    <_ContactClients>
      <Container className="contact-info-container">
        <Row className='contact-row' xs={1} sm={2} md={4}>
          <Col className='contact-col label'>
            <h4>{textIgnoreCase('Clients')}</h4>
          </Col>
          <Col className='contact-col' lg={9}>
            <Slider
              slidesToShow={slidesToShow}
              slidesToScroll={slidesToShow}
              draggable={true}
              infinite={false}
              autoplay={true}
            >
              {clientLogos?.map((attachment, i) => {
                return (
                  <div
                    key={attachment.id}
                    className="logo"
                  >
                    <Img file={attachment} ratioX={1} ratioY={1} />
                  </div>
                );
              })}
            </Slider>
          </Col>
        </Row>
      </Container>
    </_ContactClients>
  );
};