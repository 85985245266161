import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { IGroupsSlide, ISlider } from '@/api';

import { OutdoorSliderItem } from './OutdoorSliderItem';

const _OutdoorSlider = styled.div`
  
`;

interface IOutdoorSliderProps {
  readonly slider: Partial<ISlider>;
}

export const OutdoorSlider = (props: React.PropsWithChildren<IOutdoorSliderProps>) => {
  const { slider } = props;

  const isMobile = window.innerWidth < 991;

  return (
    <_OutdoorSlider>
      <Container>
        <Row>
          <Carousel
            axis={isMobile ? 'horizontal' : 'vertical'}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            swipeable={true}
            emulateTouch={true}
            autoPlay={true}
            stopOnHover={true}
            infiniteLoop
            
          >
            {slider?.slides?.map((slide) => {
              return (
                <OutdoorSliderItem key={slide.id} slide={slide as IGroupsSlide} />
              );
            })}
          </Carousel>
        </Row>
      </Container>
    </_OutdoorSlider>
  );
};