import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { ContactClients, ContactForm, ContactInfo } from './views';
import { ContactGoogleMap } from './views/ContactGoogleMap';

const _ContactPage = styled.div`
`;

interface IContactPageProps {

}

export const ContactPage = (props: React.PropsWithChildren<IContactPageProps>) => {
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-contact' }]);

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];
  
  if (!page) {
    return null;
  }

  return (
    <_ContactPage>
      <ContactForm page={page} />
      <ContactClients page={page} />
      <ContactInfo page={page} />
      <ContactGoogleMap page={page} />
    </_ContactPage>
  );
};