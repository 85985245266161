import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { featureTypeResources, pageResources } from '@/api';
import { featureCategoryResources } from '@/api/featureCategory';

import {
    FeatureCategories,
    FeatureFilter,
    FeatureList,
    FeaturesCover
} from './views';

const _FeaturesPage = styled.div`
  padding-bottom: 100px;
`;

interface FeaturesPagePathParam {
  readonly categorySlug?: string;
  readonly typeSlug?: string;
}

interface IFeaturesPageProps { }

export const FeaturesPage = (props: React.PropsWithChildren<IFeaturesPageProps>) => {

  const pathParams = useParams<FeaturesPagePathParam>();

  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'features-page' }]);
  const [featureCategories] = useResourceAnonymous(featureCategoryResources.find);
  const [featureTypes] = useResourceAnonymous(featureTypeResources.find);

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  const selectedCategory = pathParams.categorySlug ? featureCategories?.find(o => o.slug === pathParams.categorySlug) : undefined;
  const selectedType = pathParams.typeSlug ? featureTypes?.find(o => o.slug === pathParams.typeSlug) : undefined;

  return (
    <_FeaturesPage>
      <FeaturesCover page={page} />
      <FeatureFilter
        headingText="FEATURE"
        featureCategories={featureCategories}
        featureTypes={featureTypes}
        activeCategorySlug={pathParams.categorySlug}
        activeTypeSlug={pathParams.typeSlug}
      />
      {
        selectedCategory ?
          (
            <FeatureList key={selectedCategory.id + selectedType?.id} category={selectedCategory!} type={selectedType} />
          )
          : (
            <FeatureCategories featureCategories={featureCategories} />
          )
      }
    </_FeaturesPage>
  );
};
