import { useResourceAnonymous, useResourceLazy } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions } from '@/_shared/components';
import { customerRequestResources, IProduct, settingResources } from '@/api';
import {
    _MCollectionFileRequestForm
} from '@/pages/m-collection-file-request/views/form';
import {
    MCollectionFileRequestFormThanks
} from '@/pages/m-collection-file-request/views/form/m-collection-file-request-form';
import {
    privacyPolicyPagePath
} from '@/pages/privacy-policy/PrivacyPolicyPage.shared';
import { textIgnoreCase } from '@/translation';

const _ModelQuoteRequestForm = styled(_MCollectionFileRequestForm)`

`;

interface IModelQuoteRequestFormProps {
  readonly model: IProduct;
}

export const ModelQuoteRequestForm = (props: React.PropsWithChildren<IModelQuoteRequestFormProps>) => {
  const { model } = props;

  const [thanksContentSettings] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'QUOTE_REQUEST_THANKS_CONTENT' }]);
  const [newCustomerRequest, loading, sendRequest] = useResourceLazy(customerRequestResources.create);

  React.useEffect(
    () => {
      if (!newCustomerRequest) {
        return;
      }
      const form = document.getElementById('newCustomerRequestForm') as HTMLFormElement;
      form?.reset();
    },
    [newCustomerRequest]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget);
      sendRequest([{
        type: 'body',
        value: {
          ...formValues,
          requestType: 'quote',
          modelId: model?.id,
          model: model?.name
        }
      }]);
    },
    [model?.id, model?.name, sendRequest]
  );

  return (
    <_ModelQuoteRequestForm>
      <GlobalModalNoActions isOpen={!!newCustomerRequest}>
        <MCollectionFileRequestFormThanks setting={thanksContentSettings && thanksContentSettings[0]} />
      </GlobalModalNoActions>
      <Form id="newCustomerRequestForm" className='form-contact' onSubmit={onSubmit}>
        <h2 className='title'>
          {textIgnoreCase('QUOTE REQUEST')}
          <br />
          <small>{model.name}</small>
        </h2>
        <p>
          {textIgnoreCase('Our Data are downloadable with a SpecifierPortal account')}.&nbsp;
                <Link to='/partner'>
            <b>{textIgnoreCase('Log in')} / {textIgnoreCase('Sign Up')}</b>
          </Link>
          <br />
          <br />
          {textIgnoreCase('Please fill in the request form below and a team member will be in touch as soon as possible.')}
        </p>
        <FormGroup>
          <Input type="text" name="name" id="name" placeholder={textIgnoreCase('Name') + ' *'} required={true} />
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Input type="email" name="email" id="email" placeholder={textIgnoreCase('Your email address') + ' *'} required={true} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Input type="text" name="phone" id="phone" placeholder={textIgnoreCase('Phone number') + ' *'} required={true} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='company-col' lg={6}>
            <FormGroup>
              <Input type="text" name="company" id="company" placeholder={textIgnoreCase('Company') + ' *'} required={true} />
            </FormGroup>
          </Col>
          <Col className='Job-title-col' lg={6}>
            <FormGroup>
              <Input type="text" name="jobTitle" id="jobTitle" placeholder={textIgnoreCase('Job title') + ' *'} required={true} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='Project-reference-col' lg={6}>
            <FormGroup>
              <Input type="text" name="projectReference" id="projectReference" placeholder={textIgnoreCase('Project reference')} />
            </FormGroup>
          </Col>
          <Col className='Postal-code-col' lg={6}>
            <FormGroup>
              <Input type="text" name="postalCode" id="postalCode" placeholder={textIgnoreCase('Postal code')} />
            </FormGroup>
          </Col>
        </Row>
        <div className='wraper-check'>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" />
              {textIgnoreCase('I would like to receive Bplusfuniture newsletter updates')}. <Link to={privacyPolicyPagePath}><b>{textIgnoreCase('See Privacy Policy')}</b></Link>.
                  </Label>
          </FormGroup>
        </div>
        <Button type="submit" disabled={loading}>
          {textIgnoreCase('Send')}
        </Button>
      </Form>
    </_ModelQuoteRequestForm>
  );
};