import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import styled from 'styled-components';

import { IProduct } from '@/api';
import { textIgnoreCase } from '@/translation';

import {
    MCollectionProductCard,
    MCollectionProductSearchBox
} from './m-collection-products';

const _MCollectionProducts = styled.div`
  .products-container {
    border-top: 0.5px solid #C4C4C4;
    border-right: 0.5px solid #C4C4C4;
  }
  .all-products-card-wraper{
    padding : 0;
  }
  .all-products-card {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 0.5px solid #C4C4C4;
  }

  .card-product {
    padding : 0;
    &:nth-child(4n) {
      .product-card {
        border-right: none;
      }
    }
    &:nth-child(4n+1) {
      .product-card {
        border-left: 0.5px solid #C4C4C4;
      }
    }
  }

  .product-card {
    min-height: 319px;
    border-bottom: 0.5px solid #C4C4C4;
    border-right: 0.5px solid #C4C4C4;
    padding : 0
  }
  
  @media (max-width: 991px) {
    .all-products-card-wraper{
      display :none;
    }
    .card-product {
      min-height: unset;
      &:nth-child(4n) {
        .product-card {
          border-right: 0.5px solid #C4C4C4;
        }
      }
      &:nth-child(4n+1) {
        .product-card {
          border-left: none;
        }
      }
    }

    .product-card {
      min-height: 319px;
      border-bottom: 0.5px solid #C4C4C4;
      border-left: none;
      border-right: 0.5px solid #C4C4C4;
      padding : 0
    }
  }
`;

interface IMCollectionProductsProps {
  readonly refetch: ({ search }) => void;
  readonly products: IProduct[];
  readonly loading?: boolean;
}

export const MCollectionProducts = (props: React.PropsWithChildren<IMCollectionProductsProps>) => {
  const { loading, products, refetch } = props;

  return (
    <_MCollectionProducts>
      <Container className="products-container">
        <Row md={4}>
          <Col className='all-products-card-wraper' lg={3} sm={12}>
            <div className="all-products-card product-card">
              <h3>
                {textIgnoreCase('ALL PRODUCT')}
              </h3>
              <p className="">{textIgnoreCase('Droll down to view products')}</p>
              <MCollectionProductSearchBox
                onSearch={(search) => refetch({ search })}
              />
              {loading && <Spinner color="dark" />}
            </div>
          </Col>
          {
            products.map((product) => {
              return (
                <Col className='card-product' key={product.id} lg={3} xs={12}>
                  <MCollectionProductCard product={product} />
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </_MCollectionProducts>
  );
};