
import { Resource } from 'libs/restful';

import { ICategory } from './category';
import { IFile } from './file';

export interface IProductType {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug: string;
  readonly thumbnail: Partial<IFile>;
  readonly category?: Partial<ICategory>;
  readonly published?: boolean;
  readonly cover?: IFile;
}

export const productTypeResources = {
  find: new Resource<IProductType, IProductType[]>({
    method: 'GET',
    url: '/admin/producttypes'
  }),
  count: new Resource<IProductType, number>({
    method: 'GET',
    url: '/admin/producttypes/count'
  }),
  findOne: new Resource<IProductType, IProductType>({
    method: 'GET',
    url: '/admin/producttypes/:id'
  }),
  create: new Resource<IProductType, IProductType>({
    method: 'POST',
    url: '/admin/producttypes'
  }),
  update: new Resource<IProductType, IProductType>({
    method: 'PUT',
    url: '/admin/producttypes/:id'
  }),
  delete: new Resource<IProductType, IProductType>({
    method: 'DELETE',
    url: '/admin/producttypes/:id'
  }),
};
