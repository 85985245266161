import { Resource } from 'libs/restful';

import { IFile } from './file';
import { IGroupsAgency } from './groupsAgency';
import { IGroupsWebsiteSetting } from './groupsWebsiteSetting';
import { IRole } from './role';

export interface IUser {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly username: string;
  readonly email: string;
  readonly provider?: string;
  readonly password?: string | null;
  readonly resetPasswordToken?: string;
  readonly confirmed?: boolean;
  readonly blocked?: boolean;
  readonly role?: Partial<IRole>;
  readonly agency?: Partial<IGroupsAgency>;  
  readonly websiteSetting?: Partial<IGroupsWebsiteSetting>;
  readonly displayName?: string;
  readonly company?: string;
  readonly companyWebsite?: string;
  readonly jobTitle?: string;
  readonly phone?: string;
  readonly verified?: boolean;
  readonly avatar?: IFile;
}

export interface IChangePassword {
  readonly oldPassword: string;
  readonly newPassword: string;
}

export const userResources = {
  
  find: new Resource<IUser, IUser[]>({
    method: 'GET',
    url: '/customer/users'
  }),
  me: new Resource<IUser, IUser>({
    method: 'GET',
    url: '/customer/users/me'
  }),
  findOne: new Resource<IUser, IUser>({
    method: 'GET',
    url: '/customer/users/:id'
  }),
  create: new Resource<IUser, IUser>({
    method: 'POST',
    url: '/customer/users',
  }),
  update: new Resource<IUser, IUser>({
    method: 'PUT',
    url: '/customer/users/:id',
  }),
  updateSelf: new Resource<IUser, IUser>({
    method: 'PUT',
    url: '/customer/users/self/:id',
  }),
  updateAgency: new Resource<IUser, IUser>({
    method: 'PUT',
    url: '/customer/users/agency/:id',
  }),
  updateWebsiteSetting: new Resource<IUser, IUser>({
    method: 'PUT',
    url: '/customer/users/website-setting/:id',
  }),
  changePassword: new Resource<IChangePassword, IUser>({
    method: 'PUT',
    url: '/customer/users/change-password/:id',
  }),
  destroy: new Resource<IUser, IUser>({
    method: 'DELETE',
    url: '/customer/users/:id'
  })
};
