import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { AboutContent, AboutImage } from './views';

const _AboutPage = styled.div`
  .col-lg-6{
    padding : 0;
  }
  @media (max-width : 992px) {
    .col-lg-6{
      padding :0 20px;
      &.col-left{
        padding :0;
      }
      &.col-right{
        margin: 30px 0;
      }
      &>div{
       max-width : 100%;
      }
    }
  }
`;

interface IAboutPageProps { }

export const AboutPage = (props: React.PropsWithChildren<IAboutPageProps>) => {
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-about-us' }]);

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_AboutPage>
      <Container>
        <Row>
          <Col className='col-left' lg="6">
            <AboutImage page={page} />
          </Col>
          <Col className='col-right' lg="6">
            <AboutContent page={page} />
          </Col>
        </Row>
      </Container>
    </_AboutPage>
  );
};
