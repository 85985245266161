import { useResource } from 'libs/restful';
import React, { useEffect } from 'react';
import { Container } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { IWishlist, wishlistResources } from '@/api';
import { currentUserState } from '@/state';

import { Wishlists } from './views';
import { LoginRequired } from './views/LoginRequired';

const _WishlistsPage = styled.div`

`;

interface IWishlistsPageProps {

}

export const WishlistsPage = (props: React.PropsWithChildren<IWishlistsPageProps>) => {

  const [currentUser] = useRecoilState(currentUserState);

  return (
    <_WishlistsPage>
      <Container>
        {
          currentUser 
            ? <Wishlists />
            : <LoginRequired />
        }
      </Container>
    </_WishlistsPage>
  );
};