import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { getUrlSearchParam } from '@/_shared';
import { Img } from '@/_shared/components';
import { IModel } from '@/api';
import {
    ModelPreview
} from '@/pages/design/views/design-page-tool/design-tool-preview';
import {
    _MCollectionFileRequest
} from '@/pages/m-collection-file-request/views';
import { textIgnoreCase } from '@/translation';

import { ModelQuoteRequestForm } from './model-quote-request';

export const _ModelQuoteRequest = styled(_MCollectionFileRequest)`
`;

interface IModelQuoteRequestProps {
  readonly model: IModel;
}

export const ModelQuoteRequest = (props: React.PropsWithChildren<IModelQuoteRequestProps>) => {
  const { model } = props;

  const sourceUrlStr = getUrlSearchParam('url');
  if (!sourceUrlStr) {
    return null;
  }

  const sourceURL = new URL(sourceUrlStr);

  return (
    <_ModelQuoteRequest>
      <Container>
        <Row>
          <Col className='col-left' lg={6}>
            <ModelPreview
              model={model}
              initQModules={sourceURL.searchParams.get('qModules')!}
              showHint={false}
            />
          </Col>
          <Col className='col-right' lg={6}>
            <ModelQuoteRequestForm
              model={model}
            />
          </Col>
        </Row>
      </Container>
    </_ModelQuoteRequest >
  );
};