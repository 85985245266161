import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IFile, IProduct } from '@/api';
import {
    MCollectionProductPhotos
} from '@/pages/m-collection-product/views/m-collection-product-detail';

const _OfficeProductPhotos = styled.div`
  .office-col {
    margin: 0;
    padding: 0;
    flex-basis: unset;
  }

  .bg-title {
    height: 100%;
    position: relative;
    .title {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-family: Copperplate;
      font-weight: 500;
      line-height: 45px;
      text-transform: uppercase;
      color: #fff;
    }
  }
  @media (max-width : 767px){
    .banner{
      display : none;
    }
  }
`;

interface IProductPhotosProps {
  readonly product: IProduct;
  readonly defaultProductFeaturePhoto?: Partial<IFile>;
}

export const ProductPhotos = (props: React.PropsWithChildren<IProductPhotosProps>) => {
  const { product, defaultProductFeaturePhoto } = props;

  return (
    <_OfficeProductPhotos>
      <Container>
        <Row md={2}>
          <Col className='office-col'>
            <MCollectionProductPhotos files={product.photos ?? []} />
          </Col>
          <Col className='office-col banner'>
            <div className='bg-title'>
              {
                product.featurePhoto
                  ? <Img file={product.featurePhoto} />
                  : <Img file={defaultProductFeaturePhoto} />
              }
              <div className='title'>
                <h1>{product.name}</h1>
                <h3>{product.productType?.name}</h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </_OfficeProductPhotos>
  );
};