import { useResourceLazy } from 'libs/restful';
import React from 'react';
import { Alert, Button, Col, Form, Input, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { authResources, IUser, userResources } from '@/api';
import { useAccessToken } from '@/hooks';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

const _PartnerRegisterForm = styled.div`
  .description{
  }
  .form-control{
    margin-bottom:10px;
    height : 50px;
    background: #E9E9E9;
    border: none;
  }
`;

interface IPartnerRegisterFormProps {
}

export const PartnerRegisterForm = (props: React.PropsWithChildren<IPartnerRegisterFormProps>) => {
  const [, setAccessToken] = useAccessToken();
  const [, setCurrentUser] = useRecoilState(currentUserState);
  const [newUser, , register, registerError] = useResourceLazy(authResources.register);
  const [formError, setFormError] = React.useState<string>();

  React.useEffect(
    () => {
      if (!newUser) {
        return;
      }

      setAccessToken(newUser.jwt);
      setCurrentUser(newUser.user);
    },
    [newUser, setAccessToken, setCurrentUser]
  );

  React.useEffect(
    () => {
      if (!registerError) {
        return;
      }

      setFormError(registerError.message ?? 'Error');
    },
    [registerError]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget) as IUser;

      if (formValues.password !== formValues['rePassword']) {
        return void setFormError(textIgnoreCase('Password doesn\'t match'));
      }

      if (formError) {
        setFormError('');
      }

      if (formValues)
        register([{
          type: 'body',
          value: {
            ...formValues,
            username: formValues.email
          }
        }]);
    },
    [register]
  );

  return (
    <_PartnerRegisterForm>
      <p className='description'>
        {textIgnoreCase('Please fill in the request form below and a team member will be in touch as soon as possible')}.
      </p>
      <Form onSubmit={onSubmit}>
        {formError && (<Alert color="danger">{textIgnoreCase(formError)}</Alert>)}
        <Row form>
          <Col md={12}>
            <Input type="text" name="displayName" placeholder={textIgnoreCase('Name')} />
          </Col>
          <Col md={6}>
            <Input type="number" name="phone" placeholder={textIgnoreCase('Phone')} />
          </Col>
          <Col md={6}>
            <Input type="email" name="email" placeholder={textIgnoreCase('Your email address')} />
          </Col>
          <Col md={6}>
            <Input type="password" name="password" placeholder={textIgnoreCase('Password')} />
          </Col>
          <Col md={6}>
            <Input type="password" name="rePassword" placeholder={textIgnoreCase('Repassword')} />
          </Col>
          <Col md={12}>
            <Input name="jobTitle" placeholder={textIgnoreCase('Job title')} />
          </Col>
          <Col md={12}>
            <Input type="text" name="company" placeholder={textIgnoreCase('Company')} />
          </Col>
          <Col md={12}>
            <Input type="text" name="companyWebsite" placeholder={textIgnoreCase('Company website')} />
          </Col>
        </Row>
        <Button color='black'>{textIgnoreCase('REGISTER')}</Button>
      </Form>
    </_PartnerRegisterForm>
  );
};