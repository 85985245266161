import React from 'react';
import styled from 'styled-components';

import { UserChangePasswordForm, UserProfileLayout } from './views';
import { UserProfileForm } from './views/UserProfileForm';

const _UserProfilePage = styled.div`
`;

interface IUserProfilePageProps {

}

export const UserProfilePage = (props: React.PropsWithChildren<IUserProfilePageProps>) => {
  return (
    <_UserProfilePage>
      <UserProfileLayout>
        <UserProfileForm />
      </UserProfileLayout>
      <UserChangePasswordForm />
    </_UserProfilePage>
  );
};