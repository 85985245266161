import { useResourceLazy } from 'libs/restful';
import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { IWishlistMoodboardItem, wishlistMoodboardItemResources } from '@/api';

const _WishlistMoodboardOrganize = styled.div`
  width: 550px;

  .drag-drop-container {
    max-height: 400px;
    min-height: 350px;
    overflow: auto;
    border: 1px solid #e4e5e7;
    margin: 1.5em 0;
  }

  .drag-drop-list {
    overflow: hidden;
  }

  .drag-drop-item {
    display: flex;
    border-bottom: 1px solid #e4e5e7;
    align-items: center;
    height: 60px;
    padding: 0.5em;
    cursor: row-resize;
    position: relative;
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #fff;
    }
  }

  .item {
    display: flex;
    align-items: center;
    position: relative;
  }

  .item-thumbnail {
    width: 60px;
    height: 40px;
    margin-right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e4e5e7;
    overflow: hidden;
    img {
      max-width: 100%;
    }
    .item-thumbnail-icon {
      height: 20px;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 768px) {
    width: ${window.innerWidth - 50}px;
  }
`;

interface IWishlistMoodboardOrganizeProps {
  readonly items: IWishlistMoodboardItem[];
  readonly onOk: () => void;
  readonly onCancel: () => void;
}

const reorder = (list: IWishlistMoodboardItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  borderTop: isDragging ? '1px solid #e4e5e7' : 'none',
  borderRight: isDragging ? '1px solid #e4e5e7' : 'none',
  borderLeft: isDragging ? '1px solid #e4e5e7' : 'none',
  userSelect: 'none',
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const WishlistMoodboardOrganize = (props: React.PropsWithChildren<IWishlistMoodboardOrganizeProps>) => {
  const [items, setItems] = React.useState(props.items);

  const [, reordering, updateOrder] = useResourceLazy(wishlistMoodboardItemResources.reorder);

  useEffect(() => {
    setItems(props.items);
  }, [props.items]);

  const handleReorder = async () => {
    await updateOrder([{
      type: 'body',
      value: {
        items: items.map((item) => ({ id: item.id }))
      }
    }]);

    props.onOk();
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(sortedItems);
  };

  return (
    <_WishlistMoodboardOrganize>
      <p>
        <strong>Organize</strong>
      </p>
      <div className="drag-drop-container">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided, droppableSnapshot) => (
              <div
                className="drag-drop-list"
                ref={droppableProvided.innerRef}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        {...draggableProvided.dragHandleProps}
                        className="drag-drop-item"
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style,
                        )}
                      >
                        <div className="item">
                          {
                            item.type === 'color' && (
                              <>
                                <div className='item-thumbnail' style={{ backgroundColor: item.color }} />
                                <div>{item.color}</div>
                              </>
                            )
                          }
                          {
                            item.type === 'text' && (
                              <>
                                <div className='item-thumbnail'>
                                  <img className="item-thumbnail-icon" src="/static/assets/mb-text.webp" />
                                </div>
                                <div>
                                  {(new RegExp('"text":"(.*?)"', 'g')).exec(item.text!)?.filter(o => !!o && !o.startsWith('"text"')).map(o => o.trim()).join('. ')}
                                </div>
                              </>
                            )
                          }
                          {
                            item.type === 'image' && (
                              <>
                                <div className='item-thumbnail'>
                                  <img src={item.image} />
                                </div>
                                <div>Image</div>
                              </>
                            )
                          }
                        </div>
                        <div className="item-content">

                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="actions">
        <Button type="button" color="secondary" onClick={() => props.onCancel()}>
          Cancel
        </Button>
        <Button type="button" color="black" onClick={() => handleReorder()} disabled={reordering}>
          Update
        </Button>
      </div>
    </_WishlistMoodboardOrganize>
  );
};