import { useResourceLazy } from 'libs/restful';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Input, Popover, UncontrolledPopover } from 'reactstrap';
import { useRecoilState } from 'recoil';
import slugify from 'slugify';
import styled from 'styled-components';

import { GlobalModalNoActions } from '@/_shared/components';
import { IWishlist, wishlistResources } from '@/api';
import { officePath } from '@/pages/products/ProductsPage.shared';
import { wishlistsPagePath } from '@/pages/whislists/WishlistsPage.shared';
import { textIgnoreCase } from '@/translation';

import { wishlistItemsState } from '../WishlistDetailPage.shared';
import { WishlistItemExternalForm } from './wishlist-header';
import { WishlistSendMailForm } from './wishlist-header/WishlistSendMailForm';

export const _WishlistDetailHeader = styled.div`
  margin-bottom: 30px;
  .header-wrapper {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  .header-name {
    flex-grow: 1;
  }

  .header-actions {
    display: flex;
    gap: 12px;
  }
`;

const _HeaderAction = styled.a`
  font-size: 18px;
  width: 150px;
  cursor: pointer;
  display: flex;
  &:hover {
    font-weight: unset;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .header-action-icon {
    margin-right: 15px;
  }
`;

interface IWishlistDetailHeaderProps {
  readonly wishlist: IWishlist;
}

export const WishlistDetailHeader = (props: React.PropsWithChildren<IWishlistDetailHeaderProps>) => {
  const history = useHistory();

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [wishlistItems, updateWishlistItems] = useRecoilState(wishlistItemsState);
  const [wishlist, setWishlist] = useState(props.wishlist);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSendMailModal, setShowSendMailModal] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [, deleting, deleteWishlist] = useResourceLazy(wishlistResources.delete);
  const [, updating, updateWishlist] = useResourceLazy(wishlistResources.update);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleDelete = async () => {
    await deleteWishlist([{ type: 'path', parameter: 'id', value: wishlist.id }]);

    history.replace(wishlistsPagePath);
  };

  const handleUpdate = async () => {
    if (!inputRef.current || !inputRef.current.value) {
      return;
    }

    const nextWishlist = await updateWishlist([{
      type: 'path',
      parameter: 'id',
      value: wishlist.id
    }, {
      type: 'body',
      value: { name: inputRef.current.value }
    }]);

    setWishlist(nextWishlist);

    setShowEditModal(false);
  };

  const projectSlug = slugify(wishlist.name, { lower: true });

  return (
    <_WishlistDetailHeader>
      <div className="header-wrapper">
        <h2 className="header-name">{wishlist.name}</h2>
        <div className="header-actions">
          <Button type="button" id="addProduct">
            Add product
          </Button>
          <Button type="button" id="sendMail" onClick={() => setShowSendMailModal(true)}>
            <i className="fa fa-paper-plane-o" aria-hidden="true"></i> Send mail
          </Button>
          <Button id="Popover1" type="button">
            <i className="fa fa-ellipsis-v" />
          </Button>

          <UncontrolledPopover placement="bottom-end" offset={1} target="addProduct" trigger="hover">
            <_HeaderAction className="header-action" onClick={() => { history.push(officePath); }}>
              {textIgnoreCase('From existing')}
            </_HeaderAction>
            <_HeaderAction className="header-action" onClick={() => setShowCreateModal(true)}>
              {textIgnoreCase('External product')}
            </_HeaderAction>
          </UncontrolledPopover>

          <Popover placement="bottom-end" offset={1} target="Popover1" toggle={togglePopover} isOpen={popoverOpen} trigger="hover">
            <_HeaderAction className="header-action" href={`https://api.bplusfurniture.com.vn/customer/wishlists/getPDF/${wishlist.id}/${projectSlug}.pdf`} target="_blank" rel="noreferrer">
              <span className="header-action-icon"><i className="fa fa-file-pdf-o" /></span>
              {textIgnoreCase('Download PDF')}
            </_HeaderAction>
            <_HeaderAction className="header-action" onClick={() => { setShowEditModal(true); togglePopover(); }}>
              <span className="header-action-icon"><i className="fa fa-pencil" /></span>
              {textIgnoreCase('Edit')}
            </_HeaderAction>
            <_HeaderAction className="header-action" onClick={() => { setShowDeleteModal(true); togglePopover(); }}>
              <span className="header-action-icon"><i className="fa fa-trash" /></span>
              {textIgnoreCase('Delete')}
            </_HeaderAction>
          </Popover>
        </div>
      </div>
      <GlobalModalNoActions isOpen={showEditModal} onClosed={() => setShowEditModal(false)}>
        <div style={{ width: 340 }}>
          <p style={{ fontSize: 20, textAlign: 'center' }}>
            {textIgnoreCase('Edit this project')}
          </p>
          <div>
            <Input
              innerRef={inputRef}
              defaultValue={wishlist.name}
              placeholder={textIgnoreCase('Project name')}
              handleUpdate={handleUpdate}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
            <Button
              className="btn-black"
              onClick={handleUpdate}
              disabled={updating}
            >
              Edit
            </Button>
          </div>
        </div>
      </GlobalModalNoActions>
      <GlobalModalNoActions isOpen={showDeleteModal} onClosed={() => setShowDeleteModal(false)}>
        <div>
          <p style={{ fontSize: 20 }}>
            {textIgnoreCase('Do you want to delete this project?')}
          </p>
          <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
            <Button className="btn-black" onClick={handleDelete} disabled={deleting}>Delete</Button>
          </div>
        </div>
      </GlobalModalNoActions>

      <GlobalModalNoActions isOpen={showCreateModal} onClosed={() => setShowCreateModal(false)}>
        <WishlistItemExternalForm
          onCreated={(createdItem) => {
            updateWishlistItems([...wishlistItems, createdItem]);
            setShowCreateModal(false);
          }}
          onCanceled={() => setShowCreateModal(false)}
          wishlistId={wishlist.id}
        />
      </GlobalModalNoActions>

      <GlobalModalNoActions isOpen={showSendMailModal} onClosed={() => setShowSendMailModal(false)}>
        <WishlistSendMailForm
          wishlist={wishlist}
          onCanceled={() => setShowSendMailModal(false)}
          onSent={() => setShowSendMailModal(false)}
        />
      </GlobalModalNoActions>
    </_WishlistDetailHeader>
  );
};