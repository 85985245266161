
import { Resource } from 'libs/restful';

import { IGroupsSlide } from './groupsSlide';

export interface ISlider {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly slides: Partial<IGroupsSlide>[];
}

export const sliderResources = {
  find: new Resource<ISlider, ISlider[]>({
    method: 'GET',
    url: '/admin/sliders'
  }),
  count: new Resource<ISlider, number>({
    method: 'GET',
    url: '/admin/sliders/count'
  }),
  findOne: new Resource<ISlider, ISlider>({
    method: 'GET',
    url: '/admin/sliders/:id'
  }),
  create: new Resource<ISlider, ISlider>({
    method: 'POST',
    url: '/admin/sliders'
  }),
  update: new Resource<ISlider, ISlider>({
    method: 'PUT',
    url: '/admin/sliders/:id'
  }),
  delete: new Resource<ISlider, ISlider>({
    method: 'DELETE',
    url: '/admin/sliders/:id'
  }),
};
