import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import { App } from './_app';

export const RootExtended = (props) => {
  return (
    <RecoilRoot>
      <App {...props} />
    </RecoilRoot>
  );
};

export default () => {
  const element = (
    <RootExtended />
  );
  ReactDOM.render(element, document.getElementById('root'));
};
