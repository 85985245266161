import classNames from 'classnames';
import { RestfulRender } from 'libs/restful';
import * as React from 'react';
import { Col, Input, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import {
    customMaterialResources,
    customMaterialTypeResources,
    ICustomMaterial,
    ICustomMaterialType,
    IGroupsComponent
} from '@/api';

import { DesignToolBase } from '../DesignToolBase';

export const _DesignToolMaterialListCustom = styled.div`
  /** */
`;

interface IDesignToolMaterialListCustomProps {
  readonly selectedComponent: Partial<IGroupsComponent>;
}

interface IDesignToolMaterialListCustomState {
  readonly selectedCustomMataterialTypeId?: string;
}

export class DesignToolMaterialListCustom extends DesignToolBase<
  IDesignToolMaterialListCustomProps,
  IDesignToolMaterialListCustomState
  > {

  // tslint:disable-next-line:max-func-body-length
  public render() {
    const {
      selectedComponent
    } = this.props;
    const { selectedCustomMataterialTypeId } = this.state;

    const selectedMaterialId = this.getSelectedMaterialId();

    return (
      <_DesignToolMaterialListCustom>
        <RestfulRender
          resource={customMaterialTypeResources.find}
          onFetchCompleted={this.setDefaultCustomMaterialTypeId}
        >
          {({ data }) => {
            return (
              <Input
                type="select"
                onChange={this.onCustomMaterialTypeChange}
              >
                {data?.map((customMaterialType) => {
                  return (
                    <option
                      key={customMaterialType.id}
                      value={customMaterialType.id}
                      aria-selected="true"
                    >
                      {customMaterialType.name}
                    </option>
                  );
                })}
              </Input>
            );
          }}
        </RestfulRender>
        {
          selectedCustomMataterialTypeId && (
            <RestfulRender
              resource={customMaterialResources.find}
              parameters={[{
                type: 'query',
                parameter: "custommaterialtype",
                value: selectedCustomMataterialTypeId
              }]}
            >
              {({ data }) => {

                return (
                  <Row>
                    {
                      data?.map((customMaterial) => {
                        const isSelected = customMaterial.id === selectedMaterialId;

                        const onMaterialClick = () => {
                          const { qModules } = this.state;
                          const qModuleObject = qModules ? JSON.parse(qModules) : {};

                          const componentType = selectedComponent?.type;
                          if (!componentType) {
                            return;
                          }
                          
                          const qMaterial = {
                            id: customMaterial.id,
                            name: `[Custom] ${customMaterial.name}`,
                            type: '#custom',
                            custom: true
                          };

                          if (qModuleObject[componentType]) {
                            qModuleObject[componentType].material = qMaterial;
                          } else {
                            qModuleObject[componentType] = {
                              material: qMaterial
                            };
                          }

                          this.setState({
                            qModules: JSON.stringify(qModuleObject)
                          });
                        };

                        return (
                          <Col key={customMaterial.id} xs="3">
                            <div
                              className={classNames('material', { selected: isSelected })}
                              onClick={onMaterialClick}
                              role="button"
                            >
                              <Img file={customMaterial.texture} ratioX={1} ratioY={1.01} />
                            </div>
                          </Col>
                        );
                      })
                    }
                  </Row>
                );
              }}
            </RestfulRender>
          )
        }
      </_DesignToolMaterialListCustom >
    );
  }

  private readonly onCustomMaterialTypeChange = (e) => {
    this.setState({
      selectedCustomMataterialTypeId: e.target.value
    });
  }

  private readonly setDefaultCustomMaterialTypeId = (data: ICustomMaterialType[]) => {
    if (!data.length) {
      return;
    }

    this.setState({
      selectedCustomMataterialTypeId: data[0].id,
    });
  }
}
