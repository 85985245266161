
import { Resource } from 'libs/restful';

import { IFile } from './file';
import { IGroupsPageSetting } from './groupsPageSetting';
import { IBanner } from './banner';
import { ISlider } from './slider';

export interface IPage {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug: string;
  readonly cover?: Partial<IFile>;
  readonly coverSmall?: Partial<IFile>;
  readonly content?: string;
  readonly content_en?: string;
  readonly settings?: Partial<IGroupsPageSetting>[];
  readonly banners?: Partial<IBanner>[];
  readonly slider?: Partial<ISlider>;
}

export const pageResources = {
  find: new Resource<IPage, IPage[]>({
    method: 'GET',
    url: '/admin/pages'
  }),
  count: new Resource<IPage, number>({
    method: 'GET',
    url: '/admin/pages/count'
  }),
  findOne: new Resource<IPage, IPage>({
    method: 'GET',
    url: '/admin/pages/:id'
  }),
  create: new Resource<IPage, IPage>({
    method: 'POST',
    url: '/admin/pages'
  }),
  update: new Resource<IPage, IPage>({
    method: 'PUT',
    url: '/admin/pages/:id'
  }),
  delete: new Resource<IPage, IPage>({
    method: 'DELETE',
    url: '/admin/pages/:id'
  }),
};
