
import { Resource } from 'libs/restful';

import { IUser } from './user';

export interface IProjectRequest {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly projectName: string;
  readonly projectType: string;
  readonly constructionSite: string;
  readonly createdBy?: Partial<IUser>;
}

export const projectRequestResources = {
  find: new Resource<IProjectRequest, IProjectRequest[]>({
    method: 'GET',
    url: '/customer/projectrequests'
  }),
  count: new Resource<IProjectRequest, number>({
    method: 'GET',
    url: '/customer/projectrequests/count'
  }),
  findOne: new Resource<IProjectRequest, IProjectRequest>({
    method: 'GET',
    url: '/customer/projectrequests/:id'
  }),
  create: new Resource<IProjectRequest, IProjectRequest>({
    method: 'POST',
    url: '/customer/projectrequests'
  }),
  update: new Resource<IProjectRequest, IProjectRequest>({
    method: 'PUT',
    url: '/customer/projectrequests/:id'
  }),
  delete: new Resource<IProjectRequest, IProjectRequest>({
    method: 'DELETE',
    url: '/customer/projectrequests/:id'
  }),
};
