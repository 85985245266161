import React from 'react';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IPage } from '@/api';
import { textIgnoreCase } from '@/translation';

const _AboutImage = styled.div`
  position: relative;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    z-index: 1;
  }
  .img-wrapper {
    height: 100%;
  }
  .information {
    position: absolute;
    width: 250px;
    height: 96px;
    left: 213px;
    top: 340px;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 50px;
    text-transform: uppercase;
    color: #ffffff;
    z-index: 2;
    h4 {
      text-transform: uppercase;
      font-size: 36px;
      font-family: 'Copperplate';
      font-style: normal;
      font-weight: normal;
      line-height: 50px;
    }
    p {
      font-size: 30px;
      margin-bottom: 10px;
      font-family: 'Copperplate';
    }
  }
  @media (max-width : 992px){
    .information {
      position: absolute;
      width: 250px;
      height: 96px;
      font-size: 30px;
      line-height: 50px;
      text-transform: uppercase;
      color: #ffffff;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      top: 0;
      flex-direction: column;
      left: 0;
      padding-left: 20px;
      justify-content: center;  
    }
  }
`;

interface IAboutImageProps {
  readonly page: IPage;
}

export const AboutImage = (
  props: React.PropsWithChildren<IAboutImageProps>
) => {
  const { page } = props;

  return (
    <_AboutImage>
      <Img file={page.cover} />
      <div className="information">
        <p>{textIgnoreCase('the story')}</p>
        <h4>{textIgnoreCase('about us')}</h4>
      </div>
    </_AboutImage>
  );
};
