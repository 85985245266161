
import { Resource } from 'libs/restful';

import { ICategory } from './category';
import { IDesigner } from './designer';
import { IFile } from './file';
import { IGroupsComponent } from './groupsComponent';
import { IGroupsComponentVariant } from './groupsComponentVariant';
import { IGroupsProperty } from './groupsProperty';
import { IGroupsSceneControlSetting } from './groupsSceneControlSetting';
import { IModelLightMap } from './modelLightMap';
import { IProductType } from './productType';

export interface IModel {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly slug?: string;
  readonly description?: string;
  readonly description_en?: string;
  readonly components?: Partial<IGroupsComponent>[];
  readonly properties?: Partial<IGroupsProperty>[];
  readonly photos?: Partial<IFile>[];
  readonly thumbnail?: Partial<IFile>;
  readonly details?: object;
  readonly details_en?: object;
  readonly designer?: Partial<IDesigner>;
  readonly sceneControlSetting?: Partial<IGroupsSceneControlSetting>;
  readonly productType?: Partial<IProductType>;
  readonly modellightmap?: Partial<IModelLightMap>;
  readonly componentVariants?: Partial<IGroupsComponentVariant>[];
  readonly attachments?: Partial<IFile>[];
  readonly displayPriority?: number;
  readonly defaultUrl?: string;
  readonly feedbackPhotos?: Partial<IFile>[];
  readonly published?: boolean;
  readonly category?: Partial<ICategory>;
  readonly featurePhoto?: IFile;
  readonly processPhotos?: IFile[];
  readonly illustrationsPhotos?: IFile[];
}

export const modelResources = {
  find: new Resource<IModel, IModel[]>({
    method: 'GET',
    url: '/admin/models'
  }),
  count: new Resource<IModel, number>({
    method: 'GET',
    url: '/admin/models/count'
  }),
  findOne: new Resource<IModel, IModel>({
    method: 'GET',
    url: '/admin/models/:id'
  }),
  create: new Resource<IModel, IModel>({
    method: 'POST',
    url: '/admin/models'
  }),
  update: new Resource<IModel, IModel>({
    method: 'PUT',
    url: '/admin/models/:id'
  }),
  delete: new Resource<IModel, IModel>({
    method: 'DELETE',
    url: '/admin/models/:id'
  }),
};
