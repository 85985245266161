import React from 'react';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IFeature } from '@/api';

import { FeatureProductPoint } from './feature-detail-cover';

const _FeatureDetailsCover = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  .text {
    font-family: Copperplate;
    font-weight: bold;
    font-size: 42px;
    line-height: 40px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface IFeatureDetailsCoverProps {
  readonly feature: IFeature;
}

export const FeatureDetailsCover = (props: React.PropsWithChildren<IFeatureDetailsCoverProps>) => {

  const { feature } = props;
  const featureProducts = feature.featureProducts;

  return (
    <_FeatureDetailsCover>
      <Img file={props.feature.cover} />
      <div className="text">{props.feature.coverText ?? 'FEATURE'}</div>
      <div className="feature-product-points">
        {
          featureProducts?.filter(o => !!o.bgPointX && !!o.bgPointY)
            .map((featureProduct, index) => (
              <FeatureProductPoint
                key={featureProduct.id}
                index={index + 1}
                featureProduct={featureProduct}
              />
            ))
        }
      </div>
    </_FeatureDetailsCover>
  );
};