
import { Resource } from 'libs/restful';


export interface ISubscriber {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly email?: string;
}

export const subscriberResources = {
  find: new Resource<ISubscriber, ISubscriber[]>({
    method: 'GET',
    url: '/admin/subscribers'
  }),
  count: new Resource<ISubscriber, number>({
    method: 'GET',
    url: '/admin/subscribers/count'
  }),
  findOne: new Resource<ISubscriber, ISubscriber>({
    method: 'GET',
    url: '/admin/subscribers/:id'
  }),
  create: new Resource<ISubscriber, ISubscriber>({
    method: 'POST',
    url: '/admin/subscribers'
  }),
  update: new Resource<ISubscriber, ISubscriber>({
    method: 'PUT',
    url: '/admin/subscribers/:id'
  }),
  delete: new Resource<ISubscriber, ISubscriber>({
    method: 'DELETE',
    url: '/admin/subscribers/:id'
  }),
};
