import classNames from 'classnames';
import { useResource, useResourceLazy } from 'libs/restful';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Input } from 'reactstrap';
import styled from 'styled-components';

import { GlobalModalNoActions, Img, PageLoading } from '@/_shared/components';
import { IWishlist, wishlistResources } from '@/api';
import {
    getWishlistDetailUrl
} from '@/pages/wishlist-detail/WishlistDetailPage.shared';
import { textIgnoreCase } from '@/translation';

const _Wishlists = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  
  .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
  }

  .wishlists {
  }

  .wishlist {
    height: 180px;
    border: 1px solid #C4C4C4;
    margin-bottom: 12px;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #F5F5F5;
    }

    @media (max-width: 768px) {
      display: flex;
      align-items: unset;
      flex-direction: column;
      height: auto;
    }
  }

  .wishlist-item-info {
    flex-grow: 1
  }

  .wishlist-name {
    font-size: 20px;
    margin-bottom: 4px;
    font-weight: 700;
  }

  .wishlist-description {
    font-size: 16px;
    font-weight: 500;
    color: #777;
  }

  .wishlist-item-thumbnails {
    display: flex;
    gap: 10px;
  }

  .wishlist-item-thumbnail {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
    &.more {
      position: relative;
      display: flex;
      font-size: 30px;
      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        font-weight: 700;
      }
      &:after {
        content: " ";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(209,210,212,0.8);
      }

      @media (max-width: 768px) {
        font-size: 21px;
      }
    }

    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
      border: 1px solid #C4C4C4;
      margin-top: 8px;
    }
  }

  .wishlists-empty {
    text-align: center;
    padding: 100px 0;
  }
`;

const _CreateWishlist = styled.div`
  width: 400px;
  text-align: center;
  button {
    min-width: 150px;
  }
`;

interface IWishlistsProps {
}

export const Wishlists = (props: React.PropsWithChildren<IWishlistsProps>) => {
  const [createModalVisible, setCreateModalVisible] = React.useState(false);

  const [wishlists, setWishlists] = React.useState<IWishlist[]>([]);
  const [fetchedWishlist, loading] = useResource(wishlistResources.find);
  const [, isCreating, createWishlist,] = useResourceLazy(wishlistResources.create);
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!fetchedWishlist) {
      return;
    }

    setWishlists([...fetchedWishlist]);
  }, [fetchedWishlist]);

  const handleCreate = async () => {
    const name = inputRef.current?.value;
    if (!name) {
      return;
    }

    const newWishlist = await createWishlist([{
      type: 'body',
      value: { name }
    }]);

    setCreateModalVisible(false);
    setWishlists([newWishlist, ...wishlists]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const isEmpty = wishlists.length === 0 && !loading;

  return (
    <_Wishlists>
      <div className="header">
        <h2 style={{ flexGrow: 1 }}>Projects</h2>
        <div className="actions">
          <Button className="btn-black" onClick={() => setCreateModalVisible(true)}>Create</Button>
        </div>
      </div>
      <div className="wishlists">
        {
          loading && (
            <PageLoading />
          )
        }
        {
          isEmpty && (
            <div className="wishlists-empty">
              <h4>You have no projects yet</h4>
              <p>Click the button above to create a new project</p>
            </div>
          )
        }
        {wishlists.map(o => {
          return (
            <Link key={o.id} className="wishlist" to={getWishlistDetailUrl(o.id)}>
              <div className="wishlist-item-info">
                <div className="wishlist-name">{o.name}</div>
                <div className="wishlist-description">
                  {o.wishlistItems.length}&nbsp;
                  {o.wishlistItems.length > 1 ? textIgnoreCase('products') : textIgnoreCase('product')}
                </div>
              </div>
              <div className="wishlist-item-thumbnails">
                {o.wishlistItems.map((p, i) => {
                  if (i > 3) {
                    return null;
                  }

                  const moreCount = o.wishlistItems.length - 4;

                  return (
                    <div key={i} className={classNames('wishlist-item-thumbnail', { more: i === 3 })}>
                      <Img file={p.thumbnail} ratioX={1} ratioY={1} />
                      {
                        (moreCount > 0 && i === 3) && (<span>+{moreCount}</span>)
                      }
                    </div>
                  );
                })}
              </div>
            </Link>
          );
        })}
      </div>
      <GlobalModalNoActions isOpen={createModalVisible} onClosed={() => setCreateModalVisible(false)}>
        <_CreateWishlist>
          <h4>{textIgnoreCase('Create new project')}</h4>
          <div className="mt-4 mb-4">
            <Input innerRef={inputRef} placeholder={textIgnoreCase('Project name')} autoFocus />
          </div>
          <div className="modal-footer-actions">
            <Button className="" onClick={() => setCreateModalVisible(false)}>
              {textIgnoreCase('Cancel')}
            </Button>
            <Button className="btn-black" onClick={() => handleCreate()} disabled={isCreating}>
              {textIgnoreCase('Create')}
            </Button>
          </div>
        </_CreateWishlist>
      </GlobalModalNoActions>
    </_Wishlists>
  );
};