import React from 'react';

interface IDefaultLayoutMainProps {

}

export const DefaultLayoutMain = (props: React.PropsWithChildren<IDefaultLayoutMainProps>) => {
  return (
    <main>
      { props.children}
    </main>
  );
};