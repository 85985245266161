import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { getFileUrl } from '@/_shared';
import { GlobalModalNoActions } from '@/_shared/components';
import { IFile, IModel } from '@/api';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

const _DesignToolAttackments = styled.div`
  /** Styles here */
  position: relative;
  .attachments-header {
    font-weight: 300;
    margin-bottom: 6px;
  }

  .attachments-content {
    display: flex;
    justify-content: flex-end;
  }

  .attachments-item {
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  .attachments-icon {
    background: #E9E9E9;
    display: flex;
    flex-direction: column;
    justify-content:  flex-end;
    align-items: center;
    width: 47px;
    height: 56px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    color: #7E7E7E;
    cursor: pointer;
    padding: 4px;
  }
`;

interface IDesignToolAttackmentsProps {
  readonly model?: IModel;
}

export const DesignToolAttackments = (props: IDesignToolAttackmentsProps) => {
  const { model } = props;

  const [currentUser] = useRecoilState(currentUserState);
  const [showNotifyModal, setShowNotifyModal] = React.useState(false);

  const [currentDownloadFile, setCurrentDownloadFile] = React.useState<IFile>(null);

  if (!model) {
    return null;
  }

  const canDownload = !!currentUser;

  return (
    <_DesignToolAttackments>
      <GlobalModalNoActions isOpen={showNotifyModal} onClosed={() => setShowNotifyModal(false)}>
        <div style={{ minWidth: '300px', textAlign: 'center' }}>
          <div className="mb-1"><strong style={{ fontSize: '16px', textTransform: 'uppercase' }}>{textIgnoreCase('You are blocked')}!</strong></div>
          <div className="mb-3">
            {textIgnoreCase('Please register to our service to use this feature.')}
          </div>
          <div>
            <Button
              className="active"
              color='black'
              tag={Link}
              to="/partner"
              style={{ padding: '5px 10px' }}
            >
              {textIgnoreCase('Register')}
            </Button>
          </div>
        </div>
      </GlobalModalNoActions>
      {
        !!model.attachments?.length && (
          <div className="attachments">
            <div className="attachments-content">
              {model.attachments.map((attachmentFile) => {
                const downloadUrl = canDownload ? getFileUrl(attachmentFile) : undefined;

                return (
                  <div
                    key={attachmentFile.id}
                    className="attachments-item"
                  >
                    {
                      downloadUrl
                        ? (
                          <span
                            onClick={async () => {
                              setCurrentDownloadFile(attachmentFile);
                              await convertFileToBlobAndDownload(downloadUrl, `${model.name}.${attachmentFile.ext}`);
                              setCurrentDownloadFile(null);
                            }}
                          >
                            {
                              attachmentFile.id === currentDownloadFile?.id
                                ? (<span className="attachments-icon">
                                  <i className="fa fa-spinner fa-spin" />
                                  {attachmentFile.ext}
                                </span>
                                )
                                : (
                                  <span className="attachments-icon">
                                    {attachmentFile.ext}
                                  </span>
                                )
                            }

                          </span>
                        )
                        : (
                          <a
                            rel="noreferrer"
                            onClick={() => setShowNotifyModal(true)}
                          >
                            <span className="attachments-icon">
                              {attachmentFile.ext}
                            </span>
                          </a>
                        )
                    }
                  </div>
                );
              })}
            </div>
          </div>
        )
      }
    </_DesignToolAttackments>
  );
};

const convertFileToBlobAndDownload = async (file, name) => {
  const blob = await fetch(file).then(r => r.blob());
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = name; // add custom extension here
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};