import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { ContentPageContent } from './views';

const _ContentPage = styled.div`
`;

interface IContentPageProps {

}

export const ContentPage = (props: React.PropsWithChildren<IContentPageProps>) => {
  const params = useParams();

  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: params['pageSlug'] }]);

  if (!pages || !pages.length) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_ContentPage>
      <ContentPageContent page={page} />
    </_ContentPage>
  );
};