import { useResourceLazy } from 'libs/restful';
import React, { FormEventHandler } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';

import { IWishlist, wishlistResources } from '@/api';
import { textIgnoreCase } from '@/translation';

const _WishlistSendMailForm = styled.div`
  width: 500px;
  min-width: 360px;

  .header {
    text-align: center;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
      width: 120px;
    }
  }
`;

interface IWishlistSendMailFormProps {
  readonly wishlist: IWishlist;
  readonly onCanceled: () => void;
  readonly onSent: () => void;
}

export const WishlistSendMailForm = (props: React.PropsWithChildren<IWishlistSendMailFormProps>) => {
  const { } = props;

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [, sending, sendMail] = useResourceLazy(wishlistResources.sendMail);

  const handleSendMail: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const body = {
      email: String(data.get('email')),
    };

    try {
      await sendMail([
        { type: 'path', parameter: 'id', value: props.wishlist.id },
        { type: 'body', value: body as any }
      ]);

      setShowSuccess(true);
    }
    catch (err) {
      console.error(err);
    }
  };

  if (showSuccess) {
    return (
      <_WishlistSendMailForm>
        <div className="header">
          <h4>{textIgnoreCase('Share project')}</h4>
          <p>{textIgnoreCase('Your project successfully sent')}.</p>
        </div>
        <div className="actions">
          <Button className="btn-black" onClick={props.onSent}>
            {textIgnoreCase('Ok')}
          </Button>
        </div>
      </_WishlistSendMailForm>
    );
  }

  return (
    <_WishlistSendMailForm>
      <div className="header">
        <h4>{textIgnoreCase('Share project')}</h4>
        <p>{textIgnoreCase('Please enter a valid email address to share this project')}.</p>
      </div>
      <form onSubmit={handleSendMail}>
        <div className="form-group">
          <label htmlFor="email"><strong>{textIgnoreCase('Send to')}:</strong> </label>
          <input className="form-control" id="email" name="email" type="email" required />
        </div>
        <div className="actions">
          <Button className="" type="button" disabled={sending} onClick={props.onCanceled}>
            {textIgnoreCase('Cancel')}
          </Button>
          <Button className="btn-black" type="submit" disabled={sending}>
            {textIgnoreCase('Send')}
          </Button>
        </div>
      </form>
    </_WishlistSendMailForm>
  );
};