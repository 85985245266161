import { ReactUrlQuery } from 'libs/react-url-query';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import * as React from 'react';

import {
    browserHistory,
    formatCurrency,
    getFileUrl,
    getModelPrice,
    IFurnitureModule
} from '@/_shared';
import { IProductModule, ThreeSence } from '@/_shared/components';
import { IGroupsComponent, IGroupsComponentVariant, IModel } from '@/api';

interface IQueryModule extends IFurnitureModule {
}

interface IDesignToolBaseProps {
  readonly model?: IModel;
  readonly initQModules?: string;
}

interface IDesignToolBaseState {
  readonly hadDecorations?: boolean;
  readonly qModules?: string;
  readonly selectedComponentId?: string;
  readonly selectedVariantId?: string;
}

export class DesignToolBase<P = Record<string, unknown>, S =  Record<string, unknown>> extends React.Component<
  P & IDesignToolBaseProps,
  Partial<S> & IDesignToolBaseState
  > {
  public static readonly three3DRef = React.createRef<ThreeSence>();

  private readonly urlQuery = new ReactUrlQuery(this, browserHistory);

  protected constructor(props: P & IDesignToolBaseProps) {
    super(props);
    this.state = {
      selectedComponentId: this.urlQuery.syncWithUrl('selectedComponentId') as string,
      qModules: props.initQModules ?? this.urlQuery.syncWithUrl('qModules') as string,
      hadDecorations: this.urlQuery.syncWithUrl('hadDecorations', true as any) as boolean,
      selectedVariantId: this.urlQuery.syncWithUrl('selectedVariantId') as string
    } as Partial<S> & IDesignToolBaseState;
  }

  public readonly getCurrentDetails = () => {
    const { model } = this.props;

    if (!model) {
      return {};
    }

    const currentModules = this.getQModules();
    const modules3d = this.getModules3D();

    const detailSections = Object.keys(model.details || {});
    const propertyRegex = /^\{(.*?)\}$/;

    const result = {};

    detailSections.forEach((section) => {
      const details = {
        ...get(model.details, section)
      };

      for (const module3D of modules3d) {
        if (!module3D.component.details) {
          continue;
        }

        map(module3D.component.details[section], (componentDetailValue, componentDetailKey) => {
          details[componentDetailKey] = componentDetailValue;
        });
      }
      result[section] = {};

      map(details, (detailValue: string, detailKey) => {
        const isModuleProperty = propertyRegex.test(detailValue);
        const property = isModuleProperty ? propertyRegex.exec(detailValue)![1] : false;

        result[section][detailKey] =
          property
            ? get(currentModules, property, '...')
            : detailValue;
      });
    });

    return result;
  }

  public readonly getCurrentModulesPrice = () => {
    const { model } = this.props;

    const modules = this.getFurnitureModules();

    return model
      ? getModelPrice(model, modules)
      : 0;
  }

  public readonly getFurnitureModules = () => {
    const { model } = this.props;

    if (!model) {
      return [];
    }

    const modules3d = this.getModules3D();
    const furnitureModules: IFurnitureModule[] = [];
    for (const module3d of modules3d) {
      const { component, material } = module3d;
      const materialType = component.materialTypes?.find((o) => o.materials?.includes(material!));

      const componentVariant = this.getVariantForComponent(component);

      furnitureModules.push({
        component: {
          id: component.id,
          variantId: componentVariant?.id
        },
        material: material
          ? {
            id: material.id!,
            name: material.name!,
            type: materialType?.name ?? '',
          }
          : undefined
      });
    }

    return furnitureModules;
  }

  public readonly getModule3DMaterialType = (module3D: IProductModule) => {
    const materialTypes = module3D.component?.materialTypes || [];

    return materialTypes?.find(
      (mt) => !!mt.materials?.find((m) => m.id === module3D.material?.id)
    );
  }

  public readonly getModules3D = (): IProductModule[] => {
    const { model } = this.props;
    const { hadDecorations } = this.state;

    if (!model) {
      return [];
    }

    const qModulesObject = this.getQModules();
    const sourceComponents = hadDecorations
      ? model.components
      : model.components?.filter((o) => !o.isDecorations);

    const componentGroupByType = groupBy(sourceComponents, (component) => component.type);

    const componentByType = map(
      componentGroupByType,
      (components) => components.find((component) => {
        const qModule = qModulesObject[component.type!];
        if (qModule?.component) {
          return component.id === qModule.component.id;
        }

        return (component.default === true) || components[0];
      }) as IGroupsComponent
    );

    return componentByType
      .map((component): IProductModule => {
        const avaliableMaterials = flatten(component.materialTypes?.map((materialType) => materialType.materials));
        const qMaterial = qModulesObject[component.type!]?.material;
        const qMaterialId = qMaterial?.id;
        const cMaterial = avaliableMaterials.find((material) => material?.id === qMaterialId);

        const lightMap = model.modellightmap?.lightMaps?.find((o) => o.targetPosition === component.type);

        const componentVariant = this.getVariantForComponent(component);

        if (component.isOptional && !componentVariant) {
          return null!;
        }

        return {
          component: {
            ...component,
            mtl: componentVariant?.mtl ?? component.mtl,
            obj: componentVariant?.obj ?? component.obj,
            price: componentVariant?.price ?? component.price,
            materialQuota: componentVariant?.materialQuota ?? component.materialQuota,
            details: {
              ...component.details,
              ...componentVariant?.details
            }
          },
          lightmapUrl: getFileUrl(lightMap?.lightMapFile),
          uvObjUrl: getFileUrl(lightMap?.uvObj),
          material: cMaterial
        };
      })
      .filter(o => o != null);
  }

  public readonly getQModules = (): { readonly [key: string]: IQueryModule } => {
    const { model } = this.props;
    const { qModules } = this.state;
    if (!qModules) {
      return {};
    }

    const result = JSON.parse(qModules!);

    // Re-set material name for modules
    const moduleKeys = Object.keys(result);
    for (const moduleKey of moduleKeys) {
      const component = model?.components?.find(o => o.type === moduleKey);
      if (component && component.materialTypes) {
        const materials = flatten(component.materialTypes.map(o => o.materials));

        for (const material of materials) {
          if (material?.id === result[moduleKey].material?.id) {
            result[moduleKey].material.name = material!.name;
          }
        }
      }
    }

    return result;
  }

  public readonly getSelectedComponent = (): Partial<IGroupsComponent> | undefined => {
    const { model } = this.props;
    const { selectedComponentId } = this.state;

    if (!model?.components || !selectedComponentId) {
      return undefined;
    }

    return model.components.find((component) => component.id! === selectedComponentId);
  }

  public readonly getSelectedMaterialId = () => {
    const selectedComponent = this.getSelectedComponent();
    if (!selectedComponent) {
      return undefined;
    }

    const qModuleObject = this.getQModules();

    return qModuleObject[selectedComponent.type!]?.material?.id;
  }

  public readonly take3DScreenshot = async () => {
    return DesignToolBase.three3DRef.current?.takeScreenshot();
  }

  private readonly getVariantForComponent = (component: IGroupsComponent) => {
    const { model } = this.props;
    if (!model) {
      return undefined;
    }

    const { selectedVariantId } = this.state;
    let componentVariant: Partial<IGroupsComponentVariant> | undefined;
    if (selectedVariantId) {
      componentVariant = model.componentVariants?.find((v) => {
        return v.type === component.type && v.variantName === selectedVariantId;
      });
    }

    return componentVariant;
  }

  public readonly formatPrice = (price?: number, useOriginPrice?: boolean) => {
    if (!price) {
      return '0';
    }

    if (useOriginPrice) {
      return formatCurrency(price);
    }

    const { currentUser } = this.context;
    if (!currentUser?.websiteSetting?.displayPricePercent) {
      return formatCurrency(price);
    }

    const displayPrice = price * 0.01 * currentUser?.websiteSetting?.displayPricePercent;

    return formatCurrency(displayPrice);
  }
}

interface IDesignToolQualityContext {
  readonly quantity: number;
  readonly setQuantily: (quantity: number) => void;
}

export const DesignToolQualityContext = React.createContext<IDesignToolQualityContext>({
  quantity: 1,
  setQuantily: () => {/** NOPE */ }
});
