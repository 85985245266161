import jwtDecode from 'jwt-decode';
import {
    getStoredAccessToken,
    removeStoreAccessToken,
    saveAccessToken
} from 'libs/restful/tokens';
import React from 'react';

export const useAccessToken = (): [string | undefined, (value: string | undefined) => void] => {
  const [accessToken, setAccessToken] = React.useState(getStoredAccessToken);

  React.useEffect(
    () => {
      if (!accessToken) {
        return void removeStoreAccessToken();
      }

      const tokenObj = jwtDecode(accessToken) as any;
      const expiry = new Date(0);
      expiry.setUTCSeconds(tokenObj.exp);

      saveAccessToken(accessToken, expiry, true);
    },
    [accessToken]
  );

  return [accessToken, setAccessToken];
};