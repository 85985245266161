import { useResource } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { projectResources } from '@/api';

import {
    ProjectDetailInfo,
    ProjectDetailProducts,
    ProjectDetailSlider
} from './views';

const _ProjectDetailPage = styled.div`
  .project-products {
    &:last-child {
      border-bottom: 1px solid #C4C4C4!important;
    }
  }
`;

interface IProjectDetailPageProps {

}

export const ProjectDetailPage = (props: React.PropsWithChildren<IProjectDetailPageProps>) => {

  const params = useParams();

  const [project] = useResource(projectResources.findOne, [{ type: 'path', parameter: 'id', value: params['projectId'] }]);

  if (!project) {
    return <PageLoading />;
  }

  return (
    <_ProjectDetailPage>
      <ProjectDetailSlider project={project} />
      <ProjectDetailInfo project={project} />
      <ProjectDetailProducts project={project} />
    </_ProjectDetailPage>
  );
};