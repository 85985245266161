
import { Resource } from 'libs/restful';

import { IFile } from './file';

export interface ITag {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly description?: string;
  readonly description_en?: string;
  readonly icon?: IFile;
  readonly color?: string;
}

export const tagResources = {
  find: new Resource<ITag, ITag[]>({
    method: 'GET',
    url: '/admin/tags'
  }),
  count: new Resource<ITag, number>({
    method: 'GET',
    url: '/admin/tags/count'
  }),
  findOne: new Resource<ITag, ITag>({
    method: 'GET',
    url: '/admin/tags/:id'
  }),
  create: new Resource<ITag, ITag>({
    method: 'POST',
    url: '/admin/tags'
  }),
  update: new Resource<ITag, ITag>({
    method: 'PUT',
    url: '/admin/tags/:id'
  }),
  delete: new Resource<ITag, ITag>({
    method: 'DELETE',
    url: '/admin/tags/:id'
  }),
};
