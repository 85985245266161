/**
 * Template: page-section/section.ejs.t
 * Version: 0.0.1
 */

import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared';
import { Img } from '@/_shared/components';
import { IProject } from '@/api';
import {
    projectProductPagePath
} from '@/pages/project-product/ProjectProductPage.shared';
import { textIgnoreCase } from '@/translation';

export const _ProjectDetailProducts = styled.section`
  /** Styled */
  margin-bottom: 6rem;

  img {
    max-width: 100%;
  }
  .container {
      padding: 0;
  }
  .slick-slide {
      overflow: hidden;
      border-right: 1px solid #C4C4C4;
      .project-card {
      }
    }
  .slick-track {
    margin-left: 0;
  }
  .slick-arrow {
  }

  .slick-next {
    height: 100%;
    width: 60px;
    right: 0;
    :before {
      color: black;
      font-size: 30px;
    }
    &.slick-disabled {
      :before {
        display: none;
      }
    }
  }

  .slick-prev {
    height: 100%;
    width: 60px;
    left: 0;
    z-index: 1;
    :before {
      color: black;
      font-size: 30px;
    }
    &.slick-disabled {
      :before {
        display: none;
      }
    }
  }

  .project {
    display: flex;
  }

  .project-products {
    display: flex;
    &:first-child {
      border-bottom: 0;
    }
  }

  .project-cards {
    width: 75%;
    border: 1px solid #C4C4C4;
    border-bottom: 0;
    min-height: 320px;
    .img-wrapper {
      transform: scale(1.01);
    }
  }

  .project-card {
    position: relative;
    .hint {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      font-style: italic;
    }
  }

  .project-card-area {
    border: 1px solid #C4C4C4;
    border-bottom: 0;
    border-right: 0;
    width: 25%;
    font-family: "Copperplate";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    padding: 0 68px;
  }

  @media (max-width: 991px) {
    .project-products {
      display: block;
    }
    .project-card-area {
      width: 100%;
      height: 80px;
      min-height: unset;
    }
    .project-cards {
      width: 100%;
    }
    .project-products-title {
      text-align: center;
    }
  }
`;
interface IProjectDetailProductsProps {
  readonly project?: IProject;
}

interface IProjectDetailProductsState {

}

export class ProjectDetailProducts extends React.Component<
  IProjectDetailProductsProps,
  IProjectDetailProductsState> {

  public static readonly renderCards = (project: IProject, projectProducts?: IProject['projectProducts']) => {
    const isMobile = window.innerWidth <= 991;
    const slidesToShow = isMobile ? 1 : 3;

    return (
      <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} draggable={false} infinite={false}>
        {projectProducts?.map((product) => {
          const uRL = new URL(product.modelUrl!);
          uRL.searchParams.delete('selectedComponentId');

          const splitedModelUrl = uRL.pathname?.split('/');
          const modelSlug = splitedModelUrl[splitedModelUrl.length - 1];

          const modelPath = replaceRoutePath(projectProductPagePath, { projectSlug: project.slug, modelSlug: modelSlug }) + uRL.search;

          return (
            <div key={product.id} className="project-card">
              <Link to={modelPath}>
                <Img file={product.photo} ratioX={1} ratioY={1} />
                <span className="hint">{textIgnoreCase('Click to view details')}</span>
              </Link>
            </div>
          );
        })}
      </Slider>
    );
  }

  public constructor(props: IProjectDetailProductsProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { project } = this.props;

    if (!project) {
      return null;
    }

    const productGroups = this.groupProductByArea();

    return (
      <_ProjectDetailProducts
        id="projectDetailProducts"
      >
        <Container>
          {
            map(productGroups, (products, group) => {
              return (
                <div key={group} className="project-products">
                  <div className="project-card-area">
                    <div className="project-products-title">
                      {textIgnoreCase(group)}
                    </div>
                  </div>
                  <div className="project-cards">
                    {ProjectDetailProducts.renderCards(project, products)}
                  </div>
                </div>
              );
            })
          }
        </Container>
      </_ProjectDetailProducts>
    );
  }

  private readonly groupProductByArea = () => {
    const { project } = this.props;
    const products = project?.projectProducts;

    return groupBy(products, (product) => product['Areas']?.trim().toLowerCase() ?? textIgnoreCase('Products'));
  }
}
