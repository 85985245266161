import map from 'lodash/map';
import * as React from 'react';
import styled from 'styled-components';

import { DesignToolBase } from './DesignToolBase';

const _DesingToolInfo = styled.div`
  /** Styles here */
  .detail-section {
    margin-bottom: 12px;
    padding-left: 12px;
    &-header {
      line-height: 130%;
      text-align: justify;
      letter-spacing: 0.03em;
      color: #7E7E7E;
      padding: 12px 0 6px 0;
      text-transform: uppercase;
      font-weight: bold;
    }

    &-content {
      line-height: 1.9rem;
      color: #7E7E7E;
    }
  }
`;

interface IDesingToolInfoProps {

}

interface IDesingToolInfoState {

}

export class DesingToolInfo extends DesignToolBase<
  IDesingToolInfoProps,
  IDesingToolInfoState> {

  public render() {
    const { model } = this.props;

    if (!model) {
      return null;
    }

    const productDetails = this.getCurrentDetails();
    const detailSections = Object.keys(productDetails || {});

    return (
      <_DesingToolInfo>
        {
          detailSections.map((section) => {
            const details = productDetails[section];

            return (
              <div key={section} className="detail-section">
                <div className="detail-section-header" >{section}</div>
                <div className="detail-section-content">
                  {map(details, (detailValue: string, detailKey) => {

                    return (
                      <div key={detailKey} className="clearfix">
                        <span>{detailKey}</span>:
                        <strong className="pull-right">
                          {detailValue}
                        </strong>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        }
      </_DesingToolInfo >
    );
  }
}
