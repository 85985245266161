import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IProduct } from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '@/pages/product/ProductPage.shared';

const _MCollectionProductCardBase = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  &:hover {
    .information {
      bottom: 0;
    }
  }
  .information{
    transition: 0.3s;
    position: absolute;
    bottom:-100%;
    display: block;
    z-index: 2;
    width: 100%;
    background: rgb(51 51 51 / 0.7);
    &-wraper {
      display: flex;
      justify-content: space-between;
      padding: 0px 15px;
      align-items: center;
      h4 {
        font-size : 15px;
        color : #ffffff;
        font-weight : 700;
        margin: 15px 0;
      } 
      i {
        font-size: 30px;
        color: #ffffff;
        font-weight: 100;
        height: 50px;
      }
      p {
        color : #ffffff;
        margin-bottom: 15px ;
      }
    }
  }

  .tag-wrapper {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 24px;
  }

  .tag{
    background-color: #000000;
    width: 75px;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin-bottom: 12px;

    img {
      width: 25px;
      margin-right: 16px;
    }
  }

  @media (max-width: 991px) {
    .information {
      position: relative;
      background: transparent;
      bottom: 0;
      text-align: center;
      margin-bottom: 24px;
      h4 {
        color: #000;
        text-align: inherit;
        width: 100%;
        margin-bottom: 6px;
      }
      .product-type {
        display: inline;
        text-transform: capitalize;
      }
      .arrow {
        display: none;
      }
    }
    .information-wraper {
      display: block;
    }
  }
`;

const _MCollectionProductCard = styled(_MCollectionProductCardBase)`
  .product-img-thumb {
    position: relative;
    transition: all 0.3s;
    left: 0;
  }

  .product-type {
    display: none;
  }

  @media (max-width: 991px) {
    .information {
      position: relative;
      background: transparent;
      bottom: 0;
      text-align: center;
      margin-bottom: 24px;
      h4 {
        color: #000;
        text-align: inherit;
        width: 100%;
        margin-bottom: 6px;
      }
      .product-type {
        display: inline;
        text-transform: capitalize;
      }
      .arrow {
        display: none;
      }
    }
    .information-wraper {
      display: block;
    }
  }
`;

interface IMCollectionProductCardProps {
  readonly isCollection?: boolean;
  readonly product: IProduct;
}

export const MCollectionProductCard = (props: React.PropsWithChildren<IMCollectionProductCardProps>) => {
  const { product } = props;

  const productURL = product.for === 'commercial'
    ? getMCollectionProductUrl(product)
    : getProductUrl(product);

  return (
    <_MCollectionProductCard className="product-card">
      <Link to={productURL}>
        <div className="tag-wrapper">
          {
            product.tags?.map((tag) => {
              if (!tag.icon) {
                return;
              }

              return (
                <div key={tag.id} className='tag' style={{ backgroundColor: tag.color ?? undefined }}>
                  <Img useWrap={false} file={tag.icon} />
                </div>
              );
            })
          }
        </div>
        <div className="product-img">
          <div className="product-img-thumb">
            <Img file={product.thumbnail} />
          </div>
        </div>
        <div className='information'>
          <div className='information-wraper'>
            <div>
              <h4 className="product-name">
                {product.name}
              </h4>
              <span className="product-description">
                {product.previewDescription}
              </span>
              <span className="product-type">
                {product.productType?.name?.toLowerCase()}
              </span>
            </div>
            <i aria-hidden="true" className="arrow">→</i>
          </div>
        </div>
      </Link>
    </_MCollectionProductCard >
  );
};