import React from 'react';

import { Resource } from '../base';
import { RequestParameter } from '../Types';
import { useResourceLazy } from './useResourceLazy';

export const useResource = <TReq, TRes>(
  resource: Resource<TReq, TRes>,
  defaultParams?: RequestParameter[]
): [TRes | undefined, boolean, (params?: RequestParameter[]) => void, Error | undefined] => {

  const [result, loading, request, error] = useResourceLazy(resource);

  const request2 = React.useCallback(() => request(defaultParams), [defaultParams, request]);

  React.useEffect(
    () => {
      request2();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [result, loading, request2, error];
};