import { Resource } from 'libs/restful';

import { IUser } from './user';

export interface AuthConnectRequest {

}

export interface AuthConnectResponse {

}

export interface AuthLocalLoginRequest {
  readonly identifier: string;
  readonly password: string
}

export interface AuthLocalLoginResponse {
  readonly user: IUser;
  readonly jwt: string;
}

export interface AuthRegisterRequest {
  readonly username: string;
  readonly email: string;
  readonly password: string;
}

export interface AuthRegisterResponse {
  readonly user: IUser;
  readonly jwt: string;
}

export interface AuthProviderLoginRequest {

}

export interface AuthProviderLoginResponse {
  readonly user: IUser;
  readonly jwt: string;
}

export interface AuthForgotPasswordRequest {
  readonly email: string;
  readonly url: string;
}

export interface AuthForgotPasswordResponse {

}

export interface AuthChangePasswordRequest {
  readonly code: string;
  readonly password: string;
  readonly passwordConfirmation: string;
}

export interface AuthChangePasswordResponse {

}

export interface AuthEmailConfirmationRequest {

}

export interface AuthEmailConfirmationResponse {

}

export const authResources = {
  connectToProvider: new Resource<AuthConnectRequest, AuthConnectResponse>({
    method: 'GET',
    url: '/customer/connect/:provider'
  }),
  localLogin: new Resource<AuthLocalLoginRequest, AuthLocalLoginResponse>({
    method: 'POST',
    url: '/customer/auth/local',
  }),
  register: new Resource<AuthRegisterRequest, AuthRegisterResponse>({
    method: 'POST',
    url: '/customer/auth/local/register',
  }),
  provderLogin: new Resource<AuthProviderLoginRequest, AuthProviderLoginResponse>({
    method: 'GET',
    url: '/customer/auth/:provider/callback',
  }),
  forgotPassword: new Resource<AuthForgotPasswordRequest, AuthForgotPasswordResponse>({
    method: 'POST',
    url: '/customer/auth/forgot-password',
  }),
  changePassword: new Resource<AuthChangePasswordRequest, AuthChangePasswordResponse>({
    method: 'POST',
    url: '/customer/auth/reset-password',
  }),
  emailConfirmation: new Resource<AuthEmailConfirmationRequest, AuthEmailConfirmationResponse>({
    method: 'GET',
    url: '/customer/auth/email-confirmation',
  })
}
