
import { Resource } from 'libs/restful';

import { IFile } from './file';

export interface IFeatureCategory {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug?: string;
  readonly cover?: IFile;
}

export const featureCategoryResources = {
  find: new Resource<IFeatureCategory, IFeatureCategory[]>({
    method: 'GET',
    url: '/admin/featurecategories'
  }),
  count: new Resource<IFeatureCategory, number>({
    method: 'GET',
    url: '/admin/featurecategories/count'
  }),
  findOne: new Resource<IFeatureCategory, IFeatureCategory>({
    method: 'GET',
    url: '/admin/featurecategories/:id'
  })
};
