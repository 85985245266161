import React from 'react';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IInspiration } from '@/api';

const _InspirationDetailsCover = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;

  .coo-hom-iframe {
    width: 100%;
    height: calc(100vh - 71px);
  }

  .text {
    font-family: Copperplate;
    font-weight: bold;
    font-size: 42px;
    line-height: 40px;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface IInspirationDetailsCoverProps {
  readonly inspiration: IInspiration;
}

export const InspirationDetailsCover = (props: React.PropsWithChildren<IInspirationDetailsCoverProps>) => {

  const iSCooHom = props.inspiration?.coverText?.includes('https://www.coohom.com');
  
  return (
    <_InspirationDetailsCover>
      {
        iSCooHom ?
        <iframe className='coo-hom-iframe' src={props.inspiration?.coverText} ></iframe>
        : <Img file={props.inspiration.cover} />
      }
      {!iSCooHom && (
        <div className="text">{ props.inspiration.coverText ?? 'INSPIRATION'}</div>
      ) }
    </_InspirationDetailsCover>
  );
};