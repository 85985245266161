import { useResource, useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { modelResources, projectResources } from '@/api';
import { DesignPageTool } from '@/pages/design/views';
import {
    ProjectProductComments,
    ProjectProductProcessPhotos
} from '@/pages/project-product/views';

import {
    ProjectBplusModelInfo,
    ProjectBplusProductPageOtherProducts
} from './views';

const _ProjectBplusModelPage = styled.div`
`;

interface IProjectBplusModelPageProps {

}

export const ProjectBplusModelPage = (props: React.PropsWithChildren<IProjectBplusModelPageProps>) => {

  const params = useParams();

  const [project] = useResource(projectResources.bplusProject);
  const [models] = useResourceAnonymous(modelResources.find, [{ type: 'query', parameter: 'slug', value: params['modelSlug'] }]);

  if (!project || !models) {
    return null;
  }

  const model = models[0];

  return (
    <_ProjectBplusModelPage>
      <DesignPageTool
        model={model}
      />
      <ProjectBplusModelInfo model={model} project={project} />
      <ProjectProductComments model={model} project={project} />
      <ProjectProductProcessPhotos model={model} project={project} />
      <ProjectBplusProductPageOtherProducts model={model} project={project} />
    </_ProjectBplusModelPage>
  );
};