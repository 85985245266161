import React from 'react';
import styled from 'styled-components';

import { getPageSettings } from '@/_shared';
import { IPage } from '@/api';

import { HomeContentCard } from './home-content';

const _HomeContent = styled.div`
  @media (max-width: 991px) {
    margin-bottom : 50px;

    .product-card:nth-child(even) {
      .information {
        text-align: right;
      }
    }
  }
`;

interface IHomeContentProps {
  readonly page: IPage;
}

export const HomeContent = (props: React.PropsWithChildren<IHomeContentProps>) => {
  const { page } = props;

  const pageContentSettings = getPageSettings(page, 'HOME_CONTENT');

  return (
    <_HomeContent>
      {
        pageContentSettings?.map((setting, index) => {
          return (
            <HomeContentCard
              key={index}
              index={index}
              setting={setting}
            />
          );
        })
      }
    </_HomeContent>
  );
};