import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IPage } from '@/api';
import { textIgnoreCase } from '@/translation';

const _PrivacyPolicyContent = styled.div`
  .col-sm-12 {
    padding: 0;
  }
  .page-cover-wrapper {
    position: relative;
    min-height: 200px;

    &::before {
      content: "";
      background-color: rgba(0,0,0,0.5);
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .page-cover {

  }

  .page-title {
    position: absolute;
    top: 40%;
    left: 33%;
    z-index: 2;
    color: #fff;
    width: 0;
  }

  .page-content {
    height: calc(100vh - 74px);
    overflow: auto;
    padding: 60px;
  }

  h2 {
    font-family: "Copperplate";
    font-style: normal;
    font-size: 18px;
    line-height: 19px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 24px;
    &::after {
      content: "";
      background-color: rgba(0,0,0,1);
      display: block;
      width: 47px;
      height: 1px;
      margin-top: 24px;
    }
  }

  @media (max-width: 991px) {
    .page-title {
      left: 30px;
    }
    .page-content {
      height: unset;
      padding: 60px 15px;
    }
  }
`;

interface IPrivacyPolicyContentProps {
  readonly page: IPage;
}

export const PrivacyPolicyContent = (props: React.PropsWithChildren<IPrivacyPolicyContentProps>) => {
  const { page } = props;

  return (
    <_PrivacyPolicyContent>
      <Container>
        <Row >
          <Col sm={12} lg={6}>
            <div className="page-cover-wrapper">
              <div className="page-cover">
                <Img file={page.cover} />
              </div>
              <h1 className="page-title">{textIgnoreCase('Privacy Policy')}</h1>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            <div className="page-content">
              <h2>{page.name}</h2>
              <ReactMarkdown
                source={page.content ?? ''}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </_PrivacyPolicyContent>
  );
};