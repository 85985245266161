import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IGroupsSlide } from '@/api';

const _OutdoorSliderItem = styled.div`
  .col-right {
    padding : 0;
  }

  .col-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left : 155px;
    h3 {
      font-size : 42px;
      margin-bottom: 20px;
    }

    p {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width : 991px) {
  .wrapper-row {
    margin-left: 0;
    margin-right: 0;

      position: relative;
      .col-left {
        position : absolute;
        top : 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99;
        padding: 50px;
        color : #ffffff;
        background: rgba(0,0,0,.3);
        .button-home-slider {
          background: #ffffff;
        }
      }
    }
  }
`;

interface IOutdoorSliderItem {
  readonly slide: IGroupsSlide;
}

export const OutdoorSliderItem = (props: React.PropsWithChildren<IOutdoorSliderItem>) => {
  const { slide } = props;

  return (
    <_OutdoorSliderItem>
      <Row className='wrapper-row'>
        <Col lg={6} className='col-left'>
          <div className='wrapper-content'>
            <div dangerouslySetInnerHTML={{ __html: slide.content! }} />
            <Button className='button-home-slider' tag="a" href={slide.url} color='grey'>
              {slide.urlLabel}
            </Button>
          </div>
        </Col>
        <Col lg={6} className='col-right'>
          <Img file={slide.image} />
        </Col>
      </Row>
    </_OutdoorSliderItem>
  );
};