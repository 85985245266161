import React from 'react';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IPage } from '@/api';

const _FeaturesCover = styled.div`
  position: relative;

  .img-wrapper {
    @media (max-width: 991px) {
      padding-bottom: 75%!important;
    } 
  }
  img {
    @media (max-width: 991px) {
      height: 100%!important;
      max-height: 100%!important;
      width: auto!important;
      max-width: unset!important;
    }
  }

  .cover-text {
    max-width: 850px;
    color: white;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    p {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

interface IFeaturesCoverProps {
  readonly page: IPage;
}

export const FeaturesCover = (props: React.PropsWithChildren<IFeaturesCoverProps>) => {
  return (
    <_FeaturesCover>
      <Img file={props.page.cover} />
      <div className="cover-text" dangerouslySetInnerHTML={{ __html: props.page.content! }} />
    </_FeaturesCover>
  );
};