// tslint:disable

import * as React from 'react';
import * as THREE from 'three';
import { BufferGeometry, DirectionalLightHelper, Object3D } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
    MaterialCreator,
    MTLLoader
} from 'three/examples/jsm/loaders/MTLLoader';
import {
    MtlObjBridge
} from 'three/examples/jsm/loaders/obj2/bridge/MtlObjBridge';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import {
    EffectComposer
} from 'three/examples/jsm/postprocessing/EffectComposer';
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import {
    SSAARenderPass
} from 'three/examples/jsm/postprocessing/SSAARenderPass';
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader';

import { getFileUrl } from '@/_shared/utils';
import { IGroupsComponent, IGroupsMaterial } from '@/api';

export interface IViewSettings {
  readonly initRotateX: number;
  readonly initRotateY: number;
}

export interface IControlSetting {
  readonly enableZoom?: boolean;
  readonly minDistance?: number;
  readonly maxDistance?: number;
}

export interface IProductModule {
  readonly lightmapUrl?: string;
  readonly uvObjUrl?: string;
  readonly component: IGroupsComponent;
  readonly material?: Partial<IGroupsMaterial>;
}

export interface IThreeSenceBaseProps {
  readonly onObjectSelect?: (objectId?: string) => void;
  readonly productModules: IProductModule[];
  readonly viewSettings: IViewSettings;
  readonly controlSetting?: IControlSetting;
  readonly selectedComponentId?: string;
  readonly groundLightMapUrl?: string;
}

export class ThreeSenceBase<TProps extends IThreeSenceBaseProps> extends React.PureComponent<TProps> {
  private readonly clearColor = 0xFFFFFF;

  public animationFrameId!: number;
  public aspectRatio = 1;
  public camera!: THREE.PerspectiveCamera;
  public cameraDefaults = {
    posCamera: new THREE.Vector3(0, 70, 150),
    posCameraTarget: new THREE.Vector3(0, 400, 0),
    near: 1,
    far: 10000,
    fov: 25
  };
  public cameraTarget!: THREE.Vector3;
  public composer!: EffectComposer;

  public container!: HTMLDivElement;
  public controls!: OrbitControls;

  public highlightObjects: THREE.Object3D[] = [];

  public highlightTimeout!: NodeJS.Timeout | number;
  public isMouseHold!: boolean;
  public mouse!: THREE.Vector2;
  public mouseHoldTimeout!: NodeJS.Timeout | number;
  public outlinePass!: OutlinePass;
  public raycaster!: THREE.Raycaster;
  public renderer!: THREE.WebGLRenderer;
  public scene!: THREE.Scene;
  public selectedObject!: THREE.Object3D | null;

  public canSelect = (object: THREE.Object3D | null) => {
    if (!object) {
      return false;
    }

    const { productModules } = this.props;

    const furnitureModule = productModules.find(
      ({ component }) => component.id === object.name || component.id === object.parent?.name
    );

    if (!furnitureModule?.component?.selectable) {
      return false;
    }

    return true;
  }

  public checkIntersection = () => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects([this.scene], true);

    if (intersects.length > 0) {
      if (this.highlightTimeout) {
        clearTimeout(this.highlightTimeout as NodeJS.Timeout);
      }

      const selectedObject = intersects[0].object;

      const canSelect = this.canSelect(selectedObject);
      if (!canSelect) {
        return;
      }

      if (this.outlinePass.selectedObjects[0] !== selectedObject) {
        this.container.style.cursor = 'default';
        if (this.selectedObject) {
          return;
        }
        this.outlinePass.selectedObjects = [];
      }

      this.highlightTimeout = setTimeout(
        () => {
          this.outlinePass.selectedObjects = [selectedObject];
          this.container.style.cursor = 'pointer';
        },
        50
      );

    } else {
      if (this.selectedObject) {
        return;
      }

      this.outlinePass.selectedObjects = [];
      this.container.style.cursor = 'default';
    }
  }

  public clearScene = () => {
    cancelAnimationFrame(this.animationFrameId);
  }

  public initCamera() {
    const { viewSettings } = this.props;

    const canvas = this.renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    this.camera = new THREE.PerspectiveCamera(
      this.cameraDefaults.fov,
      width / height,
      this.cameraDefaults.near,
      this.cameraDefaults.far
    );

    this.camera.position.x = viewSettings.initRotateX || 0;

    this.cameraTarget = this.cameraDefaults.posCameraTarget;
    this.resetCamera();
  }

  public initComposer() {
    this.composer = new EffectComposer(this.renderer);
    this.composer.setSize(this.container.clientWidth, this.container.clientHeight);

    // * SSAA Render
    const renderPass = new SSAARenderPass(this.scene, this.camera, this.clearColor, 1);
    renderPass.sampleLevel = 1;
    this.composer.addPass(renderPass);

    // * Outline
    this.outlinePass = new OutlinePass(
      new THREE.Vector2(this.container.clientWidth, this.container.clientHeight),
      this.scene,
      this.camera
    );
    this.outlinePass.edgeStrength = 10;
    this.outlinePass.pulsePeriod = 0;
    this.composer.addPass(this.outlinePass);

    // * FXAA
    const effectFXAA = new ShaderPass(FXAAShader);
    effectFXAA.uniforms['resolution'].value.set(1 / this.container.clientWidth, 1 / this.container.clientHeight);
    effectFXAA.renderToScreen = true;
    this.composer.addPass(effectFXAA);
  }

  public initControls() {
    const { viewSettings, controlSetting } = this.props;

    const polarAngleMin = ((viewSettings.initRotateY || 90) * 0.01);
    const polarAngleMax = ((viewSettings.initRotateY || 145) * 0.01);

    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.target = this.cameraTarget;

    controls.minDistance = controlSetting?.minDistance || 4500;
    controls.maxDistance = controlSetting?.maxDistance || controls.minDistance;
    controls.enableZoom = controls.minDistance !== controls.maxDistance
      ? controlSetting?.enableZoom === true
      : false;

    controls.maxPolarAngle = polarAngleMax;
    controls.minPolarAngle = polarAngleMin;
    controls.enablePan = false;
    controls.enableDamping = true;
    controls.dampingFactor = 0.07;
    controls.rotateSpeed = 0.7;

    this.controls = controls;
  }

  public initGround = () => {
    const { groundLightMapUrl } = this.props;
    if (!groundLightMapUrl) {
      return;
    }

    const modelName = 'ground';
    const objLoader2 = new OBJLoader2();

    const callbackOnLoad = (object3d: THREE.Object3D) => {
      const geometry = (object3d.children[0] as THREE.Mesh).geometry as THREE.BufferGeometry;
      geometry.rotateY(Math.PI);
      geometry.attributes.uv2 = (geometry.attributes.uv as THREE.BufferAttribute).clone();
      this.scene.add(object3d);
    };

    const onLoadMtl = (mtlParseResult: MaterialCreator) => {
      mtlParseResult.preload();

      const materials = Object.values(mtlParseResult.materials) as THREE.MeshPhongMaterial[];
      materials[0].color.set(0xfffffff);
      materials[0].emissiveIntensity = 0;
      const textureLoader = new TGALoader();
      textureLoader.load(
        getFileUrl(groundLightMapUrl)!,
        (texture) => {
          materials[0].lightMap = texture;
          materials[0].needsUpdate = true;
        }
      );

      objLoader2.setModelName(modelName);
      objLoader2.addMaterials(MtlObjBridge.addMaterialsFromMtlLoader(mtlParseResult), true);
      objLoader2.load('/static/scene/' + modelName + '.obj', callbackOnLoad);
    };

    const mtlLoader = new MTLLoader();
    mtlLoader.load('/static/scene/' + modelName + '.mtl', onLoadMtl);
  }

  public initLights() {
    // * Environtment
    const light = new THREE.HemisphereLight(0xFFFFFF, 0x404040);
    light.position.set(0, 1000, 0);
    light.intensity = .35;
    this.scene.add(light);

    const baseShadowCamera = 3000;

    // * Directional
    const dirLightLeft = new THREE.DirectionalLight(0xFFFFFF, 1);

    // dirLightLeft.intensity = 1;
    dirLightLeft.position.set(100, 800, 500);
    // light.position.multiplyScalar( 200 );

    dirLightLeft.castShadow = true;
    dirLightLeft.shadow.camera.far = 3500;
    dirLightLeft.shadow.bias = -0.00001;
    dirLightLeft.shadowMapHeight = 2048;
    dirLightLeft.shadowMapWidth = 2048;

    dirLightLeft.shadowCameraLeft = -baseShadowCamera;
    dirLightLeft.shadowCameraRight = baseShadowCamera;
    dirLightLeft.shadowCameraTop = baseShadowCamera;
    dirLightLeft.shadowCameraBottom = -baseShadowCamera;

    this.scene.add(dirLightLeft);

    const useLightHelpers = false;
    if (useLightHelpers) {
      const lightHelper = new DirectionalLightHelper(dirLightLeft);
      this.scene.add(lightHelper);
    }
  }

  public disableLights() {
    this.scene.traverse(o => {
      if (o instanceof THREE.Light) {
        o.visible = false;
      }
    });
  }

  public initRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      preserveDrawingBuffer: true
    });
    this.renderer.autoClear = false;
    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    this.container.appendChild(this.renderer.domElement);
  }

  public initSence() {
    // * Sence
    this.scene = new THREE.Scene();
    this.raycaster = new THREE.Raycaster();

    // * Function binds
    this.renderSence = this.renderSence.bind(this);

    this.recalcAspectRatio();
    const resizeWindow = () => {
      this.resizeDisplayGL();
    };

    if (!this.mouse) {
      this.mouse = new THREE.Vector2();
    }

    this.initRenderer();
    this.initCamera();
    this.initControls();
    this.initComposer();
    this.initLights();

    this.resizeDisplayGL();

    this.renderSence();

    this.container.onmousemove = this.onTouchMove.bind(this);

    this.container.onmousedown = () => {
      this.mouseHoldTimeout = setTimeout(() => {
        this.isMouseHold = true;
      }, 250);
    };

    this.container.onmouseup = () => {
      this.onClick();
      clearTimeout(this.mouseHoldTimeout as NodeJS.Timeout);
      this.isMouseHold = false;
    };

    this.container.ontouchmove = this.onTouchMove.bind(this);

    this.container.ontouchstart = () => {
      this.mouseHoldTimeout = setTimeout(
        () => {
          this.isMouseHold = true;
        },
        250
      );
    };

    this.container.ontouchend = (event) => {
      event.preventDefault();

      const bounds = event.target!['getBoundingClientRect']();
      const x = event.changedTouches[0].clientX - bounds.left;
      const y = event.changedTouches[0].clientY - bounds.top;

      this.mouse.x = (x / this.container.clientWidth) * 2 - 1;
      this.mouse.y = - (y / this.container.clientHeight) * 2 + 1;

      this.onClick();

      this.isMouseHold = false;
      clearTimeout(this.mouseHoldTimeout as NodeJS.Timeout);
    };

    window.addEventListener('resize', resizeWindow, false);
  }

  public onClick = () => {
    if (this.isMouseHold || !this.props.onObjectSelect) {
      return;
    }
    const { onObjectSelect, selectedComponentId } = this.props;

    this.raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = this.raycaster.intersectObjects([this.scene], true);
    if (intersects.length > 0) {

      let selectedObject: THREE.Object3D | null = intersects[0].object;

      if (selectedObject === this.selectedObject) {
        selectedObject = null;
      }

      const canSelect = this.canSelect(selectedObject);
      if (!canSelect) {
        return;
      }

      if (!selectedObject) {
        return;
      }

      this.selectObject(selectedObject.parent);
      if (onObjectSelect) {
        const nextSelect = selectedObject.parent?.name;
        if (nextSelect === selectedComponentId) {
          onObjectSelect();
        } else {
          onObjectSelect(nextSelect);
        }
      }
    } else {
      this.selectObject(null);
      if (onObjectSelect) {
        onObjectSelect(undefined);
      }
    }
  }

  public onTouchMove = (event: MouseEvent & TouchEvent) => {
    if (this.isMouseHold || !this.props.onObjectSelect) {
      return;
    }

    let x, y;
    if (event.changedTouches) {
      x = event.changedTouches[0].pageX;
      y = event.changedTouches[0].pageY;
    } else {
      const bounds = event.target!['getBoundingClientRect']();
      x = event.clientX - bounds.left;
      y = event.clientY - bounds.top;
    }
    this.mouse.x = (x / this.container.clientWidth) * 2 - 1;
    this.mouse.y = - (y / this.container.clientHeight) * 2 + 1;
    this.checkIntersection();
  }

  public recalcAspectRatio = () => {
    if (!this.container) {
      return 1;
    }

    this.aspectRatio = (this.container.offsetHeight === 0) ? 1 :
      this.container.offsetWidth / this.container.offsetHeight;
  }

  public renderSence = () => {
    this.animationFrameId = requestAnimationFrame(this.renderSence);

    if (!this.renderer.autoClear) {
      this.renderer.clear();
    }

    this.controls.update();
    this.composer.render();
  }

  public resetCamera() {
    const { viewSettings } = this.props;
    const cameraX = viewSettings.initRotateX || 0;

    this.cameraDefaults.posCamera = new THREE.Vector3(cameraX, 70, 180);

    this.camera.position.copy(this.cameraDefaults.posCamera);
    this.cameraTarget.copy(this.cameraDefaults.posCameraTarget);
    this.updateCamera();
  }

  public resetCameraZoom() {
    if (!this.controls) {
      return;
    }

    const zoomDistance = Number(this.controls.maxDistance),
      currDistance = this.camera.position.length(),
      factor = zoomDistance / currDistance;

    this.camera.position.x *= factor;
    // this.camera.position.y *= factor;
    this.camera.position.z *= factor;

    this.updateCamera();
  }

  public resetControl = () => {
    this.cameraTarget = new THREE.Vector3(0, 0, 0);
    this.controls.target = this.cameraTarget;
  }

  public resizeDisplayGL = () => {
    const canvas = this.renderer.domElement;
    // Look up the size the canvas is being displayed
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;

    // Adjust displayBuffer size to match
    if (canvas.width !== width || canvas.height !== height) {
      // You must pass false here or three.js sadly fights the browser
      this.renderer.setSize(width, height, false);
      this.composer.setSize(width, height);
      this.recalcAspectRatio();
      this.updateCamera();
    }
  }

  public selectObject = (object?: THREE.Object3D | string | null) => {
    let nextSelctedObject: THREE.Object3D | null;

    if (typeof object === 'string') {
      nextSelctedObject = this.scene.children.find(o => o.name === object) || null;
    } else {
      nextSelctedObject = object || null;
    }

    this.selectedObject = nextSelctedObject;
    this.outlinePass.selectedObjects = nextSelctedObject ? [nextSelctedObject] : [];
  }

  public tryResetCanvasSize = (oldViewportHeight: number, curentCanvasHeight: number) => {
    const canvas = this.renderer.domElement;
    const width = canvas.clientWidth;

    if (curentCanvasHeight === oldViewportHeight) {
      return;
    }

    this.renderer.setSize(width, curentCanvasHeight, true);
    this.composer.setSize(width, curentCanvasHeight);
    this.recalcAspectRatio();
    this.updateCamera();
    this.updateControl();
  }

  public updateCamera() {
    this.camera.aspect = this.aspectRatio;
    this.camera.lookAt(this.cameraTarget);
    this.camera.updateProjectionMatrix();
  }

  public updateControl = () => {
    const { controlSetting } = this.props;

    this.controls.minDistance = controlSetting?.minDistance || 4500;
    this.controls.maxDistance = controlSetting?.maxDistance || this.controls.minDistance;
    this.controls.enableZoom = this.controls.minDistance !== this.controls.maxDistance
      ? controlSetting?.enableZoom === true
      : false;
  }

  public readonly registerAfterRenderEvent = (object3D: THREE.Object3D, callback: () => void) => {
    const rendered = new Set();
    for (const mesh of object3D.children as THREE.Mesh[]) {
      const originOnAfterRender = mesh.onAfterRender;
      mesh.onAfterRender = () => {
        rendered.add(mesh);
        if (rendered.size === object3D.children.length) {
          callback();
        }
        mesh.onAfterRender = originOnAfterRender;
      };
    }
  }

  public readonly hideObject3D = (object3D: THREE.Object3D) => {
    for (const mesh of object3D.children as THREE.Mesh[]) {
      if (Array.isArray(mesh.material)) {
        mesh.material.map((material) => {
          material.opacity = 0;
          material.transparent = true;
        });
      } else {
        mesh.material.opacity = 0;
        mesh.material.transparent = true;
      }
    }
  }

  public readonly showObject3D = (object3D: THREE.Object3D) => {
    for (const mesh of object3D.children as THREE.Mesh[]) {
      let opacity = 0;
      const animateInterval = setInterval(
        () => {
          opacity += .1;
          if (opacity === 1) {
            clearInterval(animateInterval);
          }

          if (Array.isArray(mesh.material)) {
            mesh.material.map((material) => {
              material.opacity += opacity;
            });
          } else {
            mesh.material.opacity += opacity;
          }
        },
        20
      );
    }
  }

  public readonly loadLightMap = (productModule: IProductModule, object3D: Object3D) => {
    const { uvObjUrl, lightmapUrl } = productModule;

    if (!lightmapUrl || !uvObjUrl) {
      return;
    }

    const targetMesh = object3D.children[0] as THREE.Mesh;

    const uvObjLoader = new OBJLoader2();
    uvObjLoader.load(
      uvObjUrl,
      (obj) => {
        const uvMesh = obj.children[0] as THREE.Mesh;
        const uvGeometry = uvMesh.geometry as BufferGeometry;
        const targetGeometry = targetMesh.geometry as THREE.BufferGeometry;

        targetGeometry.attributes.position = (uvGeometry.attributes.position as THREE.BufferAttribute).clone();
        targetGeometry.attributes.uv2 = (uvGeometry.attributes.uv as THREE.BufferAttribute).clone();
      }
    );

    const textureLoader = new TGALoader();
    textureLoader.load(
      lightmapUrl,
      (texture) => {
        const targetMaterial = targetMesh.material as THREE.MeshPhongMaterial;
        targetMaterial.lightMap = texture;
        targetMaterial.needsUpdate = true;
      }
    );
  }
}
