import isHotkey from 'is-hotkey';
import { useResourceLazy } from 'libs/restful';
import React, {
    PropsWithChildren,
    Ref,
    useCallback,
    useMemo,
    useState
} from 'react';
import {
    createEditor,
    Editor,
    Element as SlateElement,
    Transforms
} from 'slate';
import { withHistory } from 'slate-history';
import { Editable, Slate, useSlate, withReact } from 'slate-react';
import styled from 'styled-components';

import { Button, Icon, Toolbar } from './SlateEditorComponents';

const _SlateEditor = styled.div`
 
`;

interface ISlateEditorProps {
  readonly initialValue?: string;
  readonly onChange: (value: string) => void;
}

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];
export const SlateEditor = (props: React.PropsWithChildren<ISlateEditorProps>) => {
  const value = useMemo(() => props.initialValue ? JSON.parse(props.initialValue) : initialValue, [props.initialValue]);
  const valueRef = React.useRef(value);

  const [isFocused, setIsFocused] = useState(false);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <_SlateEditor>
      <Slate editor={editor} value={value} onChange={(nextValue) => valueRef.current = nextValue}>
        {
          isFocused && (
            <Toolbar>
                <MarkButton format="bold" icon="fa-bold" />
                <MarkButton format="italic" icon="fa-italic" />
                <MarkButton format="underline" icon="fa-underline" />
                <BlockButton format="heading-one" icon="fa-header">1</BlockButton>
                <BlockButton format="heading-two" icon="fa-header">2</BlockButton>
                <BlockButton format="numbered-list" icon="fa-list-ol" />
                <BlockButton format="bulleted-list" icon="fa-list-ul" />
                <BlockButton format="left" icon="fa-align-left" />
                <BlockButton format="center" icon="fa-align-center" />
                <BlockButton format="right" icon="fa-align-right" />
                <BlockButton format="justify" icon="fa-align-justify" />
            </Toolbar>
          )
        }
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Enter some rich text…"
          onFocus={() => {
            setIsFocused(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            props.onChange(JSON.stringify(valueRef.current));
          }}
          onKeyDown={event => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </_SlateEditor>
  );
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes((n as any).type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    } as any;
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    } as any;
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

interface IBlockButtonProps {
  format: string,
  icon: string
}

const BlockButton = (props: React.PropsWithChildren<IBlockButtonProps>) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        props.format,
        TEXT_ALIGN_TYPES.includes(props.format) ? 'align' : 'type'
      )}
      onMouseDown={event => {
        event.preventDefault();
        toggleBlock(editor, props.format);
      }}
    >
      <Icon className={props.icon} />
      {props.children}
    </Button>
  );
};

interface IMarkButtonProps {
  format: string,
  icon: string
}

const MarkButton = (props: React.PropsWithChildren<IMarkButtonProps>) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, props.format)}
      onMouseDown={event => {
        event.preventDefault();
        toggleMark(editor, props.format);
      }}
    >
      <Icon className={props.icon} />
    </Button>
  );
};

const _Paragraph = styled.p`
  line-height: 1.5;
  margin-bottom: 1rem;
`;

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align, fontFamily: 'Roboto' };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <_Paragraph style={style} {...attributes}>
          {children}
        </_Paragraph>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
const LIST_TYPES = ['numbered-list', 'bulleted-list'];