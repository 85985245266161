import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { formatDate, replaceRoutePath } from '@/_shared';
import { IModel, IProject } from '@/api';
import {
    projectDetailPagePath
} from '@/pages/project-detail/ProjectDetailPage.shared';
import { textIgnoreCase } from '@/translation';

const _ProjectProductInfo = styled.div`
  /** Styles here */
  margin-bottom: 2rem;

  .model-info {
    margin-top: 2rem;
  }
  .model-name {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .model-description {
    margin-top: 1rem;
  }
`;

interface IProjectProductInfoProps {
  readonly model?: IModel;
  readonly project?: IProject;
}

interface IProjectProductInfoState {

}

export class ProjectProductInfo extends React.Component<
  IProjectProductInfoProps,
  IProjectProductInfoState> {

  public static readonly getProjectProduct = (project: IProject, model: IModel) => {
    const projectProduct = project?.projectProducts?.find((o) => {
      if (!o.modelUrl) {
        return false;
      }

      const modelURL = new URL(o.modelUrl);
      const pathname = modelURL.pathname;
      const pathnameSplited = pathname.split('/');
      const modelSlug = pathnameSplited[pathnameSplited.length - 1];

      return modelSlug === model.slug;
    });

    return projectProduct;
  }

  public constructor(props: IProjectProductInfoProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { model, project } = this.props;

    if (!project || !model) {
      return null;
    }

    const projectProduct = ProjectProductInfo.getProjectProduct(project, model);
    if (!projectProduct) {
      return null;
    }

    return (
      <_ProjectProductInfo id="projectProductInfo">
        <Container>
          <div className="model-info">
            <h1 className="model-name">{model?.name}</h1>
            <div>{textIgnoreCase('Last update')} - <strong>{formatDate(model?.updatedAt)}</strong></div>
            <div>
              <Link to={replaceRoutePath(projectDetailPagePath, { projectId: project.id })}>
                {project?.name} - {projectProduct['Areas']}
              </Link>
            </div>
            {
              projectProduct['description'] && (
                <div className="model-description">
                  <ReactMarkdown source={projectProduct['description']} />
                </div>
              )
            }
          </div>
        </Container>
      </_ProjectProductInfo>
    );
  }
}
