import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import styled from 'styled-components';

import { getFileUrl } from '@/_shared';
import { socialResources } from '@/api';

const _FooterSocialList = styled.div`
`;

interface IFooterSocialListProps {

}

export const FooterSocialList = (props: React.PropsWithChildren<IFooterSocialListProps>) => {
  const [socials] = useResourceAnonymous(socialResources.find);

  return (
    <_FooterSocialList>
      <ul className='list-socical'>
        {
          socials?.map((o) => {
            return (
              <li key={o.id}>
                <a href={o.link} target="_blank" rel="noreferrer">
                  <img src={getFileUrl(o.icon)} alt={o.name} />
                </a>
              </li>
            );
          })
        }
      </ul>
    </_FooterSocialList>
  );
};