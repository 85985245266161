import { ILanuage } from 'libs/i18n';

import { en, vi } from './langs';

export const translationResources = {
  en,
  vi,
};

export type AvailableLanguage = keyof typeof translationResources;

export const availableLanguages: ILanuage[] = [
  { name: 'en', label: 'English', symbol: '🇬🇧' },
  { name: 'vi', label: 'Vietnamese', symbol: '🇻🇳' },
];
