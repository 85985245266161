import classNames from 'classnames';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

import { GlobalModalNoActions, Img } from '@/_shared/components';
import { IGroupsComponent, IGroupsMaterial, IMaterialType } from '@/api';

import { DesignToolBase } from '../DesignToolBase';
import { DesignToolMaterialInfo } from './DesignToolMaterialInfo';

export const _DesignToolMaterialList = styled.div`
  /** */
`;

interface IDesignToolMaterialListProps {
  readonly selectedComponent: Partial<IGroupsComponent>;
  readonly selectedMataterialType: Partial<IMaterialType>;
}

interface IDesignToolMaterialListState {
  readonly selectedMataterialTypeId?: string;
  readonly showInfoFor?: {
    material: Partial<IGroupsMaterial>;
    materialType: Partial<IMaterialType>;
  };
}

export class DesignToolMaterialList extends DesignToolBase<IDesignToolMaterialListProps, IDesignToolMaterialListState> {
  public render() {
    const {
      selectedMataterialType,
      selectedComponent
    } = this.props;

    const { showInfoFor } = this.state;

    const materials = selectedMataterialType?.materials;
    const selectedMaterialId = this.getSelectedMaterialId();

    return (
      <_DesignToolMaterialList>
        {
          showInfoFor && (
            <GlobalModalNoActions isOpen={true} onClosed={() => this.setState({ showInfoFor: undefined })}>
              <DesignToolMaterialInfo material={showInfoFor.material} materialType={showInfoFor.materialType} />
            </GlobalModalNoActions>
          )
        }
        <Row>
          {
            materials?.map((material) => {
              const isSelected = material.id === selectedMaterialId;
              const materialType = selectedComponent?.materialTypes?.find((mt) => mt.materials?.includes(material));

              const onMaterialClick = () => {
                const { qModules } = this.state;
                const qModuleObject = qModules ? JSON.parse(qModules) : {};

                const componentType = selectedComponent?.type;
                if (!componentType) {
                  return;
                }

                const qMaterial = {
                  id: material.id,
                  name: material.name,
                  type: materialType?.name
                };

                if (qModuleObject[componentType]) {
                  qModuleObject[componentType].material = qMaterial;
                } else {
                  qModuleObject[componentType] = {
                    material: qMaterial
                  };
                }

                this.setState({
                  qModules: JSON.stringify(qModuleObject)
                });
              };

              const onInfoClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                if (!materialType) {
                  return;
                }
                
                this.setState({
                  showInfoFor: {
                    material,
                    materialType
                  }
                });
                event.stopPropagation();
              };

              return (
                <Col key={material.id} xs={2} lg={2}>
                  <div
                    className={classNames('material', { selected: isSelected })}
                    onClick={onMaterialClick}
                    role="button"
                  >
                    <Img file={material.texture} size="Thumbnail" className="w-100" />
                    <div className="info-btn" onClick={onInfoClick} role="button">i</div>
                  </div>
                </Col>
              );
            })
          }
        </Row>
      </_DesignToolMaterialList>
    );
  }
}
