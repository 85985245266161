import { IPage } from '@/api';

export const getPageSettingValue = (page: IPage, name: string) => {
  return page.settings?.find(o => o.name === name)?.value;
};

export const getPageSettings = (page: IPage, groupKey: string) => {
  return page.settings?.filter(o => o.group === groupKey);
};

export const getPageSettingValues = (page: IPage, groupKey: string) => {
  return page.settings?.filter(o => o.group === groupKey).map(o => o.value);
};

export const getPageSettingFile = (page: IPage, name: string) => {
  return page.settings?.find(o => o.name === name)?.valueMedia;
};

export const getPageSettingFiles = (page: IPage, name: string) => {
  return page.settings?.find(o => o.name === name)?.valueMedias;
};
