
import { Resource } from 'libs/restful';

import { IGroupsMaterial } from './groupsMaterial';

export interface IMaterialType {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug?: string;
  readonly materials?: Partial<IGroupsMaterial>[];
  readonly details?: object;
  readonly details_en?: object;
  readonly description?: string;
  readonly description_en?: string;
  readonly showInLibrary?: boolean;
}

export const materialTypeResources = {
  find: new Resource<IMaterialType, IMaterialType[]>({
    method: 'GET',
    url: '/admin/materialtypes'
  }),
  count: new Resource<IMaterialType, number>({
    method: 'GET',
    url: '/admin/materialtypes/count'
  }),
  findOne: new Resource<IMaterialType, IMaterialType>({
    method: 'GET',
    url: '/admin/materialtypes/:id'
  }),
  create: new Resource<IMaterialType, IMaterialType>({
    method: 'POST',
    url: '/admin/materialtypes'
  }),
  update: new Resource<IMaterialType, IMaterialType>({
    method: 'PUT',
    url: '/admin/materialtypes/:id'
  }),
  delete: new Resource<IMaterialType, IMaterialType>({
    method: 'DELETE',
    url: '/admin/materialtypes/:id'
  }),
};
