
import { Resource } from 'libs/restful';

export interface INewsLetterSignup {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly email?: string;
}

export const newsLetterSignupResources = {
  find: new Resource<INewsLetterSignup, INewsLetterSignup[]>({
    method: 'GET',
    url: '/customer/newslettersignups'
  }),
  count: new Resource<INewsLetterSignup, number>({
    method: 'GET',
    url: '/customer/newslettersignups/count'
  }),
  findOne: new Resource<INewsLetterSignup, INewsLetterSignup>({
    method: 'GET',
    url: '/customer/newslettersignups/:id'
  }),
  create: new Resource<INewsLetterSignup, INewsLetterSignup>({
    method: 'POST',
    url: '/customer/newslettersignups'
  }),
  update: new Resource<INewsLetterSignup, INewsLetterSignup>({
    method: 'PUT',
    url: '/customer/newslettersignups/:id'
  }),
  delete: new Resource<INewsLetterSignup, INewsLetterSignup>({
    method: 'DELETE',
    url: '/customer/newslettersignups/:id'
  }),
};
