import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { IGroupsSlide, ISlider } from '@/api';

import { HomeSliderItem } from './home-slider';

const _HomeSlider = styled.div`

`;

interface IHomeSliderProps {
  readonly slider: Partial<ISlider>;
}

export const HomeSlider = (props: React.PropsWithChildren<IHomeSliderProps>) => {
  const { slider } = props;

  const isMobile = window.innerWidth < 991;

  return (
    <_HomeSlider>
      <Container>
        <Row>
          <Carousel
            axis={isMobile ? 'horizontal' : 'vertical'}
            showArrows={false}
            showIndicators={false}
            showThumbs={false}
            swipeable={true}
            emulateTouch={true}
            autoPlay={false}
            stopOnHover={true}
            infiniteLoop={true}
          >
            {slider?.slides?.map((slide) => {
              return (
                <HomeSliderItem key={slide.id} slide={slide as IGroupsSlide} />
              );
            })}
          </Carousel>
        </Row>
      </Container>
    </_HomeSlider>
  );
};