
import { Resource } from 'libs/restful';


export interface IContact {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly email: string;
  readonly country: string;
  readonly phone?: string;
  readonly company?: string;
  readonly message: string;
}

export const contactResources = {
  find: new Resource<IContact, IContact[]>({
    method: 'GET',
    url: '/customer/contacts'
  }),
  count: new Resource<IContact, number>({
    method: 'GET',
    url: '/customer/contacts/count'
  }),
  findOne: new Resource<IContact, IContact>({
    method: 'GET',
    url: '/customer/contacts/:id'
  }),
  create: new Resource<IContact, IContact>({
    method: 'POST',
    url: '/customer/contacts'
  }),
  update: new Resource<IContact, IContact>({
    method: 'PUT',
    url: '/customer/contacts/:id'
  }),
  delete: new Resource<IContact, IContact>({
    method: 'DELETE',
    url: '/customer/contacts/:id'
  }),
};
