import { useResourceAnonymous, useResourceLazy } from 'libs/restful';
import React from 'react';
import {
    Button,
    Col,
    Container,
    CustomInput,
    Form,
    FormGroup,
    Input,
    Label,
    Row
} from 'reactstrap';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions, Img } from '@/_shared/components';
import { contactResources, IPage, settingResources } from '@/api';
import { textIgnoreCase } from '@/translation';

import {
    ContactFormThanksContent
} from './contact-form/ContactFormThanksContent';

const _ContactForm = styled.div`
  font-family: Roboto;
  .row, .col-lg-6 {
    padding: 0;
  }
  .bg-title {
    position: relative;
    .title {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Copperplate;
      font-size: 48px;
      line-height: 50px;
      text-transform: uppercase;
      text-align: center;
      color: #FFFFFF;
      background: rgba(0, 0, 0, 0.35);
      @media (max-width: 992px) {
        text-align: left;
        align-items: center;
        justify-content: start;
        h2 {
         width: 195px;
         margin-left : 20px;
         font-size: 48px;
        }
      }
    }
  }
  .form-contact {
    padding: 0 20px;
    @media (min-width: 1024px) {
      padding: 0 110px;
    }
    @media (max-width: 800px) {
      margin-bottom: 30px;
    }
    .title {
      margin-bottom: 25px;
      font-family: Copperplate;
      font-weight: 600;
      font-size: 30px;
      line-height: 31px;
      text-transform: uppercase;
      color: #000000;
    }
    .form-group {
      &.check {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 800px) {
          label {
            display: block;
          }
          .custom-radio {
            display: inline-block;
            margin-right: 20px;
          }
        }
      }
      label {
        margin: 0;
        font-size: 13px;
        line-height: 20px;
        color: #000000;
      }
      .custom-radio {
        display: flex;
        align-items: center;
        label:before, label:after {
          top: 1px;
        }
      }
      input, textarea {
        height: 40px;
        background: #F1F1F1;
        border: 0;
        border-radius: 2px;
      }
      textarea {
        height: 118px;
      }
    }
    button {
      width: 150px;
      height: 40px;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      color: #FFFFFF;
      background: #000000;
    }
  }
  @media (max-width : 992px){
    .col-left{
      margin : 30px 0;
    }
  }
`;

interface IContactFormProps {
  readonly page: IPage;
}

export const ContactForm = (props: React.PropsWithChildren<IContactFormProps>) => {
  const { page } = props;
  
  const [thanksContentSettings] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'CONTACT_THANKS_CONTENT' }]);
  const [newContact, loading, sendNewContact] = useResourceLazy(contactResources.create);

  React.useEffect(
    () => {
      if (!newContact) {
        return;
      }
      const form = document.getElementById('contactForm') as HTMLFormElement;
      form?.reset();
    },
    [newContact]
  );

  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget);
      sendNewContact([{
        type: 'body',
        value: formValues
      }]);
    },
    [sendNewContact]
  );

  return (
    <_ContactForm>
      <GlobalModalNoActions isOpen={!!newContact}>
        <ContactFormThanksContent setting={thanksContentSettings && thanksContentSettings[0]}/>
      </GlobalModalNoActions>
      <Container>
        <Row className='align-items-center'>
          <Col lg={6} className='bg-title'>
            <div>
              <Img file={page.cover} />
              <div className='title'>
                <h2>{textIgnoreCase('contact us')}</h2>
              </div>
            </div>
          </Col>
          <Col lg={6} className='col-left'>
            <Form id="contactForm" onSubmit={onSubmit} className='form-contact'>
              <h2 className='title'>{textIgnoreCase('Leave a message')}</h2>

              <FormGroup className='check mb-3' row={true}>
                <Label for="exampleCheckbox" sm={12}>{textIgnoreCase('How would you like to be contacted?')} *</Label>
                <Col sm={12}>
                  <CustomInput className="d-inline-block mr-3" type="radio" id="examplefavourContactType" name="favourContactType" value="email" label="Email" checked />
                  <CustomInput className="d-inline-block" type="radio" id="examplefavourContactType2" name="favourContactType" value="phone" label={textIgnoreCase('Phone')} />
                </Col>
              </FormGroup>

              <FormGroup>
                <Input type="text" name="name" id="name" placeholder={`${textIgnoreCase('Name')} *`} />
              </FormGroup>

              <FormGroup>
                <Input type="email" name="email" id="email" placeholder={`${textIgnoreCase('Email address')} *`} />
              </FormGroup>

              <FormGroup>
                <Input type="text" name="country" id="country" placeholder={`${textIgnoreCase('Country')} *`} />
              </FormGroup>

              <FormGroup>
                <Input type="text" name="phone" id="phone" placeholder={textIgnoreCase('Phone number')} />
              </FormGroup>

              <FormGroup>
                <Input type="text" name="company" id="company" placeholder={textIgnoreCase('Company')} />
              </FormGroup>

              <FormGroup>
                <Input type="textarea" name="message" id="message" placeholder={textIgnoreCase('Message')} />
              </FormGroup>
              <Button disabled={loading}>{textIgnoreCase('send message')}</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </_ContactForm>
  );
};