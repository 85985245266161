import * as React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { GallerySlider, Img } from '@/_shared/components';
import { IFile, IProject } from '@/api';

const _ProjectDetailSlider = styled.div`
  /** Styles here */
  .container {
    padding: 0;
  }
`;

interface IProjectDetailSliderProps {
  readonly project?: IProject;
}

interface IProjectDetailSliderState {

}

export class ProjectDetailSlider extends React.Component<
  IProjectDetailSliderProps,
  IProjectDetailSliderState> {
  public constructor(props: IProjectDetailSliderProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { project } = this.props;
    if (!project) {
      return null;
    }

    const photos = project['Photos'] as IFile[];

    return (
      <_ProjectDetailSlider id="projectDetailSlider">
        <Container>
          {
            photos?.length
              ? (<GallerySlider slider={{ slides: photos.map(o => ({ image: o })) }} />)
              : <Img file={project.cover} ratioX={4} ratioY={3} />
          }
        </Container>
      </_ProjectDetailSlider>
    );
  }
}
