import classNames from 'classnames';
import { useResource, useResourceLazy } from 'libs/restful';
import React, { useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { DataLoadingInline, GlobalModalNoActions } from '@/_shared/components';
import { IWishlist, IWishlistGroup, wishlistGroupResources } from '@/api';
import { textIgnoreCase } from '@/translation';

import {
    wishlistGroupsState,
    wishlistItemsState
} from '../WishlistDetailPage.shared';
import { WhislistGroupCreateForm } from './wishlist-group';

export const _WishlistGroups = styled.div`
  .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .wishlist-group-wrapper {
    margin-bottom: 20px;
  }

  .wishlist-group {
    cursor: pointer;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    display: flex;
    &:hover { 
      font-weight: bold;
      .wishlist-group-actions {
        display: flex!important;
      }
    }
    &.selected {
      font-weight: bold;
    }
  }

  .wishlist-group-name {
    flex-grow: 1;
  }

  .wishlist-group-actions {
    display: none;
  }

  .wishlist-group-action {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    &:hover {
      background: #f2f2f2;
    }
  }
`;

const _WishlistGroupEditModal = styled.div`
  width: 400px;
  .header {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    button {
      width: 100px;
    }
  }
`;

interface IWishlistGroupsProps {
  readonly wishlist: IWishlist;
  readonly onGroupSelected: (group: IWishlistGroup | null | undefined) => void;
}

export const WishlistGroups = (props: React.PropsWithChildren<IWishlistGroupsProps>) => {
  const { } = props;

  const [wishlistItems] = useRecoilState(wishlistItemsState);

  const [, setGlobalWishlistGroups] = useRecoilState(wishlistGroupsState);

  const [seletedWishlistGroup, setSelectedWishlistGroup] = React.useState<IWishlistGroup | null | undefined>(undefined);

  const [editingWishlistGroup, setEditingWishlistGroup] = React.useState<IWishlistGroup | null>(null);
  const [deletingWishlistGroup, setDeletingWishlistGroup] = React.useState<IWishlistGroup | null>(null);

  const [wishlistGroups, loading, fetchWishlistGroups] = useResource(wishlistGroupResources.find, [{ type: 'query', parameter: 'wishlist', value: props.wishlist.id }]);

  const [, updating, updateGroup] = useResourceLazy(wishlistGroupResources.update);
  const [, deleting, deleteGroup] = useResourceLazy(wishlistGroupResources.delete);

  useEffect(() => {
    props.onGroupSelected(seletedWishlistGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onGroupSelected, seletedWishlistGroup]);

  useEffect(() => {
    setGlobalWishlistGroups(wishlistGroups ?? []);
  }, [setGlobalWishlistGroups, wishlistGroups]);

  const handleUpdateGroup: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const data = new FormData(form);
    await updateGroup([{
      type: 'path',
      parameter: 'id',
      value: editingWishlistGroup!.id,
    },
    {
      type: 'body',
      value: {
        id: editingWishlistGroup?.id,
        name: data.get('name') as string,
      } as IWishlistGroup as any,
    }]);

    form.reset();
    setEditingWishlistGroup(null);
    fetchWishlistGroups();
  };

  const handleDeleteGroup = async () => {
    await deleteGroup([{
      type: 'path',
      parameter: 'id',
      value: deletingWishlistGroup!.id,
    }]);

    setDeletingWishlistGroup(null);
    fetchWishlistGroups();
  };

  return (
    <_WishlistGroups>
      <div className="header">Groups</div>
      <div className="wishlist-group-wrapper">

        <div
          className={classNames('wishlist-group', { 'selected': seletedWishlistGroup === undefined })}
          onClick={() => setSelectedWishlistGroup(undefined)}
        >
          {textIgnoreCase('All')} ({wishlistItems?.length})
        </div>
        <div
          className={classNames('wishlist-group', { 'selected': seletedWishlistGroup === null })}
          onClick={() => setSelectedWishlistGroup(null)}
        >
          {textIgnoreCase('Not assigned')} ({wishlistItems?.filter(o => !o.wishlistGroup).length})
        </div>
        {
          wishlistGroups?.map(wishlistGroup => {
            const wishlistGroupItems = wishlistItems?.filter(wishlistItem => ((wishlistItem.wishlistGroup as IWishlistGroup)?.id ?? wishlistItem.wishlistGroup) === wishlistGroup.id).length;

            return (
              <div key={wishlistGroup.id} className={classNames('wishlist-group', { 'selected': seletedWishlistGroup === wishlistGroup })}>
                <div className="wishlist-group-name" onClick={() => setSelectedWishlistGroup(wishlistGroup)}>
                  {wishlistGroup.name} ({wishlistGroupItems})
                </div>
                <div className="wishlist-group-actions">
                  <span className="wishlist-group-action" onClick={() => setEditingWishlistGroup(wishlistGroup)}>
                    <i className="fa fa-pencil" />
                  </span>

                  <span className="wishlist-group-action" onClick={() => setDeletingWishlistGroup(wishlistGroup)}>
                    <i className="fa fa-trash" />
                  </span>
                </div>
              </div>
            );
          })
        }

        {
          loading && (
            <DataLoadingInline />
          )
        }
      </div>
      <WhislistGroupCreateForm
        wishlist={props.wishlist}
        onCreated={fetchWishlistGroups}
      />

      <GlobalModalNoActions isOpen={!!editingWishlistGroup}>
        <_WishlistGroupEditModal>
          <div className="header">
            {textIgnoreCase('Edit Group')}
          </div>
          <form className='update-form' key={editingWishlistGroup?.id} onSubmit={handleUpdateGroup}>
            <Input
              type="text"
              autoFocus
              name="name"
              id="name"
              placeholder={textIgnoreCase('Input name')}
              readOnly={updating}
              defaultValue={editingWishlistGroup?.name}
            />
            <div className="actions">
              <Button type="button" disabled={updating} onClick={() => setEditingWishlistGroup(null)}>Cancel</Button>
              <Button type="submit" className="btn-black" disabled={updating}>Save</Button>
            </div>
          </form>
        </_WishlistGroupEditModal>
      </GlobalModalNoActions>

      <GlobalModalNoActions isOpen={!!deletingWishlistGroup}>
        <_WishlistGroupEditModal>
          <div className="header">
            Delete {deletingWishlistGroup?.name}
          </div>
          <p className='text-center'>
            {textIgnoreCase('Are you sure you want to delete this group?')}
          </p>

          <div className="actions">
            <Button type="button" disabled={deleting} onClick={() => setDeletingWishlistGroup(null)}>Cancel</Button>
            <Button type="submit" className="btn-black" disabled={deleting} onClick={() => handleDeleteGroup()}>Delete</Button>
          </div>
        </_WishlistGroupEditModal>
      </GlobalModalNoActions>
    </_WishlistGroups>
  );
};