import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { modelResources } from '@/api';

import {
    MCollectionIllutrationPhotos,
    MCollectionReallifePhotos
} from '../m-collection-product/views';
import { DesignPageTool } from './views';

const _DesignPage = styled.div`
`;

interface IDesignPageProps {

}

export const DesignPage = (props: React.PropsWithChildren<IDesignPageProps>) => {
  const pathParams = useParams();

  const [models] = useResourceAnonymous(modelResources.find, [{ type: 'query', parameter: 'slug', value: pathParams['modelSlug'] }]);

  const model = models && models[0];

  return (
    <_DesignPage>
      <DesignPageTool model={model} />
      {!!model?.illustrationsPhotos?.length && (
        <div style={{marginTop: 50}}>
          <MCollectionIllutrationPhotos
            productName={model.name}
            photos={model.illustrationsPhotos}
          />
        </div>
      )}
    </_DesignPage>
  );
};