
import { Resource } from 'libs/restful';

import { IFile } from './file';

export interface ISocial {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly link: string;
  readonly icon: Partial<IFile>;
}

export const socialResources = {
  find: new Resource<ISocial, ISocial[]>({
    method: 'GET',
    url: '/admin/socials'
  }),
  count: new Resource<ISocial, number>({
    method: 'GET',
    url: '/admin/socials/count'
  }),
  findOne: new Resource<ISocial, ISocial>({
    method: 'GET',
    url: '/admin/socials/:id'
  }),
  create: new Resource<ISocial, ISocial>({
    method: 'POST',
    url: '/admin/socials'
  }),
  update: new Resource<ISocial, ISocial>({
    method: 'PUT',
    url: '/admin/socials/:id'
  }),
  delete: new Resource<ISocial, ISocial>({
    method: 'DELETE',
    url: '/admin/socials/:id'
  }),
};
