/* eslint-disable react/display-name */
import classNames from 'classnames';
import React, { PropsWithChildren, Ref } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface BaseProps {
  className: string
  [key: string]: unknown
}

const _Button = styled.span`
  cursor: pointer;
  color: ${(props: { reversed, active }) => props.reversed
    ? props.active
      ? 'white'
      : '#aaa'
    : props.active
      ? 'black'
      : '#ccc'};
`;

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
      {
        active: boolean
        reversed: boolean
      } & BaseProps
    >,
    ref: Ref<HTMLSpanElement>
  ) => (
    <_Button
      {...props}
      ref={ref}
      active={active}
      reversed={reversed}
      className={classNames(className)}
    />
  )
);

const _Icon = styled.i`
  font-size: 18px;
  vertical-align: text-bottom;
`;

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLSpanElement>
  ) => (
    <_Icon
      {...props}
      ref={ref}
      className={classNames('fa', className)}
    />
  )
);

const _Menu = styled.div`
  position: relative;
  padding: 10px;
  padding-right: 40px;
  border-bottom: 2px solid #eee;
  margin-bottom: 20px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLDivElement>
  ) => (
    <_Menu
      {...props}
      ref={ref}
      className={classNames(className)}
    />
  )
);

export const Portal = ({ children }) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLDivElement>
  ) => (
    <Menu
      {...props}
      ref={ref}
      className={className}
    />
  )
);