import {
    RequestParameter,
    useResourceAnonymous,
    useResourceAnonymousLazy
} from 'libs/restful';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import {
    pageResources,
    productResources,
    productTypeResources,
    tagResources
} from '@/api';

import { MCollectionProductTypes } from '../m-collection/views';
import { ProductList } from './views';

const _ProductsPage = styled.div`
`;

interface ProductPageParams {
  readonly productTypeSlug: string;
}

export const ProductsPage = () => {
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);
  const { productTypeSlug } = useParams<ProductPageParams>();

  const tagId = currentSearchParams.get('tagId');

  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-office' }]);
  const [productTypes] = useResourceAnonymous(productTypeResources.find, [{ type: 'query', parameter: 'for', value: 'office' }]);
  const [tags] = useResourceAnonymous(tagResources.find, []);

  const productTypeId = productTypes?.find(e => e.slug === productTypeSlug)?.id;

  const defaultFetchProductParams: RequestParameter[] = [{
    type: 'query',
    parameter: 'for',
    value: 'office'
  },
  {
    type: 'query',
    parameter: 'productType', value: productTypeId ?? undefined
  },
  {
    type: 'query',
    parameter: '_sort',
    value: 'displayPriority:desc'
  }];

  const [products, productsFetching, fetchProducts] = useResourceAnonymousLazy(productResources.find);

  const refetch = React.useCallback(
    ({ search }) => {
      fetchProducts([
        ...defaultFetchProductParams,
        search && {
          type: 'query',
          value: search,
          parameter: 'name_contains'
        }
      ]);
    },
    [defaultFetchProductParams, fetchProducts]
  );

  React.useEffect(
    () => {
      if (productTypeSlug && !productTypeId) {
        return;
      }

      fetchProducts([
        { type: 'query', parameter: 'tags_in', value: tagId ?? undefined },
        { type: 'query', parameter: 'productType', value: productTypeId ?? undefined },
        { type: 'query', parameter: 'for', value: 'office' }
      ]);
    },
    [productTypeId, fetchProducts, tagId, productTypes, tags, productTypeSlug]
  );

  if (!pages || !products || !productTypes) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_ProductsPage>
      <MCollectionProductTypes
        productTypes={productTypes}
        currentProductTypeId={productTypeId || ''}
        defaultCoverFile={page.cover}
        tags={tags}
        currentTagId={tagId}
      />
      <ProductList
        products={products}
        refetch={refetch}
        loading={productsFetching}
      />
    </_ProductsPage>
  );
};