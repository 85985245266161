import React from 'react';
import { Col, Container, Row, Spinner } from 'reactstrap';
import styled from 'styled-components';

import { IProduct } from '@/api';
import { textIgnoreCase } from '@/translation';

import { OutdoorProductCard } from './outdoor-product-list';

const _OutdoorProductList = styled.div`
  .products-container {
    border-top: 0.5px solid #C4C4C4;
    border-right: 0.5px solid #C4C4C4;
  }
  .all-products-card-wrapper{
    padding : 0;
  }
  .all-products-card {
    padding-left: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-left: 0.5px solid #C4C4C4;
    border-right: 0.5px solid #C4C4C4;
  }

  .card-product {
    padding : 0;
    min-height: 400px;
    &:nth-child(4n) {
      .product-card {
        border-right: none;
      }
    }
    &:nth-child(4n+1) {
      .product-card {
        border-left: 0.5px solid #C4C4C4;
      }
    }
  }
  .product-card:not(.all-products-card) {
    min-height: 319px;
    border-bottom: 0.5px solid #C4C4C4;
    border-right: 0.5px solid #C4C4C4;
    padding : 0
  }
  
  @media (max-width:991px ) {
    .all-products-card-wrapper{
    }
    .card-product {
      &:nth-child(4n) {
        .product-card {
          border-right: 0.5px solid #C4C4C4;
        }
      }
      &:nth-child(4n+1) {
        .product-card {
          border-left: none;
        }
      }
    }

    .product-card {
      min-height: 260px;
      border-bottom: 0.5px solid #C4C4C4;
      border-left: none;
      border-right: 0.5px solid #C4C4C4;
      padding : 0
    }
  }
`;

interface IOutdoorProductListProps {
  readonly products?: IProduct[];
  readonly loading?: boolean;
  readonly outdoorName?: string;
}

export const OutdoorProductList = (props: React.PropsWithChildren<IOutdoorProductListProps>) => {
  const {  products, outdoorName } = props;

  return (
    <_OutdoorProductList>
      <Container className="products-container">
        <Row md={4}>
          <Col className='card-product all-products-card-wrapper' lg={3} sm={12}>
            <div className="all-products-card product-card">
              <h4 className='name-collection'>
                {outdoorName}
              </h4>
              <p className="">{textIgnoreCase('Shop now')}</p>
            </div>
          </Col>
          {
            products?.map((product) => {
              return (
                <Col className='card-product' key={product.id} lg={3} xs={6}>
                  <OutdoorProductCard product={product} />
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </_OutdoorProductList>
  );
};