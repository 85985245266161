import { ICartItem } from './Types';

export const setOrderItemQuantity = (
  orderItem: Partial<ICartItem>,
  quatity: number
): Partial<ICartItem> => {
  return {
    ...orderItem,
    quantity: quatity,
    subTotalPrice: (orderItem.unitPrice || 0) * quatity,
    totalPrice: (orderItem.unitPrice || 0) * quatity - (orderItem.totalDiscount || 0),
  };
};
