import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import styled from 'styled-components';

import { getUrlSearchParam } from '@/_shared';
import { PageLoading } from '@/_shared/components';
import { modelResources } from '@/api';

import { ModelQuoteRequest } from './views';

const _ModelQuoteRequestPage = styled.div`
`;

interface IModelQuoteRequestPageProps {

}

export const ModelQuoteRequestPage = (props: React.PropsWithChildren<IModelQuoteRequestPageProps>) => {
  const modelId = getUrlSearchParam('modelId');

  const [model] = useResourceAnonymous(modelResources.findOne, [{ type: 'path', parameter: 'id', value: modelId! }]);

  if (!model) {
    return <PageLoading />;
  }

  return (
    <_ModelQuoteRequestPage>
      <ModelQuoteRequest
        model={model}
      />
    </_ModelQuoteRequestPage>
  );
};