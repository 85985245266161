import classNames from 'classnames';
import { useResourceLazy } from 'libs/restful';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { GlobalModalNoActions } from '@/_shared/components';
import { SlateEditor } from '@/_shared/components/slate-editor';
import { IWishlistMoodboardItem, wishlistMoodboardItemResources } from '@/api';

const _WishlistMoodboardItem = styled.div`
  position: relative;
  &:before {
      content: '';
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      position: absolute;
      top: -10px;
      left: -10px;
      border: 1px solid #000000;
      display: none;
      background-color: #fff;
      z-index: 1;
  }
  &:hover {
    &:before {
      display: block;
    }

    .moodboard-item-actions {
      display: flex!important;
    }

    .moodboard-item-container {
      z-index: 2;
    }
  }

  &.text {
    margin-top: 1rem;
  }

  .moodboard-image {
    width: 100%;
  }

  .moodboard-item-actions {
    display: none;
    position: absolute;
    gap: 5px;
    flex-direction: column;
    top: 0px;
    right: 0px;
  }

  .moodboard-item-action {
    transition: .2s linear;
    background-color: #fff;
    line-height: 1;
    z-index: 99;
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 8%);
    text-align: right;
    cursor: pointer;
    padding 6px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .moodboard-item-container {
    position: relative;
  }

  .moodboard-item-color {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px;
  }
  .moodboard-item-color-label {
    color: #fff;
    background-color: rgba(0,0,0,.12);
    display: inline-block;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 13px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }
`;

const _DeleteModalContent = styled.div`
  width: 400px;
  text-align: center;
  .moodboard-item-modal-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  `;

interface IWishlistMoodboardItemProps {
  readonly wishlistMoodboardItem: IWishlistMoodboardItem;
  readonly onDelete: (wishlistMoodboardItem: IWishlistMoodboardItem) => void;
  readonly onUpdate: (wishlistMoodboardItem: IWishlistMoodboardItem) => void;
}

export const WishlistMoodboardItem = (props: React.PropsWithChildren<IWishlistMoodboardItemProps>) => {

  const history = useHistory();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  const [result, deleting, deleteItem, error] = useResourceLazy(wishlistMoodboardItemResources.delete);

  useEffect(() => {
    if (window.location.hash === `#${props.wishlistMoodboardItem.id}`) {
      history.replace(location.pathname);
      const element = document.getElementById(props.wishlistMoodboardItem.id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [history, props.wishlistMoodboardItem.id]);

  const handleDelete = async () => {
    await deleteItem([{
      type: 'path',
      parameter: 'id',
      value: props.wishlistMoodboardItem.id,
    }]);

    props.onDelete(props.wishlistMoodboardItem);
  };

  return (
    <_WishlistMoodboardItem id={props.wishlistMoodboardItem.id} className={classNames(props.wishlistMoodboardItem.type)}>
      {
        props.wishlistMoodboardItem.type === 'image' && (
          <div className="moodboard-item-container">
            <img className="moodboard-image" src={props.wishlistMoodboardItem.image} />
          </div>
        )
      }
      {
        props.wishlistMoodboardItem.type === 'color' && (
          <div className="moodboard-item-container">
            <div className="moodboard-item-color" style={{ backgroundColor: props.wishlistMoodboardItem.color, height: 100 }}>
              <span className="moodboard-item-color-label">
                {props.wishlistMoodboardItem.color}
              </span>
            </div>
          </div>
        )
      }
      {
        props.wishlistMoodboardItem.type === 'text' && (
          <div className="moodboard-item-container">
            <SlateEditor
              initialValue={props.wishlistMoodboardItem.text}
              onChange={(nextText) => {
                props.onUpdate({
                  ...props.wishlistMoodboardItem,
                  text: nextText,
                });
              }}
            />
          </div>
        )
      }
      <div className="moodboard-item-actions">
        {/* <div className="moodboard-item-action edit">
          <svg className="icon icon-medium middle"><use xlinkHref="/static/assets/sprite-icons.svg#icon-pencil-alt"></use></svg>
        </div> */}
        <div className="moodboard-item-action delete" onClick={() => setIsDeleteModalOpen(true)}>
          <svg className="icon icon-medium middle"><use xlinkHref="/static/assets/sprite-icons.svg#icon-trash"></use></svg>
        </div>
      </div>
      <GlobalModalNoActions isOpen={isDeleteModalOpen} onClosed={() => setIsDeleteModalOpen(false)}>
        <_DeleteModalContent>
          <p>Are you sure you want to delete this {props.wishlistMoodboardItem.type}?</p>
          <div className="moodboard-item-modal-actions">
            <button className="btn btn-secondary" onClick={() => setIsDeleteModalOpen(false)}>Cancel</button>
            <button className="btn btn-black" onClick={() => handleDelete()} disabled={deleting}>Delete</button>
          </div>
        </_DeleteModalContent>
      </GlobalModalNoActions>
    </_WishlistMoodboardItem >
  );
};