import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

const _PageLoading = styled.div`
  min-height: calc(100vh - 74px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface IPageLoadingProps {

}

export const PageLoading = (props: React.PropsWithChildren<IPageLoadingProps>) => {
  return (
    <_PageLoading>
      <div className="mb-4">
        <Spinner color="dark" size="sm" />
      </div>
      <div>
        Loading, please wait...
      </div>
    </_PageLoading>
  );
};