import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IFeatureCategory } from '@/api';

import { getFeaturesPageUrl } from '../FeaturesPage.shared';

const _FeatureCategories = styled.div`
  .row {
    border-left: 1px solid #C4C4C4;
    @media screen and (max-width: 991px) {
      margin: 0 -15px;
    }
  }
  .col {
    border: 1px solid #C4C4C4;
    border-top: none;
    border-left: none;
    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      border-top: 1px solid #C4C4C4;
    }
  }

  .item {
    padding: 30px;
    @media screen and (max-width: 991px) {
      padding: 12px;
    }
  }
  
  .name {
    font-family: Copperplate;
    font-weight: bold;
    font-size: 24px;
    line-height: 21px;
    text-align: center;
    padding: 40px 0 0 0;
    @media screen and (max-width: 991px) {
      font-size: 14px;
      padding: 20px 0 10px 0;
    }
  }
`;

interface IFeatureCategoriesProps {
  readonly featureCategories?: IFeatureCategory[];
}

export const FeatureCategories = (props: React.PropsWithChildren<IFeatureCategoriesProps>) => {
  return (
    <_FeatureCategories>
      <Container>
        <Row noGutters>
          {props.featureCategories?.map(o => {
            return (
              <Col key={o.id} xs={6} md={4} className="col">
                <Link to={getFeaturesPageUrl(o.slug) + '#top'}>
                  <div className="item">
                    <div className="cover">
                      <Img file={o.cover} />
                    </div>
                    <div className="name">
                      {o.name}
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
        </Row>
      </Container>
    </_FeatureCategories>
  );
};