import React from 'react';

import { Resource } from '../base';
import { RequestParameter } from '../Types';
import { useResourceAnonymousLazy } from './useResourceAnonymousLazy';

export const useResourceAnonymous = <TReq, TRes>(
  resource: Resource<TReq, TRes>,
  defaultParams?: RequestParameter[]
): [TRes | undefined, boolean, (params?: RequestParameter[]) => void, Error | undefined] => {

  const [result, loading, request, error] = useResourceAnonymousLazy(resource);

  React.useEffect(
    () => {
      request(defaultParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return [result, loading, request, error];
};