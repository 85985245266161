import { customFetch } from '../base';
import { getStoredAccessToken } from '../tokens';
import { useRecourceFactory } from './useRecourceFactory';

const injectedAuthFetch: Window['fetch'] = (url: RequestInfo, init: RequestInit) => {
  const newRequestInit: RequestInit = { ...init };
  const newHeaders = new Headers(init?.headers);
  const accessToken = getStoredAccessToken();

  if (accessToken) {
    newHeaders.set('Authorization', `Bearer ${accessToken}`);
  }

  newRequestInit.headers = newHeaders;

  return customFetch(url, newRequestInit);
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useResourceLazy = useRecourceFactory(injectedAuthFetch);