import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IPage } from '@/api';
import {
    MCollectionFileRequestForm
} from '@/pages/m-collection-file-request/views/form';
import {
    _MCollectionFileRequest,
    requestTypes
} from '@/pages/m-collection-file-request/views/MCollectionFileRequest';

const _CatalogueFileRequest = styled(_MCollectionFileRequest)`
`;

interface ICatalogueFileRequestProps {
  readonly requestType: string;
  readonly page?: IPage;
}

export const CatalogueFileRequest = (props: React.PropsWithChildren<ICatalogueFileRequestProps>) => {
  const { requestType, page } = props;

  const requestTypeTitle = requestTypes[requestType];

  return (
    <_CatalogueFileRequest>
      <Container>
        <Row>
          <Col className='col-left' lg={6}>
            {
              page?.cover
                ? (
                  <Img file={page?.cover} />
                )
                : (
                  <img src="/static/COM/image 4.1-request.jpg" alt="" />
                )
            }
          </Col>
          <Col className='col-right' lg={6}>
            <MCollectionFileRequestForm
              requestType={requestType}
              requestTypeTitle={requestTypeTitle}
            />
          </Col>
        </Row>
      </Container>
    </_CatalogueFileRequest >
  );
};