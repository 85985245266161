import { replaceRoutePath } from '@/_shared';
import { IProduct } from '@/api';

export const productPageDetail = '/product/:productSlug';
export const productTypePage = '/productType/:productTypeSlug';

export const getProductUrl = (product: Partial<IProduct>) => {
  const productURL = new URL(location.href);
  productURL.pathname = replaceRoutePath(productPageDetail, { productSlug: product.slug });
  return productURL.pathname;
};