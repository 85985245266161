import * as React from 'react';
import { Input, InputProps } from 'reactstrap';
import styled from 'styled-components';

const _FormInputNumber = styled.div`
  position: relative;
  display: inline-block;
  .spinner-button {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    text-align: center;
    margin: 0px;
    line-height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1px;
    height: 19px;
    width: 20px;
    border-left: 1px solid #C4C4C4;
    color: #C4C4C4;

    &.inc {
      top: 1px;
      border-bottom: 1px solid #C4C4C4;
    }
    &.dec {
      top: 20px;
    }
  }
`;

interface IFormInputNumberProps extends InputProps {
  readonly name?: string;
  readonly setFieldValue?: (fieldName: string, value?: number) => void;
  readonly step?: number;
  readonly value?: number;
}

// eslint-disable-next-line react/display-name
export const FormInputNumber = React.memo((props: IFormInputNumberProps) => {

  const onChange = (e) => {
    if (!props.setFieldValue || !props.name) {
      return;
    }
    if (Number.isNaN(+e.target.value)) {
      return;
    }

    props.setFieldValue(props.name, +e.target.value || undefined);
  };

  const onInsert = () => {
    if (!props.setFieldValue || !props.name) {
      return;
    }

    const nextValue = (props.value || 0) + (props.step || 1);
    if (props.max !== undefined && nextValue > props.max) {
      return;
    }

    props.setFieldValue(props.name, nextValue);
  };

  const onDesert = () => {
    if (!props.setFieldValue || !props.name) {
      return;
    }

    const nextValue = (props.value || 0) - (props.step || 1);
    if (props.min !== undefined && nextValue < props.min) {
      return;
    }

    props.setFieldValue(props.name, nextValue);
  };

  return (
    <_FormInputNumber className="input-number-wrapper">
      <Input
        placeholder="1"
        {...props}
        onChange={onChange}
      />
      <div
        id="inc-button"
        className="noselect spinner-button inc"
        onClick={onInsert}
        role="button"
      >
        +
      </div>
      <div
        id="dec-button"
        className="noselect spinner-button dec"
        onClick={onDesert}
        role="button"
      >
        -
      </div>
    </_FormInputNumber>
  );
});
