import { RestfulRender } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared';
import { Img } from '@/_shared/components';
import { IProductType, IProject, modelResources } from '@/api';
import {
    projectBplusModelPagePath
} from '@/pages/project-bplus-model/ProjectBplusModel.shared';
import { _ProjectDetailProducts } from '@/pages/project-detail/views';
import { textIgnoreCase } from '@/translation';

const _ProjectBplusProducts = styled(_ProjectDetailProducts)`
  margin-bottom: 0;
  .load-more {
    border-top: 1px solid #C4C4C4;
    font-family: 'Copperplate';
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 140px;
    cursor: pointer;
  }

`;

interface IProjectBplusProductsProps {
  readonly project: IProject;
  readonly productTypes?: IProductType[];
  readonly selectedProductTypeSlug?: string;
}

export const ProjectBplusProducts = (props: React.PropsWithChildren<IProjectBplusProductsProps>) => {
  const { project, productTypes, selectedProductTypeSlug } = props;

  const isMobile = window.innerWidth <= 991;
  const slidesToShow = isMobile ? 1 : 3;

  return (
    <_ProjectBplusProducts>
      <Container>
        {
          productTypes?.map((productType) => {
            if (selectedProductTypeSlug && productType.slug !== selectedProductTypeSlug) {
              return null;
            }

            return (
              <RestfulRender
                key={productType.id}
                resource={modelResources.find}
                parameters={[{ type: 'query', parameter: 'productType', value: productType.id }]}
              >
                {({ data }) => {
                  return (
                    <div className="project-products">
                      <div className="project-card-area">
                        <div className="project-products-title">
                          {productType.name}
                        </div>
                      </div>
                      <div className="project-cards">
                        <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} draggable={false} infinite={false}>
                          {data?.map((ortherModel) => {
                            return (
                              <div key={ortherModel.id} className="project-card">
                                <Link to={replaceRoutePath(projectBplusModelPagePath, { projectSlug: project.slug, productTypeSlug: productType.slug, modelSlug: ortherModel.slug })}>
                                  <Img file={ortherModel.thumbnail} ratioX={1} ratioY={1} />
                                  <span className="hint">{ textIgnoreCase('Click to view details')}</span>
                                </Link>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  );
                }}
              </RestfulRender>
            );
          })
        }
      </Container>
      <div className="load-more d-none">
        <span>
          {textIgnoreCase('Load more')}
        </span>
      </div>
    </_ProjectBplusProducts>
  );
};