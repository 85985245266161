import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { getSettingValue, replaceRoutePath } from '@/_shared';
import { Img } from '@/_shared/components';
import { ISetting, settingResources } from '@/api';
import { officePagePath } from '@/pages/products/ProductsPage.shared';
import { textIgnoreCase } from '@/translation';

const _ProjectRequestThanksContent = styled.div`
  width: 630px;

  .thanks-img {
    img {
      max-height: 100%;
    }
  }
  .thanks-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .thanks-content-title {
    font-family: "Copperplate";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: #000000;
    margin-top: 24px;
    margin-bottom: 12px;
    text-align: center;
  }
  .thanks-content-message {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #7E7E7E;
    margin-bottom: 24px;
    text-align: center;
  }
  .thanks-content-button {
    margin-bottom: 12px;
  }

  @media(max-width: 991px) {
    width: 246px;
    margin: 0 auto;
  }
`;

interface IProjectRequestThanksContentProps {
  readonly setting?: ISetting;
}

export const ProjectRequestThanksContent = (props: React.PropsWithChildren<IProjectRequestThanksContentProps>) => {
  const { setting } = props;

  const [hotlineNumberSetting] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'HOTLINE_NUMBER' }]);

  const hotlineNumber = getSettingValue(hotlineNumberSetting, 'HOTLINE_NUMBER');

  return (
    <_ProjectRequestThanksContent>
      <Row>
        <Col sm={12} lg={5}>
          <div className="thanks-img">
            {
              setting?.valueMedia
                ? <Img file={setting.valueMedia} />
                : <img src="/static/assets/sub-thanks.jpg" alt="" />
            }
          </div>
        </Col>
        <Col sm={12} lg={7}>
          <div className="thanks-content">
            <div className="thanks-content-title">{textIgnoreCase('Thanks you')}!</div>
            <p className="thanks-content-message">
              {textIgnoreCase('Thanks! We\'ll get back to you as soon as possible. For urgent enquiries, please call us on')} {hotlineNumber ?? '...'}
            </p>
            <Button
              tag="a"
              href={replaceRoutePath(officePagePath, { productTypeId: undefined })}
              color="dark"
              className="thanks-content-button"
            >
              {textIgnoreCase('BROWN ALL PRODUCTS')}
            </Button>
          </div>
        </Col>
      </Row>
    </_ProjectRequestThanksContent>
  );
};