
import { Resource } from 'libs/restful';

import { IProject } from './project';
import { IUser } from './user';

export interface IComment {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly topic: string;
  readonly content: string;
  readonly project?: Partial<IProject>;
  readonly parent?: Partial<IComment>;
  readonly createdBy?: Partial<IUser>;
}

export const commentResources = {
  find: new Resource<IComment, IComment[]>({
    method: 'GET',
    url: '/customer/comments'
  }),
  count: new Resource<IComment, number>({
    method: 'GET',
    url: '/customer/comments/count'
  }),
  findOne: new Resource<IComment, IComment>({
    method: 'GET',
    url: '/customer/comments/:id'
  }),
  create: new Resource<IComment, IComment>({
    method: 'POST',
    url: '/customer/comments'
  }),
  update: new Resource<IComment, IComment>({
    method: 'PUT',
    url: '/customer/comments/:id'
  }),
  delete: new Resource<IComment, IComment>({
    method: 'DELETE',
    url: '/customer/comments/:id'
  }),
};
