import React from 'react';
import { Link } from 'react-router-dom';
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IPage } from '@/api';
import { textIgnoreCase } from '@/translation';

import { PartnerLoginForm, PartnerRegisterForm } from './partner-forms';

const _PartnerForms = styled.div`
  .col-lg-6 {
    padding : 0;
  }
  .col-left{
    position : relative;

    .iframe-video {
      height: calc(100vh - 70px);
    }
    .information {
      position : absolute;
      width : 100%;
      height : 100%;
      left : 0;
      top : 0;
      background: rgb(72 72 72 / .5);
      color: #ffffff;
      display : flex;
      align-items : center ;
      justify-content : center;
      flex-direction : column;
      h3 {
      text-transform : uppercase;
      font-size : 48px;
      span {
        font-size : 36px;
      }
      }
    }
  }
  .col-right {
    display : flex;
    align-items:center;
    justify-content : center;
    flex-direction : column;
    .wrapper-col{
      width : 70%;
     .description {
        margin : 30px 0;
      }
    }
    .nav-tabs .nav-link{
      &:hover {
        border-radius: 0;
      }
      &.active{
        color: #495057;
        background: #E9E9E9;
        border-radius: 0;
      }
    }
    h3{
      text-transform : uppercase;
    }
  }
  a {
    font-weight: bold;
  }
  @media (max-width : 992px){
    .col-lg-6 {
      padding : 0 15px;
        &:first-child {
        padding : 0;
      }
      &.col-left{
    .information {
     padding : 15px;
    }
  }
    }
    .col-right {
      margin : 30px 0;
      .wrapper-col{
        width : 100%;
      }
    }
  }
  
`;

interface IPartnerFormsProps {
  readonly title?: string;
  readonly page: IPage;
  readonly showLogin?: boolean;
  readonly defaultTab?: string;
}

export const PartnerForms = (props: React.PropsWithChildren<IPartnerFormsProps>) => {
  const { page } = props;

  const [activeTab, setActiveTab] = React.useState<string>(props.defaultTab ?? 'login');

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const linkVideo = page?.settings?.find(e => e.group === 'LINK_VIDEO_YOUTUBE');

  let urlYoutube = '';
  const YOUTUBE_URL_EMBED = 'https://youtube.com/embed/';

  if (linkVideo?.value) {
    if (linkVideo?.value.includes('/watch?v=')) {
      urlYoutube = linkVideo?.value?.replace(
        /^.*\/watch\?v=/i,
        YOUTUBE_URL_EMBED
      );
    } else {
      const lastSlashIndex = linkVideo?.value?.lastIndexOf('/');
      urlYoutube = [
        YOUTUBE_URL_EMBED,
        linkVideo?.value.slice(lastSlashIndex + 1),
      ].join('');
    }
  }

  return (
    <_PartnerForms>
      <Container>
        <Row>
          <Col lg={6} className='col-left'>
            {linkVideo?.value ? <>
              <iframe
                className='iframe-video'
                title="youtube"
                width="100%"
                src={`${urlYoutube}?autoplay=1&mute=1`}
                allowFullScreen
              />
            </> : <>
            <Img file={page.cover} />
            <div className='information'>
              <div dangerouslySetInnerHTML={{ __html: page.content! }} />
            </div>
            </>}
          </Col>
          <Col lg={6} className='col-right'>
            <div className='wrapper-col' >
              <h3>
                {props.title ?? textIgnoreCase('Partner AREA')}
              </h3>
              <div>
                <Nav tabs>
                  {
                    props.showLogin && (
                      <NavItem>
                        <NavLink
                          className={activeTab === 'login' ? 'active' : ''}
                          onClick={() => { toggle('login'); }}
                        >
                          {textIgnoreCase('LOGIN')}
                        </NavLink>
                      </NavItem>
                    )
                  }
                  <NavItem>
                    <NavLink
                      className={activeTab === 'register' ? 'active' : ''}
                      onClick={() => { toggle('register'); }}
                    >
                      {textIgnoreCase('REGISTER')}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="login">
                    <Row>
                      <Col sm="12">
                        <p className='description'>
                          {textIgnoreCase('Pro tool try with a SpecifierPortal account')}.
                          <Link to='/partner'>
                            <b>{textIgnoreCase('Log in')} / {textIgnoreCase('Sign Up')}</b>
                          </Link>
                        </p>
                        <p>
                          {textIgnoreCase('Please fill in the request form below and a team member will be in touch as soon as possible')}.
                        </p>
                        <PartnerLoginForm />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="register">
                    <PartnerRegisterForm />
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </_PartnerForms >
  );
};