export class HttpRequestFailError extends Error {
  public readonly status: number;
  public readonly body: Record<string, unknown>;

  constructor(status: number, body: Record<string, any>) {
    if (Array.isArray(body.message)) {
      super(body.message[0].messages[0].message);
    } else {
      super(body.message);
    }

    this.status = status;
    this.body = body;
  }
}