import React, { useState } from 'react';
import { Carousel, CarouselControl, CarouselItem } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IFile } from '@/api';

const _MCollectionProductPhotos = styled.div`
  height: 100%;

  .carousel {
    height: 100%;
    .carousel-inner {
      height: inherit;
    }

    .carousel-item {
      height: inherit;
      overflow: hidden;
      img {
        height: 100%;
      }
    }

    .carousel-control-prev, .carousel-control-next {
      width: 100px;
      height: 50px;
      top: calc(100% - 50px);
      opacity: 1;
      font: normal normal normal 14px/1 FontAwesome;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      height: 100%;
      width: 100%;
      background-image: none;
    }
    .carousel-control-next-icon:after {
      content:"→";
      font-size: 40px;
      font-weight: 300;
      color: #000;
    }
    .carousel-control-prev-icon:after {
      content:"←";
      font-size: 40px;
      font-weight: 300;
      color: #000;
    }
  }
`;

interface IMCollectionProductPhotosProps {
  readonly files: Partial<IFile>[];
}

export const MCollectionProductPhotos = (props: React.PropsWithChildren<IMCollectionProductPhotosProps>) => {
  const { files } = props;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [animating, setAnimating] = useState<boolean>(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === files.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? files.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <_MCollectionProductPhotos>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        {files.map((file) => {
          return (
            <CarouselItem
              key={file.id}
              onExiting={() => setAnimating(true)}
              onExited={() => setAnimating(false)}
            >
              <Img useWrap={false} file={file} ratioX={1} ratioY={1} />
            </CarouselItem>
          );
        })}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </_MCollectionProductPhotos >
  );
};