import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { IProject } from '@/api';

const _ProjectDetailInfo = styled.div`
  /** Styles here */
  .project {
    &-body {
      padding: 60px 0;
      max-width: 80%;
      margin: 0 auto;
    }
    &-name {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 6px;
      font-size: 24px;
      font-family: "Copperplate";
    }
    &-description {
      text-align: justify;
      margin-bottom: 24px;
      font-family: "Copperplate";
      p {
        font-weight: 300;
        margin-bottom: 0;
        strong, b {
          font-weight: 600;
        }
      }
    }
    &-content {
      margin-bottom: 48px;
      font-size: 13px;
      color: #7E7E7E;
      line-height: 20px;
    }
  }

  @media (max-width: 991px) {
    .project-body {
      max-width: 100%;
    }
  }
`;

interface IProjectDetailInfoProps {
  readonly project?: IProject;
}

interface IProjectDetailInfoState {

}

export class ProjectDetailInfo extends React.Component<
  IProjectDetailInfoProps,
  IProjectDetailInfoState> {
  public constructor(props: IProjectDetailInfoProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { project } = this.props;
    if (!project) {
      return null;
    }

    return (
      <_ProjectDetailInfo className="projectDetailInfo">
        <Container>
          <div className="project-body">
            <div className="project-name">
              {project.name}
            </div>
            <p className="project-description">
              <ReactMarkdown source={project.description} />
            </p>
            {!!project.content && (
              <div className="project-content">
                <ReactMarkdown source={project.content} />
              </div>
            )}
          </div>
        </Container>
      </_ProjectDetailInfo>
    );
  }
}
