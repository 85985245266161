import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { GlobalModalNoActions, Img } from '@/_shared/components';
import {
    ProductSaveDialog
} from '@/_shared/components/domain/ProductSaveDialog';
import { IFile, IProduct } from '@/api';
import {
    getProductFileRequestUrl
} from '@/pages/product-file-request/ProductFileRequest.shared';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

const _OfficeProductDetail = styled.div`
  padding: 100px 0 0 0;
  margin-bottom: 50px;
  border-top: 1px solid #eee;
  color: #7E7E7E; 

  img {
    max-width: 100%;
    width: auto;
  }

  .title {
    font-family: Copperplate;
    font-weight: 600;
    line-height: 45px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #000;
    h2 {
      margin-bottom: 0;
      font-size: 34px;
    }
    h5 {
      font-size: 24px;
    }
  }

  .description {
    display: flex;
    justify-content: space-between;
    strong {
      margin-bottom: 0;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  
  .custom {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #eee;

    label {
      font-family: UTM Copperplate;
      font-weight: bold;
      font-size: 13px;
      line-height: 20px;
      text-transform: uppercase;
    }

    ul {
      padding-left: 18px;
      li {
        margin-bottom: 5px;
      }
    }

    .btn {
      width: 130px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 5px 10px;
      font-family: Roboto;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      border: 0;
      border-radius: 2px;
      color: #FFFFFF;
      background: #C4C4C4;
      text-transform: uppercase;
      transition: 0.2s all;
      &.active, &:hover {
        background: #000;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .share {
      margin-top: 20px;
      padding: 30px 0;
      border-top: 1px solid #eee;
      display: flex;
      align-items: center;
      justify-content: space-between;
      label {
        font-family: "Copperplate";
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
      }
      ul {
        list-style: none;
        li {
          display: inline-block;
          margin-left: 10px;
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background-color: #000;
            color: #FFFFFF;
            cursor: pointer;
          }
          i {
            font-size: 18px;
          }
        }
      }
    }
    
  .banner{
    display : none;
    padding: 0;
  }
  @media (max-width : 767px){
    padding: 30px 0 0 0;
    .banner{
      display : block;
    }
    .wrap-buttons {
      margin-bottom: 6px; 
    }
  }
`;

interface IProductDetailProps {
  readonly product: IProduct;
  readonly defaultProductFeaturePhoto?: Partial<IFile>;
}

export const ProductDetail = (props: React.PropsWithChildren<IProductDetailProps>) => {
  const { product, defaultProductFeaturePhoto } = props;

  const [currentUser] = useRecoilState(currentUserState);

  const [isSaveDialogVisible, setIsSaveDialogVisible] = React.useState(false);

  const modelURL = product.modelUrl ? new URL(product.modelUrl!) : null;
  modelURL?.searchParams.delete('selectedComponentId');
  const modelFullPath = modelURL ? modelURL.pathname + modelURL.search : null;

  const modelUrlPaths = modelURL?.pathname.split('/');
  const modelSlug = modelUrlPaths && modelUrlPaths[modelUrlPaths?.length - 1];

  return (
    <_OfficeProductDetail>
      <Container>
        <Row>
          <Col sm={12} lg={8}>
            <div>
              <div className='title'><h2>{product.name}</h2><h5>{product.productType?.name}</h5></div>
              <div className='description'>
                <div className='subcontent' dangerouslySetInnerHTML={{ __html: product.detailDescription! }} />
              </div>
            </div>
          </Col>
          <Col sm={12} lg={4}>
            <div className="text-lg-right" dangerouslySetInnerHTML={{ __html: product.secondaryDescription! }} />
          </Col>
          <Col sm={12}>
            <div>
              <div className='custom'>
                <label>{textIgnoreCase('Customization')}</label>
                <ul>
                  <li>{textIgnoreCase('Customize material by order')}.</li>
                  <li>{textIgnoreCase('Customize dimension by order')}.</li>
                  <li>{textIgnoreCase('Available for material provided by customer')}.</li>
                </ul>
                <div className='wrap-buttons'>
                  {
                    (modelFullPath) && (
                      <Button
                        className="active"
                        color='black'
                        tag={Link}
                        to={modelFullPath}
                      >
                        {textIgnoreCase('360° view')}
                      </Button>
                    )
                  }
                  <Button color='grey' tag={Link} to={getProductFileRequestUrl(product.id, 'quote', modelSlug)}>
                    {textIgnoreCase('QUOTE')}
                  </Button>
                  <Button color='grey' tag={Link} to={getProductFileRequestUrl(product.id, 'catalogue', modelSlug)}>
                    {textIgnoreCase('CATALOGUE')}
                  </Button>
                  <Button color='grey' tag={Link} to={getProductFileRequestUrl(product.id, '3d-files', modelSlug)}>
                    {textIgnoreCase('DATA')}
                  </Button>
                  {
                    currentUser && (
                      <Button color='grey' onClick={() => setIsSaveDialogVisible(!isSaveDialogVisible)}>
                      {textIgnoreCase('SAVE')}
                    </Button>
                    )
                  }
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} className='office-col banner'>
            <div className='bg-title'>
              {
                product.featurePhoto
                  ? <Img file={product.featurePhoto} />
                  : <Img file={defaultProductFeaturePhoto} />
              }
            </div>
          </Col>
        </Row>
      </Container>

      <GlobalModalNoActions isOpen={isSaveDialogVisible} onClosed={() => setIsSaveDialogVisible(false)}>
        <ProductSaveDialog product={product} onClose={() => setIsSaveDialogVisible(false)} />
      </GlobalModalNoActions>
    </_OfficeProductDetail>
  );
};