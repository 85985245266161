import { useResourceLazy } from 'libs/restful';
import React, { FormEventHandler, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row, UncontrolledPopover } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { GlobalModalNoActions, Img } from '@/_shared/components';
import {
    IWishlist,
    IWishlistGroup,
    IWishlistItem,
    wishlistItemResources
} from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '@/pages/product/ProductPage.shared';
import { textIgnoreCase } from '@/translation';

import {
    wishlistGroupsState,
    wishlistItemsState
} from '../WishlistDetailPage.shared';
import { WishlistItemExternalForm } from './wishlist-header';
import { WishlistItemForm } from './wishlist-header/WishlistItemForm';

export const _WishlistDetail = styled.div`
  .row {
    border-left: 1px solid #C4C4C4;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 991px) {
      margin: 0 -15px;
    }
    &::before, &:after {
      content: " ";
      display: block;
      width: 100%;
      position: relative;
      top: 0px;
      z-index: 9;
    }
    &::before {
      border-top: 1px solid #C4C4C4;
    }
    &::after {
      top: -1px;
      border-bottom: 1px solid #C4C4C4;
    }
    
    .border-right-helper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      border-right: 1px solid #C4C4C4;
    }
  }
  .col {
    border: 1px solid #C4C4C4;
    border-top: none;
    border-left: none;
    padding: 0;
  }

  .item {
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:hover {
      .actions {
        display: flex!important;
      }
    }
  }

  .actions {
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    padding:  0;
    justify-content: center;
    align-items: center;
    background: white;
    color: black;
    border-color: #C4C4C4;
    @media screen and (max-width: 991px) {
      display: flex;
      background: transparent;
      color: black;
      border: none;
    }
  }
  .action {
    padding: 0 20px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    font-weight: 400;
    
    &:not(:last-child) {
      margin-bottom: 6px;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .name{
    font-size: 14px;
    margin-bottom: 8px;
  }

  .quantity {
    background-color: transparent;
    border-top: 1px solid #f1f1f1;
    margin: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .8rem;
    color: #777;
  }
`;

interface IWishlistDetailProps {
  readonly wishlist: IWishlist;
  readonly selectedWishlistGroup?: IWishlistGroup | null | undefined;
}

export const WishlistDetail = (props: React.PropsWithChildren<IWishlistDetailProps>) => {

  const [wishListGroups] = useRecoilState(wishlistGroupsState);

  const [deletingItem, setDeletingItem] = useState<IWishlistItem | null>(null);

  const [wishlistItems, updateWishlistItems] = useRecoilState(wishlistItemsState);
  const [updatingWishlistItem, setUpdateWishlistItem] = useState<IWishlistItem | null>(null);

  const [, creating, createItem] = useResourceLazy(wishlistItemResources.create);
  const [, deleting, deleteItem] = useResourceLazy(wishlistItemResources.delete);

  const sortWishlistItems = (items: IWishlistItem[] = []) => items.sort((a, b) => (a.productName).localeCompare(b.productName) || b.createdAt!.localeCompare(a.createdAt!));

  useEffect(() => {
    updateWishlistItems(sortWishlistItems(props.wishlist.wishlistItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onItemUpdated = (wishlistItem: IWishlistItem) => {
    setUpdateWishlistItem(null);
    const oldWishlistItemIndex = wishlistItems.findIndex(wi => wi.id === wishlistItem.id);
    const newWishlistItems = [...wishlistItems];

    newWishlistItems[oldWishlistItemIndex] = wishlistItem;
    updateWishlistItems(newWishlistItems);
  };

  const handleDuplicateItem = (source: IWishlistItem) => {
    if (creating) {
      return;
    }

    const item = {
      productName: source.productName,
      wishlist: source.wishlist,
      data: source.data,
      description: source.description,
      thumbnail: source.thumbnail,
      quantity: source.quantity,
      estPrice: source.estPrice,
      wishlistGroup: source.wishlistGroup,
      isExternal: source.isExternal,
      link: source.link,
    } as IWishlistItem;

    createItem([
      { type: 'body', value: item as any }
    ]).then((newWishlistItem) => {
      updateWishlistItems([...wishlistItems, newWishlistItem]);
    });
  };

  const handleDeleteItem = async (item: IWishlistItem) => {
    if (deleting) {
      return;
    }

    await deleteItem([
      { type: 'path', parameter: 'id', value: item.id }
    ]);

    setDeletingItem(null);
    updateWishlistItems(wishlistItems.filter(wi => wi.id !== item.id));
  };

  const displayingWishlistItems = wishlistItems.filter(wi => {
    if (props.selectedWishlistGroup === undefined) {
      return true;
    }

    if (props.selectedWishlistGroup === null) {
      return !wi.wishlistGroup;
    }

    if (typeof wi.wishlistGroup === 'string') {
      return wi.wishlistGroup === props.selectedWishlistGroup.id;
    }

    return wi.wishlistGroup?.id === props.selectedWishlistGroup.id;
  });

  return (
    <_WishlistDetail>
      <Container>
        <Row>
          {
            sortWishlistItems(displayingWishlistItems)?.map((item) => {

              const product = {
                id: item.data?.productId,
                slug: item.data?.productSlug,
              };

              const productURL = item.isExternal
                ? item.link
                : (
                  item.data?.for === 'commercial'
                    ? getMCollectionProductUrl(product)
                    : getProductUrl(product)
                );

              const group = wishListGroups.find(wg => wg.id === ((item.wishlistGroup as IWishlistGroup)?.id ?? item.wishlistGroup));

              return (
                <Col key={item.id} xs={12} sm={6} md={4} className="col">
                  <div className="item" id={`item${item.id}`} onClick={() => setUpdateWishlistItem(item)}>
                    <div className="thumbnail">
                      <Img file={item.thumbnail} ratioX={1} ratioY={1} />
                    </div>
                    <div style={{ padding: '12px 25px', background: '#f3f3f3' }}>
                      <small><b>{item.data?.type ?? '-'}</b></small>
                      <div className="name">
                        {item.productName}
                      </div>
                      <div className="quantity">
                        x{item.quantity} | {group?.name ?? 'Not assigned'}
                      </div>
                    </div>
                    <Button className="actions" id={`itemActions${item.id}`} onClick={(e) => e.stopPropagation()}>
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledPopover
                      container={`item${item.id}`}
                      trigger="hover"
                      placement="bottom-end"
                      target={`itemActions${item.id}`}
                      delay={{ show: 0, hide: 250 }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {
                        (item.isExternal && productURL)
                          ? (<a className="action" href={productURL} target="_blank" rel="noreferrer">{textIgnoreCase('Product page')}</a>)
                          : (
                            <Link className="action" to={productURL!}>
                              {textIgnoreCase('Product page')}
                            </Link>
                          )
                      }
                      <div className="action" onClick={() => handleDuplicateItem(item)}>
                        {textIgnoreCase('Duplicate')}
                      </div>
                      <div className="action" onClick={() => setDeletingItem(item)}>
                        {textIgnoreCase('Delete')}
                      </div>
                    </UncontrolledPopover>
                  </div>
                </Col>
              );
            })
          }
          <div className="border-right-helper"></div>
        </Row>
      </Container>
      {
        !!updatingWishlistItem && (
          <GlobalModalNoActions isOpen={true} onClosed={() => setUpdateWishlistItem(null)}>
            {
              updatingWishlistItem.isExternal
                ? (
                  <WishlistItemExternalForm
                    onCanceled={() => setUpdateWishlistItem(null)}
                    onUpdated={onItemUpdated}
                    updatingWishlistItem={updatingWishlistItem}
                    wishlistId={props.wishlist.id}
                  />
                )
                : (
                  <WishlistItemForm
                    onCanceled={() => setUpdateWishlistItem(null)}
                    onUpdated={onItemUpdated}
                    updatingWishlistItem={updatingWishlistItem}
                  />
                )
            }
          </GlobalModalNoActions>
        )
      }
      <GlobalModalNoActions isOpen={!!deletingItem} onClosed={() => setDeletingItem(null)}>
        <div>
          <p>{textIgnoreCase('Are you sure you want to delete this item?')}</p>
          <div className="modal-footer-actions">
            <Button className="" onClick={() => setDeletingItem(null)}>
              {textIgnoreCase('Cancel')}
            </Button>
            <Button className="btn-black" onClick={() => handleDeleteItem(deletingItem!)} disabled={deleting}>
              {textIgnoreCase('Delete')}
            </Button>
          </div>
        </div>
      </GlobalModalNoActions>
    </_WishlistDetail>
  );
};