import groupBy from 'lodash/groupBy';
import * as React from 'react';
import styled from 'styled-components';

import { DesignToolBase } from './';

const _DesignToolVariants = styled.div`
  margin-bottom: 36px;
`;

interface IDesignToolVariantsProps {
  readonly onChange: (toggle: boolean) => void;
}

export class DesignToolVariants extends DesignToolBase<IDesignToolVariantsProps> {
  public render() {
    const { } = this.props;
    const { selectedVariantId } = this.state;

    const avaliableVariants = this.getAvaliableVariants();

    return (
      <_DesignToolVariants>
        <div >
          {avaliableVariants.map((avaliableVariant) => {
            const isSelected = selectedVariantId === avaliableVariant;

            const onCheck = () => {
              this.setState({
                selectedVariantId: !isSelected ? avaliableVariant : undefined
              });
            };

            return (
              <div key={avaliableVariant} className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id={avaliableVariant}
                  checked={isSelected}
                  placeholder="b"
                  value={avaliableVariant}
                  defaultValue={avaliableVariant}
                  onChange={onCheck}
                  aria-checked={isSelected}
                />
                <label className="custom-control-label" htmlFor={avaliableVariant}>
                  {avaliableVariant}
                </label>
              </div>
            );
          })}
        </div>
      </_DesignToolVariants>
    );
  }

  private readonly getAvaliableVariants = () => {
    const { model } = this.props;

    if (!model) {
      return [];
    }

    const variantGroups = groupBy(model.componentVariants, 'variantName');

    return Object.keys(variantGroups);
  }
}
