
import { Resource } from 'libs/restful';

import { IFile } from './file';

export interface IBanner {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly content?: string;
  readonly content_en?: string;
  readonly image?: Partial<IFile>;
  readonly url?: string;
  readonly urlLabel?: string;
  readonly urlLabel_en?: string;
  readonly position?: string;
}

export const bannerResources = {
  find: new Resource<IBanner, IBanner[]>({
    method: 'GET',
    url: '/admin/banners'
  }),
  count: new Resource<IBanner, number>({
    method: 'GET',
    url: '/admin/banners/count'
  }),
  findOne: new Resource<IBanner, IBanner>({
    method: 'GET',
    url: '/admin/banners/:id'
  }),
  create: new Resource<IBanner, IBanner>({
    method: 'POST',
    url: '/admin/banners'
  }),
  update: new Resource<IBanner, IBanner>({
    method: 'PUT',
    url: '/admin/banners/:id'
  }),
  delete: new Resource<IBanner, IBanner>({
    method: 'DELETE',
    url: '/admin/banners/:id'
  }),
};
