import { Resource } from '../base';
import { baseURI } from '../configs';
import { RequestParameter } from '../Types';

export const getRequestUrl = (
  resource: Resource<unknown, unknown>,
  requestParams?: RequestParameter[]
) => {
  let requestUrl = resource.urlReslover(requestParams);
  
  if (requestUrl.startsWith('/')) {
    requestUrl = baseURI + requestUrl;
  }

  return requestUrl;
};