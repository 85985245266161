import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';

interface GlobalModalProps {
  readonly className?: string;
  readonly isOpen?: boolean;
  readonly onClosed?: () => void;
}

export const GlobalModalNoActions = (props: React.PropsWithChildren<GlobalModalProps>) => {
  const {
    children,
    className,
    isOpen,
    onClosed
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  React.useEffect(
    () => {
      setModal(isOpen ?? false);
    },
    [isOpen]
  );

  return (
    <Modal
      onClosed={onClosed}
      isOpen={modal}
      toggle={toggle}
      className={className}
      style={{ width: 'max-content', marginLeft: 'auto', marginRight: 'auto', maxWidth: 'unset' }}
    >
      <ModalBody style={{ width: 'max-content', margin: '0 auto' }}>
        {children}
      </ModalBody>
    </Modal >
  );
};