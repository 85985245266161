import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { CatalogueFileRequest } from './views';

const _CataloguePage = styled.div`
`;

interface ICataloguePageProps {

}

export const CataloguePage = (props: React.PropsWithChildren<ICataloguePageProps>) => {
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'catalogue' }]);

  if (!pages || !pages.length) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_CataloguePage>
      <CatalogueFileRequest
        page={page}
        requestType="catalogue"
      />
    </_CataloguePage>
  );
};