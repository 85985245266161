import classNames from 'classnames';
import React from 'react';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import {
    Col,
    Collapse,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem,
    Row,
    UncontrolledDropdown,
    UncontrolledPopover
} from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { useAccessToken } from '@/hooks';
import { getFeaturesPageUrl } from '@/pages/features/FeaturesPage.shared';
import {
    getInspirationsPageUrl
} from '@/pages/inspirations/InspirationsPage.shared';
import {
    MCollectionTypePath
} from '@/pages/m-collection/MCollectionPage.shared';
import { outdoorPath } from '@/pages/outdoor/OutDoorPage.shared';
import { officePath } from '@/pages/products/ProductsPage.shared';
import { projectsPagePath } from '@/pages/projects/ProjectsPage.shared';
import {
    userProfilePagePath
} from '@/pages/user-profile/UserProfilePage.shared';
import { wishlistsPagePath } from '@/pages/whislists/WishlistsPage.shared';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

import { FooterSocialList } from './default-layout-footer';

interface IDefaultLayoutHeaderProps {
}

const _DefaultLayoutHeader = styled.header`
  transition: top 0.3s;
  height: 74px;
  display: flex;
  align-items: center;
  background : #ffffff;
  border-bottom :0.5px solid #C4C4C4;
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0; /* Stay on top */

  .header-container {
    padding : 0;
    .logo {
      width: 200px;
    }
    .nav-link-drop {
      a{
        padding:0 20px;
      }
      .dropdown-menu {
        border: none;
        .dropdown-item{
          color: #767676;
          font-size: 12px;
        }
      }
    }
    .col-nav{
      display : flex;
      align-items: center;
      justify-content : flex-end;
      min-width: fit-content;
      .button-close{
        display: none;
      }
      .logo-mobile{
        display: none;
      }
      .list-socical{
        display: none;
      }
    }
    a {
      &.logo-wrapper {
        padding: 26px 0;
      }
      padding: 26px ;
      line-height : 18px;
      font-size: 12px;
        &.selected {
          font-weight: 700;
          font-size: 12px;
      } 
    }
  }

  .icon {
    height: 20px;
  }

  @media(max-width : 991px) {
    .col-nav {
      padding: 0;
    }

      .header-container {
        padding: 0 15px;
        .dropdown-menu {
          padding-bottom: 0;
          .dropdown-item {
            text-align: end;
            &:nth-child(3) {
              margin-top: 12px;
            }
          }
          .dropdown-divider {
            display: none;
          }
        }
        

        .fake-bg{
          position: fixed;
          top: 0;
          left: 0%;
          width: 100%;
          height: 100%;
          background: rgb(0 0 0 / 0.5);
          opacity: 0;
          transition: all 0.3s;
          visibility: hidden;
          &.isOpen {
            visibility: visible;
            opacity: 1;
          }
        }
        .logo{
          width: 138px;
        }
          .navbar-collapse {
            position: fixed;
            z-index: 1000;
            width: 327px;
            background: rgb(255, 255, 255);
            height: 100% !important;
            display:flex;
            right : 0;
            top: 0;
            align-items: start;
            flex-direction: column;
            padding: 0 50px 0 0;  
            .navbar-nav{
              display: flex;
              flex-direction: column;
              padding-left: 0;
              margin-bottom: 0;
              width: 100%;
              list-style: none;
              flex: 1;
              font-size : 14px;
              li {
                margin: 15px 15px;
              }
            }
            .button-close{
              display: block;
              position: absolute;
              top: 0;
              padding: 10px;
              right:0 ;
              color :  #C4C4C4;
            }
            .logo-mobile{
              width: 100%;
              display: block;
              z-index: 99;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              float: none;
              margin-top: 50px;
              padding-right: 0;
              padding-bottom: 30px;
              margin-bottom: 23px;
              border-bottom: 0.5px solid #C4C4C4;
              .logo{
                width: 232px;
              }
            }
            &.collapsing {
              right : 0 !important;
            }
            &:not(.show) {
              right : -100%;
              transition : 0.3s;
              .fake-bg {
                display: none;
              }
            }
            .nav-item {
              margin: 10px 15px;
              text-align:right;
              a {
                width: 100%;
                font-size: 14px;
                padding: 0;
              }
            }
           .list-socical{
             display: flex ;
             list-style: none;
             margin: 0;
             padding : 0;
             margin-bottom: 40px;
             padding-left: 50px;
             li{
               a{
                padding: 0;
                justify-content: center;
                margin-right: 6px;
                background: #000000;
                width: 30px;
                height: 30px;
                color: #ffffff;
                margin-left: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
               }
               img {
                  height: 18px;
                  width: auto;
                  filter: contrast(5);
                }
             }
           }
        }
      }
    }
`;

const _SubmenuAction = styled.a`
  font-size: 14px;
  width: 100px;
  cursor: pointer;
  display: flex;
  &:hover {
    font-weight: unset;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  a {
    font-size: inherit!important;
    &.selected {
      font-weight: 700;
    }
  }

  .header-action-icon {
    margin-right: 15px;
  }
`;

export const DefaultLayoutHeader = (props: React.PropsWithChildren<IDefaultLayoutHeaderProps>) => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [, setAccessToken] = useAccessToken();
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory();


  const toggle = () => setIsOpen(!isOpen);

  React.useEffect(
    () => {
      setIsOpen(false);

      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));

      window.scrollTo({
        top: elementToScroll ? elementToScroll.offsetTop : 0
      });
    },
    [location.pathname]
  );

  return (
    <_DefaultLayoutHeader id="defaultLayoutHeader">
      <Container className="header-container">
        <Row>
          <Col>
            <Link className="logo-wrapper" to="/">
              <img className='logo' src="/static/logo-full.png" alt="logo" />
            </Link>
          </Col>

          <Col className='col-nav'>
            <Navbar de expand='lg' >
              <div onClick={toggle} className={classNames('fake-bg', { isOpen })} />
              <NavbarToggler onClick={toggle}>
                <i className="fa fa-bars" aria-hidden="true"></i>
              </NavbarToggler>
              <Collapse
                isOpen={isOpen}
                navbar
              >
                <NavbarToggler className='button-close' onClick={toggle}>
                  <i className="fa fa-times" aria-hidden="true"></i>
                </NavbarToggler>
                <Link className='logo-mobile' to="/">
                  <img className='logo' src="/static/logo-full.png" alt="logo" />
                </Link>
                <Nav navbar>
                  <NavItem>
                    <NavLink
                      exact
                      to="/"
                      activeClassName="selected"
                    >
                      {textIgnoreCase('Home')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={MCollectionTypePath}
                      activeClassName="selected"
                    >
                      {textIgnoreCase('[M] Collection')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={officePath}
                      activeClassName="selected"
                    >
                      {textIgnoreCase('Indoor')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to={outdoorPath}
                      activeClassName="selected"
                    >
                      {textIgnoreCase('Outdoor')}
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar className='nav-link-drop'
                  >
                    <DropdownToggle nav caret >
                    {textIgnoreCase('Gallery')}
                    </DropdownToggle>
                    <DropdownMenu >
                      <DropdownItem
                        onClick={()=>history.push(getFeaturesPageUrl())}
                      > {textIgnoreCase('Features')}</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                       onClick={()=>history.push(getInspirationsPageUrl())}
                      >{textIgnoreCase('Inspirations')}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  {
                    !currentUser?.id && (
                      <NavItem>
                        <NavLink
                          to="/partner"
                          activeClassName="selected"
                        >
                          {textIgnoreCase('Partner')}
                        </NavLink>
                      </NavItem>
                    )
                  }
                  <NavItem>
                    <NavLink
                      to={wishlistsPagePath}
                      activeClassName="selected"
                    >
                      {textIgnoreCase('Projects')}
                    </NavLink>
                  </NavItem>
                  {
                    currentUser && (
                      <>
                        <NavItem>
                          <NavLink to="/user" id="profileMenu" activeClassName="selected">
                            {currentUser.displayName ?? currentUser.email}
                          </NavLink>
                        </NavItem>

                        <UncontrolledPopover placement="bottom-end" offset={1} target="profileMenu" trigger="hover">
                          <_SubmenuAction>
                            <NavLink
                              activeClassName="selected"
                              to={userProfilePagePath}
                              exact={true}
                            >
                              {textIgnoreCase('Edit Profile')}
                            </NavLink>
                          </_SubmenuAction>
                          <_SubmenuAction>
                            <NavLink
                              to={projectsPagePath}
                              activeClassName="selected"
                              exact={true}
                            >
                              {textIgnoreCase('3D Library')}
                            </NavLink>
                          </_SubmenuAction>
                          <_SubmenuAction>
                            <a
                              className="logout-link"
                              href="/"
                              onClick={() => {
                                setCurrentUser(undefined);
                                setAccessToken(undefined);
                              }}
                            >
                              {textIgnoreCase('Logout')}
                            </a>
                          </_SubmenuAction>
                        </UncontrolledPopover>
                      </>
                    )
                  }
                </Nav>
                <FooterSocialList />
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </_DefaultLayoutHeader >
  );
};  