import { useResource, useResourceLazy } from 'libs/restful';
import React, { useEffect } from 'react';
import { Button, Col, Input, InputGroup, Row } from 'reactstrap';
import styled from 'styled-components';

import {
    IProduct,
    IWishlist,
    IWishlistItem,
    wishlistItemResources,
    wishlistResources
} from '@/api';
import { textIgnoreCase } from '@/translation';

import { Img } from '../generic';

const _ProductSaveDialog = styled.div`
  width: 765px;

  @media (max-width: 768px) {
    width: 310px;
  }

  .content-area {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    margin-bottom: 20px;
    font-size: 20px;
    font-wight: bold;
  }

  .wishlists {
    flex-grow: 1;
    max-height: 372px;
    overflow-y: auto;
  }

  .wishlist-item {
    padding: 10px 20px;
    background: #f1f1f1;
    margin-bottom: 1px;
    display: flex;

    .save-btn {
      display: none;
    }

    &:hover {
      .save-btn {
        display: block;
      }
    }
  }

  .wishlist-item-name {
    flex-grow: 1;
    line-height: 41px;
    font-weight: bold;
    text-transform: capitalize;
  }

  .actions {
    margin-top: 24px;
  }
`;

interface IProductSaveDialogProps {
  readonly product?: IProduct;
  readonly onClose: () => void;
}

export const ProductSaveDialog = (props: IProductSaveDialogProps) => {
  const { product } = props;

  const [wishlists, setWishlists] = React.useState<IWishlist[]>([]);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [fetctedWishlist, loading] = useResource(wishlistResources.find);

  const [, isCreating, createWishlist,] = useResourceLazy(wishlistResources.create);
  const [, isCreatingWishlistItem, createWishlistItem,] = useResourceLazy(wishlistItemResources.create);

  useEffect(() => {
    if (!fetctedWishlist) {
      return;
    }

    setWishlists([...fetctedWishlist]);
  }, [fetctedWishlist]);

  const onCreate = async () => {
    const name = inputRef.current?.value;
    if (!name) {
      return;
    }

    const newWishlist = await createWishlist([{
      type: 'body',
      value: { name }
    }]);

    setWishlists([newWishlist, ...wishlists]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  if (!product) {
    return null;
  }

  const isEmpty = wishlists?.length === 0 && !loading;

  const renderWishlist = (wishlist: IWishlist) => {
    const onSave = async () => {
      await createWishlistItem([{
        type: 'body',
        value: {
          wishlist: wishlist.id,
          productName: product?.name,
          thumbnail: product?.thumbnail?.url,
          quantity: 1,
          data: {
            productId: product?.id,
            productSlug: product?.slug,
            type: product?.productType?.name,
            for: product?.for,
          }
        } as Partial<IWishlistItem>
      }]);

      props?.onClose();
    };

    return (
      <div key={wishlist.id} className="wishlist-item">
        <div className="wishlist-item-name">
          {wishlist.name}
        </div>
        <div className="wishlist-item-actions">
          <Button className="save-btn" disabled={isCreatingWishlistItem} onClick={onSave}>
            {textIgnoreCase('Save')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <_ProductSaveDialog>
      <Row>
        <Col ms={12} lg={6}>
          <div>
            <Img file={product?.thumbnail} />
          </div>
        </Col>
        <Col ms={12} lg={6}>
          <div className="content-area">
            <div className="header">{textIgnoreCase('Save to')}:</div>
            <div className="wishlists">
              {
                isEmpty && (
                  <p><i>{textIgnoreCase('No existing project, create now!')}</i></p>
                )
              }
              {wishlists.map(renderWishlist)}
            </div>
            <div className="actions">
              <InputGroup>
                <Input innerRef={inputRef} placeholder={textIgnoreCase('Create new project')} />
                <Button
                  className="btn-black"
                  onClick={onCreate}
                  disabled={isCreating}
                >
                  {textIgnoreCase('Create')}
                </Button>
              </InputGroup>
            </div>
          </div>
        </Col>
      </Row>
    </_ProductSaveDialog>
  );
};