import React from 'react';
import styled from 'styled-components';

import { IOutdoorCollection } from '@/api/outdoorcollections';

import { OutdoorContentCard } from './outdoor-content';

const _HomeContent = styled.div`
  @media (max-width: 991px) {
    margin-bottom : 50px;

    .product-card:nth-child(even) {
      .information {
        text-align: right;
      }
    }
  }
`;

interface IOutdoorContentProps {
  readonly outdoorCollections: IOutdoorCollection[] | undefined;
}

export const OutdoorContent = (props: React.PropsWithChildren<IOutdoorContentProps>) => {
  const { outdoorCollections } = props;


  return (
    <_HomeContent>
      {
        outdoorCollections?.map((outdoorcollection, index) => {
          return (
            <OutdoorContentCard
              key={index}
              index={index}
              outdoorcollection={outdoorcollection}
            />
          );
        })
      }
    </_HomeContent>
  );
};