
import { Resource } from 'libs/restful';


export interface IPartnerRegistration {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly email: string;
  readonly mobile: string;
  readonly companyName: string;
  readonly businessAreas: string;
  readonly companyAddress: string;
  readonly jobTitle: string;
  readonly companyWebsite?: string;
}

export const partnerRegistrationResources = {
  find: new Resource<IPartnerRegistration, IPartnerRegistration[]>({
    method: 'GET',
    url: '/customer/partnerregistrations'
  }),
  count: new Resource<IPartnerRegistration, number>({
    method: 'GET',
    url: '/customer/partnerregistrations/count'
  }),
  findOne: new Resource<IPartnerRegistration, IPartnerRegistration>({
    method: 'GET',
    url: '/customer/partnerregistrations/:id'
  }),
  create: new Resource<IPartnerRegistration, IPartnerRegistration>({
    method: 'POST',
    url: '/customer/partnerregistrations'
  }),
  update: new Resource<IPartnerRegistration, IPartnerRegistration>({
    method: 'PUT',
    url: '/customer/partnerregistrations/:id'
  }),
  delete: new Resource<IPartnerRegistration, IPartnerRegistration>({
    method: 'DELETE',
    url: '/customer/partnerregistrations/:id'
  }),
};
