import { useResourceLazy } from 'libs/restful';
import React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

import { IWishlist, IWishlistGroup, wishlistGroupResources } from '@/api';
import { textIgnoreCase } from '@/translation';

const _WhislistGroupCreateForm = styled.div`
  .group-create-btn {
    font-style: italic;
    cursor: pointer;
    height: 50px;
    line-height: 50px;
  }

  .group-create-form {
    display: flex;
    input {
      margin-bottom: 0;
    }
  }
`;

interface IWhislistGroupCreateFormProps {
  readonly wishlist: IWishlist;
  readonly onCreated: () => void;
}

export const WhislistGroupCreateForm = (props: React.PropsWithChildren<IWhislistGroupCreateFormProps>) => {
  const { } = props;

  const [, loading, createGroup] = useResourceLazy(wishlistGroupResources.create);

  const [showCreateGroupForm, setShowCreateGroupForm] = React.useState(false);

  const handleCreateGroup: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const data = new FormData(form);
    await createGroup([{
      type: 'body',
      value: {
        wishlist: props.wishlist.id,
        name: data.get('name') as string,
      } as IWishlistGroup as any,
    }]);

    form.reset();
    setShowCreateGroupForm(false);
    props.onCreated();
  };

  return (
    <_WhislistGroupCreateForm>
      {
        showCreateGroupForm
          ? (
            <div>
              <form className='group-create-form' onSubmit={handleCreateGroup}>
                <Input
                  type="text"
                  autoFocus name="name"
                  id="name"
                  placeholder={textIgnoreCase('Input name')}
                  onBlur={() => setShowCreateGroupForm(false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Escape' && !loading) {
                      setShowCreateGroupForm(false);
                    }
                  }}
                />
              </form>
            </div>
          ) : (
            <div className="group-create-btn" onClick={() => setShowCreateGroupForm(true)}>
              {textIgnoreCase('Create a group')}
            </div>
          )
      }
    </_WhislistGroupCreateForm>
  );
};