import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IFeature } from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '@/pages/product/ProductPage.shared';

const _FeatureDetailsProducts = styled.div`
  .row {
    border-left: 1px solid #C4C4C4;
    position: relative;

    @media screen and (max-width: 991px) {
      margin: 0 -15px;
    }
    &::before, &:after {
      content: " ";
      display: block;
      width: 100%;
      border-bottom: 1px solid #C4C4C4;
      position: relative;
      top: 1px;
      z-index: 9;
    }
    &::after {
      top: -1px;
    }
    
    .border-right-helper {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #C4C4C4;
    }
  }
  .col {
    border: 1px solid #C4C4C4;
    border-top: none;
    border-left: none;
    padding: 0;
  }

  .item {
    overflow: hidden;
    position: relative;
    cursor: pointer;

    &:hover {
      .name {
        bottom: 0;
      }
    }
  }
  .name {
    width: 100%;
    bottom: -58px;
    position: absolute;
    transition: all 0.3s;
    background: rgba(0,0,0,0.6);
    padding: 12px;
    font-size: 15px;
    font-family: "Copperplate";
    color: white;
  }

  .feature-name {
    font-family: "Copperplate";
    font-weight: bold;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 12px;
    
    @media screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 1.2;
    }
  }

  .feature-info {
    padding: 0 0 0 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    @media screen and (max-width: 991px) {
      padding: 0 0 0 20px;
    }
  }

  .feature-type {
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
  }

`;

interface IFeatureDetailsProductsProps {
  readonly feature: IFeature;
}

export const FeatureDetailsProducts = (props: React.PropsWithChildren<IFeatureDetailsProductsProps>) => {

  if (!props.feature.featureProducts?.length) {
    return null;
  }

  return (
    <_FeatureDetailsProducts>
      <Container>
        <Row noGutters>
          <Col xs={6} lg={3} className="col">
            <div className="feature-info">
              <div className="feature-name">
                {props.feature.name}
              </div>
              <div className="feature-type">
                {props.feature.featureType.name}
              </div>
              <div>
                All products of {props.feature.name}
              </div>
            </div>
          </Col>
          {props.feature.featureProducts?.map(o => {
            if (!o.product) {
              return null;
            }
            const productURL = o.product.for === 'commercial'
              ? getMCollectionProductUrl(o.product)
              : getProductUrl(o.product);

            return (
              <Col key={o.id} xs={6} lg={3} className="col">
                <Link to={productURL}>
                  <div className="item">
                    <div className="thumbnail">
                      <Img file={o.photo} />
                    </div>
                    <div className="name">
                      {o.name}
                    </div>
                  </div>
                </Link>
              </Col>
            );
          })}
          <div className="border-right-helper" />
        </Row>
      </Container>
    </_FeatureDetailsProducts>
  );
};