import { IFile } from '@/api';

export type ImageSize = 'Thumbnail';

export const getFileUrl = (file?: Partial<IFile> | string, size?: ImageSize) => {
  if (!file) {
    return undefined;
  }

  if (typeof file === 'string') {
    const isBase64 = file.startsWith('data:');
    if (isBase64) {
      return file;
    }

    return `${MEDIA_ENTRY}${file}`;
  }

  return `${MEDIA_ENTRY}${file['url' + (size || '')]}`;
};
