import { useResourceLazy } from 'libs/restful';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Input } from 'reactstrap';
import { useRecoilState } from 'recoil';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions } from '@/_shared/components';
import { events } from '@/_shared/events';
import { userResources } from '@/api';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

interface IUserChangePasswordFormValue {
  readonly password: string;
  readonly rePassword: string;
}

interface IUserChangePasswordFormProps {

}

export const UserChangePasswordForm = (props: React.PropsWithChildren<IUserChangePasswordFormProps>) => {
  const [currentUser] = useRecoilState(currentUserState);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formError, setFormError] = React.useState<string>();
  const [changePasswordResult, , changePassword, changePasswordError] = useResourceLazy(userResources.changePassword);

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget) as IUserChangePasswordFormValue;

      if (formValues.password !== formValues['rePassword']) {
        return void setFormError(textIgnoreCase('Password doesn\'t match'));
      }

      if (formError) {
        setFormError('');
      }

      if (formValues)
        changePassword([
          {
            type: 'path',
            parameter: 'id',
            value: currentUser?.id as string
          },
          {
            type: 'body',
            value: {
              ...formValues,
            }
          }
        ]);
    },
    [changePassword, currentUser?.id, formError]
  );

  useEffect(
    () => {
      if (changePasswordResult == null) {
        return;
      }

      setIsModalOpen(false);
    },
    [changePasswordResult]
  );

  useEffect(
    () => {
      const changePasswordEventCB = () => setIsModalOpen(true);
      events.addListener('OPEN_CHANGE_PASSWORD_MODAL', changePasswordEventCB);
      return () => void events.removeAllListeners('OPEN_CHANGE_PASSWORD_MODAL');
    },
    []
  );

  return (
    <>
      <GlobalModalNoActions isOpen={isModalOpen} onClosed={() => setIsModalOpen(false)}>
        <Form className="change-password-form" onSubmit={onSubmit} style={{ width: 340 }}>
          <h5 className='mb-4'>{textIgnoreCase('Change Password')}</h5>
          {formError && (<Alert color="danger">{textIgnoreCase(formError)}</Alert>)}
          <FormGroup row>
            <Col>
              <Input type="password" name="password" id="password" placeholder={textIgnoreCase('New password')} required={true} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Input type="password" name="rePassword" id="rePassword" placeholder={textIgnoreCase('New password again')} required={true} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Button color='black'>{textIgnoreCase('Submit')}</Button>
            </Col>
          </FormGroup>
        </Form>
      </GlobalModalNoActions>
    </>
  );
};