// tslint:disable
import './ThreeSence.scss';

import differenceBy from 'lodash/differenceBy';
import * as React from 'react';
import * as THREE from 'three';
import {
    MaterialCreator,
    MTLLoader
} from 'three/examples/jsm/loaders/MTLLoader';
import {
    MtlObjBridge
} from 'three/examples/jsm/loaders/obj2/bridge/MtlObjBridge.js';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';

import { getFileUrl } from '@/_shared/utils';
import { IGroupsComponent, IGroupsMaterial } from '@/api';

import {
    IProductModule,
    IThreeSenceBaseProps,
    ThreeSenceBase
} from './ThreeSenceBase';

type QueueAction = () => Promise<unknown>;

interface IThreeSenceProps extends IThreeSenceBaseProps {
  readonly height?: number;
  readonly onload?: () => void;
}

export class ThreeSence extends ThreeSenceBase<IThreeSenceProps> {

  public static instance: ThreeSence;
  public static defaultMaterialTextureUrl = getFileUrl('/uploads/2f14c3ef55fe4219819093734cd589a1.jpg')!;

  readonly state = {
    isQueueRuning: false
  };

  private _loaded = false;

  private _isQueueRuning = false;
  private _queue: Array<QueueAction> = [];

  private readonly runQueue = async () => {
    setTimeout(
      () => {
        if (!this._isQueueRuning) {
          return;
        }

        this.setState({
          isQueueRuning: true
        });
      },
      250
    );

    this._isQueueRuning = true;

    while (this._queue.length) {
      await this._queue[0]();
      this._queue.shift();
    }

    this.setState({
      isQueueRuning: false
    });
    this._isQueueRuning = false;
  }

  private readonly addToQueue = (action: QueueAction) => {
    this._queue.push(action);

    if (this._isQueueRuning) {
      return;
    }

    this.runQueue();
  }

  private readonly loadModuleComponent = (productModule: IProductModule, renderedCallack: () => void): Promise<THREE.Object3D> => {
    if (!productModule.component.mtl || !productModule.component.obj) {
      throw new Error(`Missing .obj or .mtl for component #${productModule.component.id}`);
    }

    return new Promise((resolver) => {
      const onLoadMtl = (mtl: MaterialCreator) => {
        mtl.setCrossOrigin(true);

        const textureFile = productModule.material
          ? getFileUrl(productModule.material.texture)!
          : ThreeSence.defaultMaterialTextureUrl;

        for (const materialInfoKey in mtl.materialsInfo) {
          const materialInfo = mtl.materialsInfo[materialInfoKey];
          materialInfo.map_kd = textureFile;
        }

        mtl.preload();

        const materials = Object.values(mtl.materials) as THREE.MeshPhongMaterial[];

        for (const material of materials) {
          // Reset material color
          material.color.set('#FFF');
          material.shininess = productModule.material?.shininess || 0;
          material.transparent = true;

          if (productModule.material) {
            material.name = productModule.material.id!;
          }

          if (material.map) {
            material.map.encoding = THREE.LinearEncoding;

            material.map.anisotropy = 16;
            material.map.needsUpdate = true;
          }
        }

        const objLoader = new OBJLoader2();
        const callbackOnLoadObj = this.createInitModulesOnLoadHandler(
          productModule,
          materials,
          (loadResult) => {
            resolver(loadResult);
            renderedCallack();
          }
        );

        objLoader.setLogging(false, false);
        objLoader.setModelName(productModule.component.name);
        objLoader.addMaterials(MtlObjBridge.addMaterialsFromMtlLoader(mtl), true);

        const objFile = getFileUrl(productModule.component.obj)!;
        objLoader.load(objFile, callbackOnLoadObj);
      };

      const mtlLoader = new MTLLoader();

      const mtlFile = getFileUrl(productModule.component.mtl)!;
      mtlLoader.load(mtlFile, onLoadMtl);
    });
  }

  private readonly createInitModulesOnLoadHandler = (
    productModule: IProductModule,
    materials: THREE.Material[],
    renderedCallack: (loadResult: THREE.Object3D) => void
  ) => (root) => {

    const { component } = productModule;

    root.name = component.id;

    for (const child of root.children) {
      // if child has multi material, we need set child's material to first material in the list
      if (Array.isArray(child.material)) {
        child.material = child.material.find((o: THREE.Material) => {
          for (const materialKey in materials) {
            const material = materials[materialKey];
            if (material.name == o.name) {
              return true;
            }
          }
        });
      }

      child.castShadow = true;
      child.receiveShadow = true;
    }

    this.registerAfterRenderEvent(root, () => { renderedCallack(root); });
    this.loadLightMap(productModule, root);
    this.hideObject3D(root);
    this.scene.add(root);
  }

  private readonly replaceComponentObject = (
    oblObject3D: THREE.Object3D,
    newComponent: IGroupsComponent
  ) => {
    return new Promise((resolve, reject) => {
      try {
        const objLoader = new OBJLoader2();

        const objFileUrl = getFileUrl(newComponent.obj)!;
        objLoader.load(objFileUrl, (root) => {

          root.name = newComponent.id;

          const onRendered = () => {
            this.selectObject(root);
            this.showObject3D(root);
            this.scene.remove(oblObject3D);
            resolve();
          };

          this.registerAfterRenderEvent(root, onRendered);

          const oldChild = oblObject3D.children[0] as THREE.Mesh;
          for (const mesh of root.children as THREE.Mesh[]) {
            mesh.castShadow = true;
            mesh.receiveShadow = true;
            mesh.position.set(oldChild.position.x, oldChild.position.y, oldChild.position.z);
            mesh.material = oldChild.material;
          }

          this.hideObject3D(root);
          this.scene.add(root);
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  private readonly addNewComponent = async (
    productModule: IProductModule,
    renderedCallback: () => void
  ) => {
    const object3DResult = await this.loadModuleComponent(productModule, renderedCallback);
    await this.replaceComponentMaterial({
      material: productModule.material,
      object3D: object3DResult
    });
  }

  private readonly replaceComponentMaterial = (props: {
    readonly object3D: THREE.Object3D;
    readonly material?: Partial<IGroupsMaterial>;
  }) => {
    return new Promise((resolve, reject) => {
      try {
        const {
          object3D,
          material
        } = props;

        const texture = new THREE.TextureLoader();

        const textureFile = material
          ? getFileUrl(material.texture)!
          : ThreeSence.defaultMaterialTextureUrl;

        texture.load(textureFile, (textureMap) => {
          textureMap.encoding = THREE.RGBM16Encoding;
          textureMap.needsUpdate = true;

          object3D.children.forEach((mesh: THREE.Mesh) => {
            const meshMaterial = mesh.material as THREE.MeshPhongMaterial;

            if (meshMaterial.map) {
              meshMaterial.map.image = textureMap.image;
              meshMaterial.map.needsUpdate = true;
            } else {
              meshMaterial.map = textureMap;
            }

            meshMaterial.shininess = material?.shininess || 0;
            meshMaterial.needsUpdate = true;
          });

          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  private readonly removeComponentObject = (productModule: IProductModule) => {
    return new Promise((resolve, reject) => {
      try {
        const childToRemove = this.scene.children.find(o => o.name === productModule.component.id);
        if (!childToRemove) {
          resolve();
          return;
        }

        this.scene.remove(childToRemove);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  private readonly modulesLoadCompleted = () => {
    const { productModules } = this.props;
    const allComponentIds = productModules.map(o => o.component.id);

    this.scene.traverse((child) => {
      if (allComponentIds.includes(child.name)) {
        this.showObject3D(child);
      }
    });

    this.selectObject(this.props.selectedComponentId);
    this.calcComponentsPosition();
    if (!this._loaded) {
      this._loaded = true;
      if (this.props.onload) {
        this.props.onload();
      }
    }
  }

  private readonly calcComponentsPosition = () => {
    const legs = this.props.productModules.find(o => {
      return o.component.type === 'LEGS';
    });

    if (!legs) {
      return;
    }

    const surface = this.props.productModules.find(o => {
      return o.component.type === 'SURFACE';
    });

    if (!surface) {
      return;
    }

    const surface3DObject = this.scene.children.find(o => o.name === surface.component.id);

    if (!surface3DObject) {
      return;
    }

    for (const child of surface3DObject.children) {
      const componentHeight = (legs.component.height || 0);
      child.position.setY(componentHeight * 0.1);
    }
  }

  private readonly update3DViewData = (props: {
    readonly prevProductModules: IProductModule[];
    readonly nextProductModules: IProductModule[];
  }) => {

    return new Promise((resolve, reject) => {
      try {
        const {
          prevProductModules,
          nextProductModules
        } = props;

        let renderCount = prevProductModules.filter(o => nextProductModules.map(o => o.component.id).includes(o.component.id)).length;

        const renderedCallback = () => {
          renderCount += 1;
          if (renderCount === nextProductModules.length) {
            this.modulesLoadCompleted();
          }
        };

        const replaced3DObjects: Array<{
          readonly object3d: THREE.Object3D;
          readonly component: IGroupsComponent;
        }> = [];

        const groups = this.scene.children.filter(o => o instanceof THREE.Object3D) as THREE.Object3D[];

        if (nextProductModules.length < prevProductModules.length) {
          const removedModules = differenceBy(
            prevProductModules,
            nextProductModules,
            (productModule) => productModule.component.id
          );

          removedModules.forEach(productModule =>
            this.addToQueue(
              () => this.removeComponentObject(productModule)
            ));
        }

        for (let index = 0; index < nextProductModules.length; index++) {
          const prevProductModule = prevProductModules[index];
          const nextProductModule = nextProductModules[index];

          if (!prevProductModule) {
            this.addToQueue(
              () => this.addNewComponent(nextProductModule, renderedCallback)
            );

            continue;
          }

          const oldObject = groups.find(o => o.name === prevProductModule.component.id)!;

          if (prevProductModule.material?.id !== nextProductModule.material?.id) {
            this.addToQueue(
              () => this.replaceComponentMaterial({
                material: nextProductModule.material,
                object3D: oldObject
              })
            );
          }

          if (
            prevProductModule.component.id !== nextProductModule.component.id ||
            prevProductModule.component.obj.id !== nextProductModule.component.obj.id
          ) {
            replaced3DObjects.push({
              object3d: oldObject,
              component: nextProductModule.component
            });
          }
        }

        replaced3DObjects.forEach((value) =>
          this.addToQueue(() => this.replaceComponentObject(value.object3d, value.component)));

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  readonly takeScreenshot = () => {
    return new Promise<string>((resolve) => {
      this.resetCamera();
      this.resetCameraZoom();
      this.selectObject(null);
      setTimeout(
        () => {
          const base64 = this.renderer.domElement.toDataURL('image/jpeg');
          resolve(base64);
        },
        500
      );
    });
  }

  constructor(props: IThreeSenceProps) {
    super(props);
    ThreeSence.instance = this;
  }

  public componentDidMount() {
    this.initSence();
    this.addToQueue(() => this.update3DViewData({
      prevProductModules: [],
      nextProductModules: this.props.productModules
    }));
  }

  public componentDidUpdate(prevProps: IThreeSenceProps) {
    const { selectedComponentId, controlSetting } = this.props;

    const idModuleChanged = JSON.stringify(prevProps.productModules) !== JSON.stringify(this.props.productModules);
    if (idModuleChanged) {
      this.addToQueue(() => this.update3DViewData({
        prevProductModules: prevProps.productModules,
        nextProductModules: this.props.productModules
      }));

      this.addToQueue(() => new Promise<void>((resolver) => {
        this.calcComponentsPosition();
        resolver();
      }));
    }

    const isSelectChanged = prevProps.selectedComponentId !== selectedComponentId;
    if (isSelectChanged) {
      this.selectObject(selectedComponentId);
    }

    if (controlSetting !== prevProps.controlSetting) {
      this.updateControl();
      this.resetCameraZoom();
    }

    if (prevProps.groundLightMapUrl != this.props.groundLightMapUrl) {
      this.initGround();
      this.disableLights();
    }
  }

  public componentWillUnmount() {
    if (this.animationFrameId) {
      this.clearScene();
    }
  }

  public render() {
    return (
      <div className="three-sence-wrapper">
        {this.state.isQueueRuning &&
          <div className="three-sence-loading queue">
            Loading...
          </div>
        }
        <div
          id="threeViewWindow"
          ref={(element: HTMLDivElement) => this.container = element}
          style={{
            height: this.props.height ?? 651
          }}
        />
      </div>
    );
  }
}
