import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { productResources } from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '@/pages/product/ProductPage.shared';
import { officePath } from '@/pages/products/ProductsPage.shared';
import { textIgnoreCase } from '@/translation';

const _HomeNewProducts = styled.div`
  /** Fix space with slider */
  margin-top: -2px;
  z-index: 1;
  position: relative;
  background: white;

  img {
    max-width: 100%;
  }
  .container {
      padding: 0;
  }
  .slick-slide {
      overflow: hidden;
      border-right: 1px solid #C4C4C4;
      .new-card {
      }
    }
  .slick-track {
    margin-left: 0;
  }
  .slick-arrow {
  }

  .slick-next {
    height: 100%;
    width: 60px;
    right: 0;
    :before {
      color: black;
      font-size: 30px;
    }
    &.slick-disabled {
      :before {
        display: none;
      }
    }
  }

  .slick-prev {
    height: 100%;
    width: 60px;
    left: 0;
    z-index: 1;
    :before {
      color: black;
      font-size: 30px;
    }
    &.slick-disabled {
      :before {
        display: none;
      }
    }
  }

  .new {
    display: flex;
  }

  .new-products {
    display: flex;
    border-bottom: 1px solid #C4C4C4;
  }

  .new-cards {
    width: 75%;
    border: 1px solid #C4C4C4;
    border-bottom: 0;
    min-height: 320px;
    .img-wrapper {
      transform: scale(1.01);
    }
  }

  .new-card {
    position: relative;
    .hint {
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);
      font-style: italic;
    }
  }

  .new-card-area {
    border: 1px solid #C4C4C4;
    border-bottom: 0;
    border-right: 0;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 320px;
    padding: 0 20px;
    flex-direction: column;

    .new-products-title {
      font-family: "Copperplate";
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 25px;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 8px;
    }

    a {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
    }
  }

  @media (max-width: 991px) {
    .new-products {
      display: block;
    }
    .new-card-area {
      width: 100%;
      height: 80px;
      min-height: unset;
    }
    .new-cards {
      width: 100%;
      min-height: 260px;
    }
    .new-products-title {
      text-align: center;
    }
    .slick-list, .slick-slide {
      min-height: 260px;
    }

    .slick-slide > div {
      min-height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

interface IHomeNewProductsProps {
  readonly productSlugs: string[];
}

export const HomeNewProducts = (props: React.PropsWithChildren<IHomeNewProductsProps>) => {

  const [products] = useResourceAnonymous(
    productResources.find,
    [{
      type: 'query',
      parameter: 'slug_in',
      value: props.productSlugs
    }]
  );

  const isMobile = window.innerWidth <= 991;
  const slidesToShow = isMobile ? 2 : 3;

  return (
    <_HomeNewProducts>
      <Container>
        <div className="new-products">
          <div className="new-card-area">
            <div className="new-products-title">
              {textIgnoreCase('NEW PRODUCTS')}
            </div>
            <NavLink to={officePath}>
            {textIgnoreCase('DETAIL')}
            </NavLink>
          </div>
          <div className="new-cards">
            <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} draggable={false} infinite={false}>
              {props.productSlugs?.map((productSlug) => {
                const product = products?.find((p) => p.slug === productSlug);
                if (!product) {
                  return null;
                }
                
                const productPath = product.for === 'office'
                  ? getProductUrl(product)
                  : getMCollectionProductUrl(product);

                return (
                  <div key={product.id} className="new-card">
                    <Link to={productPath}>
                      <Img file={product.thumbnail} ratioX={1} ratioY={1} />
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </_HomeNewProducts>
  );
};