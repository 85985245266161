import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { getFileUrl } from '@/_shared';
import { Img } from '@/_shared/components';
import { IModel, IProject } from '@/api';
import { _ProjectDetailProducts } from '@/pages/project-detail/views';
import { textIgnoreCase } from '@/translation';

const _ProjectProductIllustrationPhotos = styled(_ProjectDetailProducts)`
  /** Styles here */
  margin-bottom: 0rem;

  .project-card {
    cursor: pointer;
    .img-wrapper {
      background: #fff;
      transform: scale(1.01);
      img {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
`;

interface IProjectProductIllustrationPhotosProps {
  readonly model?: IModel;
  readonly project?: IProject;
}

export const ProjectProductIllustrationPhotos = (props: IProjectProductIllustrationPhotosProps) => {
  const { model } = props;

  const [lightboxImageIndex, setLightboxImageIndex] = React.useState<number>(-1);

  const photos = model?.illustrationsPhotos ?? [];
  const lightboxPhotos = photos.map(o => getFileUrl(o)!);

  if (!photos?.length) {
    return null;
  }

  const isDesktop = window.innerWidth > 991;
  const slidesToShow = isDesktop ? 3 : 1;

  return (
    <_ProjectProductIllustrationPhotos id="projectProductAttachments">
      <Container>
        <div className="project-products">
          <div className="project-card-area">
            <div className="section-title">{textIgnoreCase('Illustrations')}</div>
          </div>
          <div className="project-cards">
            <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} draggable={false} infinite={false}>
              {photos?.map((attachment, i) => {
                return (
                  <div
                    key={attachment.id}
                    className="project-card"
                    onClick={() => {
                      setLightboxImageIndex(i);
                    }}
                  >
                    <Img file={attachment} ratioX={1} ratioY={1} />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
      {
        lightboxImageIndex >= 0 && (
          <Lightbox
            mainSrc={lightboxPhotos[lightboxImageIndex]}
            nextSrc={lightboxPhotos[(lightboxImageIndex + 1) % lightboxPhotos.length]}
            prevSrc={lightboxPhotos[(lightboxImageIndex + lightboxPhotos.length - 1) % lightboxPhotos.length]}
            onCloseRequest={() => setLightboxImageIndex(-1)}
            onMovePrevRequest={() =>
              setLightboxImageIndex((lightboxImageIndex + lightboxPhotos.length - 1) % lightboxPhotos.length)
            }
            onMoveNextRequest={() =>
              setLightboxImageIndex((lightboxImageIndex + 1) % lightboxPhotos.length)
            }
          />
        )
      }
    </_ProjectProductIllustrationPhotos>
  );
};
