import { useResourceAnonymous } from 'libs/restful/hooks';
import React from 'react';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';
import { outdoorResources } from '@/api/outdoorcollections';

import { OutdoorContent } from './views';
import { OutdoorSlider } from './views/outdoor-slider';
import { OutdoorIntroduce } from './views/OutdoorIntroduce';

const _OutdoorPage = styled.div`

`;
interface IOutdoorPage {

}

export const OutdoorPage = (props: React.PropsWithChildren<IOutdoorPage>) => {
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'outdoor' }]);
  const [outdoorCollections] = useResourceAnonymous(outdoorResources.find);

  if (!pages) {
    return <PageLoading />;
  }
  const outdoorPage = pages[0];

  return (
    <_OutdoorPage>
      <OutdoorSlider slider={outdoorPage.slider!} />
      <OutdoorIntroduce page={outdoorPage}/>
      <OutdoorContent outdoorCollections={outdoorCollections} />
    </_OutdoorPage>
  );
};