import React from 'react';
import { Spinner } from 'reactstrap';
import styled from 'styled-components';

const _DataLoading = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const _DataLoadingInline = styled.div`
  display: inline-flex;
  gap: 10px;
`;

interface IDataLoadingProps {

}

export const DataLoading = (props: React.PropsWithChildren<IDataLoadingProps>) => {
  return (
    <_DataLoading>
      <div className="mb-4">
        <Spinner color="dark" size="sm" />
      </div>
      <div>
        Loading, please wait...
      </div>
    </_DataLoading>
  );
};

export const DataLoadingInline = (props: React.PropsWithChildren<IDataLoadingProps>) => {
  return (
    <_DataLoadingInline>
      <div className="mb-4">
        <Spinner color="dark" size="sm" />
      </div>
      <div>
        Loading, please wait...
      </div>
    </_DataLoadingInline>
  );
};