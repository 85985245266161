import { useResource } from 'libs/restful';
import React from 'react';
import { Redirect, useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { GlobalModalNoActions, PageLoading } from '@/_shared/components';
import { WelcomeBack } from '@/_shared/components/domain';
import { projectResources } from '@/api';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

import {
    projectRequestPagePath
} from '../project-request/ProjectRequestPage.shared';
import { ProjectList } from './views';

const _ProjectsPage = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  @media (min-width: 1106px) {
    width: 1066px;
    margin: 0 auto;
  }
  @media (max-width: 1106px) {
    width: unset;
    margin: 0 -15px;
    padding-bottom: 0;
  }
`;

interface IProjectsPageProps {

}

export const ProjectsPage = (props: React.PropsWithChildren<IProjectsPageProps>) => {
  const history = useHistory();
  const [currentUser] = useRecoilState(currentUserState);
  const [projects] = useResource(projectResources.find, [{ type: 'query', parameter: '_sort', value: 'displayPriority:desc,createdAt:desc' }]);
  const [publicProjects] = useResource(projectResources.findAllPublic, [{ type: 'query', parameter: '_sort', value: 'displayPriority:desc,createdAt:desc' }]);

  const [showModal, setShowModal] = React.useState(!currentUser?.verified);

  if (!currentUser) {
    return <Redirect to="/partner" />;
  }

  if (!currentUser.verified) {
    return (
      <>
        <GlobalModalNoActions
          isOpen={showModal}
          onClosed={() => {
            history.replace(projectRequestPagePath);
          }}
        >
          <div>
            <p>
              <strong>
              {textIgnoreCase('Notify')}
              </strong>
            </p>
            <p>
              {textIgnoreCase('Your account is not valid to access to this data')}.
              <br />
              {textIgnoreCase('Please wait until your account is verified')}.
            </p>
            <div>
              <Button
                color="dark"
                size="sm"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {textIgnoreCase('Back')}
              </Button>
            </div>
          </div>
        </GlobalModalNoActions>
      </>
    );
  }

  if (!projects || !publicProjects) {
    return <PageLoading />;
  }

  const allProjects = [...projects, ...publicProjects];

  return (
    <_ProjectsPage>
      <WelcomeBack />
      <ProjectList projects={allProjects} />
    </_ProjectsPage>
  );
};