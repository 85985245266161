import React, { ChangeEventHandler } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { fileResources, IFile } from '@/api';
import { useAccessToken } from '@/hooks';
import { currentUserState } from '@/state';

const _UserAvatarField = styled.div`
img {
  height: 45px;
  width: 45px;
}

input {
  display: none;
}
`;

interface IUserAvatarFieldProps {

}

export const UserAvatarField = (props: React.PropsWithChildren<IUserAvatarFieldProps>) => {
  const [token] = useAccessToken();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.currentTarget.files) {
      return;
    }

    const selectedFile = event.currentTarget.files[0];
    const formData = new FormData();
    formData.append('files', selectedFile);
    formData.append('source', 'users-permissions');
    formData.append('ref', 'user');
    formData.append('refId', currentUser!.id);
    formData.append('field', 'avatar');

    fetch(
      'https://api.bplusfurniture.com.vn' + fileResources.upload.url,
      {
        method: 'POST',
        body: formData,
        headers: {
          authorization: 'Bearer ' + token
        }
      }
    )
      .then((response) => response.json())
      .then((result: IFile[]) => {
        setCurrentUser({ ...currentUser!, avatar: result![0]!.related![0]!.avatar });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <_UserAvatarField>
      <label htmlFor="avatarUpload">
        <input
          id="avatarUpload"
          type="file"
          onChange={changeHandler}
        />
        <img src="/static/assets/upload-img-icon.png" alt="" />
      </label>
    </_UserAvatarField>
  );
};