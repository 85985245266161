
import { Resource } from 'libs/restful';


export interface IColor {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name?: string;
  readonly name_en?: string;
  readonly hexCode?: string;
}

export const colorResources = {
  find: new Resource<IColor, IColor[]>({
    method: 'GET',
    url: '/admin/colors'
  }),
  count: new Resource<IColor, number>({
    method: 'GET',
    url: '/admin/colors/count'
  }),
  findOne: new Resource<IColor, IColor>({
    method: 'GET',
    url: '/admin/colors/:id'
  }),
  create: new Resource<IColor, IColor>({
    method: 'POST',
    url: '/admin/colors'
  }),
  update: new Resource<IColor, IColor>({
    method: 'PUT',
    url: '/admin/colors/:id'
  }),
  delete: new Resource<IColor, IColor>({
    method: 'DELETE',
    url: '/admin/colors/:id'
  }),
};
