
import React from 'react';
import Lightbox from 'react-image-lightbox';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { getFileUrl } from '@/_shared';
import { Img } from '@/_shared/components';
import { IFeature } from '@/api';

const _FeatureDetailsPhotos = styled.div`
  padding: 70px 0 70px 0;
  overflow: hidden;
  .container {
    padding: 0;
  }
  .img-wrapper {
    border: 1px solid lightgray;
  }

  .slide-item {
    cursor: pointer;
    padding: 0 14px;
  }

  .slick-slider {
    margin: 0 -14px;
  }

  .slick-next {
    height: 100%;
    width: 60px;
    right: -60px;
    :before {
      color: black;
      font-size: 30px;
    }
    &.slick-disabled {
      :before {
        display: none;
      }
    }
  }

  .slick-prev {
    height: 100%;
    width: 60px;
    left: -60px;
    z-index: 1;
    :before {
      color: black;
      font-size: 30px;
    }
    &.slick-disabled {
      :before {
        display: none;
      }
    }
  }

  @media (max-width: 1370px) {
    .slick-next {
      right: 0
    }
    .slick-prev {
      left: 0;
    }
  }
  @media (max-width: 1280px) {
    #MCollectionReallifePhotosTitle {
      padding: 0 15px;
    }
  }
`;

interface IFeatureDetailsPhotosProps {
  readonly feature: IFeature;
}

export const FeatureDetailsPhotos = (props: React.PropsWithChildren<IFeatureDetailsPhotosProps>) => {

  const [lightboxImageIndex, setLightboxImageIndex] = React.useState<number>(-1);

  const isDesktop = window.innerWidth > 911;
  const slidesToShow = isDesktop ? 3 : 1;

  const lightboxPhotos = props.feature.photos.map(o => getFileUrl(o)!);

  return (
    <_FeatureDetailsPhotos>
      <Container id="MCollectionReallifePhotosContainer">
        <Slider
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          draggable={true}
          infinite={false}

        >
          {props.feature.photos?.map((photo, i) => {
            return (
              <div key={photo.id} className="slide-item">
                <Img
                  file={photo}
                  onClick={() => {
                    setLightboxImageIndex(i);
                  }}
                />
              </div>
            );
          })}
        </Slider>
      </Container>
      {
        lightboxImageIndex >= 0 && (
          <Lightbox
            mainSrc={lightboxPhotos[lightboxImageIndex]}
            nextSrc={lightboxPhotos[(lightboxImageIndex + 1) % lightboxPhotos.length]}
            prevSrc={lightboxPhotos[(lightboxImageIndex + lightboxPhotos.length - 1) % lightboxPhotos.length]}
            onCloseRequest={() => setLightboxImageIndex(-1)}
            onMovePrevRequest={() =>
              setLightboxImageIndex((lightboxImageIndex + lightboxPhotos.length - 1) % lightboxPhotos.length)
            }
            onMoveNextRequest={() =>
              setLightboxImageIndex((lightboxImageIndex + 1) % lightboxPhotos.length)
            }
          />
        )
      }
    </_FeatureDetailsPhotos>
  );
};