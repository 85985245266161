import debounce from 'lodash/debounce';
import React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

import { textIgnoreCase } from '@/translation';

const _MCollectionProductSearchBox = styled.div`
  input {
    text-align: center;
    border: none;
    border-radius: 0;
    &:focus {
      border: 0;
    }
  }
`;

interface IMCollectionProductSearchBoxProps {
  readonly onSearch: (search?: string) => void;
}

export const MCollectionProductSearchBox = (props: React.PropsWithChildren<IMCollectionProductSearchBoxProps>) => {
  const { onSearch } = props;

  const onInputChange = React.useMemo(
    () => {
      return debounce(onSearch, 500);
    },
    []
  );

  return (
    <_MCollectionProductSearchBox>
      <Input
        placeholder={textIgnoreCase('Find...')}
        onChange={(e) => { onInputChange(e.currentTarget.value); }}
      />
    </_MCollectionProductSearchBox>
  );
};