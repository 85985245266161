import { MCollectionPath } from '../m-collection/MCollectionPage.shared';

export const MCollectionFileRequestPath = MCollectionPath + '/file-request';

export const getMCollectionFileRequestUrl = (productId: string, requestType: string, modelSlug: string | undefined) => {
  const nextURL = new URLSearchParams();
  nextURL.set('productId', productId);
  nextURL.set('requestType', requestType);

  if (modelSlug) {
    nextURL.set('modelSlug', modelSlug);
  }

  return MCollectionFileRequestPath + '?' + nextURL.toString();
};