import * as React from 'react';
import { Input } from 'reactstrap';
import styled from 'styled-components';

import { textIgnoreCase } from '@/translation';

import {
    DesignToolMaterialList
} from './design-tool-materials/DesignToolMaterialList';
import {
    DesignToolMaterialListCustom
} from './design-tool-materials/DesignToolMaterialListCustom';
import { DesignToolBase } from './DesignToolBase';
import { DesignToolCollapse } from './DesignToolCollapse';

export const _DesignToolMaterials = styled.div`
  .row {
    margin: 0 -6px;
  }

  [class*="col-"] {
    padding: 0 6px;
  }

  .editor-section {
    &-header {
      font-weight: bold;
      font-size: 16px;
      line-height: 130%;
      text-align: justify;
      letter-spacing: 0.1em;
      color: #000000;
      margin-bottom: .8rem;
    }
    &-select {
      margin-bottom: .8rem;
    }
  }

  .component, .material {
    cursor: pointer;
    margin-bottom: 1rem;

    .info-btn {
      position: absolute;
      height: 20px;
      width: 20px;
      background: rgba(255,255,255,.5);
      top: 4px;
      right: 10px;
      border-radius: 50%;
      cursor: pointer;
      display: none;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: bold;
      font-style: italic;
    }

    &.selected {
      border: 2px solid #C4C4C4;
    }

    &:hover {
      .info-btn {
        display: flex;
      }
    }
  }

  select {
    border: 0;
    font-size: 16px;
    text-align: justify;
    letter-spacing: 0.1em;
    color: #000000;
    margin-bottom: 12px;
  }

  @media (max-width: 991px) {

  }
`;

interface IDesignToolMaterialsProps {
}

interface IDesignToolMaterialsState {
  readonly selectedMaterialTypeId?: string;
}

export class DesignToolMaterials extends DesignToolBase<IDesignToolMaterialsProps, IDesignToolMaterialsState> {
  public render() {
    const { model } = this.props;
    const { selectedMaterialTypeId } = this.state;

    const selectedComponent = this.getSelectedComponent();
    if (!selectedComponent) {
      return null;
    }

    const materialTypes = selectedComponent?.materialTypes || [];
    const selectedMaterialType =
      materialTypes?.find((materialType) => materialType.id === selectedMaterialTypeId) || materialTypes[0];

    const selectedMaterialId = this.getSelectedMaterialId();

    return (
      <DesignToolCollapse
        label={textIgnoreCase('SELECT MATERIALS')}
        icon={selectedMaterialId && '/static/assets/icon-check.png'}
        defaultOpen={true}
      >
        <_DesignToolMaterials>
          <div className="DesignTool-section-select">
            <Input
              type="select"
              onChange={this.onMaterialTypeChange}
              value={selectedMaterialTypeId}
            >
              {materialTypes.map((materialType) => {

                return (
                  <option
                    key={materialType.id}
                    value={materialType.id}
                    aria-selected="true"
                  >
                    {materialType.name}
                  </option>
                );
              })}
            </Input>
          </div>
          {
            selectedMaterialTypeId === '#custom'
              ? (
                <DesignToolMaterialListCustom
                  selectedComponent={selectedComponent}
                  model={model}
                />
              )
              : (
                <DesignToolMaterialList
                  selectedMataterialType={selectedMaterialType}
                  selectedComponent={selectedComponent}
                />
              )
          }
        </_DesignToolMaterials>
      </DesignToolCollapse>
    );
  }

  private readonly onMaterialTypeChange = (e) => {
    this.setState({
      selectedMaterialTypeId: e.target.value
    });
  }
}
