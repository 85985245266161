
import { Resource } from 'libs/restful';

import { IInspirationCategory } from './';

export interface IInspirationType {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug?: string;
  readonly inspirationCategory: IInspirationCategory;
}

export const inspirationTypeResources = {
  find: new Resource<IInspirationType, IInspirationType[]>({
    method: 'GET',
    url: '/admin/inspirationtypes'
  }),
  count: new Resource<IInspirationType, number>({
    method: 'GET',
    url: '/admin/inspirationtypes/count'
  }),
  findOne: new Resource<IInspirationType, IInspirationType>({
    method: 'GET',
    url: '/admin/inspirationtypes/:id'
  })
};
