import * as React from 'react';
import styled from 'styled-components';

import { IViewSettings, ThreeSence } from '@/_shared/components';
import { textIgnoreCase } from '@/translation';

import { DesignToolBase } from '../DesignToolBase';

const _ModelPreview = styled.div`
  /** Styles here */
  position: relative;
  .note-text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .taptap-icon {
    width: 50px;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    opacity: .5;
    animation: highlight 700ms ease-out;
    animation-iteration-count: 3;
    visibility: hidden;
  }

  @keyframes highlight {
    0% {
      opacity: 0;
      visibility: visible;
    }
    50% {
      opacity: .5;
    }
    100% {
      opacity: 0;
    }
  }
`;

interface IModelPreviewProps {
  readonly height?: number;
  readonly on3DObjectSelect?: (componentId?: string) => void;
  readonly showHint?: boolean;
}

interface IModelPreviewState {
  readonly viewSettings: IViewSettings;
  readonly loaded?: boolean;
}

export class ModelPreview extends DesignToolBase<IModelPreviewProps, IModelPreviewState> {

  public componentDidMount() {
    this.setState({
      viewSettings: {
        initRotateX: 0,
        initRotateY: 0
      }
    });
  }

  public render() {
    const { model, height, showHint } = this.props;

    const { selectedComponentId, viewSettings, loaded } = this.state;

    if (!viewSettings) {
      return null;
    }

    const modules3D = this.getModules3D();

    return (
      <_ModelPreview>
        <ThreeSence
          ref={DesignToolBase.three3DRef}
          productModules={modules3D}
          viewSettings={viewSettings}
          selectedComponentId={selectedComponentId}
          onObjectSelect={this.on3DObjectSelect}
          controlSetting={model?.sceneControlSetting}
          groundLightMapUrl={this.getGroundLightMapUrl()}
          height={height}
          onload={() => {
            if (loaded) {
              return;
            }

            setTimeout(
              () => {
                this.setState({
                  loaded: true
                });
              },
              500
            );
          }}
        />
        {
          showHint && (
            <span className="note-text">
              <b>{'<'}</b> {textIgnoreCase('Drag to rotate')} <b>{'>'}</b>
            </span>
          )
        }

        {
          (showHint && loaded) && (
            <img className="taptap-icon" src="/static/taptap.png" />
          )
        }
      </_ModelPreview>
    );
  }

  private readonly getGroundLightMapUrl = () => {
    const { model } = this.props;

    if (!model?.modellightmap?.groundLightMap) {
      return undefined;
    }

    return model.modellightmap.groundLightMap?.url;
  }

  private readonly on3DObjectSelect = (componentId?: string) => {
    this.setState({
      selectedComponentId: componentId
    });

    if (this.props.on3DObjectSelect) {
      this.props.on3DObjectSelect(componentId);
    }
  }
}
