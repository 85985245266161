import 'cropperjs/dist/cropper.css';

import { useResourceLazy } from 'libs/restful';
import React, { FormEventHandler, useState } from 'react';
import Cropper from 'react-cropper';
import { Button, Col, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { IWishlistItem, wishlistItemResources } from '@/api';
import { textIgnoreCase } from '@/translation';

import { wishlistGroupsState } from '../../WishlistDetailPage.shared';

const _WishlistItemExternalForm = styled.div`
  width: ${window.innerWidth * 0.9}px;
  max-width: 1000px;
  
  label {
    text-transform: uppercase;
    font-weight: 500;
  }

  .actions {
    margin-top: 14px;
    display: flex;
    gap: 10px;
    button {
      flex-grow: 1;
    }
  }

  .thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 200px;
    margin-bottom: 25px;
  }

  .thumbnail-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  @media screen and (max-width: 991px) {
    width: 300px;
  }
`;

interface IWishlistItemExternalFormProps {
  readonly onCreated?: (wishlistItem: IWishlistItem) => void;
  readonly onUpdated?: (wishlistItem: IWishlistItem) => void;
  readonly onCanceled: () => void;
  readonly wishlistId: string;
  readonly updatingWishlistItem?: IWishlistItem;
}

export const WishlistItemExternalForm = (props: React.PropsWithChildren<IWishlistItemExternalFormProps>) => {
  const { } = props;

  const [wishListGroups] = useRecoilState(wishlistGroupsState);

  const [, creating, createItem] = useResourceLazy(wishlistItemResources.create);
  const [, updating, updateItem] = useResourceLazy(wishlistItemResources.update);

  const [image, setImage] = useState<string | ArrayBuffer | null>(props.updatingWishlistItem?.thumbnail ?? null);
  const [cropData, setCropData] = useState<string | null>(props.updatingWishlistItem?.thumbnail ?? null);
  const [cropper, setCropper] = useState<any>();

  const onImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      const originBase64 = cropper.getCroppedCanvas().toDataURL('image/jpeg');
      const cropFile = await scaleDataURL(originBase64, 512, 512);
      setCropData(cropFile);
    }
  };

  const handleCreateItem: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);

    const value = Object.fromEntries(data.entries());

    createItem([
      { type: 'body', value: value as any }
    ]).then((newWishlistItem) => {
      props.onCreated?.(newWishlistItem);
    });
  };

  const handleUpdateItem: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const item = {
      ...props.updatingWishlistItem,
      thumbnail: String(formData.get('thumbnail')),
      productName: String(formData.get('productName')),
      data: {
        type: String(formData.get('data.type')),
      },
      quantity: Number(formData.get('quantity')),
      estPrice: Number(formData.get('estPrice')),
      wishlistGroup: String(formData.get('wishlistGroup')),
      description: String(formData.get('description')),
      link: String(formData.get('link')),
    } as IWishlistItem;

    updateItem([
      { type: 'path', parameter: 'id', value: item.id },
      { type: 'body', value: item as any }
    ]).then((newWishlistItem) => {
      props.onUpdated?.(newWishlistItem);
    });
  };

  return (
    <_WishlistItemExternalForm>
      <form onSubmit={props.updatingWishlistItem ? handleUpdateItem : handleCreateItem}>
        <input type="hidden" name="wishlist" value={props.wishlistId} />
        <input type="hidden" name="isExternal" value="true" />
        <input type="hidden" name="thumbnail" value={cropData as string} />

        <Row>
          <Col xs={12} lg={6}>
            <div className="thumbnail">
              {
                cropData && (
                  <div>
                    <img style={{ width: '100%' }} src={cropData} alt="cropped" />
                    <div className="thumbnail-actions">
                      <Button
                        type="button"
                        color="danger"
                        size="sm"
                        onClick={() => {
                          setImage(null);
                          setCropData(null);
                        }}
                      >
                        Delete image
                      </Button>
                      <Button type="button" color="black" size="sm" onClick={() => setCropData(null)}>
                        Crop
                      </Button>
                    </div>
                  </div>
                )
              }
              {
                (image && !cropData) && (
                  <div>
                    <Cropper
                      style={{ height: 400, width: '100%' }}
                      zoomTo={0}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={image as string}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                      guides={true}
                    />
                    <div className="thumbnail-actions">
                      <Button type="button" color="secondary" size="sm" onClick={() => setImage(null)}>
                        Cancel
                      </Button>
                      <Button type="button" color="black" size="sm" onClick={() => getCropData()}>
                        Save image
                      </Button>
                    </div>
                  </div>
                )
              }
              {
                (!image && !cropData) && (
                  <input type="file" onChange={onImageChange} accept="image/*" />
                )
              }
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="brand">Type *</label>
                  <input className="form-control" id="type" name="data.type" defaultValue={props.updatingWishlistItem?.data?.type} required />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="productName">Product name *</label>
                  <input className="form-control" id="productName" name="productName" defaultValue={props.updatingWishlistItem?.productName} required />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="quantity">Quantity</label>
                  <input type="number" className="form-control" id="quantity" name="quantity" min={1} defaultValue={props.updatingWishlistItem?.quantity} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="estPrice">Estimated Price</label>
                  <input type="number" className="form-control" id="estPrice" name="estPrice" defaultValue={props.updatingWishlistItem?.estPrice} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="description">Product description</label>
                  <input className="form-control" id="description" name="description" defaultValue={props.updatingWishlistItem?.description} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="link">Link</label>
                  <input className="form-control" id="link" name="link" defaultValue={props.updatingWishlistItem?.link} />
                </div>
              </Col>
              <Col xs={12}>
                <div className="form-group">
                  <label htmlFor="group">Group</label>
                  <select
                    className="form-control"
                    id="wishlistGroup"
                    name="wishlistGroup"
                    placeholder="Select a group"
                    defaultValue={typeof props.updatingWishlistItem?.wishlistGroup === 'string' ? props.updatingWishlistItem?.wishlistGroup : props.updatingWishlistItem?.wishlistGroup?.id ?? ''}
                  >
                    <option value="" disabled selected>Select a group</option>
                    {
                      wishListGroups.map((group) => (
                        <option key={group.id} value={group.id}>{group.name}</option>
                      ))
                    }
                  </select>
                </div>
              </Col>
            </Row>
            <div className="actions">
              <Button className="" type="button" disabled={creating} onClick={props.onCanceled}>
                {textIgnoreCase('Cancel')}
              </Button>
              <Button
                className="btn-black"
                type="submit"
                disabled={creating || updating || !cropData}
              >
                {
                  props.updatingWishlistItem ? textIgnoreCase('Update') : textIgnoreCase('Create')
                }
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </_WishlistItemExternalForm>
  );
};

export function scaleDataURL(dataURL, maxWidth, maxHeight): Promise<string> {
  return new Promise(done => {
    const img = new Image;
    img.onload = () => {
      let scale;
      if (img.width < maxWidth) {
        scale = maxWidth / img.width;
      } else {
        scale = maxHeight / img.height;
      }

      const newWidth = img.width * scale;
      const newHeight = img.height * scale;
      const canvas = document.createElement('canvas');
      canvas.height = newHeight;
      canvas.width = newWidth;
      const ctx = canvas.getContext('2d')!;
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, newWidth, newHeight);
      done(canvas.toDataURL());
    };
    img.src = dataURL;
  });
}