
import { Resource } from 'libs/restful';

import { IGroupsModelModule } from './groupsModelModule';
import { IOrder } from './order';
import { IUser } from './user';

export interface IOrderItem {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly discountValue?: number;
  readonly discountUnit?: string;
  readonly unitPrice?: number;
  readonly quantity: number;
  readonly totalDiscount?: number;
  readonly subTotalPrice?: number;
  readonly totalPrice?: number;
  readonly productName: string;
  readonly productId?: string;
  readonly productURL?: string;
  readonly type?: string;
  readonly thumbnailURL?: string;
  readonly modelModules?: Partial<IGroupsModelModule>[];
  readonly hadDecorations?: boolean;
  readonly productDetails?: object;
  readonly order?: Partial<IOrder>;
  readonly createdBy?: Partial<IUser>;
}

export const orderItemResources = {
  find: new Resource<IOrderItem, IOrderItem[]>({
    method: 'GET',
    url: '/customer/orderitems'
  }),
  count: new Resource<IOrderItem, number>({
    method: 'GET',
    url: '/customer/orderitems/count'
  }),
  findOne: new Resource<IOrderItem, IOrderItem>({
    method: 'GET',
    url: '/customer/orderitems/:id'
  }),
  create: new Resource<IOrderItem, IOrderItem>({
    method: 'POST',
    url: '/customer/orderitems'
  }),
  update: new Resource<IOrderItem, IOrderItem>({
    method: 'PUT',
    url: '/customer/orderitems/:id'
  }),
  delete: new Resource<IOrderItem, IOrderItem>({
    method: 'DELETE',
    url: '/customer/orderitems/:id'
  }),
};
