import { map } from 'lodash';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IGroupsMaterial, IMaterialType } from '@/api';

const _DesignToolMaterialInfo = styled.div`
  /** Styles here */
  width: 800px;
  .detail-key {
    font-weight: 300;
  }

  @media screen and (max-width: 991px) {
    width: 340px;
  }
`;

interface IDesignToolMaterialInfoProps {
  readonly material: Partial<IGroupsMaterial>;
  readonly materialType: Partial<IMaterialType>;
}

interface IDesignToolMaterialInfoState {

}

export class DesignToolMaterialInfo extends React.Component<
  IDesignToolMaterialInfoProps,
  IDesignToolMaterialInfoState> {
  public constructor(props: IDesignToolMaterialInfoProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { materialType, material } = this.props;
    const details = {
      ...(typeof materialType.details === 'string')  ? JSON.parse(materialType.details) : materialType.details,
      ...(typeof material.details === 'string')  ? JSON.parse(material.details) : material.details
    };

    return (
      <_DesignToolMaterialInfo>
        <Row>
          <Col xs="12" lg="6" className="mb-2">
            <Img file={material.texture} />
          </Col>
          <Col xs="12" lg="6">
            <h2>{material.name}
              <br />
              <small>{materialType.name}</small>
            </h2>
            <p>{material.description || materialType.description}</p>
            <div className="material-details">
              {map(details, (detailValue: string, detailKey) => {
                return (
                  <div key={detailKey} className="clearfix">
                    <span className="detail-key">{detailKey}</span>:&nbsp;
                    <strong>
                      {detailValue}
                    </strong>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </_DesignToolMaterialInfo>
    );
  }
}
