import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Col, Container, Row, Tag } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared';
import { Img } from '@/_shared/components';
import { IFile, IProductType, ITag } from '@/api';
import { officePagePath } from '@/pages/products/ProductsPage.shared';
import { textIgnoreCase } from '@/translation';

import { MCollectionTypePagePath } from '../MCollectionPage.shared';

const _MCollectionProductTypes = styled.div`
  .col-left {
    height: 100%;
    padding: 100px 0 100px 100px;

    display: flex ;
    justify-content : center;
    flex-direction : column;
    text-transform: uppercase;
    h3 {
      font-size : 48px;
    }
    h4 {
      font-size : 34px;
    }
    h5{
      font-size: 13px;
      font-weight : 700;
    }
    ul {
      list-style: none;
      padding : 0;
      margin : 0
    }
  }

  .active {
    font-weight: bold;
  }

  .col-lg-6{
    padding :0;
  }

  @media(max-width : 991px) {
    .col-left {
      h3 {
          font-size : 38px;
      }
    }
    .col-left {
      padding: 15px;
      .title-wraper{
        display: none;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        li {
          margin: 10px 16px;
        }
      }
    }
    .wraper-col-right{
      order : -1;
    }
    .sticky {
      position: fixed;
      top: 74px;
      width: 100%;
      height: auto;
      background: #FFF;
      z-index: 999;
      border-bottom: 0.5px solid #C4C4C4;
    }
  }

  .tag {
    font-size: 16px;
    .active {
      background-color: black;
    }
  }
`;

interface IMCollectionProductTypesProps {
  readonly tags?: ITag[];
  readonly productTypes: IProductType[];
  readonly loading?: boolean;
  readonly currentProductTypeId: string | null;
  readonly defaultCoverFile?: Partial<IFile>;
  readonly typeFor?: 'commercial' | 'office';
  readonly currentTagId?: string | null;
}

export const MCollectionProductTypes = (props: React.PropsWithChildren<IMCollectionProductTypesProps>) => {
  const { productTypes, currentProductTypeId, defaultCoverFile, typeFor, tags, currentTagId } = props;

  const currentProductType = productTypes.find((productType) => productType.id === currentProductTypeId);

  React.useEffect(
    () => {
      const productTypeListWrapper = document.getElementById('productTypeListWrapper')!;
      const productTypeList = document.getElementById('productTypeList')!;
      const productTypeListSticky = document.getElementById('productTypeListSticky')!;

      let sticky = 0;
      function onscroll() {
        if (window.innerWidth > 991) {
          return;
        }

        const bodyRect = document.body.getBoundingClientRect();
        const bounding = productTypeListSticky.getBoundingClientRect();
        sticky = bounding.top - bodyRect.top - 74;

        if (window.pageYOffset > sticky) {
          productTypeList.classList.add('sticky');
          productTypeListWrapper.style.paddingBottom = `${productTypeList.clientHeight}px`;
        } else {
          productTypeList.classList.remove('sticky');
          productTypeListWrapper.style.paddingBottom = '0';
        }
      }
      window.addEventListener('scroll', onscroll);

      return () => {
        window.removeEventListener('scroll', onscroll);
      };
    },
    []
  );

  const toPath = typeFor === 'commercial' ? MCollectionTypePagePath : officePagePath;
  const toProductType = (slug) => typeFor === 'commercial' ? replaceRoutePath(toPath, { productTypeSlug: slug }) : `/products/${slug}`;

  const productTypesAlphabetically = productTypes.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <_MCollectionProductTypes>
      <Container>
        <Row>
          <Col lg={6} className='wraper-col-left' id="productTypeListWrapper">
            <div id="productTypeListSticky" />
            <div id="productTypeList" className='col-left'>
              <div className='title-wraper' dangerouslySetInnerHTML={{ __html: `<h3>${typeFor === 'commercial' ? '[M] Collection' : 'All'}</h3><h4>products</h4>` }} />
              <ul className="mb-4">
                <li>
                  <NavLink exact={true} to={replaceRoutePath(toPath, { productTypeSlug: undefined })} isActive={(m) => !currentProductTypeId}>
                    {textIgnoreCase('All Product')}
                  </NavLink>
                </li>
                {
                  productTypesAlphabetically.map((productType) => {
                    return (
                      <li key={productType.id}>
                        <NavLink to={toProductType(productType.slug)} isActive={(m) => productType.id === currentProductTypeId}>
                          {productType.name}
                        </NavLink>
                      </li>
                    );
                  })
                }
              </ul>
              <div>
                <strong>Tags</strong>
              </div>
              <div className="tags">
                {tags?.map(o => {
                  const isActive = currentTagId === o.id;
                  const nextUrl = new URL(location.href);

                  if (isActive) {
                    nextUrl.searchParams.delete('tagId');
                  }
                  else {
                    nextUrl.searchParams.set('tagId', o.id);
                  }

                  return (
                    <NavLink
                      key={o.id}
                      className="tag mr-2"
                      to={nextUrl.pathname + nextUrl.search}
                    >
                      <Badge className={isActive ? 'active' : ''} si>{o.name}</Badge>
                    </NavLink>
                  );
                })}
              </div>
            </div>
          </Col>
          <Col className='wraper-col-right' lg={6}>
            <Img file={currentProductType?.thumbnail ?? defaultCoverFile} />
          </Col>
        </Row>
      </Container>
    </_MCollectionProductTypes >
  );
};
