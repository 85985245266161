
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { defaultColor } from '../styled';

const _TitleComponent = styled.div`
  display : flex ;
  align-items : center ;
  margin :20px 0 ; 
  h3 {
    margin-right : 20px;
  }
  a {
    color : ${defaultColor};
    font-size : 15px;
  }
  @media (max-width: 991px) {
    h3 {
      font-size: 23px;
    }
  }
`;

interface ITitleComponentProps {
  linkMore?: string;
  children: string;
}

export const TitleComponent = (props: React.PropsWithChildren<ITitleComponentProps>) => {
  const { linkMore } = props;

  return (
    <_TitleComponent>
      <h3>
        {props.children}
      </h3>
      {linkMore && (
        <Link to={linkMore}>
          Xem thêm <i className="fa fa-angle-right" aria-hidden="true"></i>

        </Link>
      )}
    </_TitleComponent>
  );
};