import { useResourceAnonymous, useResourceLazy } from 'libs/restful';
import React from 'react';
import { Alert, Button, Form, Input } from 'reactstrap';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions } from '@/_shared/components';
import { newsLetterSignupResources, settingResources } from '@/api';
import { textIgnoreCase } from '@/translation';

import { NewsLetterSignupFormThanksContent } from './news-letter-signup-form';

const _NewsLetterSignupForm = styled.div`
  .alert {
    max-width: 400px;
  }
`;

interface INewsLetterSignupFormProps {

}

export const NewsLetterSignupForm = (props: React.PropsWithChildren<INewsLetterSignupFormProps>) => {
  const [thanksContentSettings] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'NEW_LETTER_THANKS_CONTENT' }]);
  const [newNewsLetterSignUpRequest, loading, sendRequest, error] = useResourceLazy(newsLetterSignupResources.create);

  React.useEffect(
    () => {
      if (!newNewsLetterSignUpRequest) {
        return;
      }
      const form = document.getElementById('newNewsLetterSignUpRequestForm') as HTMLFormElement;
      form?.reset();
    },
    [newNewsLetterSignUpRequest]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget);
      sendRequest([{
        type: 'body',
        value: {
          ...formValues
        }
      }]);
    },
    [sendRequest]
  );

  return (
    <_NewsLetterSignupForm>
      <GlobalModalNoActions isOpen={!!newNewsLetterSignUpRequest}>
        <NewsLetterSignupFormThanksContent setting={thanksContentSettings && thanksContentSettings[0]} />
      </GlobalModalNoActions>
      {error && (<Alert color="danger">{textIgnoreCase('Email is invalid or registered')}</Alert>)}
      <Form onSubmit={onSubmit} id="newNewsLetterSignUpRequestForm">
        <Input type="email" name="email" placeholder="Your email address" required={true} />
        <Button className='button-submmit' type='submit' color='grey' disabled={loading}>
          {textIgnoreCase('SUBSCRIBE')}
        </Button>
      </Form>
    </_NewsLetterSignupForm>
  );
};