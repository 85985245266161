import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IPerspective } from '@/api';

import { MCollectionPerspectiveItem } from './popover';

const _MCollectionPerspective = styled.div`

  padding: 70px 0;

  .wraper {
    position: relative;
    .poper1 {
      position: absolute;
      top: 25%;
      left: 15%;
    }
    .poper2 {
      position: absolute;
      top: 48%;
      left: 9%;
    }
    .poper3 {
      position: absolute;
      top: 55%;
      left: 33%;
    }
    .poper4 {
      position: absolute;
      top: 36%;
      left: 85%;
    }
  }
    
  .perspective-container {
      padding: 0;
  }
  @media (max-width: 992px) {
    padding-top: 0;
  }
`;

interface IMCollectionPerspectiveProps {
  readonly perspectives: IPerspective[];
}

export const MCollectionPerspective = (props: React.PropsWithChildren<IMCollectionPerspectiveProps>) => {
  const { perspectives } = props;

  const perspective = perspectives[0];

  return (
    <_MCollectionPerspective>
      <Container className="perspective-container">
        <div className='wraper'>
          <Img file={perspective.photo} />
          {
            perspective.perspectiveItems?.map((perspectiveItem, index) => {
              return (
                <MCollectionPerspectiveItem
                  key={perspectiveItem.id}
                  perspectiveItem={perspectiveItem}
                  index={index}
                />
              );
            })
          }
        </div>
      </Container>
    </_MCollectionPerspective >
  );
};