import { useResourceAnonymous } from 'libs/restful/hooks';
import React from 'react';
import styled from 'styled-components';

import { getPageSettings, getPageSettingValue } from '@/_shared';
import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { HomeContent, HomeNewProducts, HomeSlider } from './views';

const _HomePage = styled.div`
`;

interface IHomePageProps {

}

export const HomePage = (props: React.PropsWithChildren<IHomePageProps>) => {
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-home' }]);

  if (!pages) {
    return <PageLoading />;
  }

  const homePage = pages[0];

  const newProductSlugs = getPageSettingValue(homePage, 'NEW_PRODUCTS')?.split('\n') ?? [];

  return (
    <_HomePage>
      <HomeSlider slider={homePage.slider!} />
      {
        newProductSlugs.length > 0 && (
          <HomeNewProducts productSlugs={newProductSlugs} />
        )
      }
      <HomeContent page={homePage} />
    </_HomePage>
  );
};