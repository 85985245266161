import * as React from 'react';

import { customFetch, Resource } from './base';
import { RequestParameter, RequestParams } from './Types';
import { getRequestInit, getRequestUrl } from './utils';

export interface RestfulRenderChildProps<R> {
  data?: R;
  error?: Error;
  fetching: boolean;
  refetch: () => void;
}

export type RestfulRenderChildType<R> = React.ComponentType<RestfulRenderChildProps<R>>;

export interface RestfulRenderProps<R> {
  resource: Resource<unknown, R>;
  parameters?: RequestParameter[];
  render?: RestfulRenderChildType<R>;
  children?: (renderProps: RestfulRenderChildProps<R>) => React.ReactNode;
  onFetchCompleted?: (data: R) => void;
  initData?: R | undefined;
  initFetch?: boolean;
}

export interface RestfulRenderState<R> extends RestfulRenderProps<R> {
  prevParams?: RequestParams;
  needsUpdate?: boolean;
  fetching: boolean;
  componentRenderProps: RestfulRenderChildProps<R>;
}

export class RestfulRender<T> extends React.Component<RestfulRenderProps<T>, RestfulRenderState<T>> {
  static defaultProps = {
    parameters: []
  };

  Component?: RestfulRenderChildType<T>;

  static getDerivedStateFromProps<R>(
    nextProps: RestfulRenderProps<R>,
    prevState: RestfulRenderState<R>): RestfulRenderState<R> | null {

    const isResourceChanged = nextProps.resource !== prevState.resource;
    const isParamsChanged = JSON.stringify(nextProps.parameters) !== JSON.stringify(prevState.parameters);

    if (isResourceChanged || isParamsChanged) {
      return {
        ...nextProps,
        prevParams: prevState.parameters,
        componentRenderProps: {
          ...prevState.componentRenderProps,
          fetching: true
        },
        needsUpdate: true,
        fetching: true
      };
    }

    return null;
  }

  constructor(props: RestfulRenderProps<T>) {
    super(props);

    const { render, initData, initFetch } = props;

    this.Component = render;

    const needsFetch = initFetch || !initData;

    this.state = {
      ...props,
      fetching: needsFetch,
      componentRenderProps: {
        data: initData,
        error: undefined,
        refetch: this.fetching,
        fetching: needsFetch
      }
    };

    if (needsFetch) {
      this.fetching();
    }
  }

  componentDidUpdate() {
    const { needsUpdate, fetching } = this.state;
    if (needsUpdate && fetching) {
      this.fetching();
    }
  }

  render() {
    const { componentRenderProps } = this.state;
    const { children } = this.props;
    const { Component } = this;

    if (Component) {
      return <Component {...componentRenderProps} />;
    }

    if (children) {
      return children(componentRenderProps);
    }

    return null;
  }

  fetching = async () => {
    const { resource, parameters, onFetchCompleted, componentRenderProps } = this.state;

    const requestInit = getRequestInit(resource, parameters);
    const requestUrl = getRequestUrl(resource, parameters);

    try {
      const response = await customFetch(requestUrl, requestInit);
      const data = await response.json();

      if (onFetchCompleted) {
        onFetchCompleted(data);
      }

      this.setState({
        needsUpdate: false,
        fetching: false,
        componentRenderProps: {
          data: data,
          refetch: this.fetching,
          fetching: false
        }
      });
    } catch (error) {
      this.setState({
        fetching: false,
        componentRenderProps: {
          data: componentRenderProps.data,
          error: error,
          refetch: this.fetching,
          fetching: false
        }
      });
    }
  }
}