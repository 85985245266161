import React from 'react';
import styled from 'styled-components';

import {
    DefaultLayoutFooter,
    DefaultLayoutHeader,
    DefaultLayoutMain
} from './default-layout';

interface IDefaultLayoutProps {
}

const _DefaultLayout = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction : column;
  main {
    flex: 1;
    margin-top: 74px;
  }
`;

export const DefaultLayout = (props: React.PropsWithChildren<IDefaultLayoutProps>) => {
  const { children } = props;

  return (
    <_DefaultLayout >
      <DefaultLayoutHeader />
      <DefaultLayoutMain>
        {children}
      </DefaultLayoutMain>
      <DefaultLayoutFooter />
    </_DefaultLayout >
  );
};