import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';

import { ProjectRequestForm } from './views';

const _ProjectRequestPage = styled.div``;

interface IProjectRequestPageProps { }

export const ProjectRequestPage = (
  props: React.PropsWithChildren<IProjectRequestPageProps>
) => {

  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'project-request' }]);

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_ProjectRequestPage>
      <ProjectRequestForm page={page} />
    </_ProjectRequestPage>
  );
};
