import React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { IFeature } from '@/api';

const _FeatureDetailsInfo = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  @media screen and (max-width: 991px) {
    padding-top: 0px;
  }

  .content {
    margin: 0 auto;
    text-align: center;
    max-width: 670px;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-family: 'Copperplate';
    font-size: 48px;
    line-height: 1;
    small {
      font-size: 26px;
      font-weight: bold;
      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }
    @media screen and (max-width: 991px) {
      font-size: 36px;
      line-height: 1.1;
    }
  }
`;

interface IFeatureDetailsInfoProps {
  readonly feature: IFeature;
}

export const FeatureDetailsInfo = (props: React.PropsWithChildren<IFeatureDetailsInfoProps>) => {
  return (
    <_FeatureDetailsInfo>
      <Container>
        <div className="content" dangerouslySetInnerHTML={{__html: props.feature.content!}}/>
      </Container>
    </_FeatureDetailsInfo>
  );
};