import { pushItemToCart } from 'libs/offline-cart';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import styled from 'styled-components';

import { FormInputNumber } from '@/_shared/components';
import { reloadOfflineCartEvent } from '@/_shared/events';
import { IGroupsModelModule, IOrderItem } from '@/api';
import {
    getModelQuoteRequestUrl
} from '@/pages/model-quote-request/ModelQuoteRequest.shared';
import { textIgnoreCase } from '@/translation';

import { DesignToolBase } from './DesignToolBase';

const _DesignToolAddToCart = styled.div`
  /** Styles here */
  padding-bottom: 12px;
  line-height: 1.9rem;

  button {
    height: 40px;
  }

  .input-number-wrapper {
    background: transparent;
    input {
      background: transparent;
      width: 60px;
      height: 40px;
    }
  }

  .pricing-label {
    line-height: 1.5;
  }
  #orderQuantity {
    margin-bottom: 0;
  }
`;

interface IDesignToolAddToCartProps {
  readonly onAddToCart: () => void;
}

interface IDesignToolAddToCartState {
  readonly orderQuantity?: number;
}

export class DesignToolAddToCart extends DesignToolBase<
  IDesignToolAddToCartProps,
  IDesignToolAddToCartState> {

  public constructor(props: IDesignToolAddToCartProps) {
    super(props);

    this.state = {
      ...this.state,
      orderQuantity: 1
    };
  }

  public render() {
    const { model } = this.props;

    if (!model) {
      return null;
    }

    const modules3D = this.getModules3D();

    const allowAddtoCart = !modules3D.find((o) => {
      if (!o.component.materialTypes?.length) {
        return false;
      }

      return !o.material;
    });

    return (
      <_DesignToolAddToCart>
        <Row>
          <Col>
            <div className="d-flex">
              <div className="mr-2">
                <FormInputNumber
                  min={1}
                  name="orderQuantity"
                  id="orderQuantity"
                  value={this.state.orderQuantity}
                  setFieldValue={(name, value) => {
                    this.setState({
                      orderQuantity: value
                    });
                  }}
                />
              </div>
              <Button
                tag={Link}
                to={getModelQuoteRequestUrl(model.id, this.state.orderQuantity ?? 1, location.href)}
                className="text-uppercase"
                color="dark"
              >
                {textIgnoreCase('Quote')}
              </Button>
            </div>
          </Col>
        </Row>
        {
          !allowAddtoCart && (
            <div className="mt-2">
              <i>{textIgnoreCase('Please select full material before adding to cart')}!</i>
            </div>
          )
        }
      </_DesignToolAddToCart >
    );
  }

  private readonly createCartItem = async () => {
    const { model } = this.props;

    if (!model) {
      return undefined;
    }

    const modules3D = this.getModules3D();
    const { orderQuantity, hadDecorations } = this.state;

    const modudulesPrice = this.getCurrentModulesPrice();
    const subTotal = modudulesPrice * orderQuantity!;

    const thumbnailBase64 = await this.take3DScreenshot();

    const orderItem: Partial<IOrderItem> = {
      type: 'MODEL',
      productName: model.name,
      quantity: orderQuantity,
      modelModules: modules3D.map((o): Partial<IGroupsModelModule> => {
        const materialType = this.getModule3DMaterialType(o);

        return {
          componentId: o.component.id,
          componentName: o.component.name,
          componentPrice: o.component.price,
          materialId: o.material?.id,
          materialName: o.material?.name,
          materialPrice: (o.material?.unitPrice ?? 0) * o.component.materialQuota!,
          materialPricePerUnit: o.material?.unitPrice,
          materialTypeId: materialType?.id,
          materialTypeName: materialType?.name,
          materialVolume: o.component.materialQuota,
          materialUnit: 'undefined'
        };
      }),
      productId: model.id,
      productURL: location.pathname + location.search,
      subTotalPrice: subTotal,
      totalPrice: subTotal,
      totalDiscount: 0,
      unitPrice: modudulesPrice,
      thumbnailURL: thumbnailBase64,
      hadDecorations: !!hadDecorations,
      productDetails: this.getCurrentDetails()
    };

    return orderItem;
  }

  private readonly onAddtoCart = async () => {
    const cartItem = await this.createCartItem();
    if (!cartItem) {
      return;
    }

    pushItemToCart(cartItem);
    reloadOfflineCartEvent.emit(cartItem);
    document.getElementById('designPageTool')!
      .scrollIntoView({
        behavior: 'smooth'
      });

    this.props.onAddToCart();
  }
}
