import { useResource } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { IWishlistGroup, wishlistResources } from '@/api';

import { WishlistDetail, WishlistDetailHeader, WishlistGroups } from './views';

const _WishlistSharedPage = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

export const WishlistSharedPage = () => {

  const { id } = useParams<{ id: string }>();
  const [selectedWishlistGroup, setSelectedWishlistGroup] = React.useState<IWishlistGroup | null | undefined>(undefined);

  const [wishlist, loading] = useResource(wishlistResources.findOne, [{ type: 'path', parameter: 'id', value: id }]);

  if (loading) {
    return <PageLoading />;
  }

  if (!wishlist) {
    return null;
  }

  return (
    <_WishlistSharedPage>
      <Container>
        <WishlistDetailHeader wishlist={wishlist} />
        <Row>
          <Col xs={12} md={4} lg={3}>
            <WishlistGroups
              wishlist={wishlist}
              onGroupSelected={(group) => setSelectedWishlistGroup(group)}
            />
          </Col>
          <Col xs={12} md={8} lg={9}>
            <WishlistDetail
              wishlist={wishlist}
              selectedWishlistGroup={selectedWishlistGroup}
            />
          </Col>
        </Row>
      </Container>
    </_WishlistSharedPage>
  );
};