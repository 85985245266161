import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { getPageSettingValue } from '@/_shared';
import { IPage } from '@/api';
import { textIgnoreCase } from '@/translation';

const _ContactInfo = styled.div`
  font-family: 'Roboto';
  .contact-info-container {
    border-right: 1px solid #ccc;
    text-align: center;
  }
  .contact-row, .contact-col {
    padding: 0;
  }
  .contact-col {
    @media (min-width: 768px) {
      border-bottom-width: 1;
     .label {
        border-left-width: 1px;
      }
    }
    height: 350px;
    color: #000000;
    border: 1px solid #ccc;
    border-right-width: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &.label {
      background: #F1F1F1;
      h4 {
        font-family: Copperplate;
        font-weight: 500;
        font-size: 24px;
        line-height: 25px;
        text-transform: uppercase;
      }
    }
    .content {
      padding: 10px;
      display: flex;
      flex-flow: column;
      align-items: center;
      min-height: 235px;
      .icon {
        height: 70px;
        width: 70px;
        font-size: 32px;
        border-radius: 50%;
        color: #fff;
        background-color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
      }
      label {
        font-weight: bold;
        font-size: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      p {
        font-size: 13px;
        line-height: 20px;
        margin: 0;
      }
    }
  }
  @media (max-width: 992px) {
      .contact-col {
        .content {
          min-height: unset;
        }
        &.label {
          height: 100px;
          text-align: left;
          justify-content: left;
          h4{
            padding-left: 20px;
            margin: 0;
          }
      }
    }
  }
`;

interface IContactInfoProps {
  readonly page: IPage;
}

export const ContactInfo = (props: React.PropsWithChildren<IContactInfoProps>) => {
  const { page } = props;

  const ourAddress = getPageSettingValue(page, 'OUR_ADDRESS');
  const ourEmail = getPageSettingValue(page, 'OUR_EMAIL');
  const callUs = getPageSettingValue(page, 'CALL_US');

  return (
    <_ContactInfo>
      <Container className="contact-info-container">
        <Row className='contact-row' xs={1} sm={2} md={4}>
          <Col className='contact-col label'>
            <h4>{textIgnoreCase('information')}</h4>
          </Col>
          <Col className='contact-col'>
            <div className='content'>
              <div className='icon'>
                <i className="fa fa-map-marker" />
              </div>
              <label>{textIgnoreCase('our address')}</label>
              <ReactMarkdown source={ourAddress ?? textIgnoreCase('No info')} />
            </div>
          </Col>
          <Col className='contact-col'>
            <div className='content'>
              <div className='icon'>
                <i className="fa fa-envelope-o" />
              </div>
              <label>{textIgnoreCase('our email')}</label>
              <ReactMarkdown source={ourEmail ?? textIgnoreCase('No info')} />
            </div>
          </Col>
          <Col className='contact-col'>
            <div className='content'>
              <div className='icon'>
                <i className="fa fa-volume-control-phone" />
              </div>
              <label>{textIgnoreCase('call us')}</label>
              <ReactMarkdown source={callUs ?? textIgnoreCase('No info')} />
            </div>
          </Col>
        </Row>
      </Container>
    </_ContactInfo>
  );
};