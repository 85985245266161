
import { Resource } from 'libs/restful';

import { IFeatureCategory } from './featureCategory';

export interface IFeatureType {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug?: string;
  readonly featureCategory: IFeatureCategory;
}

export const featureTypeResources = {
  find: new Resource<IFeatureType, IFeatureType[]>({
    method: 'GET',
    url: '/admin/featuretypes'
  }),
  count: new Resource<IFeatureType, number>({
    method: 'GET',
    url: '/admin/featuretypes/count'
  }),
  findOne: new Resource<IFeatureType, IFeatureType>({
    method: 'GET',
    url: '/admin/featuretypes/:id'
  })
};
