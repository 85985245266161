
import { Resource } from 'libs/restful';

import { IContactInfo } from './contactInfo';
import { IOrderItem } from './orderItem';
import { IUser } from './user';

export interface IOrder {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly subTotalPrice: number;
  readonly totalDiscount: number;
  readonly totalPrice: number;
  readonly status?: string;
  readonly depositRequired: number;
  readonly depositedAmount: number;
  readonly deposited?: boolean;
  readonly deliveryDate: string;
  readonly code: string;
  readonly message?: string;
  readonly contactInfo: Partial<IContactInfo>;
  readonly orderItems?: Partial<IOrderItem>[];
  readonly createdBy?: Partial<IUser>;
}

export const orderResources = {
  find: new Resource<IOrder, IOrder[]>({
    method: 'GET',
    url: '/customer/orders'
  }),
  count: new Resource<IOrder, number>({
    method: 'GET',
    url: '/customer/orders/count'
  }),
  findOne: new Resource<IOrder, IOrder>({
    method: 'GET',
    url: '/customer/orders/:id'
  }),
  create: new Resource<IOrder, IOrder>({
    method: 'POST',
    url: '/customer/orders'
  }),
  update: new Resource<IOrder, IOrder>({
    method: 'PUT',
    url: '/customer/orders/:id'
  }),
  delete: new Resource<IOrder, IOrder>({
    method: 'DELETE',
    url: '/customer/orders/:id'
  }),
};
