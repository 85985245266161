import {
    useResource,
    useResourceAnonymousLazy,
    useResourceLazy
} from 'libs/restful';
import React, { useEffect } from 'react';
import { Redirect, useHistory, useLocation, useParams } from 'react-router';
import { Container } from 'reactstrap';

import { PageLoading } from '@/_shared/components';
import { modelResources, productResources } from '@/api';

import { getDesignPageUrl } from '../design/DesignPage.shared';
import {
    getMCollectionProductUrl
} from '../m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '../product/ProductPage.shared';

let _initialized = false;

interface IShortenUrlPageProps {

}

export const ShortenUrlPage = (props: React.PropsWithChildren<IShortenUrlPageProps>) => {

  let redirectUrl = '';
  const params = useParams<{ readonly slug: string }>();

  const [models, modelFetching, fetchModels] = useResourceAnonymousLazy(modelResources.find);
  const [products, productFetching, fetchProducts] = useResourceAnonymousLazy(productResources.find);

  if (location.pathname.startsWith('/m')) {
    if (!_initialized && !modelFetching) {
      fetchModels([{ type: 'query', parameter: 'slug', value: params.slug }]);
    }
    if (models) {
      const foundModel = models[0];

      if (foundModel) {
        if (foundModel.defaultUrl == null) {
          redirectUrl = getDesignPageUrl(params.slug);
        }
        else {
          const designPageURL = new URL(foundModel.defaultUrl);
          redirectUrl = designPageURL.pathname + designPageURL.search;
        }
      }
      else {
        redirectUrl = '/404';
      }
    }
  }

  else if (location.pathname.startsWith('/p')) {
    if (!_initialized && !productFetching) {
      fetchProducts([{ type: 'query', parameter: 'slug', value: params.slug }]);
    }
    if (products) {
      const foundProduct = products[0];

      if (foundProduct) {
        if (foundProduct.for === 'commercial') {
          redirectUrl = getMCollectionProductUrl(foundProduct);
        }
        else {
          redirectUrl = getProductUrl(foundProduct);
        }
      }
      else {
        redirectUrl = '/404';
      }
    }
  }

  useEffect(
    () => {
      _initialized = true;
    },
    []
  );

  if (redirectUrl) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Container>
      <PageLoading />
    </Container>
  );
};