import { Resource } from 'libs/restful';

export interface IFile {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly hash: string;
  readonly sha256?: string;
  readonly ext?: string;
  readonly mime: string;
  readonly size: string;
  readonly url: string;
  readonly provider: string;
  readonly public_id?: string;
  readonly aspectRatio?: string;
  readonly urlThumbnail?: string;
  readonly w1024Url?: string;
  readonly dimension?: string;
  readonly related?: any[];
}

export const fileResources = {
  upload: new Resource<IFile, IFile>({
    method: 'POST',
    url: '/customer/upload'
  }),
  count: new Resource<IFile, number>({
    method: 'GET',
    url: '/customer/upload/files/count'
  }),
  find: new Resource<IFile, IFile[]>({
    method: 'GET',
    url: '/customer/upload/files'
  }),
  findOne: new Resource<IFile, IFile>({
    method: 'GET',
    url: '/customer/upload/files/:id'
  }),
  search: new Resource<IFile, IFile>({
    method: 'GET',
    url: '/customer/upload/search/:id'
  }),
  destroy: new Resource<IFile, IFile>({
    method: 'DELETE',
    url: '/customer/upload/files/:id'
  })
};
