
import { Resource } from 'libs/restful';

import { IFile } from './file';
import { IGroupProjectProduct } from './groupProjectProduct';
import { IUser } from './user';

export interface IProject {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug: string;
  readonly description: string;
  readonly description_en?: string;
  readonly cover?: Partial<IFile>;
  readonly published?: boolean;
  readonly projectProducts?: Partial<IGroupProjectProduct>[];
  readonly content?: string;
  readonly content_en?: string;
  readonly Photos?: Partial<IFile>[];
  readonly owners?: Partial<IUser>[];
}

export const projectResources = {
  find: new Resource<IProject, IProject[]>({
    method: 'GET',
    url: '/customer/projects'
  }),
  findAllPublic: new Resource<IProject, IProject[]>({
    method: 'GET',
    url: '/customer/projects/all-public'
  }),
  count: new Resource<IProject, number>({
    method: 'GET',
    url: '/customer/projects/count'
  }),
  bplusProject: new Resource<IProject, IProject>({
    method: 'GET',
    url: '/customer/projects/bplus-project'
  }),
  findOne: new Resource<IProject, IProject>({
    method: 'GET',
    url: '/customer/projects/:id'
  }),
  create: new Resource<IProject, IProject>({
    method: 'POST',
    url: '/customer/projects'
  }),
  update: new Resource<IProject, IProject>({
    method: 'PUT',
    url: '/customer/projects/:id'
  }),
  delete: new Resource<IProject, IProject>({
    method: 'DELETE',
    url: '/customer/projects/:id'
  }),
};
