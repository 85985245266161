/**
 * Template: page-section/section.ejs.t
 * Version: 0.0.1
 */

import * as React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { IModel, IProject } from '@/api';
import {
    _ProjectDetailProducts,
    ProjectDetailProducts
} from '@/pages/project-detail/views';
import { textIgnoreCase } from '@/translation';

import { ProjectProductInfo } from './ProjectProductInfo';

const _ProjectProductPageOtherProducts = styled(_ProjectDetailProducts as any)`
  /** Styled */
  .project-products {
    border-bottom: 1px solid #C4C4C4!important;
  }
`;

interface IProjectProductPageOtherProductsProps {
  readonly model?: IModel;
  readonly project?: IProject;
}

interface IProjectProductPageOtherProductsState {

}

export class ProjectProductPageOtherProducts extends React.Component<
  IProjectProductPageOtherProductsProps,
  IProjectProductPageOtherProductsState> {

  public constructor(props: IProjectProductPageOtherProductsProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { project, model } = this.props;

    if (!project || !model) {
      return null;
    }

    const projectProduct = ProjectProductInfo.getProjectProduct(project, model);
    if (!projectProduct) {
      return null;
    }

    const otherModels = project?.projectProducts?.filter((o) =>
      o.name !== model?.name && o['Areas']?.trim().toLowerCase() === projectProduct['Areas']?.trim().toLowerCase());

    if (!otherModels?.length) {
      return null;
    }

    return (
      <_ProjectProductPageOtherProducts
        id="ProjectProductPageOtherProducts"
      >
        <Container>
          <div className="project-products">
            <div className="project-card-area">
              <div className="project-products-title" dangerouslySetInnerHTML={{ __html: textIgnoreCase('Related product same area') }} />
            </div>
            <div className="project-cards">
              {ProjectDetailProducts.renderCards(project, otherModels)}
            </div>
          </div>
        </Container>
      </_ProjectProductPageOtherProducts>
    );
  }
}
