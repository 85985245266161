/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { useResourceAnonymous } from 'libs/restful/hooks';
import React from 'react';
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { Img, PageLoading } from '@/_shared/components';
import { outdoorResources } from '@/api/outdoorcollections';

import { OutdoorProductList } from './views';
import { OutdoorSlider } from './views/OutdoorSlider';

const _OutdoorPage = styled.div`
  .wrapper-introduce-item {
    display: flex;
    border: 0.5px solid #C4C4C4;

    .introduce-item-left {
      width: 300px;
        margin:  auto;
        border-right: 0.5px solid #C4C4C4;
        padding-left: 70px;
        a {
            color: #818181;
            font-size: 13px;
        }
    }
    .introduce-item-right {
      display: flex;
      flex: 1;
      padding: 80px 80px;
      margin:  auto;
      border-left: 0.5px solid #C4C4C4;
      column-gap: 40px;
      .introduce-img {
        width: 300px;
      }
      .introduce-value {
        flex:1;
      }
    }
  }

  @media (max-width: 991px) {
    .container {
      padding: 0;
    }
    .wrapper-introduce-item  {
        flex-direction: column;
        padding: 16px 30px;
        border: none;
        .introduce-item-left {
            width: 100%;
            border: none;
            padding-left: 0;
            padding-bottom: 30px;
            border-bottom: 0.5px solid #C4C4C4;
        }
        .introduce-item-right {
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            margin-top: 30px;
            padding: 0;
            border-left: none;
            .introduce-img {
              width: 100%;
            }
        }
    }
  }
  .padding-0 {
    padding: 0;
  }
`;
interface IOutdoorCollectionPageParams {
  id?: string;
}

export const OutdoorCollectionPage = () => {
  const { id } = useParams<IOutdoorCollectionPageParams>();
  const [outdoorCollections] = useResourceAnonymous(outdoorResources.findOne, [{ type: 'path', parameter: 'id', value: id }]);
  
  if (!outdoorCollections) {
    return <PageLoading />;
  }

  return (
    <_OutdoorPage>
      <Container className='padding-0'>
        <Img file={outdoorCollections?.ImageTopPgae} />
      </Container>
      <Container className='padding-0'>
        {outdoorCollections?.outdoorIntroduce?.map((item, index) => (
          <div key={index} className="wrapper-introduce-item">
            <div className='introduce-item-left' dangerouslySetInnerHTML={{ __html: item?.valueLeft! }} />
            <div className='introduce-item-right'>
              <div className='introduce-img'>
                <Img file={item?.wireframePhoto} />
              </div>
              <div className='introduce-value' dangerouslySetInnerHTML={{ __html: item?.valueRight! }} />
            </div>
          </div>
        ))}
      </Container>
      <OutdoorSlider photosSilder={outdoorCollections?.photosSilder} />
      <OutdoorProductList
        products={outdoorCollections?.products}
        outdoorName={outdoorCollections?.Name}
      />
    </_OutdoorPage >
  );
};