
import { Resource } from 'libs/restful';


export interface IBillingInformation {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly CompanyName: string;
  readonly CompanyAddress: string;
  readonly companyTaxCode: string;
  readonly receiverName: string;
  readonly receiverPhone: string;
  readonly receiverEmail: string;
  readonly receiverAddress: string;
}

export const billingInformationResources = {
  find: new Resource<IBillingInformation, IBillingInformation[]>({
    method: 'GET',
    url: '/customer/billinginformations'
  }),
  count: new Resource<IBillingInformation, number>({
    method: 'GET',
    url: '/customer/billinginformations/count'
  }),
  findOne: new Resource<IBillingInformation, IBillingInformation>({
    method: 'GET',
    url: '/customer/billinginformations/:id'
  }),
  create: new Resource<IBillingInformation, IBillingInformation>({
    method: 'POST',
    url: '/customer/billinginformations'
  }),
  update: new Resource<IBillingInformation, IBillingInformation>({
    method: 'PUT',
    url: '/customer/billinginformations/:id'
  }),
  delete: new Resource<IBillingInformation, IBillingInformation>({
    method: 'DELETE',
    url: '/customer/billinginformations/:id'
  }),
};
