
import React from 'react';

import { HttpRequestFailError, Resource } from '../base';
import { RequestParameter } from '../Types';
import { getRequestInit, getRequestUrl } from '../utils';

export const useRecourceFactory = (customFetch: Window['fetch']) => {
  const useResourceLazy = <TReq, TRes>(
    resource: Resource<TReq, TRes>
  ): [TRes | undefined, boolean, (params?: RequestParameter[]) => Promise<TRes>, Error | undefined] => {
    const [result, setResult] = React.useState<TRes>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<any>();

    const request = React.useCallback(
      async (params?: RequestParameter[]) => {
        const requestInit = getRequestInit(resource, params);
        const requestUrl = getRequestUrl(resource, params);
        setLoading(true);
        if (error) {
          setError(undefined);
        }
        
        try {
          const response = await customFetch(requestUrl, requestInit);
          const data = await response.json();
  
          if (response.status === 200 || response.status === 201) {
            setResult(data);
          } else {
            throw new HttpRequestFailError(response.status, data);
          }

          return data;
        } catch (error) {
          setError(error);
          throw error;
        } finally {
          setLoading(false);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return [result, loading, request, error];
  };

  return useResourceLazy;
};
