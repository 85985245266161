import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';

import { Img } from '@/_shared/components';
import { IWishlist, IWishlistGroup, IWishlistItem } from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '@/pages/product/ProductPage.shared';
import { _WishlistDetail } from '@/pages/wishlist-detail/views/WishlistDetail';
import {
    wishlistItemsState
} from '@/pages/wishlist-detail/WishlistDetailPage.shared';

interface IWishlistDetailProps {
  readonly wishlist: IWishlist;
  readonly selectedWishlistGroup?: IWishlistGroup | null | undefined;
}

export const WishlistDetail = (props: React.PropsWithChildren<IWishlistDetailProps>) => {

  const [wishlistItems, updateWishlistItems] = useRecoilState(wishlistItemsState);

  const sortWishlistItems = (items: IWishlistItem[] = []) => items.sort((a, b) => (a.productName).localeCompare(b.productName) || b.createdAt!.localeCompare(a.createdAt!));

  useEffect(() => {
    updateWishlistItems(sortWishlistItems(props.wishlist.wishlistItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayingWishlistItems = wishlistItems.filter(wi => {
    if (props.selectedWishlistGroup === undefined) {
      return true;
    }

    if (props.selectedWishlistGroup === null) {
      return !wi.wishlistGroup;
    }

    if (typeof wi.wishlistGroup === 'string') {
      return wi.wishlistGroup === props.selectedWishlistGroup.id;
    }

    return wi.wishlistGroup?.id === props.selectedWishlistGroup.id;
  });

  return (
    <_WishlistDetail>
      <Container>
        <Row>
          {
            sortWishlistItems(displayingWishlistItems)?.map((item) => {

              const product = {
                id: item.data?.productId,
                slug: item.data?.productSlug,
              };

              const productURL = item.isExternal
                ? item.link
                : (
                  item.data?.for === 'commercial'
                    ? getMCollectionProductUrl(product)
                    : getProductUrl(product)
                );

              const group = props.wishlist.wishlistGroups.find(wg => wg.id === ((item.wishlistGroup as IWishlistGroup)?.id ?? item.wishlistGroup));

              const content = (
                <div className="item" id={`item${item.id}`}>
                  <div className="thumbnail">
                    <Img file={item.thumbnail} ratioX={1} ratioY={1} />
                  </div>
                  <div style={{ padding: '12px 25px', background: '#f3f3f3' }}>
                    <small><b>{item.data?.type ?? '-'}</b></small>
                    <div className="name">
                      {item.productName}
                    </div>
                    <div className="quantity">
                      x{item.quantity} | {group?.name ?? 'Not assigned'}
                    </div>
                  </div>
                </div>
              );

              return (
                <Col key={item.id} xs={12} sm={6} md={4} className="col">
                  {
                    item.isExternal
                      ? (<a href={productURL} target="_blank" rel="noopener noreferrer"> {content} </a>)
                      : (<Link to={productURL!}> {content} </Link>)
                  }
                </Col>
              );
            })
          }
          <div className="border-right-helper"></div>
        </Row>
      </Container>
    </_WishlistDetail>
  );
};