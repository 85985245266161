import {
    RequestParameter,
    RestfulRender,
    useResource,
    useResourceLazy
} from 'libs/restful';
import * as React from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { CommentBox } from '@/_shared/components';
import { commentResources, IComment, IModel, IProject } from '@/api';
import { textIgnoreCase } from '@/translation';

import { ProjectProductCommentContents } from './project-product-comments';

const _ProjectProductComments = styled.div`
  /** Styles here */
  margin-bottom: 3rem;

  .section-title {
    font-family: "Copperplate";
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
  }
`;

interface IProjectProductCommentsProps {
  readonly model?: IModel;
  readonly project?: IProject;
}

export const ProjectProductComments = (props: IProjectProductCommentsProps) => {
  const { project } = props;
  const topic = location.pathname;

  const fetchCommentParams: RequestParameter[] = [{
    type: 'query',
    parameter: 'topic',
    value: topic
  }];

  const [comments, , refetchComment] = useResource(commentResources.find, fetchCommentParams);

  const [newComment, , createComment] = useResourceLazy(commentResources.create);

  React.useEffect(
    () => {
      if (!newComment) {
        return;
      }
      refetchComment(fetchCommentParams);
    },
    [newComment]
  );

  if (!project) {
    return null;
  }

  if (!topic) {
    return null;
  }

  const commentBox = (
    <div>
      <CommentBox
        // tslint:disable-next-line:jsx-no-lambda
        onSubmit={async (data) => {
          const comment: Partial<IComment> = {
            topic,
            content: data.content,
            project: project.id as any
          };

          createComment([{
            type: 'body',
            value: comment
          }]);
        }}
      />
    </div>
  );
  return (
    <_ProjectProductComments id="projectProductComments">
      <Container>
        <div>
          <div className="section-title">{textIgnoreCase('information exchange')}</div>
        </div>
        {
          !comments || !comments.length
            ? commentBox
            : (
              <div>
                <ProjectProductCommentContents comments={comments} refetch={() => refetchComment(fetchCommentParams)} />
                {commentBox}
              </div>
            )
        }
      </Container>
    </_ProjectProductComments>
  );
};
