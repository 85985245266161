
import { Resource } from 'libs/restful';

import { IUser } from './user';

export interface ICustomMaterialType {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name?: string;
  readonly createdBy?: Partial<IUser>;
}

export const customMaterialTypeResources = {
  find: new Resource<ICustomMaterialType, ICustomMaterialType[]>({
    method: 'GET',
    url: '/customer/custommaterialtypes'
  }),
  count: new Resource<ICustomMaterialType, number>({
    method: 'GET',
    url: '/customer/custommaterialtypes/count'
  }),
  findOne: new Resource<ICustomMaterialType, ICustomMaterialType>({
    method: 'GET',
    url: '/customer/custommaterialtypes/:id'
  }),
  create: new Resource<ICustomMaterialType, ICustomMaterialType>({
    method: 'POST',
    url: '/customer/custommaterialtypes'
  }),
  update: new Resource<ICustomMaterialType, ICustomMaterialType>({
    method: 'PUT',
    url: '/customer/custommaterialtypes/:id'
  }),
  delete: new Resource<ICustomMaterialType, ICustomMaterialType>({
    method: 'DELETE',
    url: '/customer/custommaterialtypes/:id'
  }),
};
