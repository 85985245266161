import * as React from 'react';
import { Button, Input } from 'reactstrap';
import styled from 'styled-components';

import { textIgnoreCase } from '@/translation';

const _CommentBox = styled.div`
  /** Styles here */
  textarea {
    background: #F0F0F0;
    border-radius: 10px;
    padding: 30px;
    border: 0;
  }
  button {
    width: 107px;
  }
`;

interface ICommentBoxProps {
  readonly onSubmit: (data: { readonly content: string }) => Promise<void>;
}

interface ICommentBoxState {

}

export class CommentBox extends React.Component<
  ICommentBoxProps,
  ICommentBoxState> {
  public constructor(props: ICommentBoxProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { } = this.props;

    return (
      <_CommentBox>
        <form onSubmit={this.onSubmit}>
          <Input type="textarea" name="content" placeholder={textIgnoreCase('write an comment') + '...'} rows={3} />
          <Button type="submit" color="dark">{textIgnoreCase('SEND')}</Button>
        </form>
      </_CommentBox>
    );
  }

  private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formElement = e.currentTarget;
    const formData = new FormData(formElement);

    const data = {} as any;
    formData.forEach((value, key) => {
      data[key] = value;
    });

    await this.props.onSubmit(data);

    formElement.reset();
  }
}
