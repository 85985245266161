import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { productTypeResources, projectResources } from '@/api';
import { ProjectDetailSlider } from '@/pages/project-detail/views';

import { ProjectBplusInfo, ProjectBplusProducts } from './views';

const _ProjectBplusPage = styled.div`
`;

interface IProjectBplusPageProps {

}

export const ProjectBplusPage = (props: React.PropsWithChildren<IProjectBplusPageProps>) => {

  const params = useParams();

  const [project] = useResourceAnonymous(projectResources.bplusProject);
  const [productTypes] = useResourceAnonymous(productTypeResources.find);

  if (!project) {
    return null;
  }

  return (
    <_ProjectBplusPage>
      <ProjectDetailSlider project={project} />
      <ProjectBplusInfo project={project} productTypes={productTypes} />
      <ProjectBplusProducts
        project={project}
        productTypes={productTypes}
        selectedProductTypeSlug={params['productTypeSlug']}
      />
    </_ProjectBplusPage>
  );
};