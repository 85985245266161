import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IModel, IProduct } from '@/api';
import { DesignToolAttackments } from '@/pages/design/views/design-page-tool';
import { textIgnoreCase } from '@/translation';

import { MCollectionFileRequestForm } from './form';

export const _MCollectionFileRequest = styled.div`
    .col-right{
      display : flex;
      align-items : center;
    }
    .col-left {
      padding: 0;
      .title {
        position : absolute;
        z-index :1 ;
        width : 100%;
        height : 100%;
        top: 0;
        left: 0;
        justify-content : center;
        display :flex;
        flex-direction : column;
        align-items : center;
        background: rgb(0 0 0 / .3);
        h3 {
          font-size: 48px;
          color: #ffffff;
        }
        h4 {
          font-size: 36px;
          color: #ffffff;
        }
      }
    }

    .downloadable {
      padding: 0 60px;
    }

    .attachments-content {
      justify-content: flex-start;
    }

  @media (max-width : 992px) {
    .col-left{
      .title{
        padding-left: 30px;
        padding-right: 30px;
        align-items : unset;
      }
    }
    .col-right{
      margin : 30px 0;
    }
    .downloadable {
      padding: 0 20px;
      margin-bottom: 20px;
    }
  }
`;

interface IMCollectionFileRequestProps {
  readonly product: IProduct;
  readonly requestType: string;
  readonly model?: IModel;
  readonly displayFiles?: boolean;
}

export const requestTypes = {
  'quote': 'Quote',
  'catalogue': 'Catalogue',
  '3d-files': 'Data',
  'price-list': 'Price List'
};

export const MCollectionFileRequest = (props: React.PropsWithChildren<IMCollectionFileRequestProps>) => {
  const { requestType, product, model, displayFiles } = props;

  const requestTypeTitle = requestTypes[requestType];

  return (
    <_MCollectionFileRequest>
      <Container>
        <Row>
          <Col className='col-left' lg={6}>
            {
              product.featurePhoto
                ? (
                  <Img file={product.featurePhoto} />
                )
                : (
                  <img src="/static/COM/image 4.1-request.jpg" alt="" />
                )
            }
            <div className='title'>
              <div>
                <h3>
                  {product.name}
                </h3>
                <h4>
                  {textIgnoreCase(requestTypeTitle)}
                </h4>
              </div>
            </div>
          </Col>
          <Col className='col-right' lg={6}>
            {
              displayFiles
                ? (
                  <div className="downloadable">
                    <h2 className='title'>{textIgnoreCase(requestTypeTitle)}</h2>
                    <p>{textIgnoreCase('List files you can download')}</p>
                    <DesignToolAttackments model={model} />
                  </div>
                )
                : (
                  <MCollectionFileRequestForm
                    product={product}
                    requestType={requestType}
                    requestTypeTitle={requestTypeTitle}
                  />
                )
            }
          </Col>
        </Row>
      </Container>
    </_MCollectionFileRequest >
  );
};