import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { featureCategoryResources, featureResources } from '@/api';

import { FeatureFilter } from '../features/views';
import {
    FeatureDetailsCover,
    FeatureDetailsInfo,
    FeatureDetailsPhotos
} from './views';
import { FeatureDetailsProducts } from './views/FeatureDetailsProducts';

const _FeatureDetailsPage = styled.div`
padding-bottom: 100px;
`;

interface IFeatureDetailsPageParams {
  readonly slug: string;
}

interface IFeatureDetailsPageProps {
  
}

export const FeatureDetailsPage = (props: React.PropsWithChildren<IFeatureDetailsPageProps>) => {
  const params = useParams<IFeatureDetailsPageParams>();

  const [features] = useResourceAnonymous(featureResources.find, [{ type: 'query', value: params.slug, parameter: 'slug' }]);
  const [featureCategories] = useResourceAnonymous(featureCategoryResources.find);

  const feature = features && features[0];

  if (!feature) {
    return <PageLoading />;
  }

  return (
    <_FeatureDetailsPage>
      <FeatureDetailsCover feature={feature} />
      <FeatureFilter
        headingText={`${feature.featureCategory?.name} - ${feature.featureType.name}`}
        featureCategories={featureCategories}
        activeCategorySlug={feature.featureCategory?.slug}
      />
      <FeatureDetailsInfo feature={feature} />
      <FeatureDetailsProducts feature={feature} />
      <FeatureDetailsPhotos feature={feature}/>
    </_FeatureDetailsPage>
  );
};