import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { Redirect } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';
import { PartnerForms, PartnerOurShowRooms } from '@/pages/partner/views';
import { currentUserState } from '@/state';

const _LoginRequired = styled.div`
  .login-required-wrapper {
    text-align: center;
  }
`;

export const LoginRequired = () => {
  const [currentUser] = useRecoilState(currentUserState);
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'project' }]);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_LoginRequired>
      <PartnerForms
        page={page}
        title="project"
        showLogin={false}
        defaultTab="register"
      />
    </_LoginRequired>
  );
};