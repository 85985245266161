import { RequestParameter, useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useLocation, useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import {
    pageResources,
    productResources,
    productTypeResources,
    tagResources
} from '@/api';

import { MCollectionProducts, MCollectionProductTypes } from './views';

const _MCollectionPage = styled.div`
`;

interface IMCollectionPageProps {

}

interface IMCollectionPageParams {
  productTypeSlug: string;
}

export const MCollectionPage = (props: React.PropsWithChildren<IMCollectionPageProps>) => {
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);
  // const productTypeId = currentSearchParams.get('productTypeId');
  const { productTypeSlug } = useParams<IMCollectionPageParams>();

  const tagId = currentSearchParams.get('tagId');

  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-commercial' }]);
  const [productTypes] = useResourceAnonymous(productTypeResources.find, [{ type: 'query', parameter: 'for', value: 'commercial' }]);
  const [tags] = useResourceAnonymous(tagResources.find, []);

  const defaultFetchProductParams: RequestParameter[] =[{
    type: 'query',
    parameter: 'for',
    value: 'commercial'
  }, {
      type: 'query',
      parameter: '_sort',
      value: 'displayPriority:desc'
  }];

  const [products, productsFetching, fetchProducts] = useResourceAnonymous(productResources.find, defaultFetchProductParams);
  const productTypeId = productTypes?.find(e => e.slug === productTypeSlug)?.id;

  const refetch = React.useCallback(
    ({ search }) => {
      fetchProducts([
        ...defaultFetchProductParams,
        search && {
          type: 'query',
          value: search,
          parameter: 'name_contains'
        }
      ]);
    },
    []
  );

  React.useEffect(
    () => {
      if (products === undefined) {
        return;
      }

      fetchProducts([
        { type: 'query', parameter: 'tags_in', value: tagId ?? undefined },
        { type: 'query', parameter: 'productType', value: productTypeId ?? undefined },
        { type: 'query', parameter: 'for', value: 'commercial' },
      ]);
    },
    [productTypeId, fetchProducts, tagId]
  );

  if (!pages || !products || !productTypes) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_MCollectionPage>
      <MCollectionProductTypes
        tags={tags}
        productTypes={productTypes}
        currentProductTypeId={productTypeId ?? null}
        defaultCoverFile={page.cover}
        typeFor="commercial"
        currentTagId={tagId}
      />
      <MCollectionProducts
        loading={productsFetching}
        products={products}
        refetch={refetch}
      />
    </_MCollectionPage>
  );
};