import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IFeatureCategory, IFeatureType } from '@/api';
import { text } from '@/translation';

import {
    featuresPageFragment,
    getFeaturesPageUrl
} from '../FeaturesPage.shared';

const _FeatureFilter = styled.div`
  .categories {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    font-weight: bold;
    height: 100px;
    padding-left: 0;
    margin-bottom: 0;
    overflow: auto;
    @media (max-width: 991px) {
      gap: 50px;
      justify-content: unset;
      padding: 0 24px;
      height: 70px;
    }
  }

  .category {
    display: inline-block;
    line-height: 40px;
    border-bottom: 5px solid white;

    &.selected {
      border-color black;
    }
  }

  .heading {
    border-top: 1px solid #C4C4C4;
    padding: 40px;
    @media (max-width: 991px) {
      padding: 20 15px;
    }
  }

  .heading-text {
    font-family: "Copperplate";
    font-weight: bold;
    font-size: 42px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    @media screen and (max-width: 991px) {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  .types {
    flex-flow: wrap;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    padding-left: 0;
    margin-bottom: 0;
    @media screen and (max-width: 991px) {
      height: 50px;
    }
  }

  .type {
    display: inline-block;
    &:not(:first-child) {
      margin-left: 25px;
      @media screen and (max-width: 991px) {
          margin-left: 12px;
      }
    }
    &:not(:first-child) {
      ::before {
        content: " ";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: black;
        display: inline-block;
        margin-right: 25px;
        @media screen and (max-width: 991px) {
          margin-right: 12px;
        }
      }
    }
    
    &.selected {
      font-weight: bold;
    }
  }
`;

interface IFeatureFilterProps {
  readonly featureCategories?: IFeatureCategory[];
  readonly featureTypes?: IFeatureType[];
  readonly activeCategorySlug?: string;
  readonly activeTypeSlug?: string;
  readonly headingText: string;
  readonly description?: string;
}

export const FeatureFilter = (props: React.PropsWithChildren<IFeatureFilterProps>) => {
  if (!props.featureCategories) {
    return null;
  }

  const activeCategory = props.featureCategories.find(o => o.slug == props.activeCategorySlug);
  const featureTypes = props.featureTypes?.filter(o => o.featureCategory.id == activeCategory?.id);

  return (
    <_FeatureFilter id={featuresPageFragment}>
      <ul className="categories">
        {props.featureCategories.map(o => {
          const isSelected = o.slug === props.activeCategorySlug;

          return (
            <li key={o.id} className={classNames('category', { 'selected': isSelected })}>
              <Link to={getFeaturesPageUrl(o.slug) + `#${featuresPageFragment}`} replace={true}>{o.name}</Link>
            </li>
          );
        })}
      </ul>
      <div className='heading'>
        <div className="heading-text">{props.headingText}</div>
        {
          (!!activeCategory && !!featureTypes?.length) && (
            <ul className="types">
              <li className={classNames('type', { 'selected': !props.activeTypeSlug })}>
                <Link to={getFeaturesPageUrl(props.activeCategorySlug) + `#${featuresPageFragment}`} replace={true}>
                  {text('All')}
                </Link>
              </li>
              {featureTypes.map(o => {
                const isSelected = o.slug === props.activeTypeSlug;

                return (
                  <li key={o.id} className={classNames('type', { 'selected': isSelected })}>
                    <Link to={getFeaturesPageUrl(props.activeCategorySlug, o.slug,) + `#${featuresPageFragment}`} replace={true}>{o.name}</Link>
                  </li>
                );
              })}
            </ul>
          )}
      </div>
    </_FeatureFilter>
  );
};