// tslint:disable:strict-string-expressions
// tslint:disable:no-magic-numbers

import * as React from 'react';
import styled from 'styled-components';

import { getFileUrl, ImageSize } from '@/_shared/utils';
import { IFile } from '@/api';

interface IImgWrapperProps {
  readonly oriented?: 'vertical' | 'horizontal';
  readonly ratioX?: number;
  readonly ratioY?: number;
  readonly size?: ImageSize;
  readonly loaded?: boolean;
}

const _ImgWrapperRatio = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ ratioY, ratioX }: IImgWrapperProps) => (ratioY || 1) / (ratioX || 1) * 100}%;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 50%;
    height: ${({ oriented }) => oriented === 'vertical' ? '100%' : 'auto'};
    max-height: ${({ oriented }) => oriented === 'vertical' ? '100%' : 'unset'};
    width: ${({ oriented }) => oriented === 'vertical' ? 'auto' : '100%'};
    max-width: ${({ oriented }) => oriented === 'vertical' ? 'unset' : 'auto'};
    transform: translateX(-50%);
    transition: opacity ease-in  0.3s;
  }
`;

const _ImgWrapper = styled.div`
  position: relative;
  background-color: ${({ loaded }: IImgWrapperProps) => loaded ? '#F8F8F8' : 'white'};

  img {
    transition: opacity ease-in  0.3s;
  }
`;

interface IImgProps extends IImgWrapperProps, React.ImgHTMLAttributes<HTMLElement> {
  readonly file?: Partial<IFile> | string;
  readonly useWrap?: boolean;
  readonly wrapperStyle?: React.CSSProperties;
}

interface IImgState extends Pick<IImgWrapperProps, 'oriented'> {
  readonly loaded: boolean;
}

export class Img extends React.PureComponent<IImgProps, IImgState> {
  public static readonly defaultProps = {
    useWrap: true
  };

  public readonly state: IImgState = {
    loaded: false,
  };

  public render() {
    const { useWrap, file, wrapperStyle, size, ratioY, ratioX, oriented, ...imgProps } = this.props;
    const { loaded } = this.state;

    const src = getFileUrl(file, size);
    const alt = typeof file === 'object' ? file?.name : '';

    const imgElement = (
      <img
        src={src}
        alt={alt}
        onLoad={this.onLoad}
        style={{ opacity: loaded ? 1 : 0 }}
        data-oriented={oriented ?? this.state.oriented}
        {...imgProps}
      />
    );

    if (!useWrap) {
      return imgElement;
    }

    const ratio = this.getImageRatito();

    if (!ratio) {
      return (
        <_ImgWrapper
          className="img-wrapper"
          style={wrapperStyle}
          loaded={loaded}
        >
          {imgElement}
        </_ImgWrapper>
      );
    }
    
    return (
      <_ImgWrapperRatio
        className="img-wrapper"
        style={wrapperStyle}
        ratioX={ratio.ratioX}
        ratioY={ratio.ratioY}
        data-oriented={oriented ?? this.state.oriented}
        oriented={oriented ?? this.state.oriented}
        loaded={loaded}
      >
        {imgElement}
      </_ImgWrapperRatio>
    );
  }

  private readonly getImageRatito = () => {
    const { file, ratioX, ratioY } = this.props;

    if (typeof file === 'string' || ratioX || ratioY) {
      return {
        ratioX,
        ratioY
      };
    }

    if (!file?.aspectRatio) {
      return null;
    }

    const aspectRatio = file?.aspectRatio.split(':');

    return {
      ratioX: +aspectRatio[0],
      ratioY: +aspectRatio[1]
    };
  }

  private readonly onLoad = (e) => {
    const { naturalHeight, naturalWidth } = e.target;
    
    this.setState({
      loaded: true,
      oriented: naturalHeight >= naturalWidth ? 'vertical' : 'horizontal'
    });
  }
}
