import { Resource } from 'libs/restful';

import { IWishlistGroup } from './whislistGroup';

export interface IWishlistItemData {
  readonly for: string;
  readonly productId: string;
  readonly productSlug: string;
  readonly type: string;
}

export interface IWishlistItem {
  readonly id: string;
  readonly isExternal: boolean;
  readonly productName: string;
  readonly description?: string;
  readonly quantity?: number;
  readonly estPrice?: number;
  readonly thumbnail?: string;
  readonly link?: string;
  readonly data?: IWishlistItemData;
  readonly wishlist: string;
  readonly wishlistGroup: IWishlistGroup | string;
  readonly createdAt?: string;
  readonly updatedAt?: string;
}

export const wishlistItemResources = {
  find: new Resource<IWishlistItem, IWishlistItem[]>({
    method: 'GET',
    url: '/customer/wishlistitems'
  }),
  count: new Resource<IWishlistItem, number>({
    method: 'GET',
    url: '/customer/wishlistitems/count'
  }),
  findOne: new Resource<IWishlistItem, IWishlistItem>({
    method: 'GET',
    url: '/customer/wishlistitems/:id'
  }),
  create: new Resource<IWishlistItem, IWishlistItem>({
    method: 'POST',
    url: '/customer/wishlistitems'
  }),
  update: new Resource<IWishlistItem, IWishlistItem>({
    method: 'PUT',
    url: '/customer/wishlistitems/:id'
  }),
  delete: new Resource<IWishlistItem, IWishlistItem>({
    method: 'DELETE',
    url: '/customer/wishlistitems/:id'
  }),
};