import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams, useRouteMatch } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { inspirationTypeResources, pageResources } from '@/api';
import { inspirationCategoryResources } from '@/api/inspirationCategory';

import {
    InspirationCategories,
    InspirationFilter,
    InspirationList,
    InspirationsCover
} from './views';

const _InspirationsPage = styled.div`
  padding-bottom: 100px;
`;

interface InspirationsPagePathParam {
  readonly categorySlug?: string;
  readonly typeSlug?: string;
}

interface IInspirationsPageProps { }

export const InspirationsPage = (props: React.PropsWithChildren<IInspirationsPageProps>) => {
  const pathParams = useParams<InspirationsPagePathParam>();

  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-inspirations-page' }]);
  const [inspirationCategories] = useResourceAnonymous(inspirationCategoryResources.find);
  const [inspirationTypes] = useResourceAnonymous(inspirationTypeResources.find);

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  const selectedCategory = pathParams.categorySlug ? inspirationCategories?.find(o => o.slug === pathParams.categorySlug) : undefined;
  const selectedType = pathParams.typeSlug ? inspirationTypes?.find(o => o.slug === pathParams.typeSlug) : undefined;

  return (
    <_InspirationsPage>
      <InspirationsCover page={page} />
      <InspirationFilter
        headingText="INSPIRATION"
        inspirationCategories={inspirationCategories}
        inspirationTypes={inspirationTypes}
        activeCategorySlug={pathParams.categorySlug}
        activeTypeSlug={pathParams.typeSlug}
      />
      {
        selectedCategory ?
          (
            <InspirationList key={selectedCategory.id + selectedType?.id} category={selectedCategory!} type={selectedType} />
          )
          : (
            <InspirationCategories inspirationCategories={inspirationCategories} />
          )
      }
    </_InspirationsPage>
  );
};
