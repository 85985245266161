import { Resource } from 'libs/restful';

export interface IWishlistItemGroup {
  readonly id: string;
  readonly wishlistItem: string;
  readonly wishlistGroup: string;
  readonly quantity: number;
}

export const wishlistItemGroupResources = {
  find: new Resource<IWishlistItemGroup, IWishlistItemGroup[]>({
    method: 'GET',
    url: '/customer/wishlistitems'
  }),
  count: new Resource<IWishlistItemGroup, number>({
    method: 'GET',
    url: '/customer/wishlistitems/count'
  }),
  findOne: new Resource<IWishlistItemGroup, IWishlistItemGroup>({
    method: 'GET',
    url: '/customer/wishlistitems/:id'
  }),
  create: new Resource<IWishlistItemGroup, IWishlistItemGroup>({
    method: 'POST',
    url: '/customer/wishlistitems'
  }),
  update: new Resource<IWishlistItemGroup, IWishlistItemGroup>({
    method: 'PUT',
    url: '/customer/wishlistitems/:id'
  }),
  delete: new Resource<IWishlistItemGroup, IWishlistItemGroup>({
    method: 'DELETE',
    url: '/customer/wishlistitems/:id'
  }),
};