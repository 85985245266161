import { useResourceLazy } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions } from '@/_shared/components';
import { events } from '@/_shared/events';
import { IUser, userResources } from '@/api';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

import { UserProfileThanksContent } from './user-profile-form';

const _UserProfileForm = styled.div`
  .form-label {
    font-family: "Copperplate";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    text-align: center;
    color: #000000;
  }

  .form-group {
    margin-bottom: 12px;
          .custom-radio {
            display: flex;
            align-items: center;
            label:before, label:after {
              top: 1px;
            }
          }
          input, textarea {
            height: 40px;
            border: 0;
            border-radius: 2px;
          }
          textarea {
            height: 118px;
          }
        }

  .from-wrapper {
    padding: 24px;
  }

  .user-form {
    max-width: 100%;
  }
`;

interface IUserProfileFormProps {

}

export const UserProfileForm = (props: React.PropsWithChildren<IUserProfileFormProps>) => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [updatedUser, , updateUser, updateError] = useResourceLazy(userResources.updateSelf);
  const [formError, setFormError] = React.useState<string>();

  React.useEffect(
    () => {
      if (!updatedUser) {
        return;
      }

      setCurrentUser(updatedUser);
    },
    [updatedUser, setCurrentUser]
  );

  React.useEffect(
    () => {
      if (!updateError) {
        return;
      }

      setFormError(updateError.message ?? 'Error');
    },
    [updateError]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget) as IUser;

      if (formValues.password !== formValues['rePassword']) {
        return void setFormError(textIgnoreCase('Password doesn\'t match'));
      }

      if (formError) {
        setFormError('');
      }

      if (formValues)
        updateUser([
          {
            type: 'path',
            parameter: 'id',
            value: formValues?.id as string
          },
          {
            type: 'body',
            value: {
              ...formValues,
            }
          }
        ]);
    },
    [updateUser]
  );

  return (
    <_UserProfileForm>
      <GlobalModalNoActions isOpen={!!updatedUser}>
        <UserProfileThanksContent />
      </GlobalModalNoActions>
      <div className="form-label">{textIgnoreCase('Account information')}</div>
      <div className="from-wrapper">
        <Form className="user-form" onSubmit={onSubmit}>
          {formError && (<Alert color="danger">{textIgnoreCase(formError)}</Alert>)}
          <input type="hidden" name="id" value={currentUser?.id} />
          <input type="hidden" name="email" value={currentUser?.email} />
          <input type="hidden" name="username" value={currentUser?.username} />
          <FormGroup row>
            <Col>
              <Input type="text" name="displayName" id="displayName" placeholder={textIgnoreCase('Name')} required={true} defaultValue={currentUser?.displayName} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Input type="number" name="phone" id="phone" placeholder={textIgnoreCase('Phone')} required={true} defaultValue={currentUser?.phone} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Input type="email" name="email" id="email" placeholder={textIgnoreCase('Your email address')} defaultValue={currentUser?.email} disabled={true} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Input type="text" name="jobTitle" id="jobTitle" placeholder={textIgnoreCase('Job title')} defaultValue={currentUser?.jobTitle} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Input type="text" name="company" id="company" placeholder={textIgnoreCase('Company')} defaultValue={currentUser?.company} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Input type="text" name="companyWebsite" id="companyWebsite" placeholder={textIgnoreCase('Company website')} defaultValue={currentUser?.companyWebsite} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col>
              <Button color='black'>{textIgnoreCase('UPDATE')}</Button>
            </Col>
            <Col style={{textAlign: 'right'}}>
              <Button color='white' size='sm' tag="a" onClick={() => events.emit('OPEN_CHANGE_PASSWORD_MODAL')}>
                {textIgnoreCase('Change password')}
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </_UserProfileForm>
  );
};