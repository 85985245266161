import { useResourceAnonymous, useResourceLazy } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row
} from 'reactstrap';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions, Img } from '@/_shared/components';
import { WelcomeBack } from '@/_shared/components/domain';
import { IPage, projectRequestResources, settingResources } from '@/api';
import { projectsPagePath } from '@/pages/projects/ProjectsPage.shared';
import { textIgnoreCase } from '@/translation';

import { ProjectRequestThanksContent } from './project-request-form';

const _ProjectRequestForm = styled.div`
  .col {
    padding: 0;
  }
  .col-right {
    position: relative;
    padding: 0;
    .content-visit {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      opacity: 0;
      transition: 0.2s ease-in-out;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1;
      }
      h1 {
        width: 35%;
        text-align: center;
        color: #fff;
        margin-bottom: 25px;
        z-index: 2;
      }
      button {
        padding: 7px 50px;
        text-transform: uppercase;
        z-index: 2;
      }
    }
    &:hover {
      .content-visit {
        opacity: 1;
      }
    }
    .note-flash {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      .flash-icon {
        position: absolute;
        top: 25%;
        right: 10%;
        z-index: 2;
      }
    }
  }
  .col-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .wrapper-col {
      width: 80%;
      .new-project {
        h3 {
          text-transform: uppercase;
        }
        .description {
          margin-top: 17px;
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: #7e7e7e;
            &:first-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  form {
    .form-control {
      margin-bottom: 15px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 19px;
      text-transform: uppercase;
      color: #000000;
      margin-top: 40px;
      font-family: "Copperplate";
      font-weight: bold;
    }
    button {
      margin-top: 5px;
      text-transform: uppercase;
    }
    input {
      background: #E9E9E9;
      border: 0;
    }
  }

  .visit-btn {
    z-index: 1;
  }

  @media (max-width: 991px) {
    .welcome-back {
      padding-left: 0;
    }
    .content-row {
      flex-direction: column-reverse;
    }
    .col-sm-12 {
      padding: 0;
    }
    .col-left {
      .wrapper-col {
        padding: 60px 15px;
        width: 100%;
      }
    }
    .col-right {
      position: relative;
      h1 {
        width: 80%!important;
      }
      .content-visit {
        opacity: 1;
        &:before {
          background-color: rgba(0,0,0,0.4);
        }
        a {
          z-index: 1;
        }
      }
    }
  }
`;

interface IProjectRequestFormProps {
  readonly page: IPage;
}

export const ProjectRequestForm = (
  props: React.PropsWithChildren<IProjectRequestFormProps>
) => {
  const { page } = props;

  const [thanksContentSettings] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'PROJECT_REQUEST_THANKS_CONTENT' }]);
  const [newProjectRequest, requesting, createProjectRequest] = useResourceLazy(projectRequestResources.create);

  React.useEffect(
    () => {
      if (!newProjectRequest) {
        return;
      }
      const form = document.getElementById('partnerProjectRequestForm') as HTMLFormElement;
      form?.reset();
    },
    [newProjectRequest]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget);
      createProjectRequest([{
        type: 'body',
        value: formValues
      }]);
      e.currentTarget.reset();
    },
    [createProjectRequest]
  );

  return (
    <_ProjectRequestForm>
      <GlobalModalNoActions isOpen={!!newProjectRequest}>
        <ProjectRequestThanksContent setting={thanksContentSettings && thanksContentSettings[0]} />
      </GlobalModalNoActions>
      <Container>
        <Row className="content-row" sm={1} lg={2}>
          <Col sm={12} lg={6} className="col-left">
            <div className="wrapper-col">
              <WelcomeBack />
              <div className="new-project">
                <h3>{textIgnoreCase('new project')}</h3>
                <div className="description">
                  <p>{textIgnoreCase('You are not have the projected data')}</p>
                  <p>{textIgnoreCase('Please create data here')}</p>
                </div>
              </div>
              <Form id="partnerProjectRequestForm" onSubmit={onSubmit}>
                <p>{textIgnoreCase('PROJECTS information')}</p>
                <FormGroup>
                  <Input type="text" name="projectName" placeholder={textIgnoreCase('Project name')} />
                </FormGroup>
                <FormGroup>
                  <Input
                    name="constructionSite"
                    placeholder={textIgnoreCase('Construction site')}
                  />
                </FormGroup>
                <FormGroup>
                  <Input name="projectType" placeholder={textIgnoreCase('Type of project')} />
                </FormGroup>
                <Button color="black" disabled={requesting}>{textIgnoreCase('request')}</Button>
              </Form>
            </div>
          </Col>
          <Col sm={12} lg={6} className="col-right">
            {
              page.cover
                ? (<Img file={page.cover} />)
                : <img src="/static/partner/francesca-tosolini-tHkJAMcO3QE-unsplash 1.png" alt="" />
            }

            <div className="content-visit">
              <h1>{textIgnoreCase('3d furniture data')}</h1>
              <Button
                className="visit-btn"
                color="black"
                tag={Link}
                to={projectsPagePath}
              >
                {textIgnoreCase('Visit')}
              </Button>
            </div>
            <div className="note-flash">
              <img src="/static/partner/Vector.png" alt="" />
              <div className="flash-icon">
                <img src="/static/partner/Frame.png" alt="" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </_ProjectRequestForm>
  );
};
