import { isDate } from 'lodash';
import moment from 'moment';

export const getUrlSearchParam = (key: string) => {
    const currentUrlParams = new URLSearchParams(location.search);

    return currentUrlParams.get(key);
};

export const getUrlSearchParams = (key: string) => {
    const currentUrlParams = new URLSearchParams(location.search);

    return currentUrlParams.getAll(key);
};

export const searchParamsObject = <T>(query?: string): T => {
    const currentUrlParams = query ? new URLSearchParams(query) : new URLSearchParams(location.search);
    const searchParamsEntries = currentUrlParams.entries();
    const search = Array.from(searchParamsEntries);
    const result = search.reduce(
        (currentResult, item) => {
            const key = item[0];
            const value = item[1];

            return Object.assign({ [key]: value }, currentResult);
        },
        {}
    );

    return result as T;
};

export const objectToSearchParams = (obj = {}, initSearch?: string) => {
    const newSearch = new URLSearchParams(initSearch);

    for (const key of Object.keys(obj)) {
        if (obj[key]) {
            let value = obj[key];
            if (isDate(value) || moment.isMoment(value)) {
                value = value.toISOString();
            }
            if (Array.isArray(value)) {
                newSearch.delete(key);

                value.forEach(o => {
                    const searchValues = newSearch.getAll(key);
                    if (searchValues.includes(o?.toString())) {
                        return;
                    }

                    newSearch.append(key, <string>o);
                });
            } else {
                newSearch.set(key, <string>value);
            }
        } else {
            newSearch.delete(key);
        }
    }

    return newSearch;
};

export const redirect = (uri) => {
    if (navigator.userAgent.match(/Android/i)) {
        document.location = uri;
    } else {
        window.location.replace(uri);
    }
};

export const replaceRoutePath = (path: string, obj: Record<string, unknown>, searchParams?: URLSearchParams | string) => {
    const replacedPath = Object.keys(obj).reduce(
        (url, key) => {
            const regex = new RegExp(`:${key}?`, 'g');

            return url.replace(regex, obj[key] !== undefined ? String(obj[key]) : '');
        },
        path
    );

    return replacedPath + (searchParams ? searchParams.toString() : '');
};

export const getSubDomain = (host: string, domain: string) => {
    if (host.includes('localhost')) {
        return null;
    }

    return host.replace('.' + domain, '');
};