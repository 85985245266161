import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IGroupsPageSetting, IProduct } from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { getProductUrl } from '@/pages/product/ProductPage.shared';
import { text, textIgnoreCase } from '@/translation';

export const _HomeContentCard = styled.div`
  position: relative;
  overflow: hidden;

  .col-left, .col-right {
    padding: 0;
  }

  .col-reverse {
    flex-direction: row-reverse;
    text-align: right;
  }

  .information {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 36px;
  }

  .btn {
    width: 150px;
    font-weight: 400;
  }

  @media (max-width: 991px) {
    .information {
      padding: 24px 15px 36px 15px;
    }

    .col-reverse {
      flex-direction: unset;
      text-align: unset;
    }
  }
`;

interface IHomeContentCardProps {
  readonly index: number;
  readonly setting: Partial<IGroupsPageSetting>;
}

export const HomeContentCard = (props: React.PropsWithChildren<IHomeContentCardProps>) => {
  const { setting, index } = props;

  return (
    <_HomeContentCard className="product-card">
      <Container>
        <Row className={classNames({ 'col-reverse': index % 2 })}>
          <Col sm={12} lg={6} className="col-left">
            <Img file={setting.valueMedia} />
          </Col>
          <Col sm={12} lg={6} className="col-right">
            <div className='information'>
              <h4 className="product-name">
                {setting.name}
              </h4>
              <div dangerouslySetInnerHTML={{ __html: setting.value! }} />
            </div>
          </Col>
        </Row>
      </Container>
    </_HomeContentCard >
  );
};