import React from 'react';
import { Button, Popover } from 'reactstrap';
import { atom, useRecoilState } from 'recoil';
import styled from 'styled-components';

import { IGroupFeatureProduct } from '@/api';
import {
    getMCollectionProductUrl
} from '@/pages/m-collection-product/MCollectionProductPage.shared';
import { textIgnoreCase } from '@/translation';

const _FeatureProductPoint = styled.div`
  position: absolute;
  .popover-inner {
    .title {
      font-weight: 700;
    }
  }
  
  .dot{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
  }
`;

const featureProductOpenState = atom<string | null>({
  key: 'featureProductOpenState',
  default: null
});

interface IFeatureProductPointProps {
  readonly featureProduct: Partial<IGroupFeatureProduct>;
  readonly index: number;
}

export const FeatureProductPoint = (props: React.PropsWithChildren<IFeatureProductPointProps>) => {
  const [itemToOpen, setTtemToOpen] = useRecoilState(featureProductOpenState);
  const isOpen = itemToOpen === props.featureProduct.id;

  return (
    <_FeatureProductPoint
      style={{ left: `${props.featureProduct.bgPointX}%`, top: `${props.featureProduct.bgPointY}%` }}
    >
      <Button className='dot' color='black' id={'id' + props.featureProduct.id}>
        {props.index}
      </Button>
      <Popover
        popperClassName="border-0"
        placement="bottom"
        isOpen={isOpen}
        target={'id' + props.featureProduct.id}
        toggle={() => {
          if (isOpen) {
            return void setTtemToOpen(null);
          }

          setTtemToOpen(props.featureProduct.id!);
        }}
      >
        <p>
          <strong>
            {props.featureProduct.product?.name}
          </strong>
        </p>
        <Button
          tag="a"
          href={getMCollectionProductUrl(props.featureProduct.product!)}
          color='black'
          style={{ fontWeight: 'normal' }}
        >
          {textIgnoreCase('FIND OUT MORE')}
        </Button>
      </Popover>
    </_FeatureProductPoint>
  );
};