import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem
} from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components/generic/Img';
import { ISlider } from '@/api';

export const _Slider = styled.div`
  text-align: center;
  
  img {
    width: 100%;
    max-width: 100%;
  }

  .img-wrapper {
    transform: scale(1.01);
  }

  .carousel-control {
    &-prev, &-next {
      width: 7%;
    }
  }

  .slider-info-wrapper {
    position: absolute;
    top: 20%;
    left: 50%;
    text-align: left;
    transform: translateX(-50%);

    h3 {
      font-size: 25px;
      line-height: 169.92%;
      color: #000;
      text-transform: uppercase;
    }
    p {
      font-size: 13px;
      text-align: justify;
      line-height: 1.6em;
      padding: 12px;
      background: rgba(0, 0, 0, .7);
      color: #fff;
      max-width: 380px;
    }

    a {
      text-transform: uppercase;
    }
  }

  .carousel-indicators  {
    li {
      width: 13px;
      height: 13px;
      border-radius: 50%;
      &.active {
        background: #000;
      }
    }
  }

  &.mobile-head-slider {
    .carousel-indicators {
      display: none;
    }
    .carousel-control-prev, .carousel-control-next {
      align-items: flex-start;
      padding-top: 20%;
    }
    .carousel-item {
      width: 100%;
      .img-wrapper {
        position: relative;
        img {
          position: absolute;
          top: 0;
          left: 50%;
          height: 100%;
          max-height: 100%;
          width: auto;
          transform: translateX(-50%);
        }
      }
    }

    .slider-info-wrapper {
      position: relative;
      padding: 2rem;
      margin-bottom: 1rem;
      h3 {
        font-size: 18px;
      }
      p {
        background: transparent;
        color: black;
        padding: 0;
      }
    }
    .slider-info {
      p {
        max-width: unset;
      }
    }
  }
`;

interface ISliderProps {
  readonly className?: string;
  readonly slider?: Partial<ISlider>;
}

export const Slider = (props: ISliderProps) => {
  const { slider, className } = props;

  const slides = slider?.slides?.sort((a, b) => {
    return b.createdAt!.localeCompare(a.createdAt!);
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  if (!slides) {
    return null;
  }

  const next = () => {
    if (animating) { return; }
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) { return; }
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) { return; }
    setActiveIndex(newIndex);
  };

  const slideElements = slides
    .sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0))
    .map((item, index) => {

      const isExternalLink = item?.url?.startsWith('http');

      return (
        <CarouselItem
          onExiting={() => { setAnimating(true); }}
          onExited={() => { setAnimating(false); }}
          key={index}
        >
          <Img file={item.image} />
          <div className="container slider-info-wrapper">
            <div className="slider-info">
              <h3>{item.name}</h3>
              <p>{item.content}</p>
              {
                item.url && (
                  isExternalLink
                    ? <a className="btn btn-primary" href={item.url} target="_blank" rel="noreferrer">{item.urlLabel}</a>
                    : <Link className="btn btn-primary" to={item.url}> {item.urlLabel} </Link>
                )
              }
            </div>
          </div>
        </CarouselItem>
      );
    });

  return (
    <_Slider className={className}>
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        enableTouch={true}
      >
        <CarouselIndicators items={slideElements} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slideElements}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
      </Carousel>
    </_Slider>
  );
};
