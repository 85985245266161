import React from 'react';
import styled from 'styled-components';

import { textIgnoreCase } from '@/translation';

const _UserProfileThanksContent = styled.div`
  width: 630px;

  .thanks-img {
    height: 288px;
    img {
      max-height: 100%;
    }
  }
  .thanks-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .thanks-content-title {
    font-family: "Copperplate";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 45px;
    color: #000000;
    margin-top: 24px;
    margin-bottom: 12px;
    text-align: center;
  }
  .thanks-content-message {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #7E7E7E;
    margin-bottom: 24px;
    text-align: center;
  }
  .thanks-content-button {
    margin-bottom: 12px;
  }

  @media(max-width: 991px) {
    width: 246px;
    margin: 0 auto;
  }
`;

interface IUserProfileThanksContentProps {

}

export const UserProfileThanksContent = (props: React.PropsWithChildren<IUserProfileThanksContentProps>) => {
  return (
    <_UserProfileThanksContent>
      <div className="thanks-content">
        <div className="thanks-content-title">{textIgnoreCase('Updated')}!</div>
        <p className="thanks-content-message">
          {textIgnoreCase('All your information has been set')}
        </p>
      </div>
    </_UserProfileThanksContent>
  );
};