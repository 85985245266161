import React from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { useAccessToken } from '@/hooks';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

import { UserAvatarField } from './UserAvatarField';

const _UserProfileLayout = styled.div`
  padding: 60px 0;
  max-width: 1024px;
  margin: 0 auto;
  .layout-nav {
    width: 100%;
    background-color: #F0F0F0;
    padding: 24px;
    margin-bottom: 24px;
  }

  .nav-item {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .nav-link {
    color: black;
    padding-left: 0;
  }

  .user-info {
    padding-left: 24px;
  }
  .user-info-username {
    font-size: 16px;  
  }

  .active {
    font-weight: bold;
  }
  .logout-link {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #767676;
  }
  .logout-icon {
    width: 45px;
    margin-left: 6px;
  }
  .profile-icon {
    width: 226px;
  }
  .profile-info-name {
    font-family: Copperplate;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }
  .profile-icon-wrapper {
    max-width: 226px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    background: #F1F1F1;
  }
`;

interface IUserProfileLayoutProps {

}

export const UserProfileLayout = (props: React.PropsWithChildren<IUserProfileLayoutProps>) => {
  const { children } = props;

  const [, setAccessToken] = useAccessToken();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  if (currentUser == undefined) {
    return null;
  }

  if (!currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <_UserProfileLayout>
      <Container>
        <Row>
          <Col sm={12} lg={6} className="mb-5">
            <div className="text-center">
              <div className="mb-5 profile-icon-wrapper">
                {
                  currentUser.avatar
                    ? <Img file={currentUser.avatar} className="profile-icon" ratioX={1} ratioY={1} />
                    : <img className="profile-icon" src="/static/assets/profile.png" alt="" />
                }

              </div>
              <div className="mb-5">
                <p className="profile-info-name">{currentUser.displayName}</p>
              </div>
              <div className="mb-5">
                <UserAvatarField />
              </div>
              <div>
                <a
                  className="logout-link"
                  href="/"
                  onClick={() => {
                    setCurrentUser(undefined);
                    setAccessToken(undefined);
                  }}
                >
                  <img className="logout-icon" src="/static/assets/logout-icon.png" alt="" /> {textIgnoreCase('Logout')}
                </a>
              </div>
            </div>
          </Col>
          <Col sm={12} lg={6}>
            {children}
          </Col>
        </Row>
      </Container>
    </_UserProfileLayout >
  );
};