import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared';
import { IProject } from '@/api';
import {
    projectDetailPagePath
} from '@/pages/project-detail/ProjectDetailPage.shared';
import {
    projectRequestPagePath
} from '@/pages/project-request/ProjectRequestPage.shared';
import { textIgnoreCase } from '@/translation';

import { ProjectCard } from './project-list';

const _ProjectList = styled.div`
  /** Styles here */
  .col-lg-6 {
    margin-bottom: 30px;
  }
`;

interface IProjectListProps {
  readonly projects?: IProject[];
}

interface IProjectListState {

}

export class ProjectList extends React.Component<
  IProjectListProps,
  IProjectListState> {
  public constructor(props: IProjectListProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { projects } = this.props;

    const newProject = projects?.find(o => o.slug === 'new-project');

    if (!projects) {
      return null;
    }

    if (!projects.length) {
      return <p className="no-content">{textIgnoreCase('Không tìm thấy dữ liệu')}...</p>;
    }

    return (
      <_ProjectList>
        <Container>
          <Row>
            {projects.map((project) => {
              if (project === newProject) {
                return null;
              }
              
              const projectDetailUrl = replaceRoutePath(projectDetailPagePath, { projectId: project.id });

              return (
                <Col key={project.id} sm={12} lg="6">
                  <ProjectCard project={project} projectDetailUrl={projectDetailUrl} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </_ProjectList>
    );
  }
}
