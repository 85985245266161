import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared/utils';
import { cataloguePagePath } from '@/pages/catalogue/CataloguePage.shared';
import { contentPagePath } from '@/pages/content-page';
import { priceListPagePath } from '@/pages/download-list/PriceListPage.shared';
import { textIgnoreCase } from '@/translation';

import {
    FooterSocialList,
    NewsLetterSignupForm
} from './default-layout-footer';

interface IDefaultLayoutFooterProps {

}
const _DefaultLayoutFooter = styled.footer`
  background: #232323;
  display : flex;
  flex-direction : column;
  color  : #ffffff;
    .footer-container {
      overflow: hidden;
      flex : 1;
      padding  : 56px 0 10px 0;
      .col-right{
        padding-left: 100px;
        form {
        .form-control {
          width :400px;
        }
      }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        &.list-socical{
          display : flex;
          align-items : center;
          margin-top : 25px;
        }
        li{
          margin-bottom: 12px;
          margin-right: 24px;
        }
        a {
          height : 20px;
          margin-bottom : 10px;
          display : flex;
          align-items: center;
          font-size: 13px;
          img {
            height: 18px;
            width: auto;
            filter: contrast(5);
          }
        }
      }
      h3 {
        font-size : 18px;
        text-transform : uppercase;
        margin-bottom : 10px;
      }
      a {
        color : #ffffff;
      }
    }
  
    .footer-side {
        height: 36px;
        background: #2B2B2B;
    }

    .menu-footer {
      margin-bottom: 20px;
    }

    @media (max-width :991px) {
      .footer-container {
        padding:50px 50px;
        flex : 1;
        .menu-footer{
          margin-bottom: 40px;
        }
        .col-right{
          padding-left: 15px;
          form {
            .form-control {
              max-width :100%;
              height: 38px;

            }
            .button-submmit{
              padding: 8px 20px;
            }
          }
        }
      ul {  
        margin: 0;
        padding: 0;
        list-style: none;
        &.list-socical{
          display : flex;
          align-items : center;
          justify-content: unset;
          margin-top : 25px;
          li {
            margin-right: 30px;
            i {
              font-size: 20px;
            }
          }
        }
        li{
          margin-bottom: 10px;
        }
        a {
          height : 20px;
          margin-bottom : 10px;
          display : flex;
          align-items: center;
          font-size: 13px;
          i {
            font-size: 27px;
            line-height: 35px;
          }
        }
      }
      h3 {
        font-size : 18px;
        text-transform : uppercase;
        margin-bottom : 20px;
      }
      a {
        color : #ffffff;
      }
    }
  }
`;

export const DefaultLayoutFooter = (props: React.PropsWithChildren<IDefaultLayoutFooterProps>) => {
  const location = useLocation();

  return (
    <_DefaultLayoutFooter>
      <Container className="footer-container">
        <Row>
          <Col lg={6}>
            <Row>
              <Col md={6} className='menu-footer'>
                <h3>
                  {textIgnoreCase('our support')}
                </h3>
                <ul>
                  <li>
                    <Link to={replaceRoutePath(contentPagePath, { pageSlug: 'faq' })}>
                      {textIgnoreCase('FAQ')}
                    </Link>
                  </li>
                  <li>
                    <Link to={replaceRoutePath(contentPagePath, { pageSlug: 'clean-and-care' })}>
                      {textIgnoreCase('Clean & Care')}
                    </Link>
                  </li>
                  <li>
                    <Link to={replaceRoutePath(contentPagePath, { pageSlug: 'terms-and-conditions' })}>
                      {textIgnoreCase('Terms & Conditions')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact">
                      {textIgnoreCase('Contact')}
                    </Link>
                  </li>

                  <li>
                    <Link to="/about-us">
                      {textIgnoreCase('About us')}
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col md={6} className='menu-footer'>
                <h3>
                  {textIgnoreCase('follow us')}
                </h3>
                <FooterSocialList />
              </Col>
              <Col md={6} className='menu-footer'>
                <h3>
                  {textIgnoreCase('download')}
                </h3>
                <ul>
                  <li>
                    <Link to={cataloguePagePath}>
                      {textIgnoreCase('CATALOGUE')}
                    </Link>
                  </li>
                  <li>
                    <Link to={priceListPagePath}>
                      {textIgnoreCase('PRICE LIST')}
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col className='col-right' lg={6}>
            <h3>
              {textIgnoreCase('newsletter sign up')}
            </h3>
            <p>
              {textIgnoreCase('Sign up to our newsletter and get exclusive design novelties straight to your inbox!')}
            </p>
            <NewsLetterSignupForm key={location.pathname} />
          </Col>
        </Row>
      </Container >
      <div className='footer-side'></div>
    </_DefaultLayoutFooter >
  );
};