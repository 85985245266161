import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { getPageSettingFile, getPageSettingValues } from '@/_shared';
import { Img } from '@/_shared/components';
import { IPage } from '@/api';
import { textIgnoreCase } from '@/translation';

const _PartnerOurShowRooms = styled.div`
  .col-partner {
    padding  : 0;
    display : flex;
    justify-content : center;
    flex-direction: column;
    border-top: 0.5px solid #C4C4C4;
    border-right: 0.5px solid #C4C4C4;
    border-bottom: 0.5px solid #C4C4C4;
    align-items: center;
    &.title {
        h3{
          display:block;
          font-size: 24px;
        }
        .title-mobile{
          display : none;
        }
    }
    .infomataion{
      padding: 0 12px;
      margin-bottom: 30px;
      h4 {
        font-size: 14px;
        margin-bottom : 20px;
        font-family: 'Roboto';
        text-transform: uppercase;
      }
      ul {
        list-style : none;
        margin : 0;
        padding : 0;
        li{
          margin-bottom : 10px;
          i {
            margin-right : 10px;
          }
        }
      }
    }
  }
  @media (max-width : 992px){
    .col-partner {
      padding: 0 20px;
      border: none;
      justify-content: start;
      align-items: flex-start;
      &.title {
        border-top: 0.5px solid #C4C4C4;
        padding: 20px 15px;
        h3{
          display:none;
        }
        .title-mobile{
          display : block;
          font-size : 24px;
        }
      }
      &.bg{
        padding: 0 0;
      }
      .infomataion{
        padding: 0;
        margin-bottom: 30px;
        h4 {
          margin-bottom : 20px;
          font-family: 'Roboto';
          font-size : 14px;
        }
        ul {
          list-style : none;
          margin : 0;
          padding : 0;
          li{
            margin-bottom : 10px;
            i {
              margin-right : 10px;
            }
          }
        }
      }
    } 
  }
  @media (max-width : 575px){
    .col-sm-6  {
      padding : 20px 15px;
      &:last-child{
        padding :0;
      }
      .infomataion{
        padding: 0;
        margin-bottom: 30px;
        h3 {
          margin-bottom : 20px;
         
        }
        ul {
          list-style : none;
          margin : 0;
          padding : 0;
          li{
            margin-bottom : 10px;
            i {
              margin-right : 10px;
            }
          }
        }
      }
    } 
  }
`;

interface IPartnerOurShowRoomsProps {
  readonly page: IPage;
}

export const PartnerOurShowRooms = (props: React.PropsWithChildren<IPartnerOurShowRoomsProps>) => {
  const { page } = props;

  const ourShowRooms = getPageSettingValues(page, 'OUR_SHOWROOM_SYSTEM');
  const ourShowRoomCover = getPageSettingFile(page, 'OUR_SHOWROOM_SYSTEM_COVER');

  return (
    <_PartnerOurShowRooms>
      <Container>
        <Row>
          <Col className='col-partner title' lg={3}>
            <div>
              <h3 className="" dangerouslySetInnerHTML={{ __html: textIgnoreCase('Our<br />showroom<br />system<br />') }} />
              <h3 className='title-mobile'>
                {textIgnoreCase('Our SHOWROOM system')}
              </h3>
            </div>
          </Col>
          <Col className='col-partner' lg={3}>
            {ourShowRooms?.map((htmlCode, i) => {
              if (i % 2) {
                return null;
              }

              return (
                <div key={i} className='infomataion' dangerouslySetInnerHTML={{ __html: htmlCode! }} />
              );
            })}
          </Col>
          <Col className='col-partner' lg={3}>
            {ourShowRooms?.map((htmlCode, i) => {
              if (!(i % 2)) {
                return null;
              }

              return (
                <div key={i} className='infomataion' dangerouslySetInnerHTML={{ __html: htmlCode! }} />
              );
            })}
          </Col >
          <Col className='col-partner bg' lg={3}>
            {
              ourShowRoomCover
                ? <Img file={ourShowRoomCover} />
                : <img src="/static/partner/Rectangle 4.2.png" alt="" />
            }
          </Col>
        </Row >
      </Container >
    </_PartnerOurShowRooms >
  );
};