import * as React from 'react';
import { Collapse } from 'reactstrap';
import styled from 'styled-components';

import { events } from '@/_shared/events';

const _DesignToolCollapse = styled.div`
  padding-right: 12px;
  padding-left: 12px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid #C4C4C4;
  }

  .label {
    font-weight: bold;
    font-size: 14px;
    font-family: "Copperplate";
    line-height: 130%;
    text-align: justify;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #959595;
    display: block;
    padding: 14px 0 14px 0;
    transition: all .3s;
  }

  .icon {
    position: absolute;
    top: 16px;
    right: 12px;
    height: 18px;
    width: 18px;
    img {
      width: 18px;
    }
  }

  &.isOpen {
    .label {
      color: #000;
    }
  }
`;

interface IDesignToolCollapseProps {
  readonly defaultOpen?: boolean;
  readonly icon?: string;
  readonly label: string;
}

export class DesignToolCollapse extends React.PureComponent<IDesignToolCollapseProps> {
  public readonly state = {
    isOpen: false
  };

  public componentDidMount() {
    events.addListener('DESIGN_TOOL_COLLAPE_TOGGLE', this.onToggleEvent);
    if (this.props.defaultOpen) {
      events.emit('DESIGN_TOOL_COLLAPE_TOGGLE');
      this.setState({
        isOpen: true
      });
    }
  }

  public componentWillUnmount() {
    events.removeListener('DESIGN_TOOL_COLLAPE_TOGGLE', this.onToggleEvent);
  }

  public render() {
    const { label, icon } = this.props;
    const { isOpen } = this.state;

    return (
      <_DesignToolCollapse className={isOpen ? 'isOpen' : ''}>
        <span className="label" onClick={this.onToggle}>
          {label}
          <span className="icon">
            <img
              src={icon || (isOpen ? '/static/assets/icon-subtract.png' : '/static/assets/icon-plus.png')}
              alt="io"
            />
          </span>
        </span>
        <Collapse isOpen={isOpen}>
          {this.props.children}
        </Collapse>
      </_DesignToolCollapse>
    )
  }

  private readonly onToggle = () => {
    events.emit('DESIGN_TOOL_COLLAPE_TOGGLE');
    this.setState({ isOpen: !this.state.isOpen });
  }

  private readonly onToggleEvent = () => {
    this.setState({ isOpen: false });
  }
}
