import React from 'react';
import styled from 'styled-components';

import { IPage } from '@/api';

const _AboutContent = styled.div`
  position: relative;
  max-width: 70%;
  flex-flow: column;
  justify-content: center;
  height: calc(100vh - 75px);
  margin: 0 auto;
  overflow: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  h4 {
    position: relative;
    width: 206px;
    font-family: Copperplate;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 31px;
    text-transform: uppercase;
    color: #000000;
    padding-bottom: 16px;
    margin-bottom: 38px;
    line-height: 40px;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      background-color: #000;
      width: 32%;
      bottom: 0;
      left: 0;
    }
  }
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    color: #000000;
    width: 84%;
  }
`;
interface IAboutContentProps {
  readonly page: IPage;
}

export const AboutContent = (
  props: React.PropsWithChildren<IAboutContentProps>
) => {
  const { page } = props;

  return (
    <_AboutContent>
      <div dangerouslySetInnerHTML={{ __html: page.content! }} />
    </_AboutContent>
  );
};
