import { useResourceAnonymous, useResourceAnonymousLazy } from 'libs/restful';
import React from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { getUrlSearchParam } from '@/_shared';
import { PageLoading } from '@/_shared/components';
import { modelResources, productResources } from '@/api';
import { currentUserState } from '@/state';

import { MCollectionFileRequest } from './views';

const _MCollectionFileRequestPage = styled.div`
`;

interface IMCollectionFileRequestPageProps {

}

export const MCollectionFileRequestPage = (props: React.PropsWithChildren<IMCollectionFileRequestPageProps>) => {
  const [currentUser] = useRecoilState(currentUserState);

  const productId = getUrlSearchParam('productId');
  const requestType = getUrlSearchParam('requestType');
  const modelSlug = getUrlSearchParam('modelSlug');

  const [product] = useResourceAnonymous(productResources.findOne, [{ type: 'path', parameter: 'id', value: productId! }]);
  const [models, , request, ] = useResourceAnonymousLazy(modelResources.find);

  const displayFiles = !!currentUser && requestType === '3d-files';

  React.useEffect(
    () => {
      if (displayFiles) {
        request([{ type: 'query', parameter: 'slug', value: modelSlug }]);
      }
    },
    [displayFiles, modelSlug, request]
  );

  if (!product) {
    return <PageLoading />;
  }

  const model = models && models[0];
  
  return (
    <_MCollectionFileRequestPage>
      <MCollectionFileRequest
        product={product}
        requestType={requestType!}
        displayFiles={displayFiles}
        model={model}
      />
    </_MCollectionFileRequestPage>
  );
};