import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { productResources, settingResources } from '@/api';

import { MCollectionReallifePhotos } from '../m-collection-product/views';
import {
    MCollectionIllutrationPhotos
} from '../m-collection-product/views/MCollectionIllutrationPhotos';
import { ProductDetail, ProductPhotos } from './views';

const _ProductPage = styled.div`

`;

interface ProductPageParams {
  readonly productSlug: string;
}

export const ProductPage = () => {
  const { productSlug } = useParams<ProductPageParams>();

  const [products] = useResourceAnonymous(productResources.find, [{ type: 'query', parameter: 'slug', value: productSlug }]);
  const [defaultProductFeaturePhotoSetting] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'DEFAULT_PRODUCT_FEATURE_PHOTO' }]);

  if (!products) {
    return <PageLoading />;
  }

  const product = products[0];

  const defaultProductFeaturePhoto = defaultProductFeaturePhotoSetting?.length
    ? defaultProductFeaturePhotoSetting[0].valueMedia
    : undefined;

  return (
    <_ProductPage>
      <ProductPhotos product={product} defaultProductFeaturePhoto={defaultProductFeaturePhoto} />
      <ProductDetail product={product} defaultProductFeaturePhoto={defaultProductFeaturePhoto} />
      {
        (!!product.ReallifePhotos?.length || !!product.illustrationsPhotos?.length) && (
          <Container>
            <div className="diviner" />
          </Container>
        )
      }
      {!!product.ReallifePhotos?.length && (
        <MCollectionReallifePhotos
          product={product}
          photos={product.ReallifePhotos}
        />
      )}
      {!!product.illustrationsPhotos?.length && (
        <MCollectionIllutrationPhotos
          productName={product.name}
          photos={product.illustrationsPhotos}
        />
      )}
    </_ProductPage>
  );
};