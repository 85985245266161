
import { Resource } from 'libs/restful';

import { IFile } from './';

export interface IInspirationCategory {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug?: string;
  readonly cover?: IFile;
}

export const inspirationCategoryResources = {
  find: new Resource<IInspirationCategory, IInspirationCategory[]>({
    method: 'GET',
    url: '/admin/inspirationcategories'
  }),
  count: new Resource<IInspirationCategory, number>({
    method: 'GET',
    url: '/admin/inspirationcategories/count'
  }),
  findOne: new Resource<IInspirationCategory, IInspirationCategory>({
    method: 'GET',
    url: '/admin/inspirationcategories/:id'
  })
};
