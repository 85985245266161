import * as React from 'react';
import styled from 'styled-components';

import { CommentItem } from '@/_shared/components';
import { IComment } from '@/api';

const _ProjectProductCommentContents = styled.div`
  /** Styles here */
  padding-bottom: 30px;
  margin-bottom: 50px;
  border-bottom: 0.5px solid #C4C4C4;

  @media (max-width: 991px) {
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
`;

interface IProjectProductCommentContentsProps {
  readonly comments: IComment[];
  readonly refetch: () => void;
}

interface IProjectProductCommentContentsState {

}

export class ProjectProductCommentContents extends React.Component<
  IProjectProductCommentContentsProps,
  IProjectProductCommentContentsState> {
  public constructor(props: IProjectProductCommentContentsProps) {
    super(props);
    this.state = {

    };
  }

  public render() {
    const { comments, refetch } = this.props;

    return (
      <_ProjectProductCommentContents>
        {comments.map((comment) => {
          return (
            <CommentItem
              key={comment.id}
              comment={comment}
              onDeleted={() => {
                refetch();
              }}
            />
          );
        })}
      </_ProjectProductCommentContents>
    );
  }
}
