import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { IWishlist, IWishlistGroup } from '@/api';
import { _WishlistGroups } from '@/pages/wishlist-detail/views/WhislistGroup';
import {
    wishlistItemsState
} from '@/pages/wishlist-detail/WishlistDetailPage.shared';
import { textIgnoreCase } from '@/translation';

interface IWishlistGroupsProps {
  readonly wishlist: IWishlist;
  readonly onGroupSelected: (group: IWishlistGroup | null | undefined) => void;
}

export const WishlistGroups = (props: React.PropsWithChildren<IWishlistGroupsProps>) => {

  const [wishlistItems] = useRecoilState(wishlistItemsState);

  const [seletedWishlistGroup, setSelectedWishlistGroup] = React.useState<IWishlistGroup | null | undefined>(undefined);

  useEffect(() => {
    props.onGroupSelected(seletedWishlistGroup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onGroupSelected, seletedWishlistGroup]);

  return (
    <_WishlistGroups>
      <div className="header">Groups</div>
      <div className="wishlist-group-wrapper">
        <div
          className={classNames('wishlist-group', { 'selected': seletedWishlistGroup === undefined })}
          onClick={() => setSelectedWishlistGroup(undefined)}
        >
          {textIgnoreCase('All')} ({wishlistItems?.length})
        </div>
        <div
          className={classNames('wishlist-group', { 'selected': seletedWishlistGroup === null })}
          onClick={() => setSelectedWishlistGroup(null)}
        >
          {textIgnoreCase('Not assigned')} ({wishlistItems?.filter(o => !o.wishlistGroup).length})
        </div>
        {
          props.wishlist.wishlistGroups?.map(wishlistGroup => {
            const wishlistGroupItems = wishlistItems?.filter(wishlistItem => ((wishlistItem.wishlistGroup as IWishlistGroup)?.id ?? wishlistItem.wishlistGroup) === wishlistGroup.id).length;

            return (
              <div key={wishlistGroup.id} className={classNames('wishlist-group', { 'selected': seletedWishlistGroup === wishlistGroup })}>
                <div className="wishlist-group-name" onClick={() => setSelectedWishlistGroup(wishlistGroup)}>
                  {wishlistGroup.name} ({wishlistGroupItems})
                </div>
              </div>
            );
          })
        }
      </div>
    </_WishlistGroups>
  );
};