
import { Resource } from 'libs/restful';

import { IFile } from './file';

export interface ISetting {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name?: string;
  readonly name_en?: string;
  readonly group?: string;
  readonly value?: string;
  readonly value_en?: string;
  readonly valueMedia?: Partial<IFile>;
  readonly valueMedias?: Partial<IFile>[];
}

export const settingResources = {
  find: new Resource<ISetting, ISetting[]>({
    method: 'GET',
    url: '/admin/settings'
  }),
  count: new Resource<ISetting, number>({
    method: 'GET',
    url: '/admin/settings/count'
  }),
  findOne: new Resource<ISetting, ISetting>({
    method: 'GET',
    url: '/admin/settings/:id'
  }),
  create: new Resource<ISetting, ISetting>({
    method: 'POST',
    url: '/admin/settings'
  }),
  update: new Resource<ISetting, ISetting>({
    method: 'PUT',
    url: '/admin/settings/:id'
  }),
  delete: new Resource<ISetting, ISetting>({
    method: 'DELETE',
    url: '/admin/settings/:id'
  }),
};
