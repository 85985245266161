import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { Redirect } from 'react-router';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { pageResources } from '@/api';
import { currentUserState } from '@/state';

import { PartnerForms, PartnerOurShowRooms } from './views';

const _PartnerPage = styled.div`
`;

interface IPartnerPageProps {

}

export const PartnerPage = (props: React.PropsWithChildren<IPartnerPageProps>) => {
  const [currentUser] = useRecoilState(currentUserState);
  const [pages] = useResourceAnonymous(pageResources.find, [{ type: 'query', parameter: 'slug', value: 'new-partner' }]);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  if (!pages) {
    return <PageLoading />;
  }

  const page = pages[0];

  return (
    <_PartnerPage>
      <PartnerForms page={page} showLogin={true} />
      <PartnerOurShowRooms page={page} />
    </_PartnerPage>
  );
};