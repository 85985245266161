import { getAllItemFormCart } from './getAllItemFormCart';
import { setOrderItemQuantity } from './setOrderItemQuantity';
import { ICartItem } from './Types';

export const pushItemToCart = (orderItem: Partial<ICartItem>) => {
  if (!('localStorage' in window)) {
    throw new Error('(pushItemToCart) localStorage not found in window');
  }

  if (!orderItem.quantity) {
    throw new Error('(pushItemToCart) orderItem quantity must not be null');
  }

  const cartItems = getAllItemFormCart();
  const existingIndex = cartItems.findIndex(o => o.productId === orderItem.productId);

  if (existingIndex >= 0) {
    const newItem = setOrderItemQuantity(orderItem, (orderItem.quantity || 1));
    cartItems[existingIndex] = newItem;
  } else {
    cartItems.push(orderItem);
  }

  window.localStorage.setItem(
    'CART',
    JSON.stringify(cartItems)
  );
};
