import replace from 'lodash/replace';

export const roundTo = (value: number, digits: number = 0) => {
  // tslint:disable-next-line:no-magic-numbers
  const multiplicator = Math.pow(10, digits) || 1;

  const result = parseFloat((value * multiplicator).toFixed(11));
  const test = (Math.round(result) / multiplicator);

  return +test.toFixed(digits);
};

interface IFormatCurrencyParam {
  readonly amount: number;
  readonly rate: number;
  readonly sourceCurrency: string;
}

export const formatCurrency = (value?: number | IFormatCurrencyParam) => {
  if (!value) {
      return '0';
  }

  let amount: number;
  let sourceCurrency: string;
  let rate: number;

  let formated = '';

  if (typeof value === 'number') {
      amount = Math.round(value);
      sourceCurrency = '';
      rate = 1;
  } else {
      amount = Math.round(value.amount);
      sourceCurrency = value.sourceCurrency;
      rate = value.rate;
  }

  if (!amount) {
      formated = `0 ${sourceCurrency}`;
  }

  if (sourceCurrency) {
      amount = roundTo(amount / rate, 1);
  } else {
      sourceCurrency = '';
  }

  formated = `${replace(String(amount), /(\d)(?=(\d{3})+(?!\d))/g, '$1,')} ${sourceCurrency}`;

  return formated.trim();
};
