import { Resource } from 'libs/restful';

export interface IAddress {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly contactName: string;
  readonly contactPhone: string;
  readonly contactEmail: string;
  readonly street: string;
}

export const addressResources = {
  find: new Resource<IAddress, IAddress[]>({
    method: 'GET',
    url: '/customer/addresses'
  }),
  count: new Resource<IAddress, number>({
    method: 'GET',
    url: '/customer/addresses/count'
  }),
  findOne: new Resource<IAddress, IAddress>({
    method: 'GET',
    url: '/customer/addresses/:id'
  }),
  create: new Resource<IAddress, IAddress>({
    method: 'POST',
    url: '/customer/addresses'
  }),
  update: new Resource<IAddress, IAddress>({
    method: 'PUT',
    url: '/customer/addresses/:id'
  }),
  delete: new Resource<IAddress, IAddress>({
    method: 'DELETE',
    url: '/customer/addresses/:id'
  }),
};
