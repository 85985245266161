import classNames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IOutdoorCollection } from '@/api/outdoorcollections';

export const _OutdoorContentCard = styled.div`
  position: relative;
  overflow: hidden;

  .col-left, .col-right {
    padding: 0;
  }

  .col-right {
    .product-name {
      margin-bottom: 0px;
    }

    .product-description {
      text-transform: uppercase;
    }
  }

  .col-reverse {
    flex-direction: row-reverse;
    text-align: right;
  }

  .information {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 36px;
  }

  .btn {
    width: 150px;
    font-weight: 400;
  }

  @media (max-width: 991px) {
    .information {
      padding: 24px 15px 36px 15px;
    }

    .col-reverse {
      flex-direction: unset;
      text-align: unset;
    }
  }
`;

interface IOutdoorContentCardProps {
  readonly index: number;
  readonly outdoorcollection: IOutdoorCollection
}

export const OutdoorContentCard = (props: React.PropsWithChildren<IOutdoorContentCardProps>) => {
  const { outdoorcollection, index } = props;

  return (
    <_OutdoorContentCard className="product-card">
      <Container>
        <Row className={classNames({ 'col-reverse': index % 2 })}>
          <Col sm={12} lg={6} className="col-left">
            <Img file={outdoorcollection.PhotoRepresent} />
          </Col>
          <Col sm={12} lg={6} className="col-right">
            <div className='information'>
              <h4 className="product-name">
                {outdoorcollection.Name}
              </h4>
              <div dangerouslySetInnerHTML={{ __html: outdoorcollection.content! }} />
            </div>
          </Col>
        </Row>
      </Container>
    </_OutdoorContentCard >
  );
};