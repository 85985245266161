import { useResourceAnonymous, useResourceLazy } from 'libs/restful';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { getHtmlFormValue } from '@/_shared';
import { GlobalModalNoActions } from '@/_shared/components';
import { customerRequestResources, IProduct, settingResources } from '@/api';
import {
    privacyPolicyPagePath
} from '@/pages/privacy-policy/PrivacyPolicyPage.shared';
import { currentUserState } from '@/state';
import { textIgnoreCase } from '@/translation';

import {
    MCollectionFileRequestFormThanks
} from './m-collection-file-request-form';

export const _MCollectionFileRequestForm = styled.div`
      .form-contact {
        padding: 0 20px;
        .country-col ,.Project-reference-col {
          padding: 0px 15px 0 15px;
        }
        .Postal-code-col  ,.Job-title-col {
          padding: 0px 15px 0 0;
        }
        .wraper-check{
          margin-bottom:30px;
        }
        @media (min-width: 1024px) {
          padding: 0 60px;
        }
        @media (max-width: 800px) {
          margin-bottom: 30px;
        }
        .title {
          margin-bottom: 25px;
          font-family: Copperplate;
          font-weight: 600;
          font-size: 30px;
          line-height: 31px;
          text-transform: uppercase;
          color: #000000;
        }
        .form-group {
          .custom-radio {
            display: flex;
            align-items: center;
            label:before, label:after {
              top: 1px;
            }
          }
          input, textarea {
            height: 40px;
            background: #F1F1F1;
            border: 0;
            border-radius: 2px;
          }
          textarea {
            height: 118px;
          }
        }
        button {
          width: 146px;
          height: 40px;
          font-weight: 500;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 0.03em;
          color: #FFFFFF;
          background: #000000;
        }
      }

  @media (max-width : 992px) {
      .form-contact {
        .country-col ,.Project-reference-col {
          padding: 0 15px;
        }
        .Postal-code-col  ,.Job-title-col {
          padding: 0 15px;
        }
      }
  }
`;

interface IMCollectionFileRequestFormProps {
  readonly requestTypeTitle: string;
  readonly requestType: string;
  readonly product?: IProduct;
}

export const MCollectionFileRequestForm = (props: React.PropsWithChildren<IMCollectionFileRequestFormProps>) => {
  const { product, requestType, requestTypeTitle } = props;

  const [currentUser] = useRecoilState(currentUserState);

  const [thanksContentSettings] = useResourceAnonymous(settingResources.find, [{ type: 'query', parameter: 'name', value: 'QUOTE_REQUEST_THANKS_CONTENT' }]);
  const [newCustomerRequest, loading, sendRequest] = useResourceLazy(customerRequestResources.create);

  React.useEffect(
    () => {
      if (!newCustomerRequest) {
        return;
      }
      const form = document.getElementById('newCustomerRequestForm') as HTMLFormElement;
      form?.reset();
    },
    [newCustomerRequest]
  );

  const onSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formValues = getHtmlFormValue(e.currentTarget);
      sendRequest([{
        type: 'body',
        value: {
          ...formValues,
          requestType: requestType,
          productId: product?.id,
          productName: product?.name
        }
      }]);
    },
    [requestType, product?.id, product?.name, sendRequest]
  );

  return (
    <_MCollectionFileRequestForm>
      <GlobalModalNoActions isOpen={!!newCustomerRequest}>
        <MCollectionFileRequestFormThanks setting={thanksContentSettings && thanksContentSettings[0]} />
      </GlobalModalNoActions>
      <Form id="newCustomerRequestForm" className='form-contact' onSubmit={onSubmit}>
        <h2 className='title'>{textIgnoreCase(requestTypeTitle + ' REQUEST')}</h2>
        <p>
          {textIgnoreCase('Our Data are downloadable with a SpecifierPortal account')}.&nbsp;
          <Link to='/partner'>
            <b>{textIgnoreCase('Log in')} / {textIgnoreCase('Sign Up')}</b>
          </Link>
          <br />
          <br />
          {textIgnoreCase('Please fill in the request form below and a team member will be in touch as soon as possible.')}
        </p>
        <FormGroup>
          <Input type="text" name="name" id="name" placeholder={textIgnoreCase('Name') + ' *'} required={true} defaultValue={currentUser?.displayName} />
        </FormGroup>
        <Row>
          <Col lg={6} >
            <FormGroup>
              <Input type="email" name="email" id="email" placeholder={textIgnoreCase('Your email address') + ' *'} required={true} defaultValue={currentUser?.email} />
            </FormGroup>
          </Col>
          <Col lg={6} className="pl-lg-0">
            <FormGroup>
              <Input type="text" name="phone" id="phone" placeholder={textIgnoreCase('Phone number') + ' *'} required={true} defaultValue={currentUser?.phone} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='company-col' lg={6}>
            <FormGroup>
              <Input type="text" name="company" id="company" placeholder={textIgnoreCase('Company') + ' *'} required={true} defaultValue={currentUser?.company} />
            </FormGroup>
          </Col>
          <Col className='Job-title-col ' lg={6}>
            <FormGroup>
              <Input type="text" name="jobTitle" id="jobTitle" placeholder={textIgnoreCase('Job title') + ' *'} required={true} defaultValue={currentUser?.jobTitle} />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className='Project-reference-col' lg={6}>
            <FormGroup>
              <Input type="text" name="projectReference" id="projectReference" placeholder={textIgnoreCase('Project reference')} />
            </FormGroup>
          </Col>
          <Col className='Postal-code-col' lg={6}>
            <FormGroup>
              <Input type="text" name="postalCode" id="postalCode" placeholder={textIgnoreCase('Postal code')} />
            </FormGroup>
          </Col>
        </Row>
        <div className='wraper-check'>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox" />
              {textIgnoreCase('I would like to receive Bplusfuniture newsletter updates')}. <Link to={privacyPolicyPagePath}><b>{textIgnoreCase('See Privacy Policy')}</b></Link>.
            </Label>
          </FormGroup>
        </div>
        <Button type="submit" disabled={loading}>
          {textIgnoreCase('Send')}
        </Button>
      </Form>
    </_MCollectionFileRequestForm>
  );
};