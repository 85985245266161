import { Resource } from 'libs/restful';

import { IUser } from './user';
import { IWishlistGroup } from './whislistGroup';
import { IWishlistItem } from './whislistItem';

export interface IWishlist {
  readonly id: string;
  readonly name: string;
  readonly wishlistItems: IWishlistItem[];
  readonly wishlistGroups: IWishlistGroup[];
  readonly createdBy?: IUser;
  readonly updatedAt?: string;
  readonly createdAt?: string;
}

export const wishlistResources = {
  find: new Resource<IWishlist, IWishlist[]>({
    method: 'GET',
    url: '/customer/wishlists'
  }),
  count: new Resource<IWishlist, number>({
    method: 'GET',
    url: '/customer/wishlists/count'
  }),
  findOne: new Resource<IWishlist, IWishlist>({
    method: 'GET',
    url: '/customer/wishlists/:id'
  }),
  sendMail: new Resource({
    method: 'POST',
    url: '/customer/wishlists/sendMail/:id'
  }),
  create: new Resource<IWishlist, IWishlist>({
    method: 'POST',
    url: '/customer/wishlists'
  }),
  update: new Resource<IWishlist, IWishlist>({
    method: 'PUT',
    url: '/customer/wishlists/:id'
  }),
  delete: new Resource<IWishlist, IWishlist>({
    method: 'DELETE',
    url: '/customer/wishlists/:id'
  }),
};