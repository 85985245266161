
import { Resource } from 'libs/restful';

import { IFile } from './file';

export interface ICategory {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug: string;
  readonly priority?: number;
  readonly cover?: Partial<IFile>;
}

export const categoryResources = {
  find: new Resource<ICategory, ICategory[]>({
    method: 'GET',
    url: '/admin/categories'
  }),
  count: new Resource<ICategory, number>({
    method: 'GET',
    url: '/admin/categories/count'
  }),
  findOne: new Resource<ICategory, ICategory>({
    method: 'GET',
    url: '/admin/categories/:id'
  }),
  create: new Resource<ICategory, ICategory>({
    method: 'POST',
    url: '/admin/categories'
  }),
  update: new Resource<ICategory, ICategory>({
    method: 'PUT',
    url: '/admin/categories/:id'
  }),
  delete: new Resource<ICategory, ICategory>({
    method: 'DELETE',
    url: '/admin/categories/:id'
  }),
};
