
import { Resource } from 'libs/restful';


export interface ICustomerRequest {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly email?: string;
  readonly company: string;
  readonly jobTitle: string;
  readonly projectReference?: string;
  readonly postalCode?: string;
}

export const customerRequestResources = {
  find: new Resource<ICustomerRequest, ICustomerRequest[]>({
    method: 'GET',
    url: '/customer/customerrequests'
  }),
  count: new Resource<ICustomerRequest, number>({
    method: 'GET',
    url: '/customer/customerrequests/count'
  }),
  findOne: new Resource<ICustomerRequest, ICustomerRequest>({
    method: 'GET',
    url: '/customer/customerrequests/:id'
  }),
  create: new Resource<ICustomerRequest, ICustomerRequest>({
    method: 'POST',
    url: '/customer/customerrequests'
  }),
  update: new Resource<ICustomerRequest, ICustomerRequest>({
    method: 'PUT',
    url: '/customer/customerrequests/:id'
  }),
  delete: new Resource<ICustomerRequest, ICustomerRequest>({
    method: 'DELETE',
    url: '/customer/customerrequests/:id'
  }),
};
