/**
 * Template: page-section/section.ejs.t
 * Version: 0.0.1
 */

import { useResourceAnonymous } from 'libs/restful';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { replaceRoutePath } from '@/_shared';
import { Img } from '@/_shared/components';
import { IModel, IProject, modelResources } from '@/api';
import { _ProjectDetailProducts } from '@/pages/project-detail/views';
import { textIgnoreCase } from '@/translation';

import { projectBplusModelPagePath } from '../ProjectBplusModel.shared';

const _ProjectBplusProductPageOtherProducts = styled(_ProjectDetailProducts as any)`
  /** Styled */
  margin-bottom: 0;
  .project-products {
    border-bottom: 1px solid #C4C4C4!important;
  }
`;

interface IProjectBplusProductPageOtherProductsProps {
  readonly model: IModel;
  readonly project: IProject;
}

export const ProjectBplusProductPageOtherProducts = (props: IProjectBplusProductPageOtherProductsProps) => {
  const { project, model } = props;

  const [otherModels] = useResourceAnonymous(
    modelResources.find,
    [
      {
        type: 'query', parameter: 'productType', value: model.productType?.id ?? ''
      },
      {
        type: 'query', parameter: 'slug_ne', value: model.slug ?? ''
      }
    ]
  );

  if (!otherModels?.length) {
    return null;
  }

  const isMobile = window.innerWidth <= 991;
  const slidesToShow = isMobile ? 1 : 3;

  return (
    <_ProjectBplusProductPageOtherProducts
      id="ProjectBplusProductPageOtherProducts"
    >
      <Container>
        <div className="project-products">
          <div className="project-card-area">
            <div className="project-products-title" dangerouslySetInnerHTML={{ __html: textIgnoreCase('Related product same type') }} />
          </div>
          <div className="project-cards">
            <Slider slidesToShow={slidesToShow} slidesToScroll={slidesToShow} draggable={false} infinite={false}>
              {otherModels?.map((otherModel) => {
                return (
                  <div key={otherModel.id} className="project-card">
                    <Link to={replaceRoutePath(projectBplusModelPagePath, { projectSlug: project.slug, productTypeSlug: model.productType?.slug, modelSlug: otherModel.slug })}>
                      <Img file={otherModel.thumbnail} ratioX={1} ratioY={1} />
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </Container>
    </_ProjectBplusProductPageOtherProducts>
  );
};
