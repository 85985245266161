import classnames from 'classnames';
import { useResource } from 'libs/restful';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import {
    Button,
    Card,
    CardText,
    CardTitle,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { IWishlistGroup, wishlistResources } from '@/api';

import {
    WishlistDetail,
    WishlistDetailHeader,
    WishlistGroups,
    WishlistMoodboard
} from './views';

const _WishlistDetailPage = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
`;

interface IWishlistDetailPageProps {
}

export const WishlistDetailPage = () => {
  const [activeTab, setActiveTab] = useState('products');

  const { id } = useParams<{ id: string }>();
  const [selectedWishlistGroup, setSelectedWishlistGroup] = React.useState<IWishlistGroup | null | undefined>(undefined);

  const [wishlist, loading] = useResource(wishlistResources.findOne, [{ type: 'path', parameter: 'id', value: id }]);

  if (loading) {
    return <PageLoading />;
  }

  if (!wishlist) {
    return null;
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <_WishlistDetailPage>
      <Container>
        <WishlistDetailHeader wishlist={wishlist} />
        <Nav tabs className="mb-5">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'products' })}
              onClick={() => { toggle('products'); }}
            >
              Products
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'moodboard' })}
              onClick={() => { toggle('moodboard'); }}
            >
              Moodboard
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="products">
            <Row>
              <Col xs={12} md={4} lg={3}>
                <WishlistGroups
                  wishlist={wishlist}
                  onGroupSelected={(group) => setSelectedWishlistGroup(group)}
                />
              </Col>
              <Col xs={12} md={8} lg={9}>
                <WishlistDetail
                  wishlist={wishlist}
                  selectedWishlistGroup={selectedWishlistGroup}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="moodboard">
            <WishlistMoodboard wishlist={wishlist} />
          </TabPane>
        </TabContent>
      </Container>
    </_WishlistDetailPage>
  );
};