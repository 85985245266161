import { useResource, useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { modelResources, projectResources } from '@/api';
import { DesignPageTool } from '@/pages/design/views';

import {
    ProjectProductComments,
    ProjectProductIllustrationPhotos,
    ProjectProductPageOtherProducts,
    ProjectProductProcessPhotos
} from './views';

const _ProjectProductPage = styled.div`
  #designPageTool {
    margin-bottom: 60px;
    border-bottom: 0.5px solid #C4C4C4;
  }
`;

interface IProjectProductPageProps {

}

export const ProjectProductPage = (props: React.PropsWithChildren<IProjectProductPageProps>) => {
  const params = useParams();

  const [projects] = useResource(projectResources.find, [{ type: 'query', parameter: 'slug', value: params['projectSlug'] }]);
  const [models] = useResourceAnonymous(modelResources.find, [{ type: 'query', parameter: 'slug', value: params['modelSlug'] }]);

  if (!projects || !models) {
    return <PageLoading />;
  }

  const project = projects[0];
  const model = models[0];

  return (
    <_ProjectProductPage>
      <DesignPageTool
        key={params['modelSlug']}
        model={model}
      />
      <ProjectProductComments model={model} project={project} />
      <ProjectProductProcessPhotos model={model} project={project} />
      {
        model?.illustrationsPhotos?.length && (
          <ProjectProductIllustrationPhotos model={model} project={project}  />
        )
      }
      <ProjectProductPageOtherProducts model={model} project={project} />
    </_ProjectProductPage>
  );
};