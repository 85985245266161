import { replaceRoutePath } from '@/_shared';
import { IProduct } from '@/api';

import { MCollectionPath } from '../m-collection/MCollectionPage.shared';

export const MCollectionProductPagePath = MCollectionPath + '/:productSlug';

export const getMCollectionProductUrl = (product: Partial<IProduct>) => {
  const productURL = new URL(location.href);
  productURL.pathname = replaceRoutePath(MCollectionProductPagePath, { productSlug: product.slug });
  return productURL.pathname + productURL.search;
};