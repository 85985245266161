import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import styled from 'styled-components';

import { Img } from '@/_shared/components';
import { IFile } from '@/api';

const _OutdoorSlider = styled.div`
.slick-slide {
    padding-right: 15px;
    &:last-child {
    padding-right: 0;
    }

    .wrapper-image-slide {
      img {
        height: 100%;
        max-height: 450px;
      }
    }
  }
  
  .container-slide {
    height: 100%;
    max-height: 450px;
    position: relative;
    padding:0;

    .prev-slide,.next-slide {
      position: absolute;
      color: white;
      z-index: 10;
      font-size: 36px;
      top: 50%;
      cursor: pointer;
    }
    .prev-slide {
      left:20px;
    }
    .next-slide  {
      right: 20px;
    }
  }
  @media (max-width: 991px) {
    .slick-slide {
      padding-right: 8px;
      .wrapper-image-slide {
        .img-wrapper {
          min-height: 300px;
          img {
            height: 100%;
            max-height: 450px;
          }
        }
      }
    }

    .container-slide {
        .prev-slide {
          left:10px;
        }
        .next-slide  {
          right: 10px;
        }
      }
  }
`;

interface IOutdoorSliderProps {
  readonly photosSilder?: Partial<IFile>[];
}

export const OutdoorSlider = (props: React.PropsWithChildren<IOutdoorSliderProps>) => {
  const { photosSilder } = props;
  const [slideIndex, setSlideIndex] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const slideRef = useRef<any>();
  const isDesktop = window.innerWidth > 991;
  const slidesToShow = isDesktop ? 1.5 : 1.5;
  const beforeChange = (prev, next) => {
    setSlideIndex(Math.ceil(next));
  };

  return (
    <_OutdoorSlider>
      <Container className='container-slide'>
        <Slider
          ref={slideRef}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          infinite={false}
          beforeChange={beforeChange}

        >
          {photosSilder?.map((attachment, i) => {
            return (
              <div
                key={attachment.id}
                className="wrapper-image-slide"
                style={{
                  marginRight: '16px'
                }}
              >
                <Img file={attachment} ratioX={1} ratioY={1} />
              </div>
            );
          })}
        </Slider>
        {slideIndex !== 0 && <div className='prev-slide'
          onClick={() => {
            slideRef?.current?.slickPrev();
          }}
        >←</div>}
        {slideIndex !== (photosSilder?.length || 0) - 1 &&
          <div className='next-slide'
            onClick={() => {
              slideRef?.current?.slickNext();
            }}
          >→</div>
        }
      </Container>
    </_OutdoorSlider>
  );
};