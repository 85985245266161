
import { Resource } from 'libs/restful';

import { IFeatureCategory } from './featureCategory';
import { IFeatureType } from './featureType';
import { IFile } from './file';
import { IGroupFeatureProduct } from './groupFeatureProduct';

export interface IFeature {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly name_en?: string;
  readonly slug: string;
  readonly description: string;
  readonly description_en?: string;
  readonly thumbnail?: Partial<IFile>;
  readonly cover?: Partial<IFile>;
  readonly published?: boolean;
  readonly featureProducts?: Partial<IGroupFeatureProduct>[];
  readonly content?: string;
  readonly content_en?: string;
  readonly featureCategory: IFeatureCategory;
  readonly featureType: IFeatureType;
  readonly photos: IFile[];
  readonly coverText?: string;
}

export const featureResources = {
  find: new Resource<IFeature, IFeature[]>({
    method: 'GET',
    url: '/admin/features'
  }),
  count: new Resource<IFeature, number>({
    method: 'GET',
    url: '/admin/features/count'
  }),
  findOne: new Resource<IFeature, IFeature>({
    method: 'GET',
    url: '/admin/features/:id'
  }),
  create: new Resource<IFeature, IFeature>({
    method: 'POST',
    url: '/admin/features'
  }),
  update: new Resource<IFeature, IFeature>({
    method: 'PUT',
    url: '/admin/features/:id'
  }),
  delete: new Resource<IFeature, IFeature>({
    method: 'DELETE',
    url: '/admin/features/:id'
  }),
};
