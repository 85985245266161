import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { inspirationCategoryResources, inspirationResources } from '@/api';

import { InspirationFilter } from '../inspirations/views';
import {
    InspirationDetailsCover,
    InspirationDetailsInfo,
    InspirationDetailsPhotos
} from './views';
import { InspirationDetailsProducts } from './views/InspirationDetailsProducts';

const _InspirationDetailsPage = styled.div`
padding-bottom: 100px;
`;

interface IInspirationDetailsPageParams {
  readonly slug: string;
}

interface IInspirationDetailsPageProps {
  
}

export const InspirationDetailsPage = (props: React.PropsWithChildren<IInspirationDetailsPageProps>) => {
  const params = useParams<IInspirationDetailsPageParams>();

  const [inspirations] = useResourceAnonymous(inspirationResources.find, [{ type: 'query', value: params.slug, parameter: 'slug' }]);
  const [inspirationCategories] = useResourceAnonymous(inspirationCategoryResources.find);

  const inspiration = inspirations && inspirations[0];

  if (!inspiration) {
    return <PageLoading />;
  }

  return (
    <_InspirationDetailsPage>
      <InspirationDetailsCover inspiration={inspiration} />
      <InspirationFilter
        headingText={`${inspiration.inspirationCategory?.name} - ${inspiration.inspirationType.name}`}
        inspirationCategories={inspirationCategories}
        activeCategorySlug={inspiration.inspirationCategory?.slug}
      />
      <InspirationDetailsInfo inspiration={inspiration} />
      <InspirationDetailsProducts inspiration={inspiration} />
      <InspirationDetailsPhotos inspiration={inspiration}/>
    </_InspirationDetailsPage>
  );
};