import * as classNames from 'classnames';
import * as React from 'react';
import { Col, Row } from 'reactstrap';

import { Img } from '@/_shared/components';
import { IGroupsComponent, IModel } from '@/api';
import { textIgnoreCase } from '@/translation';

import { DesignToolBase } from './DesignToolBase';
import { DesignToolCollapse } from './DesignToolCollapse';
import { _DesignToolMaterials } from './DesignToolMaterials';

interface IDesignToolComponentsProps {
  readonly model?: IModel;
}

export class DesignToolComponents extends DesignToolBase<IDesignToolComponentsProps> {
  public render() {
    const { } = this.props;

    const selectableComponents = this.getGroupComponentBySelected();
    if (selectableComponents?.length === 1) {
      return null;
    }

    return (
      <DesignToolCollapse label={textIgnoreCase('Chọn cấu kiện')} defaultOpen={true}>
        <_DesignToolMaterials>
          <Row>
            {
              selectableComponents?.map((component) => {
                const isSelected = this.isComponentSelected(component);
                const onComponentClick = () => {
                  const { qModules } = this.state;
                  const qModuleObject = qModules ? JSON.parse(qModules) : {};

                  const componentType = component.type!;

                  if (qModuleObject[componentType]) {
                    qModuleObject[componentType].component = { id: component.id };
                  } else {
                    qModuleObject[componentType] = {
                      component: {
                        id: component.id
                      }
                    };
                  }

                  this.setState({
                    qModules: JSON.stringify(qModuleObject),
                    selectedComponentId: component.id
                  });
                };

                return (
                  <Col key={component.id} xs="4">
                    <div
                      className={classNames('component', { selected: isSelected })}
                      onClick={onComponentClick}
                      role="button"
                    >
                      <Img file={component.thumbnail} className="w-100" />
                    </div>
                  </Col>
                );
              })
            }
          </Row>
        </_DesignToolMaterials>
      </DesignToolCollapse>
    );
  }

  private readonly getGroupComponentBySelected = () => {
    const { model } = this.props;

    if (!model?.components) {
      return [];
    }

    const selectedComponent = this.getSelectedComponent();

    if (!selectedComponent) {
      return [];
    }

    return model.components.filter((component) => component.type === selectedComponent.type);
  }

  private readonly isComponentSelected = (component: Partial<IGroupsComponent>) => {
    const { selectedComponentId } = this.state;

    return component?.id === selectedComponentId;
  }
}
