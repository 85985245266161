
import { Resource } from 'libs/restful';

import { ICustomMaterialType } from './customMaterialType';
import { IFile } from './file';
import { IUser } from './user';

export interface ICustomMaterial {
  readonly id: string;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly name: string;
  readonly price?: number;
  readonly description?: string;
  readonly custommaterialtype?: Partial<ICustomMaterialType>;
  readonly texture: Partial<IFile>;
  readonly createdBy?: Partial<IUser>;
}

export const customMaterialResources = {
  find: new Resource<ICustomMaterial, ICustomMaterial[]>({
    method: 'GET',
    url: '/customer/custommaterials'
  }),
  count: new Resource<ICustomMaterial, number>({
    method: 'GET',
    url: '/customer/custommaterials/count'
  }),
  findOne: new Resource<ICustomMaterial, ICustomMaterial>({
    method: 'GET',
    url: '/customer/custommaterials/:id'
  }),
  create: new Resource<ICustomMaterial, ICustomMaterial>({
    method: 'POST',
    url: '/customer/custommaterials'
  }),
  update: new Resource<ICustomMaterial, ICustomMaterial>({
    method: 'PUT',
    url: '/customer/custommaterials/:id'
  }),
  delete: new Resource<ICustomMaterial, ICustomMaterial>({
    method: 'DELETE',
    url: '/customer/custommaterials/:id'
  }),
};
