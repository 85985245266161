import { useResourceAnonymous } from 'libs/restful';
import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { PageLoading } from '@/_shared/components';
import { productResources } from '@/api';

import {
    MCollectionPerspective,
    MCollectionProductDetail,
    MCollectionReallifePhotos
} from './views';

const _MCollectionProductPage = styled.div`
  #MCollectionReallifePhotosContainer {
    padding: 0;
  }
`;

interface IMCollectionProductPageParams {
  productSlug: string;
}

export const MCollectionProductPage = () => {
  const { productSlug } = useParams<IMCollectionProductPageParams>();

  const [products] = useResourceAnonymous(productResources.find, [{ type: 'query', parameter: 'slug', value: productSlug }]);

  if (!products) {
    return <PageLoading />;
  }

  const product = products[0];

  return (
    <_MCollectionProductPage>
      <MCollectionProductDetail product={product} />
      {
        !!product.perspectives?.length && (
          <MCollectionPerspective perspectives={product.perspectives} />
        )
      }
      {!!product.ReallifePhotos?.length && (
        <MCollectionReallifePhotos
          product={product}
          photos={product.ReallifePhotos}
        />
      )}
    </_MCollectionProductPage>
  );
};